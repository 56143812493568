<template>
  <section v-if="this.isUserLogged" class="mainContain-info">
    <article class="infoDashboard">
      <!-- BALANCE GENERAL -->
      <div class="salesGeneral">
        <!-- RESUMEN GENERAL -->
        <ul class="salesGeneral-containResum">
          <!-- TOTAL -->
          <li>
            <p>Total Vendido</p>
            <h4>
              <currency-style
                :valor="$filters.currency(data.totalSalesAmount)"
              />
            </h4>
            <span
              v-if="calendarFilterIsNotUsed"
              :class="data.totalSalesVariation < 0 ? 'negative' : 'positive'"
              >{{ data.totalSalesVariation }}%</span
            >
          </li>

          <!-- TRX. PROM -->
          <!-- <li class="tooltip"> -->
          <li>
            <p>Trx. prom.</p>
            <!-- <span class="tooltiptext">{{data.averageTooltipMessage}}</span> -->
            <h4>
              <currency-style
                :valor="$filters.currency(data.averagePerTransaction)"
              />
            </h4>
            <span
              v-if="calendarFilterIsNotUsed"
              :class="
                data.averagePerTransactionVariation < 0
                  ? 'negative'
                  : 'positive'
              "
              >{{ data.averagePerTransactionVariation }}%</span
            >
          </li>

          <!-- VENTAS -->
          <li>
            <p>Ventas</p>
            <h4>{{ data.quantitySaleTransactions }}</h4>
            <span
              v-if="calendarFilterIsNotUsed"
              :class="
                data.quantitySaleTransactionsVariation < 0
                  ? 'negative'
                  : 'positive'
              "
              >{{ data.quantitySaleTransactionsVariation }}%</span
            >
          </li>

          <!-- NOTAS DE CREDITO -->
          <li>
            <p>Notas de crédito</p>
            <h4>{{ data.quantityCreditNoteTransactions }}</h4>
            <span
              v-if="calendarFilterIsNotUsed"
              :class="
                data.quantityCreditNoteTransactionsVariation < 0
                  ? 'negative'
                  : 'positive'
              "
              >{{ data.quantityCreditNoteTransactionsVariation }}%</span
            >
          </li>

          <!-- COMANDAS -->
          <li>
            <p>Comandas</p>
            <h4>{{ data.quantityOrderTransactions }}</h4>
            <span
              v-if="calendarFilterIsNotUsed"
              :class="
                data.quantityOrderTransactionsVariation < 0
                  ? 'negative'
                  : 'positive'
              "
              >{{ data.quantityOrderTransactionsVariation }}%</span
            >
          </li>

          <!-- ANULACIONES -->
          <li>
            <p>Anulaciones</p>
            <h4>{{ data.quantityVoidTransactions }}</h4>
            <span
              v-if="calendarFilterIsNotUsed"
              :class="
                data.quantityVoidTransactionsVariation < 0
                  ? 'negative'
                  : 'positive'
              "
              >{{ data.quantityVoidTransactionsVariation }}%</span
            >
          </li>
        </ul>

        <!-- GRAFICA -->
        <div id="salesBarras">
          <div
            v-if="isShowBarCharByDay"
            :class="{ errorLoading: chartData.length <= 1 }"
          >
            <chart
              v-if="
                (data.salesPerDay && data.salesPerDay.length > 1) || !loaded
              "
              theme="ovilia-green"
              :option="optionBar"
              ref="area"
              auto-resize
            ></chart>
            <empty-element
              v-else-if="
                data.salesPerDay && data.salesPerDay.length === 0 && loaded
              "
            />
          </div>
          <div v-else :class="{ errorLoading: chartData.length <= 1 }">
            <chart
              v-if="
                (data.salesPerHour && data.salesPerHour.length > 1) || !loaded
              "
              theme="ovilia-green"
              :option="optionBar"
              ref="area"
              auto-resize
            ></chart>
            <empty-element
              v-else-if="
                data.salesPerHour && data.salesPerHour.length === 0 && loaded
              "
            />
          </div>

          <div class="switch">
            <label class="switchTitle">
              <input
                v-model="isShowBarCharByDay"
                @change="avoidRectangleAtTheEnd"
                type="checkbox"
              />
              <span class="lever"></span>
              <a class="switchLabelExtended">Vista por Hora/Día</a>
            </label>
          </div>
        </div>
      </div>

      <!-- VENTAS POR APLICACION -->
      <div class="salesDash">
        <!-- TITULO -->
        <div class="titleDash">
          <h3>Ventas por Origen</h3>
          <div class="line"></div>
        </div>

        <!-- GRAFICA -->
        <div
          v-show="(data.salesPerApp && data.salesPerApp.length > 0) || !loaded"
          id="salesApp"
        >
          <chart
            theme="ovilia-green"
            :option="optionPieOne"
            ref="pie1"
            autoresize
          ></chart>
        </div>
        <empty-element
          v-show="data.salesPerApp && data.salesPerApp.length === 0 && loaded"
        />
      </div>

      <!-- VENTAS POR VENDEDOR -->
      <div class="salesDash">
        <!-- TITULO -->
        <div class="titleDash">
          <h3>Ventas por Vendedor</h3>
          <div class="line"></div>
        </div>

        <!-- GRAFICA -->
        <div
          v-show="
            (data.salesPerUser && data.salesPerUser.length > 0) || !loaded
          "
          id="salesVendor"
        >
          <chart
            theme="ovilia-green"
            :option="optionPieTwo"
            ref="pie2"
            autoresize
          ></chart>
        </div>
        <empty-element
          v-show="data.salesPerUser && data.salesPerUser.length === 0 && loaded"
        />
      </div>

      <!-- VENTAS POR MEDIO DE PAGO -->
      <div class="salesDash">
        <!-- TITULO -->
        <div class="titleDash">
          <h3>Ventas por medio de pago</h3>
          <div class="line"></div>
        </div>

        <!-- RESUMEN  V2-->
        <div
          :class="{
            errorLoading:
              data.salesPerPaymentType && data.salesPerPaymentType.length < 1,
          }"
        >
          <!-- RESUMEN GENERAL -->
          <ul
            v-if="
              data.salesPerPaymentType && data.salesPerPaymentType.length > 0
            "
            class="resum"
          >
            <li
              v-for="payment in data.salesPerPaymentType"
              :key="payment.paymentType"
            >
              <p>
                {{ $filters.payment(payment.paymentType + 1, "text") }}
              </p>
              <h4>
                <currency-style :valor="$filters.currency(payment.amount)" />
              </h4>
            </li>
          </ul>
          <empty-element
            v-else-if="
              data.salesPerPaymentType &&
              data.salesPerPaymentType.length === 0 &&
              loaded
            "
          />
        </div>
      </div>

      <!-- VENTAS POR PUNTO -->
      <div class="salesDash">
        <!-- TITULO -->
        <div class="titleDash">
          <h3>Ventas por punto de venta</h3>
          <div class="line"></div>
        </div>

        <!-- RESUMEN -->
        <ul class="resum resumPDV">
          <merchant-row-v2
            :pdv="item"
            v-for="(item, index) in data.totalsByMerchant"
            :key="index"
          />
        </ul>
      </div>

      <!-- VENTAS POR PDV -->
      <div class="salesDash merchants">
        <!-- TITULO -->
        <div class="titleDash">
          <h3>Resumen de Ventas</h3>
          <div class="line"></div>
        </div>

        <!-- GRAFICA -->
        <div
          v-show="
            (data.totalsByMerchant && data.totalsByMerchant.length > 0) ||
            !loaded
          "
          id="salesPDV"
        >
          <chart
            theme="ovilia-green"
            :option="optionPieThree"
            ref="pie3"
            autoresize
          />
        </div>
        <empty-element
          v-show="
            data.totalsByMerchant &&
            data.totalsByMerchant.length === 0 &&
            loaded
          "
        />
      </div>
    </article>

    <help-center />
  </section>
</template>
<script>
import axios from "axios";
import moment from "moment";
import mixCommons from "../mixins/mixCommons";
import authCommons from "../mixins/authCommons";
import mixModalsCommons from "../mixins/mixModals";
import rolesCommons from "../mixins/rolesCommons";
import computeds from "../mixins/menuOptions";

import MerchantRowV2 from "../components/MerchantRowV2";
import EmptyElement from "../components/EmptyElement";
import CurrencyStyle from "../components/CurrencyStyle";
import HelpCenter from "../components/HelpCenter";
import { parseToQuery } from "../utils/utils";

/* CHART ELEMENTS */

import VChart from "vue-echarts";
import { initialBar, dataBar } from "../fixtures/charts/bar"; //dataBar
import { initialPie, dataPie } from "../fixtures/charts/pie"; //dataPie

import { use, registerTheme } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, PieChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import theme from "../fixtures/theme.json";

export default {
  mixins: [authCommons, mixCommons, computeds, rolesCommons, mixModalsCommons],
  setup() {
    use([BarChart, PieChart, CanvasRenderer, TooltipComponent, GridComponent]);
    registerTheme("ovilia-green", theme);
  },
  data() {
    return {
      data: {},
      optionBar: initialBar,
      optionPieOne: initialPie,
      optionPieTwo: initialPie,
      optionPieThree: initialPie,
      isShowBarCharByDay: false,
      loaded: false,
      firstEntry: true,
      chartReady: false,
      google: null,
      chartData: [],
      salesApp: [],
      salesUser: [],
      chartOptions: {
        title: "Ventas por horas",
        orientation: "horizontal",
        legend: { position: "none" },
        hAxis: {
          title: "Horas",
        },
        vAxis: {
          title: "Monto",
          minValue: 0,
        },
      },
      DonutchartOptions: {
        pieSliceText: "none",
        pieHole: 0.6,
      },
      params: {},
      isUserLogged: false,
    };
  },
  watch: {
    tab() {
      this.params["Period"] = this.tab.param;
      this.$store.initLoading(this);
      if (this.firstEntry === true) {
        this.firstEntry = false;
      } else if (this.tab.id !== 6) {
        this.getData();
      }
      this.$store.finishLoading(this);
    },
    dateCalendar() {
      if (this.dateCalendar.indexOf("Seleccione") < 0) {
        this.params["Period"] = this.tab.param;
        this.$store.initLoading(this);
        this.getData();
      }
    },
    selectedPdvs() {
      if (this.firstEntry === true) {
        this.firstEntry = false;
      } else {
        let ids = this.selectedPdvs.map((item) => {
          return item.shopCode;
        });

        if (ids.length > 0) {
          this.params["MerchantFilter"] = ids;
        } else {
          delete this.params["MerchantFilter"];
        }

        this.getData();
      }
    },
    isShowBarCharByDay() {
      this.loadBarChart();
    },
  },
  async mounted() {
    document.title = `${this.appTitle} - Dashboard`;
    this.$store.setHasMounted(false);
    this.isUserLogged = localStorage["loggedUser"] === "true" ? true : false;
    if (this.isUserLoggedIn() === false) {
      axios
        .get("/api/auth/logout") /// OK
        .then(() => {
          this.$store.SET_USER(null);
          this.resetUser();
          this.setAutomaticLogin(false);
          location.href = "/login";
        });
    } else if (this.hasToShowThisOption(2) === false) {
      this.handleUnauthorizedAccess();
    } else {
      if (await this.checkSessionInBackendAsync()) {
        this.onMounted();
      }
    }
  },
  computed: {
    calendarFilterIsNotUsed() {
      return (
        this.params["Period"] !== undefined &&
        this.params["Period"] !== null &&
        !this.params["Period"].startsWith("ConfigurablePeriod")
      );
    },
  },
  methods: {
    async onMounted() {
      this.$store.updateTopMenu(2);
      this.$store.updateSideMenu(0);

      if (this.getPeriodSelectionFromLocalStorage() !== null) {
        await this.$store.updateTabSelected(
          this.getPeriodSelectionFromLocalStorage()
        );
      } else {
        await this.$store.initTabs();
      }
      this.$store.hideElements({});
      this.toggleMerchantSelectorByLabel("showMerchantSelectionFilter");
      this.$store.CALL_MERCHANTS();
      this.$store.updateCalendarType("range");
      this.$store.setHasMounted(true);
    },

    getData() {
      if (!this.$store.hasMounted) return;
      this.loaded = false;
      this.params["Period"] = this.tab.param;
      axios
        .get(`/api/dashboardV2/?${parseToQuery(this.params)}`)
        .then((res) => {
          this.loaded = true;
          if (res.data.responseCode != 0) {
            this.showErrors(res);
          } else {
            this.data = res.data;
            this.showData();
          }
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          this.showErrors(err.response);
        });
    },
    showData() {
      let apps = this.getObject();
      if (this.data.salesPerApp.length > 0) {
        apps.series[0].data = [];
        apps.legend.data = [];
        this.data.salesPerApp.forEach(({ appName, amount }) => {
          apps.legend = {
            icon: "circle",
            orient: "vertical",
            x: "right",
            y: "center",
          };

          apps.series[0].data.push({
            name: appName,
            value: amount,
          });
        });

        apps.tooltip = this.optionPieOne.tooltip;

        this.$nextTick(() => {
          this.optionPieOne = apps;
          console.log(this.optionPieTwo, "ONE");
        });
      } else {
        this.salesApp = [];
      }

      let sales = this.getObject();
      //sales = dataPie;
      console.log(dataPie);
      console.log(this.data.salesPerUser.length, "PORUSER");
      if (this.data.salesPerUser.length > 0) {
        sales.series[0].data = [];
        sales.legend.data = [];
        this.data.salesPerUser.forEach(({ user, amount }) => {
          sales.legend = {
            icon: "circle",
            orient: "vertical",
            x: "right",
            y: "center",
          };
          sales.series[0].data.push({
            name: user,
            value: amount,
          });
        });

        sales.tooltip = this.optionPieTwo.tooltip;

        this.$nextTick(() => {
          this.optionPieTwo = sales;
        });
      } else {
        this.salesUser = null;
      }

      let pdvs = this.getObject();
      pdvs.legend = {
        icon: "circle",
        orient: "horizontal",
        x: "right",
        y: "center",
      };
      if (this.data.totalsByMerchant.length > 0) {
        pdvs.series[0].data = [];
        pdvs.legend.data = [];
        this.data.totalsByMerchant.forEach(
          ({ merchantName, totalSalesAmount }) => {
            pdvs.legend = {
              icon: "circle",
              orient: "vertical",
              x: "center",
              y: "bottom",
            };
            pdvs.series[0].data.push({
              name: merchantName,
              value: totalSalesAmount,
            });
          }
        );

        pdvs.tooltip = this.optionPieThree.tooltip;

        this.$nextTick(() => {
          this.optionPieThree = pdvs;
          console.log(this.optionPieThree, "three");
        });
      } else {
        this.salesUser = null;
      }

      this.loadBarChart();
    },
    loadBarChart() {
      console.log("inician graficas");
      if (this.isShowBarCharByDay) {
        this.loadBarCharByDay();
      } else {
        this.loadBarCharByHour();
      }
    },
    loadBarCharByDay() {
      this.optionBar = initialBar;
      console.log("graf data day", this.data.salesPerDay);
      if (this.data.salesPerDay.length > 0) {
        dataBar.xAxis.data = [];
        dataBar.series[0].data = [];
        this.data.salesPerDay.forEach((item) => {
          dataBar.xAxis.data.push(item.dayOfWeekLabel);
          dataBar.series[0].data.push(item.amount);
        });

        dataBar.tooltip = this.optionBar.tooltip;

        this.$nextTick(() => {
          this.optionBar = dataBar;
        });
      } else {
        this.chartData = [];
      }
    },
    loadBarCharByHour() {
      this.optionBar = initialBar;
      console.log("graf data hour", this.data.salesPerHour);
      if (this.data.salesPerHour.length > 0) {
        dataBar.xAxis.data = [];
        dataBar.series[0].data = [];
        this.data.salesPerHour.forEach((item) => {
          dataBar.xAxis.data.push(moment(item.date).format("H:mm"));
          dataBar.series[0].data.push(item.amount);
        });

        dataBar.tooltip = this.optionBar.tooltip;
        dataBar.yAxis = this.optionBar.yAxis;

        this.$nextTick(() => {
          this.optionBar = dataBar;
        });
      } else {
        this.chartData = [];
      }
    },
    getObject() {
      return {
        tooltip: {
          trigger: "item",
        },
        legend: {
          data: [],
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "65%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      };
    },
  },
  components: {
    chart: VChart,
    EmptyElement,
    CurrencyStyle,
    MerchantRowV2,
    HelpCenter,
  },
};
</script>
<style lang="scss">
body {
  .headerOptions {
    .tabs {
      li {
        a {
          width: auto;
        }
      }
    }

    .calendar-title {
      padding-top: 10px;

      figure {
        margin: 0;
      }
    }
  }

  .echarts {
    width: 100%;
    height: 380px;
  }

  .salesDash:not(.merchants) .echarts {
    height: 200px;
  }

  .mainContain-info {
    background: #f5f5f5;
  }

  figure.iconHistory span.yellow.icon-Bistro-_Check {
    background: transparent !important;
  }

  .mainEvents-history {
    li {
      .items {
        h5 {
          font-family: "Roboto", sans-serif;
        }
      }
    }
  }

  .mainContain-info .infoDashboard .salesDash .resumPDV li .titlePDV h5 {
    font-family: "Roboto", sans-serif;
  }

  .filterPDV-dropdown li a {
    display: flex;
  }
}
</style>

<template>
	<li class="header">
		<figure class="iconHistory">

		</figure>

		<!-- FECHA -->
		<div class="header">
			<p>FECHA</p>
		</div>

		<!-- OPERACION -->
		<div class="header">
			<p>TIPO EVENTO</p>

		</div>

		<!-- DESCRIPCION -->
		<div class="header">
			<p>DESCRIPCIÓN</p>
		</div>
		<!-- DESCRIPCION -->
		<div class="header">
			<p>USUARIO</p>
		</div>

		<!-- <div class="items">
			<p v-if="data.shopCode">Cod Comercio: {{ data.shopCode }}</p>


		</div> -->


	</li>
</template>
<script>
	export default {
		props: {
			modal: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {

			}
		},
		components: {
			
		}
	}
</script>

<script>
export default {
  computed: {
    isTesterUser() {
      // HZ Esto permite poder pilotear con algunos clientes el modulo de Stock
      let result = false;
      if (
        this.$store.authUser.roles !== undefined &&
        this.$store.authUser.roles !== null
      ) {
        this.$store.authUser.roles.forEach((item) => {
          if (item === "Tester") {
            result = true;
          }
        });
        return result;
      } else return this.$store.authUser.role === "Tester";
    },
  },
};
</script>

<template>
    <transition name="modal-fade">
        <div class="modal-delete-container" v-show="isOpen">
            <div class="modal-delete-overlay" @click="closeModal"></div>
            <div class="modal-delete-content">


                <div class="modal-delete-header">
                    <span>¿ Está seguro de borrar la comanda # {{ order?.id }} ?</span>
                </div>
                <hr>
                <div class="modal-delete-footer">
                    <div class="footer-buttons">
                        <ButtonBistro @click="closeModal" :text="'Cancelar'" :variant="'transparent'" />
                        <ButtonBistro @click="confirmDelete" :text="'Confirmar'" :variant="'solid-primary-blue'" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import clickCommons from '../../mixins/clickCommons.vue';
import ButtonBistro from '../ButtonBistro.vue';
export default {
    mixins: [clickCommons],
    data() {
        return {
            isOpen: this.showDeleteOrderModal
        }
    },
    watch: {
        showDeleteOrderModal(newVal) {
            this.isOpen = newVal;
        },
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        showDeleteOrderModal: {
            type: Boolean,
        },
    },
    methods: {
        openModal() {
            this.isOpen = true;
        },
        closeModal() {
            this.isOpen = false;
            this.$emit('update:showDeleteOrderModal', false);

        },
        confirmDelete() {
            this.$emit('onConfirmDelete');
            this.closeModal()
        }

    },
    components:{
        ButtonBistro
    }

};
</script>

<style scoped lang="scss">
.modal-delete-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 5000;

    .modal-delete-overlay {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    .modal-delete-content {
        background-color: #f3f0f0;
        background: $lightColorBg;
        position: relative;
        border-radius: 6px;
        width: 30%;
        box-sizing: border-box;

    }

    .modal-delete-header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 10px 40px;

        >span {
            font-size: 16px;
            color: $darkColorFont;
            font-weight: 600;
        }
    }
}


.modal-delete-footer {
    display: flex;
    justify-content: end;
}

.footer-buttons {
    >button {
        margin: 5px 30px 15px 0;
        height: auto;
        padding: 8px 30px;
        font-size: 14px;
        border: 1px solid #007bff;

        &:hover {
            background-color: #0056b3 !important;
            color: white;
            border: 1px solid #0056b3;
        }
    }
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #000;
}

</style>

export function currency(amount) {
  if (typeof amount === "undefined")
    return "";

  if (amount === "")
    amount = "0";

  return parseFloat(amount).toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

<template>
  <div id="modalErrorMessageEmite" class="modal modal-block-outside custom-modal" @keydown.esc="closeModal()">
    <div class="modal-content">
      <h2 class="modal-title">Hubo un error en el proceso de facturación</h2>
      <ul v-if="errorMessages.length">
        <li v-for="error in errorMessages" :key="error" class="error-message">{{ error }}</li>
      </ul>

      <div class="btnClose center-aligned">
        <a href="#!" @click="closeModal()">
          <button class="modal-action modal-close waves-effect waves-green btn-flat accept-button">Aceptar</button>
        </a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      /*eslint-disable*/
      this.errorMessages = EmiteBillingErrorsEx;
      this.showModal = true;
    },
    closeModal() {
		}
   }
};
</script>


<style>
.custom-modal {
  padding: 20px;
  border: 2px solid #ccc;
}

.error-message {
  margin-top: 10px;
  margin-bottom: 60px;
  display: flex;
  justify-content: left;
}

.accept-button {
  cursor: pointer;
  letter-spacing: 0.1px;
  color: #fff;
  font: normal normal normal 16px/24px Rubik-Medium;
  min-width: 130px;
  width: 60px;
  transition: background-color 0.3s;
  background-color: #24a5d3;
  border-radius: 5px;
}

.accept-button:hover {
  background-color: #00a2ffef;
}

.center-aligned {
  text-align: center;
}
</style>

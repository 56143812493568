<script>
	export default {
		data () {
			return {
				mobile: false
			}
		},
		computed: {
			isMobile () {
				return this.mobile
			}
		},
		beforeUnmount () {
			window.removeEventListener('resize', this.getWindowWidth)
		},
		mounted () {
			this.$nextTick(function () {
				window.addEventListener('resize', this.getWindowWidth)
				// window.addEventListener('resize', this.getWindowHeight)
				// Init
				this.getWindowWidth()
				// this.getWindowHeight()
			})
		},
		methods: {
			getWindowWidth () {
				this.windowWidth = document.documentElement.clientWidth
				if (this.windowWidth <= 991) {
					this.mobile = true
				} else {
					this.mobile = false
				}
			}
		}
	}
</script>

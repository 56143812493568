<script>
import datetimeCommons from "../mixins/datetimeCommons.vue";
export default {
    mixins: [datetimeCommons],
    data() {
        return {
            ESC: "\x1B", // ESC byte in hex notation
        }
    },
    methods: {
        replaceSpecialChars(text) {
            const replacements = {
                'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
                'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
                'ñ': 'n', 'Ñ': 'N',
                'ü': 'u', 'Ü': 'U',
            };

            return text.replace(/[áéíóúÁÉÍÓÚñÑüÜ]/g, function (match) {
                return replacements[match];
            });
        },
        generatePartialTicket(products, dest) {
            const header = dest + " " + this.order.origin;

            return this.generateTicket([
                this.generateSection([
                    this.addHeader(header),
                    this.addOrderId()
                ]),
                this.addSummary(),
                this.addProducts(products)
            ]);
        },
        generateTestTicket(origin) {

            return this.generateTicket([
                this.generateSection([
                    this.addHeader('IMPRESION DE TEST')
                ]),
                this.addSummary(),
                this.generateSection([
                    this.addOrderComment('TEXTO NEGRITA'),
                    this.addOrderComment('texto negrita')
                ]),
                this.generateSection([
                this.addOrderComment(origin)
                ]),
            ]);
        },
        generateCloseOrderTicket() {
            const products = this.getProductRowsToPrint();

            return this.generateTicket([
                this.generateSection([
                    // this.addLogo(),
                    this.addOrderId()
                ]),
                this.addSummary(),
                this.generateSection([
                    this.addProducts(products),
                    this.addTotal()
                ]),
                this.addOrderComment()
            ]);
        },
        generatePaymentOrderTicket(ticketNumber) {
            const products = this.getProductRowsToPrint();

            return this.generateTicket([
                this.generateSection([
                    // this.addLogo(),
                    this.addOrderId()
                ]),
                this.addSummary(ticketNumber),
                this.generateSection([
                    this.addProducts(products),
                    this.addTotal()
                ]),
                this.addPaymentDetails(),
                this.addOrderComment(),
               
                this.generateSection([
                    this.addExtraTicketLines() 
                ])
            ]);
        },
        generateTicket(sections) {
            let content = this.ESC + "@\n";
            let isFirstLine = true;

            sections.forEach(section => {
                if (!isFirstLine) {
                    content += this.ESC + "a" + "0" + "------------------------------------------------\n";
                }
                content += section;
                isFirstLine = false;
            });

            content += this.ESC + "d" + "\x03" +
                this.ESC + "m";

            return content;
        },
        generateSection(lines) {
            let section = "";
            lines.forEach(line => {
                section += line;
            });
            return section;
        },
        addHeader(header) {
            return this.ESC + "a" + "1" + header + "\n\n";
        },
        // Traemos la configuracion del localStorage
        getSettingsLocalStorage(settingsKey) {
            const settings = localStorage.getItem(settingsKey);
            return settings ? JSON.parse(settings) : null;
        },
        addOrderId() {
            const settings = this.getSettingsLocalStorage("settingsPrint")
            if(!settings) return  this.ESC + "a" + "1" + "C" + String(this.order.id).padStart(8, '0') + "\n\n";

            else if (settings?.printOrderNumber){
                console.log("Esta en false pero entra igual ", settings.printOrderNumber)
                return this.ESC + "a" + "1" + "C" + String(this.order.id).padStart(8, '0') + "\n\n";
            }  else {
                return ""
            }
        },
        addLogo() {
            return this.ESC + "a" + "1" + "{{LOGO}}\n\n"; // TODO ADV: De donde sacamos el logo???
        },
        addSummary(ticketNumber) {
            const timestamp = Date.now();
            let section = this.addLine(this.convertTimestampToDate(timestamp), this.convertTimestampToHour(timestamp));
            if (ticketNumber) {
                section += this.addLine("Numero de ticket", ticketNumber.toString());
            }
            return section;
        },
        addTotal() {
            return this.ESC + "a" + "1" + "TOTAL $ " + this.order.amount.toFixed(2) + "\n\n";
        },
        addProducts(products) {
            return products.join('\n') + "\n\n\n";
        },
        addOrderComment(comment) {
            if (!comment) {
                return this.ESC + "a" + "1" + this.order.comment + "\n\n";
            }
            return this.ESC + "a" + "1" + comment + "\n\n";
        },
        addExtraTicketLines(){
            const settings = this.getSettingsLocalStorage("settingsPrint")
            if (settings?.ticketText) {
                return this.ESC + "a" + "1" + settings.ticketText + "\n\n";
            } else {
                return ""
            }
        },
        addPaymentDetails() {
            const payment = this.order.payments[0];
            switch (payment.paymentWay) {
                case "EFECTIVO":
                    return payment.paymentWay + "\n" +
                        "Entregado: $" + Number(payment.receivedPaidAmount).toFixed(2) + "\n" +
                        "Vuelto: $" + (Number(payment.receivedPaidAmount) - payment.amount).toFixed(2) + "\n";
                default:
                    return payment.paymentWay + "\n";
            }
        },
        getProductRowsToPrint() {
            let products = [];
            this.order.products.forEach(element => {
                const quantityAndName = this.replaceSpecialChars(element.quantity + " " + element.name);
                const totalPrice = (Number(element.originPrice) * element.quantity);
                const priceAsString = `$ ${totalPrice.toFixed(2)}`;

                products.push(this.addLine(quantityAndName, priceAsString));

                if (element.comment) {
                    products.push(this.addLine(`   ${element.comment}`));
                }
                if (element.discountByPercentage) {
                    const label = `   - DESCUENTO POR PRODUCTO (${element.discountByPercentage}%)`;
                    const discountPrice = `$ -${(totalPrice * (element.discountByPercentage / 100)).toFixed(2)}`;
                    products.push(this.addLine(label, discountPrice));
                }
                if (element.discountByAmount) {
                    const label = `   - DESCUENTO FIJO`;
                    const discountPrice = `$ -${element.discountByAmount.toFixed(2)}`;
                    products.push(this.addLine(label, discountPrice));
                }
            });
            return products;
        },
        addLine(label, price) {
            if (!price) {
                return label;
            }

            const lineLength = 48;
            let spacesNeeded = lineLength - (label.length + price.length);
            return label + " ".repeat(spacesNeeded) + price;
        }
    }
}
</script>
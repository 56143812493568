<template>
  <div class="main-body">
    <header class="main-header">
      <header-simple />
      <header-options />
    </header>

    <div class="body-container">
      <drawer />
      <slot />
    </div>

    <modal-calendar title="Seleccione fecha" />
  </div>
</template>

<script>
import HeaderSimple from "../components/HeaderSimple.vue";
import HeaderOptions from "../components/HeaderOptions.vue";
import ModalCalendar from "../components/ModalCalendar";
import Drawer from "../components/MenuDashboard.vue";
import mixResize from "../mixins/mixResize";


export default {
  mixins: [mixResize],

  computed: {
    loading() {
      return this.$store.getLoading;
    },
  },
  mounted() { },

  components: {
    HeaderSimple,
    HeaderOptions,
    ModalCalendar,
    Drawer
  },
};
</script>

<style scoped>
/* General body settings */
.main-body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header styles */
header {
  padding: 0px 0px;
}

.main-header {
  padding: .7em 1.5em 0 1.5em;
}



/* Body container layout */
.body-container {
  display: flex;
  flex-grow: 1;
  /* Permite que el contenedor crezca y ocupe el espacio disponible */
  flex-direction: row;
  /* Establece que los hijos (aside y main) se dispongan en filas */
  overflow: hidden;
}

/* Aside styles */
aside {
  background-color: #f4f4f4;
  /* Gris claro */
  width: 250px;
  /* Ancho fijo del aside */
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* Sombra ligera para el aside */
  overflow-y: auto;
  /* Añade scroll si el contenido del aside es muy grande */
  flex-shrink: 0;
  /* Evita que el aside se encoja */
}



</style>
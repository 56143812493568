<template>
	<main>
		<article class="mainFilter mainFilterMobile mainFilter-activeHover"
			:class="{ 'mainFilter-active': this.expandAndroidUsers }">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title pointer" @click="onExpandUserAndroid()">
				<div class="border-right fix-title">
					<h1><span>Usuarios del equipo</span></h1>
					<!-- <p class="clickHint" v-if="expandAndroidUsers">Clickea aquí para esconder Usuarios</p>
					<p class="clickHint" v-else>Clickea aquí para mostrar Usuarios</p> -->
				</div>
				<div class="totalFilter fix-totalFilter">
					<p>
						<strong>{{
							selectedUserList ? selectedUserList.length : 0
						}}</strong>
						<br />Usuarios
					</p>
				</div>
			</section>

			<section class="mainFilter-filter showForMobile">
				<div @click="onExpandUserAndroid()" class="arrow" :class="{ expanded: expandAndroidUsers }"></div>
			</section>
		</article>

		<!-- LISTADO DE CLIENTES -->
		<article v-show="expandAndroidUsers && selectedUserList && selectedUserList.length > 0
			" class="listItems listUsers">
			<section v-for="(user, index) in selectedUserList" :key="index"
				class="listItems-product listItems-bigCard mainProvider">
				<a @click="onOpenUsersDetails(user)" class="waves-effect waves-light cardMain">
					<figure class="nameInitial">
						<p>{{ getInitials(user.name, user.lastName) }}</p>
					</figure>

					<div class="item">
						<h2>{{ user.name }}</h2>
						<p>{{ translateRole(user.role) }}</p>
					</div>
				</a>
			</section>
		</article>

		<article class="mainFilter mainFilterMobile mainFilter-activeHover"
			:class="{ 'mainFilter-active': this.expandWebUsers }">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title pointer" @click="onExpandUserWeb()">
				<div class="border-right fix-title">
					<h1>
						<span>Usuarios {{ getCommonWebName() }}</span>
					</h1>
				</div>
				<div class="totalFilter fix-totalFilter">
					<p>
						<strong>{{
							selectedWebUserList ? selectedWebUserList.length : 0
						}}</strong>
						<br />Usuarios
					</p>
				</div>
			</section>

			<section class="mainFilter-filter showForMobile">
				<article class="menues">
					<a @click="openUserModalForCreation()" class="waves-effect waves-light">
						<div class="btn-main">
							<a class="waves-effect waves-light plus-sign">
								<p v-if="mobile">+</p>
								<p v-else>+ Usuarios</p>
							</a>
						</div>
					</a>
				</article>

				<div @click="onExpandUserWeb()" class="arrow" :class="{ expanded: expandWebUsers }"></div>
			</section>
		</article>

		<!-- LISTADO DE CLIENTES WEB-->
		<article v-show="expandWebUsers && selectedWebUserList && selectedWebUserList.length > 0
			" class="listItems listUsers">
			<section v-for="(user, index) in selectedWebUserList" :key="index"
				class="listItems-product listItems-bigCard mainProvider">
				<!-- <div @click="onOpenUsersDetails(user)">{{ user.name }}</div> -->
				<a @click="onOpenUsersDetails(user)" class="waves-effect waves-light cardMain">
					<figure class="nameInitial">
						<p>{{ getInitials(user.name, user.lastName) }}</p>
					</figure>

					<div class="item">
						<h2>{{ user.userName }}</h2>
						<p>{{ user.role }}</p>
					</div>
				</a>
			</section>
		</article>

		<modal-user-creation-edition :user="user" :mode="modalMode" :selectedShopCode="selectedShopCode"
			@onCloseModal="onCloseCreationEditionModal" @deleteUser="deleteUser" />
		<help-center />
	</main>
</template>

<script>
import axios from "axios";
import authCommons from "../mixins/authCommons";
import computeds from "../mixins/menuOptions";
import commons from "../mixins/mixCommons";
import rolesCommons from "../mixins/rolesCommons";
import HelpCenter from "../components/HelpCenter";
import tester from "../mixins/mixTester";
import { db } from "../firebase";
import { ref, get } from "firebase/database";
import { parseToQuery } from "../utils/utils";
import ModalUserCreationEdition from "../components/ModalUserCreationEditionV2";
import moment from "moment";


export default {
	mixins: [authCommons, commons, computeds, tester, rolesCommons],
	data() {
		return {
			// userList: [],
			selectedShopCode: null,
			selectedUserList: [],
			loaded: false,
			openedDropDown: null,
			modalMode: "CREATION",
			user: {},
			params: {},
			isFirstLoad: true,
			expandAndroidUsers: false,
			expandWebUsers: false,
		};
	},
	async mounted() {
		document.title = `${this.appTitle}  - Usuarios`;
		if (this.isUserLoggedIn() === false) {
			axios
				.get("/api/auth/logout") /// OK
				.then(() => {
					this.$store.SET_USER( null);
					this.resetUser();
					this.setAutomaticLogin(false);
					location.href = "/login";
				});
		} else if (this.hasToShowThisOption(6) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if(await this.checkSessionInBackendAsync()) {
				this.onMounted();
			}
		}
	},
	watch: {
		onUserListChanged() {
			this.selectedUserList = this.$store.getRefreshUserList.filter(
				(x) => x.role !== "WEB"
			);
			this.selectedWebUserList = this.$store.getRefreshUserList.filter(
				(x) => x.role === "WEB"
			);
		},
		onUserSelectedShopCodeChanged() {
			console.log(
				`onUserSelectedShopCodeChanged`,
				this.$store.getRefreshSelectedShopCode
			);
			this.selectedShopCode = this.$store.getRefreshSelectedShopCode;
		},
	},
	computed: {
		hasMultipleQuantityOfList() {
			return this.userList.length > 1;
		},
		hasToSelectFirstItem() {
			/* eslint-disable */
			if (this.productLists.length === 1 && this.isFirstLoad) {
				this.isFirstLoad = false;
				this.$store.hasToSelectFirstItem(true);
				return true;
			} else {
				this.$store.hasToSelectFirstItem(false);
				return false;
			}
			/* eslint-enable */
		},
		onUserListChanged() {
			return this.$store.getRefreshUserList;
		},
		onUserSelectedShopCodeChanged() {
			return this.$store.getRefreshSelectedShopCode;
		},
	},
	methods: {
		onMounted() {
			this.$store.updateSideMenu(-1);
			this.$store.updateTopMenu(6);
			this.toggleMerchantSelectorByLabel('showUserListSelectionFilter')
			this.$store.CALL_MERCHANTS();
			this.$store.hideElements({
				hideCalendar: true,
				hideFilters: false,
				hideTabs: true,
			});
			this.$store.getMerchantTags
			console.log(this.$store.getMerchantTags)
			
			this.getListByShops();
		},
		translateRole(role) {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				case "MX": {
					if (role === "MOZO") {
						return "MESERO";
					} else return role;
				}
				case "ES": {
					if (role === "MOZO") {
						return "CAMARERO";
					} else return role;
				}
				default: {
					return role;
				}
			}
		},
		getInitials(name, lastName) {
			let str = name + " " + lastName;
			return str
				.split(" ")
				.map((n) => n[0])
				.join("");
		},
		openUserModalForCreation() {
			this.modalMode = "CREATION";
			this.user = {};
			const modalElement = document.getElementById('modalUserCreationEdition');
			const modalInstance = this.$M.Modal.init(modalElement);
			modalInstance.open();
		},
		openUserModalForEdition(user) {
			this.$store.initLoading(this);
			this.modalMode = "EDIT";
			this.params = [];
			this.params["userName"] = user.userName;
			this.params["shopCode"] = this.selectedShopCode;
			axios
				.get(`/api/userWeb?${parseToQuery(this.params)}`)
				.then((res) => {
					this.$store.finishLoading(this);
					if (res.data.responseCode === 0) {
						this.user = res.data.user;
						
						var modalInstance = document.getElementById('modalUserCreationEdition');
						var instance = this.$M.Modal.getInstance(modalInstance);
						instance.open();
						
					} else {
						this.showErrors(res);
					}
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		deleteUser(user) {
			this.$store.initLoading(this);
			this.params = [];
			this.params["username"] = user.username;
			axios
				.delete(`/api/userWeb?${parseToQuery(this.params)}`)
				.then((res) => {
					this.$store.finishLoading(this);
					if (res.data.responseCode === 0) {
						this.showSuccess(`Usuario eliminado correctamente`);
						this.onCloseCreationEditionModal();
					} else {
						this.showError(res.data.responseMessage);
					}
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		openDropdown(index, length) {
			for (var i = 0; i < length; i++) {
				if (i === index) {
					if (this.openedDropDown !== index) {
						/*eslint-disable*/
						$(`#dropdown-user-${i}`).css("opacity", 1);
						$(`#dropdown-user-${i}`).show(200);
						this.openedDropDown = index;
					} else {
						$(`#dropdown-user-${i}`).css("opacity", 0);
						$(`#dropdown-user-${i}`).hide(200);
						this.openedDropDown = null;
					}
				} else {
					$(`#dropdown-user-${i}`).css("opacity", 0);
					$(`#dropdown-user-${i}`).hide(200);
				}
			}
		},
		getListByShops(isFirst = true, shopCodeToSelect = null) {
			const t = this;
			t.userList = [];

			const merchantTags = this.$store.getMerchantTags;

			if (merchantTags && merchantTags.length > 0) {
				merchantTags.forEach((shop, index) => {
					const userRef = ref(db, "users/" + shop.shopCode);
					get(userRef).then((snapshot) => {
						let list = [];
						const localUserList = snapshot.val() || [];
						if (localUserList.length > 0) {
							list = list.concat(localUserList);
						}

						const webUserRef = ref(db, "webusers/" + shop.shopCode);
						get(webUserRef).then((snapshot) => {
							const webUserList = snapshot.val() || [];
							if (webUserList.length > 0) {
								list = list.concat(webUserList);
							}

							t.userList.push({
								shopCode: shop.shopCode,
								listName: `${shop.merchantName} (${shop.shopCode})`,
								users: list,
							});

							this.$store.setUserList(t.userList);

							if (t.selectedShopCode === shop.shopCode) {
								this.$store.setRefreshUserList(
									t.userList.find(u => u.shopCode === shop.shopCode).users
								);
								this.$store.setRefreshSelectedShopCode(shop.shopCode);
							}

							t.loaded = true;
						});
					});
				});
				if (!t.selectedShopCode && merchantTags.length > 0) {
					t.selectedShopCode = merchantTags[0].shopCode;
					this.$store.setRefreshSelectedShopCode(t.selectedShopCode);
					this.$store.setRefreshUserList(t.userList[0]?.users || []);
				}
			} else {
				console.error('Merchant tags are not available');
			}
		}
		,
		onOpenUsersDetails(user) {
			if (user.role === "WEB") {

				this.openUserModalForEdition(user);
			} else {

				this.$router.push(
					`usersDetailsV2?uId=${user.id}&shopCode=${this.selectedShopCode}`
				);
			}
		},
		onCloseCreationEditionModal() {
			this.getListByShops(false, this.selectedShopCode);
			const modalElement = document.getElementById('modalUserCreationEdition');
			const modalInstance = this.$M.Modal.init(modalElement);
			modalInstance.close();
			document.body.style.overflow = "auto";

		},
		getDate(date) {
			return moment(date).utcOffset(0).format("L");
		},
		getTime(date) {
			return moment(date).utcOffset(0).format("LT");
		},
		onExpandUserAndroid() {
			let status = !this.expandAndroidUsers;
			this.collapseItems();
			this.expandAndroidUsers = status;
		},
		onExpandUserWeb() {
			let status = !this.expandWebUsers;
			this.collapseItems();
			this.expandWebUsers = status;
		},
		collapseItems() {
			this.expandAndroidUsers = false;
			this.expandWebUsers = false;
		},
		getCommonWebName() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				case "PROVENTAS": {
					return "ProvenTasWeb";
				}
				case "ENERGY_POS_SL": {
					return "EnergyPosWeb";
				}
				default: {
					return "Bistroweb";
				}
			}
		},
	},
	components: {
		ModalUserCreationEdition,
		HelpCenter,
	},
};
</script>

export const initialScatter = {
    textStyle: {
        fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif'
    },
    title: {
        text: "MATRIZ BCG"
    },
    legend: {
        right: 10,
        data: ["Estrella", "Vaca", "Enigma", "Perro"]
    },
    xAxis: {
        type: 'category',
        name: 'Margen de Contribución',
        nameLocation: 'middle',
        data: []
    },
    yAxis: {
        type: 'category',
        name: 'Popularidad',
        nameLocation: 'middle',
        data: []
    }
}

export const dataScatter = {
    title: {
        text: "MATRIZ BCG",
        left: 'left',  // Centra el título
    },
    legend: {
        right: 10,
        data: ["Estrella", "Vaca", "Enigma", "Perro"]
    },
    xAxis: {
        type: 'category',
        name: 'Margen de Contribución',
        nameLocation: 'middle',
        data: []
    },
    yAxis: {
        type: 'category',
        name: 'Popularidad',
        nameLocation: 'middle',
        data: []
    },
    series: [
        {
            name: "Estrella",
            data: [],
            type: "scatter",
            emphasis: {
                label: {
                    show: true,
                    formatter({ data }) {
                        return data[2];
                    },
                    position: "top"
                }
            },
            itemStyle: {
                color: "rgba(55, 200, 102, 1)",
            }
        },
        {
            name: "Vaca",
            data: [],
            type: "scatter",
            emphasis: {
                label: {
                    show: true,
                    formatter({ data }) {
                        return data[2];
                    },
                    position: "top"
                }
            },
            itemStyle: {
                color: "rgba(8, 146, 246, 1)",
            }
        },
        {
            name: "Enigma",
            data: [],
            type: "scatter",
            emphasis: {
                label: {
                    show: true,
                    formatter({ data }) {
                        return data[2];
                    },
                    position: "top"
                }
            },
            itemStyle: {
                color: "rgba(253,204, 79, 1)",
            }
        },
        {
            name: "Perro",
            data: [],
            type: "scatter",
            emphasis: {
                label: {
                    show: true,
                    formatter({ data }) {
                        return data[2];
                    },
                    position: "top"
                }
            },
            itemStyle: {
                color: "rgba(232, 69, 60, 1)",
            }
        },
        {
            name: "Cuadrícula",
            data: [],
            type: "scatter",
            emphasis: {
                label: {
                    show: true,
                    formatter({ data }) {
                        return data[2];
                    },
                    position: "top"
                }
            },
            itemStyle: {
                color: "#efefef",
            }
        }
    ]
}

<script>
export default {
  methods: {
    storeFormattedDates(formattedDates) {
      localStorage["formattedDates"] = formattedDates;
      return this.$store.updateDate(formattedDates);
    },
    storeDate(tab) {
      localStorage["periodSelection"] = JSON.stringify(tab);
      this.$store.updateTabSelected(tab);
    },
    loadFormattedDates() {
      const formattedDates = localStorage["formattedDates"];
      if (formattedDates !== undefined) {
        this.storeFormattedDates(formattedDates);
      }
      return this.$store.getDateCalendar;
    },
    loadDate() {
      const tab = localStorage["periodSelection"];
      if (tab !== undefined) {
        const tabObject = JSON.parse(tab);
        if (tabObject !== undefined) {
          this.storeDate(tabObject);
        }
      }

      return this.$store.getTabSelected;
    },
  },
};
</script>

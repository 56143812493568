<template>
  <article>
    <article
      class="mainFilter mainFilterMobile pointer"
      @click="collapseTab(index)"
    >
      <section class="mainFilter-title">
        <div>
          <h1>
            <currency-style :valor="$filters.currency(sale.totalAmount)" />
          </h1>
        </div>
        <div class="totalFilter fix-totalFilter">
          <p>
            <strong>{{ sale.hour }}</strong>
            <br />{{ sale.date }}
          </p>
        </div>
      </section>

      <section class="mainFilter-filter showForMobile">
        <div class="fix-title">
          <img
            v-if="isOpenTab(index)"
            src="../assets/img/up-arrow.webp"
            alt=""
            width="35px"
            height="35px"
          />
          <img
            v-else
            src="../assets/img/down-arrow.webp"
            alt=""
            width="35px"
            height="35px"
          />
        </div>
      </section>
    </article>
    <section v-show="isOpenTab(index)">
      <article :id="`ticket-${index}`" class="ticket">
        <div class="header">
          <img :src="data.shopConfig.logoUrl" alt="" height="60%" width="60%" />
          <h3 v-if="isBill(index)">{{ data.shopConfig.fantasyName }}</h3>
          <h5 v-if="isBill(index)">{{ data.shopConfig.fantasyAddress }}</h5>
          <h5 v-if="isBill(index)">{{ data.shopConfig.fiscalName }}</h5>
          <h5 v-if="isBill(index)">{{ data.shopConfig.fiscalAddress }}</h5>
          <h5 v-show="isBill(index)">CUIT {{ data.shopConfig.fiscalKey }}</h5>
          <h5 v-show="isBill(index)">
            Inicio de Actividades: {{ data.shopConfig.activitiesStartDate }}
          </h5>
          <h5 v-show="isBill(index)">
            Ingresos Brutos: {{ data.shopConfig.grossIncome }}
          </h5>
          <h3 v-if="isBill(index) == false">{{ sale.saleId }}</h3>
        </div>

        <hr />

        <div v-if="isBill(index) == false" class="list">
          <h5 class="left">{{ sale.date }}</h5>
          <h5 class="right">{{ sale.hour }}</h5>
        </div>
        <div v-if="isBill(index) == false" class="list">
          <h5 class="left">Número de ticket</h5>
          <h5 class="right">{{ sale.ticketNumber }}</h5>
        </div>

        <div v-if="isBill(index)" class="list">
          <h5 class="left">P.V. Número:</h5>
          <h5 class="right">{{ data.shopConfig.posId }}</h5>
        </div>
        <div v-if="isBill(index)" class="list">
          <h5 class="left">Número de Factura:</h5>
          <h5 class="right">{{ sale.fiscalInfo.billTicketNumber }}</h5>
        </div>
        <div v-if="isBill(index)" class="list">
          <h5 class="left">Fecha:</h5>
          <h5 class="right">{{ sale.dateForBill }}</h5>
        </div>

        <hr />

        <div v-if="isBill(index)" class="list">
          <h5 class="left">{{ sale.fiscalInfo.billType }}</h5>
        </div>
        <div v-if="isBill(index) && sale.fiscalInfo.customerName" class="list">
          <h5 class="left">
            Nombre Cliente: {{ sale.fiscalInfo.customerName }}
          </h5>
        </div>
        <div
          v-if="isBill(index) && sale.fiscalInfo.customerFiscalKey"
          class="list"
        >
          <h5 class="left">CUIT: {{ sale.fiscalInfo.customerFiscalKey }}</h5>
        </div>

        <hr v-if="isBill(index)" />

        <div v-for="(item, index) in sale.items" :key="index">
          <div class="list">
            <h5 class="left">
              {{ item.quantity }} {{ item.measureUnit }} {{ item.decription }}
            </h5>
            <currency-style
              class="right"
              :valor="$filters.currency(item.total)"
            />
          </div>

          <div v-for="(itemDiscount, i) in item.productDiscounts" :key="i">
            <div class="list">
              <h5 class="left">
                &nbsp;&nbsp;&nbsp;- {{ itemDiscount.description }} ({{
                  itemDiscount.percentage
                }}%)
              </h5>
              <currency-style
                class="right"
                :valor="$filters.currency(itemDiscount.amount)"
              />
            </div>
          </div>
          <div v-if="item.discountAmount" class="list">
            <h5 class="left">
              &nbsp;&nbsp;&nbsp;- DESCUENTO POR PRODUCTO ({{
                item.discountPercentage
              }}%)
            </h5>
            <currency-style
              class="right"
              :valor="$filters.currency(item.discountAmount)"
            />
          </div>

          <div
            v-for="(op, i) in item.options"
            :key="i"
            class="list list-option"
          >
            <h5 class="left">{{ op.quantity }} {{ op.decription }}</h5>
            <currency-style
              class="right"
              :valor="$filters.currency(op.total)"
            />
          </div>
        </div>

        <div v-for="discount in sale.globalDiscounts" v-bind:key="discount.id">
          <div v-if="discount !== 0" class="list">
            <h5 class="left">
              {{ discount.description }} ({{ discount.percentage }}%)
            </h5>
            <currency-style
              class="right"
              :valor="$filters.currency(discount.amount)"
            />
          </div>
        </div>

        <div v-if="sale.discountAmount !== 0" class="list">
          <h5 class="left">DESCUENTO ({{ sale.discountPercentage }}%)</h5>
          <currency-style
            class="right"
            :valor="$filters.currency(sale.discountAmount)"
          />
        </div>

        <div v-if="sale.subtotalAmount" class="list">
          <h5 class="left">SUBTOTAL</h5>
          <currency-style
            class="right"
            :valor="$filters.currency(sale.subtotalAmount)"
          />
        </div>
        <div v-if="sale.vatAmount" class="list">
          <h5 class="left">IVA</h5>
          <currency-style
            class="right"
            :valor="$filters.currency(sale.vatAmount)"
          />
        </div>
        <div v-if="sale.taxes">
          <div v-for="(op, i) in sale.taxes" :key="i" class="list">
            <h5 class="left">{{ op.taxCode }}</h5>
            <currency-style
              class="right"
              :valor="$filters.currency(op.taxAmount)"
            />
          </div>
        </div>

        <div class="total">
          <h3>TOTAL</h3>
          <currency-style
            class="amount"
            :valor="$filters.currency(sale.totalAmount)"
          />
        </div>

        <hr />

        <div class="payment-details">
          <h5>{{ sale.paymentMethod }}</h5>
          <h5 class="change">Vuelto:</h5>
          <currency-style :valor="$filters.currency(sale.changeAmount)" />
        </div>

        <hr />

        <div class="footer">
          <h5>{{ sale.footer }}</h5>
        </div>

        <div v-if="isBill(index)" class="list">
          <h5 class="left">CAE:</h5>
          <h5 class="right">{{ sale.fiscalInfo.billNumber }}</h5>
        </div>
        <div v-if="isBill(index)" class="list">
          <h5 class="left">Fecha:</h5>
          <h5 class="right">{{ sale.fiscalInfo.billDate }}</h5>
        </div>
        <div v-if="isBill(index)" class="list">
          <h5 class="left">Fecha Vencimiento:</h5>
          <h5 class="right">{{ sale.fiscalInfo.expiryBillDate }}</h5>
        </div>
      </article>

      <article class="ticket-download-btn-container">
        <div @click="downloadTicket(`ticket-${index}`)" class="btn-main">
          <a class="waves-effect waves-light">
            <p>Descargar</p>
          </a>
        </div>
      </article>
    </section>
  </article>
</template>
<script>
import CurrencyStyle from "../components/CurrencyStyle";
export default {
  props: {
    index: {
      type: Number,
    },
    indexOfOpenTab: {
      type: Number,
    },
    data: {
      type: Object,
    },
    sale: {
      type: Object,
    },
  },
  methods: {
    collapseTab(index) {
      this.$emit("collapseTab", index);
    },
    isOpenTab(index) {
      return index === this.indexOfOpenTab || this.data.tickets.length === 1;
    },
    isBill(index) {
      return this.data.tickets[index].fiscalInfo !== null;
    },
    downloadTicket(name) {
      this.$emit("downloadTicket", name);
    },
  },
  components: {
    CurrencyStyle,
  },
};
</script>

<template>
  <div class="spacer-bistro" :style="{
    flexWrap: wrap ? 'wrap' : 'nowrap',
    gap: `${gap}px`,
    alignItems: alignItems,
    justifyContent: justify,
  }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SpacerBistro",
  props: {
    wrap: {
      type: Boolean,
      default: true,
    },
    alignItems: {
      type: String,
      default: "center",
    },
    justify: {
      type: String,
      default: "flex-start",
    },
    gap: {
      type: Number,
      default: 8,
    },
  },
};
</script>

<style lang="scss">
.spacer-bistro {
  display: flex;
  width: 100%;
}
</style>

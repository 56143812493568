<template>
    <div class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="closeModal()">
        <div class="modal-header">
            <div class="image-title">
                <h4>¿Confirma cambios de datos fiscales?</h4>
                <p>Fácil y Simple</p>
            </div>

            <div class="btnClose">
                <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>

        <div class="modal-body">
            <form action class="formGeneral">
                <section class="formMain">
                    <div class="compare-fiscal-data">
                        <p>
                            <label>Valores Anteriores</label>

                        <ul>
                            <li v-if="previousFiscalKey !== currentFiscalKey">
                                <strong>CUIT:</strong>{{ previousFiscalKey }}</li>
                            <li v-if="previousLegalName !== currentLegalName"><strong>Razón
                                    social:</strong>{{ previousLegalName }}</li>
                            <li v-if="previousLegalAddress !== currentLegalAddress"><strong>Dirección
                                    fiscal:</strong>{{ previousLegalAddress }}</li>
                            <li v-if="previousPosId !== currentPosId"><strong>Punto de Venta:</strong>{{ previousPosId }}
                            </li>
                            <li v-if="previousVatCondition !== currentVatCondition"><strong>Condición de
                                    IVA:</strong>{{ previousVatCondition }}</li>
                            <li v-if="previousGrossIncome !== currentGrossIncome"><strong>Ingresos
                                    Brutos:</strong>{{ previousGrossIncome }}</li>
                            <li v-if="previousActivitiesStartDate !== currentActivitiesStartDate"><strong>Inicio de
                                    Actividades:</strong>{{ previousActivitiesStartDate }}</li>
                        </ul>
                        </p>
                        <p>
                            <label>Valores Nuevos</label>

                        <ul>
                            <li v-if="previousFiscalKey !== currentFiscalKey"><strong>CUIT:</strong>{{ currentFiscalKey }}
                            </li>
                            <li v-if="previousLegalName !== currentLegalName"><strong>Razón
                                    social:</strong>{{ currentLegalName }}</li>
                            <li v-if="previousLegalAddress !== currentLegalAddress"><strong>Dirección
                                    fiscal:</strong>{{ currentLegalAddress }}</li>
                            <li v-if="previousPosId !== currentPosId"><strong>Punto de Venta:</strong>{{ currentPosId }}
                            </li>
                            <li v-if="previousVatCondition !== currentVatCondition"><strong>Condición de
                                    IVA:</strong>{{ currentVatCondition }}</li>
                            <li v-if="previousGrossIncome !== currentGrossIncome"><strong>Ingresos
                                    Brutos:</strong>{{ currentGrossIncome }}</li>
                            <li v-if="previousActivitiesStartDate !== currentActivitiesStartDate"><strong>Inicio de
                                    Actividades:</strong>{{ currentActivitiesStartDate }}</li>
                        </ul>
                        </p>
                    </div>
                    <div class="compare-fiscal-data-row">
                        Luego de hacer estos cambios necesitás ir a tu equipo, hace los siguientes pasos:
                        <ul>
                            <li>1 - CONFIGURACIÓN</li>
                            <li>2 - CONFIGURACIÓN TÉCNICA</li>
                            <li>3 - PERIFÉRICOS</li>
                            <li>4 - CONSULTAR DATOS FACTURACION ELECTRONICA</li>
                        </ul>
                        <img src="@/assets/img/fiscal_data_update_in_pos.png" />
                    </div>
                    <div class="compare-fiscal-data">
                        <article class="btn-main">
                            <Spinner v-if="isLoading" />
                            <a v-else @click="onSavingMyFiscalData()">
                                <p>CONFIRMAR</p>
                            </a>
                        </article>
                    </div>
                </section>
            </form>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import commons from '../../mixins/mixCommons'
import Spinner from '../SpinnerComp'

export default {
    mixins: [commons],
    props: {
        shopCode: {
            type: String,
            default: () => {
                return ''
            }
        },
    },
    data() {
        return {
            profile: {},
            isLoading: false
        }
    },
    watch: {},
    computed: {

    },
    methods: {
        onSavingMyFiscalData() {
            const t = this
            const selectedFiscalData = this.currentValues.fiscalData
            t.$store.initLoading( t)
            this.profile.action = 'UpdateFiscalData'
            this.profile.shopCode = this.shopCode
            this.profile.fiscalData = selectedFiscalData
            this.profile.fiscalData.posId = parseInt(selectedFiscalData.posId)
            this.isLoading = true;
            axios.post(`/api/users/userProfile`, this.profile)
                .then(res => {
                    if (res.data.responseCode === 0) {
                        this.$store.setUpdateProfileShops( res.data.shops)
                        this.$store.setUpdateOriginalProfileShops( JSON.parse(JSON.stringify(res.data.shops)))
                        t.showSuccess('Modificación realizada exitosamente')
                        this.closeModal()
                    } else {
                        t.showError(res.data.responseMessage)
                    }

                    t.$store.finishLoading( this)
                    this.isLoading = false;
                })
                .catch(err => {
                    t.$store.errorLoading( this)
                    console.log(err)
                    t.showError('Error a guardar los datos fiscales')
                    this.isLoading = false;
                })
        },
        closeModal() {
        }
    },
    components: {
        Spinner
    }
}
</script>

<template>
  <div>
  <img v-if="type == '1'" src="@assets/img/logo_header.png" :width="width" :height="height"/>
   <!-- DASHBOARD -->

  <img v-else src="@assets/img/logobistroblue.svg" :width="width" :height="height" class="logoImg"/> 
  <!-- LOGIN -->

  </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: '0'
        },
        width: {
            type: String,
            default: '45%'
        },
        height: {
            type: String,
            default: '45%'
        },
        marginTop: {
        type: String,
         default: '20%'
        },
        backgroundColor: {
      type: String,
      default: 'transparent'
        },
    }
}
</script>

<style scoped>
.logoImg{
    margin-top: 10px;
    margin-left: 10px;
}
</style>
import indexedDbRepo from "./ordersIndexedDbRepository";
import { getTotalAmountFromProduct, getTotalVatAmountFromProduct, getAmountFromProduct, getDiscountsFromProductForSync, getProductTotalDiscountAmountForSync, getProductTotalDiscountAmount } from "../products/productsService";
import axios from "axios";
import { formatDate, getAndroidId, isOlderThan } from "./../../utils/utils";
import { ASYNC_STATUS, ORDER_STATUS } from "./../../utils/orderStatuses";
import { toast } from "vue3-toastify";

const getOrderTotalAmount = (order) => {
  return order.amount;
}

const getTotalDiscountAmountOfAllProductsInTheOrder = (order) => {
  return order.products?.reduce((acc, prod) => acc + getProductTotalDiscountAmount(prod), 0) ?? 0;
}

const getOrderComment = (order) => {
  return `${order.origin} N° ${order.ticketNumber}`
}
const getOrderTotalVatAmount = (order) => {
  return parseFloat(order.vatAmount.toFixed(2));
}

const getOrder = async (shopCode, orderId) => {
  const orders = await indexedDbRepo.getOrders(shopCode);
  const order = orders.filter((x) => x.id == orderId)[0];
  return order;
};

const getOrders = async (shopCode) => {
  return await indexedDbRepo.getOrders(shopCode);
};
const getActiveOrders = async (shopCode) => {
  const allOrders = await indexedDbRepo.getOrders(shopCode);
  return allOrders.filter(order => order.status == ORDER_STATUS.ACTIVE)
};

const createOrder = async (shopCode) => {
  const order = {
    uuid: window.crypto.randomUUID(),
    origin: "Mostrador",
    status: ORDER_STATUS.ACTIVE,
    createdAt: Date.now(),
    amount: 0,
    netAmount: 0,
    vatAmount: 0,
    products: [],
    asyncStatus: ASYNC_STATUS.PENDING,
    payments: [],
    lastUpdate: Date.now(),
    ticketNumber: null,
    movementType: "COMANDA",
    BillNumber: null,
    discounts: [],
  };
  return await indexedDbRepo.insertOrder(shopCode, order);
};

const updateOrder = async (shopCode, order) => {
  if (!shopCode){
    console.error('shopCode is required')
    return;
  }
  await indexedDbRepo.updateOrder(shopCode, order);
};

const deleteOrder = async (shopCode, order) => {
  if (!shopCode){
    console.error('shopCode is required')
    return;
  }
  await indexedDbRepo.deleteOrder(shopCode, order);
};

const syncOrder = async (shopCode, pendingOrder, t) => {
  if (!navigator.onLine) {
    return false;
  }

  const androidId = getAndroidId(shopCode);
  const payload = {
    androidId: androidId,
    iMEI: "",
    Sales: [
      {
        combosDetails: [],
        dinners: 0,
        discounts: pendingOrder.discounts,
        ticketNumber: String(pendingOrder.ticketNumber),
        Origin: pendingOrder.origin,
        paymentInfo: [
          {
            change: parseFloat(
              (pendingOrder.payments[0]?.receivedPaidAmount ?? 0) -
              pendingOrder.payments[0]?.amount
            ),
            partialAmount: pendingOrder.payments[0]?.amount,
            paymentDescription: pendingOrder.payments[0]?.paymentDescription ?? "-",
            paymentWay: pendingOrder.payments[0]?.paymentWay,
            captureWay: pendingOrder.payments[0]?.captureWay,
            authCode: pendingOrder.payments[0]?.authCode,
            cardholder: pendingOrder.payments[0]?.cardHolder,
            truncatedCardNumber: pendingOrder.payments[0]?.truncatedCardNumber,
            externalReferenceId: pendingOrder.payments[0]?.externalReferenceId,
            receivedPaidAmount: parseFloat(
              pendingOrder.payments[0]?.receivedPaidAmount ?? 0
            ),
          },
        ],
        saleDetails: pendingOrder.products.map((product) => ({
          amount: getAmountFromProduct(product),
          exempt: false,
          isWeightable: product.weightable,
          nonTaxed: false,
          measureUnit: product.measureUnit,
          productCategory: product.department,
          productName: product.name,
          quantity: product.quantity,
          sku: product.sku,
          originPrice: product.originPrice,
          originIva: product.originIva,
          discountAmount: getProductTotalDiscountAmountForSync(product),
          discounts: getDiscountsFromProductForSync(product),
          syncSaleOptionList: [],
          totalAmount: getTotalAmountFromProduct(product),
          totalVatAmount: getTotalVatAmountFromProduct(product),
          vatRate: parseFloat(product.originIva),
        })),
        saleId: pendingOrder.id,
        SaleTaxes: [],
        status: "CLOSE",
        timeStamp: formatDate(),
        totalAmount: getOrderTotalAmount(pendingOrder),
        totalVatAmount: getOrderTotalVatAmount(pendingOrder),
        username: t.$store.authUser?.user?.name,
        UUID: pendingOrder.uuid,
      },
    ],
  };
  try {
    const { data } = await axios.post(`/api/pdv/SyncSales`, payload);
    if (data?.responseCode == 0) {
      return await syncCashbox(shopCode, pendingOrder, t);
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (error) {
    toast.error("Error al procesar la orden:", error);
    return false;
  }
};



const syncCashbox = async (shopCode, order, t) => {
  const androidId = getAndroidId(shopCode);
  const payload = {
    androidId: androidId,
    shopCode: shopCode,
    CashBox: [
      {
        Amount: getOrderTotalAmount(order),
        BillNumber: null,
        CashAccumAmount: 0,
        CashMovementId: order.id,
        Comments: getOrderComment(order),
        Date: formatDate(),
        ticketNumber: String(order.ticketNumber),
        MovementType: order.movementType,
        TotalAccumDiscountPerProductAmount: getTotalDiscountAmountOfAllProductsInTheOrder(order),
        SaleAuthCode: order.payments[0]?.authCode,
        SaleCaptureWay: order.payments[0]?.captureWay,
        SaleId: order.id,
        SaleOrigin: order.origin.toUpperCase(),
        SalePaymentMethod: order.payments[0]?.paymentWay,
        SaleType: order.movementType,
        sequence: 1,
        TotalAccumAmount: 0,
        TotalDiscountAmount:
          order.discounts?.reduce((acc, discount) => acc + discount, 0) ?? 0,
        TotalTaxesAmount: 0,
        TotalVatAmount: getOrderTotalVatAmount(order),
        UserName: t.$store.authUser?.user?.name,
        UUID: order.uuid,
      },
    ],
    iMEI: "",
  };
  try {
    const data = await axios.post(`/api/pdv/SyncCashbox`, payload);
    if (data?.data?.responseCode == 0) {
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    toast.error("Ocurrió un error al sincronizar la caja.");
    return false;
  }
};

// Función para sincronizar las órdenes pendientes
const syncOrders = async (shopCode, t) => {
  const orders = await indexedDbRepo.getOrders(shopCode);
  const ordersToSync = orders.filter(
    (order) =>
      order.asyncStatus == ASYNC_STATUS.PENDING &&
      order.status == ORDER_STATUS.CLOSED
  );
  for (const order of ordersToSync) {
    try {
      const isSuccess = await syncOrder(shopCode, order, t);

      if (isSuccess) {
        order.asyncStatus = ASYNC_STATUS.SYNCED;
        await updateOrder(shopCode, order);
        toast.success(`Orden ${order.id} sincronizada exitósamente`)

      }
    } catch (error) {
      toast.error(`Error al sincronizar la orden ${order.id}`)
    }
  }
  await syncDeletedOrders(shopCode)
};
const syncDeletedOrders = async (shopCode,) => {
  const orders = await indexedDbRepo.getOrders(shopCode);
  // Filtrar órdenes más antiguas de 10 días
  const ordersToDelete = orders.filter(
    (order) =>
      isOlderThan(order.createdAt, 10) &&
      order.status == ORDER_STATUS.CLOSED &&
      order.asyncStatus == ASYNC_STATUS.SYNCED
  );
  for (const order of ordersToDelete) {
    await deleteOrder(shopCode, order);
  }
};
export {
  getOrder,
  getOrders,
  getActiveOrders,
  createOrder,
  updateOrder,
  deleteOrder,
  syncOrders,
  syncDeletedOrders,
};

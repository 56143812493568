<template>
  <div id="modalNews" class="modal">
    <div class="modal-header">
      <div class="image-title">
        <h4>Novedades de Bistrosoft</h4>
      </div>
      <div class="btnClose">
        <a
          href="#!"
          @click="readyButtonLink()"
          class="modal-action modal-close waves-effect waves-green btn-flat"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <!-- BODY MODAL -->
    <div class="modal-body modalNews">
      <form action class="formGeneral">
        <article class="article articleNews-main">
          <p
            v-if="this.getMessage !== null"
            :class="{
              textAndImage: this.getImageUrl !== null,
              onlyOneContent: this.getImageUrl === null,
            }"
          >
            {{ this.getMessage }}
          </p>
          <p v-if="this.getImageUrl !== null">
            <img
              :class="{
                imageAndText: this.getMessage !== null,
                onlyOneContent: this.getMessage === null,
              }"
              :src="this.getImageUrl"
            />
          </p>
        </article>
        <article class="article articleNews">
          <!-- <div class="itemForm"> -->
          <div class="switch">
            <label class="switchTitle">
              <a class="switchLabelExtendedForNews"
                >No quiero recibir esta noticia nuevamente</a
              >
              <input v-model="dontWantToSeeThisAgain" type="checkbox" />
              <span class="lever-small"></span>
            </label>
          </div>
          <!-- </div> -->
        </article>
      </form>
    </div>

    <div v-if="this.hasToShowActionButtonLink" class="modal-footer">
      <article class="article articleNews">
        <article class="btn-main">
          <a @click="triggerActionButtonLink" type="submit">
            <p>{{ this.getActionButtonMessage }}</p>
          </a>
        </article>
      </article>
    </div>
  </div>
</template>
<script>
//import $ from 'jquery'
import axios from "axios";
//import commons from '~/mixins/commons'
import mixCommons from "../mixins/mixCommons";
export default {
  mixins: [mixCommons],
  props: {
    getData: {
      type: Function,
    },
  },
  data() {
    return {
      isLoading: false,
      dontWantToSeeThisAgain: false,
    };
  },
  computed: {
    getImageUrl() {
      if (this.$store.getNews === undefined) return null;
      else if (this.$store.getNews === null) return null;

      return this.$store.getNews.imageUrl;
    },
    getMessage() {
      if (this.$store.getNews === undefined) return null;
      else if (this.$store.getNews === null) return null;
      return this.$store.getNews.message;
    },
    getActionButtonLink() {
      if (this.$store.getNews === undefined) return null;
      else if (this.$store.getNews === null) return null;
      return this.$store.getNews.actionButtonLink;
    },
    getActionButtonMessage() {
      if (this.$store.getNews === undefined) return null;
      else if (this.$store.getNews === null) return null;
      return this.$store.getNews.actionButtonMessage;
    },
    hasToShowActionButtonLink() {
      if (this.$store.getNews === undefined) return false;
      else if (this.$store.getNews === null) return false;
      else if (this.$store.getNews.actionButtonMessage === undefined)
        return false;
      else if (this.$store.getNews.actionButtonMessage === null) return false;
      return (
        this.$store.getNews.actionButtonMessage.length > 0 &&
        this.getActionButtonLink !== null
      );
    },
  },
  methods: {
    triggerActionButtonLink() {
      window.open(
        this.getActionButtonLink,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    readyButtonLink() {
      this.$store.initLoading(this);
      axios
        .post(`/api/dashboardV2/news`, {
          newsId: this.$store.getNews.id,
          dontShowThisNewsAgain: this.dontWantToSeeThisAgain,
        }) /// OK
        .then(() => {
          this.$store.finishLoading(this);
          this.clear();
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          this.clear();
          console.error(err);
        });
    },
    clear() {
      //$('#modalNews').modal('close')
    },
  },
};
</script>

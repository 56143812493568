<template>
  <section>
    <section v-if="!isRedirected">
      <section v-if="login" class="bodyLogin">
        <figure :class="`bodyLogin-background bodyLogin-${getInstanceProductName}`">
          <figure class="logo" v-if="getInstanceProductName == 'proventas'">

          </figure>
          <figure class="logo" v-else>
            <BistroLogo />
            <CustomerServiceQr />
          </figure>
        </figure>

        <article class="bodyLogin-information">
          <!-- LOGOTIPO -->
          <LogoProVentas v-if="getInstanceProductName === 'proventas'" />
          <template v-else>
            <CountrySelector />

            <!-- <img src="~assets/img/Bistro-Logo@2x.png" alt="" /> -->

            <h3 class="welcomeMessage">Accede a tu cuenta</h3>
          </template>
          <form autocomplete="off" action="" class="formLogin">

            <!-- EMAIL -->
            <div class="input-field">
              <!-- ICONO DEL INPUT -->
              <i class="material-icons prefix">
                <span class="icon-Bistro-_iconEmail"><span class="path1"></span><span class="path2"></span></span>
              </i>

              <!-- INPUT -->
              <input autocomplete="off" v-model="user.username" v-on:keyup="keyPress" type="text" class="validate " />
              <label for="icon_prefix">Correo Electrónico</label>
            </div>

            <!-- PASSWORD -->
            <div class="input-field">
              <!-- ICONO DEL INPUT -->
              <i class="material-icons prefix">
                <span class="icon-Bistro-_iconPassword"><span class="path1"></span><span class="path2"></span><span
                    class="path3"></span></span>
              </i>

              <!-- INPUT -->
              <input autocomplete="off" v-model="user.password" v-on:keyup="keyPress" type="password" class="validate" />
              <label for="icon_prefix">Contraseña</label>
            </div>

            <!-- SUBMIT -->
            <section class="btn-main">
              <a href="#" @click="makeLogin">
                <p>
                  Iniciar Sesión
                </p>
              </a>
            </section>

            <!-- RECUPERAR CONTRASENA -->
            <section class="btnRecoverPassword">
              <a @click="login = !login">Olvidé mi Contraseña</a>
            </section>

            <!-- THIRD PARTY LOGIN PROVIDERS -->
            <label class="third-party-sign-ins">También podés conectarte con:</label>
            <div>
              <GoogleAuth :client-id="clientId" @credentialResponse="handleGoogleSignIn" />
            </div>
            <!-- <div class="third-party-login-table">
              <div :class="getFacebookButtonClass" @click="makeFacebookLogin">
                    <span class="facebook-icon"></span>
                    <span class="btn-text">Facebook</span>
              </div>
            </div> -->
            <!-- <table class="third-party-login-table">
              <tr>
                <td>
                  <div :class="getGoogleButtonClass" @click="handleGoogleSignIn">
                    <span class="google-icon"></span>
                    <span class="btn-text">Google</span>
                  </div>
                </td>
                <td>
                  <div :class="getFacebookButtonClass" @click="makeFacebookLogin">
                    <span class="facebook-icon"></span>
                    <span class="btn-text">Facebook</span>
                  </div>
                </td>
              </tr>
            </table> -->
            <CustomerServiceLink />

          </form>
        </article>
      </section>
      <section v-else class="bodyLogin">
        <!--IMAGEN REFERENCIAL-->
        <figure :class="`bodyLogin-background bodyLogin-${getInstanceProductName}`"></figure>

        <article class="bodyLogin-information">
          <!-- LOGOTIPO -->
          <figure class="logo">
            <!-- <img src="~assets/img/Bistro-Logo@2x.png" alt="" /> -->
            <Logo />
          </figure>

          <!-- ALERT -->
          <p v-if="!recover" class="formAlert">
            <strong>¡Hey!</strong> Escribí tu correo electrónico y te enviaremos
            una contraseña provisional
          </p>

          <!-- ALERT -->
          <p v-if="recover" class="formAlert">
            <strong>¡Genial!</strong> Ahora revisá tu correo electrónico. No
            olvides verificar la carpeta SPAM
          </p>

          <!-- FORM -->
          <form autocomplete="off" v-if="!recover" action="" class="formLogin">
            <!-- EMAIL -->
            <div class="input-field">
              <!-- ICONO DEL INPUT -->
              <i class="material-icons prefix">
                <span class="icon-Bistro-_iconEmail"><span class="path1"></span><span class="path2"></span></span>
              </i>

              <!-- INPUT -->
              <input autocomplete="off" v-model="RecoveryMail" type="text" class="validate" />
              <label for="icon_prefix">Correo Electrónico</label>
            </div>

            <!-- LINEA DIVISORIA -->
            <span class="lineLogin"></span>

            <!-- SUBMIT -->
            <section class="btn-main">
              <a @click="recoverMail" type="submit">
                <p>
                  Enviar
                </p>
              </a>
            </section>

            <!-- RECUPERAR CONTRASENA -->
            <section class="btnRecoverPassword">
              <a @click="login = !login">
                <p>Regresar</p>
              </a>
            </section>
          </form>
          <form autocomplete="off" v-if="recover" action="" class="formLogin">
            <!-- LINEA DIVISORIA -->
            <span class="lineLogin"></span>

            <!-- SUBMIT -->
            <section class="btn-main">
              <a @click="resetAll" type="submit">
                <p>Regresar</p>
              </a>
            </section>
          </form>
        </article>
      </section>
    </section>
    <!-- SI VINO DE LA REDIRECCION -->
    <section v-else> 
      <div class="migration-login">
        <Spinner class="migration-login-spinner"/>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import Spinner from "../components/SpinnerComp";
import BistroLogo from "../components/BistroLogo";
import mixCommons from "../mixins/mixCommons";
import rolesCommons from "../mixins/rolesCommons";
import CountrySelector from '@/components/CountrySelector'
import CustomerServiceLink from '@/components/CustomerServiceLink.vue'
import CustomerServiceQr from '@/components/CustomerServiceQr'
import LogoProVentas from '@/components/LogoProVentas.vue'
import GoogleAuth from '@/components/AuthGoogle.vue'

export default {
  mixins: [mixCommons, rolesCommons],

  data() {
    return {
      login: true,
      recover: false,
      isGoogleAuthSignedIn: false,
      clientId: "90963173342-op3vf1d0ia8uhuh6c1kjkof7ntnald08.apps.googleusercontent.com",
      user: {
        username: "",
        password: "",
      },
      RecoveryMail: "",
      isRedirected: false
    };
  },
mounted() {
    document.title = `${this.appTitle}  - Login`;
    //MIGRACION----------------------------------------------------------------
		//Se fija si la url tiene los parametros que indican un usuario derivado de la V2 a V3
		const queryParams = this.$route.query;
		if (queryParams.username && queryParams.token) {
      this.isRedirected = true;
      //ACA IRIA EL DELAY
      const decodedUsername = decodeURIComponent(atob(queryParams.username));
			const decodedToken = decodeURIComponent(atob(queryParams.token));
			this.migrationLogin(decodedUsername, decodedToken);	
		} 
  },
  methods: {
    migrationLogin(username, token){ //Esta es la funcion que se ejecuta cuando se detectaron los parametros en la url
      this.resetUser();
      this.setAutomaticLogin(false);
      this.$store.initLoading(this);
      axios
        .post("/api/auth/migration", {
          username: username,
          token: token
        })
        .then((res) => {
          if (res.data.responseCode !== 0) {
            this.$store.errorLoading(this);
            this.showError(res.data.responseMessage);
          } else {
            this.getMigrationMerchantTags(username, token, res)
          }
        })
        .catch((err) => {
          if (typeof err.response.data === "string") {
            this.$store.errorLoading(this);
            this.showError("El usuario y/o password no coinciden");
          } else {
            this.$store.errorLoading(this);
            this.showError(err.response);
          }
        });
		},
		getMigrationMerchantTags(username, token, res){
        axios
        .post("/api/auth/getMigrationMerchantTags", {
          username: username,
          token: token
        })
        .then((res2) => {
          if (res2.data.responseCode !== 0) {
            this.$store.errorLoading(this);
            this.showError(res2.data.responseMessage);
          } else {
            this.$store.finishLoading(this);
            this.$store.SET_USER(res.data);
            //this.setUserData(res.data);
            this.setMerchantTags(res2.data);
            this.setUser(res.data);
            this.setAutomaticLogin(false);
            this.redirectLoggedInUser(res);
            localStorage.setItem('isRedirectedFromV2', true);
          }
        })
        .catch((err) => {
          if (typeof err.response.data === "string") {
            this.$store.errorLoading(this);
            this.showError("El usuario y/o password no coinciden");
          } else {
            this.$store.errorLoading(this);
            this.showError(err.response);
          }
        });
      },
    keyPress(e) {
      if (e.keyCode === 13) {
        this.makeLogin();
      }
    },
    resetAll() {
      this.login = !this.login;
      this.recover = false;
      this.RecoveryMail = "";
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },

    async handleGoogleSignIn(response) {
      try {
        const idToken = response.credential;
        const payload = this.decodeJwtPayload(idToken);
        const username = payload.email || payload.name;

        this.isGoogleAuthSignedIn = true;
        this.setAutomaticLogin(false);
        this.$store.initLoading(this);

        try {
          const res = await axios.post("/api/auth/google", {
            username: username,
            id_token: idToken
          });

          if (res.data.token === null || res.data.user === null) {
            this.$store.errorLoading(this);
            this.showError(res.data.responseMessage);
            this.isGoogleAuthSignedIn = false;
          } else {
            this.getMerchantTagsFromServerFromGoogle(
              res,
              username,
              idToken);
          }
        } catch (err) {
          this.$store.errorLoading(this);
          this.showError("El usuario y/o password no coinciden");
          this.isGoogleAuthSignedIn = false;
        }
      } catch (error) {
        // on fail do something
        console.error(error);
        return null;
      }
    },

    decodeJwtPayload(token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(atob(base64));
      } catch (error) {
        console.error('Error decodificando el token:', error);
        return {};
      }
    },
    makeLogin() {
      this.resetUser();
      this.setAutomaticLogin(false);
      this.$store.initLoading(this);
      axios
        .post("/api/auth", {
          username: this.user.username,
          password: this.user.password,
        })
        .then((res) => {
          if (res.data.responseCode !== 0) {
            this.$store.errorLoading(this);
            this.showError(res.data.responseMessage);
          } else {
            this.getMerchantTagsFromServer(res);
          }
        })
        .catch((err) => {
          console.log('err', err)
          if (typeof err.response.data === "string") {
            this.$store.errorLoading(this);
            this.showError("El usuario y/o password no coinciden");
          } else {
            this.$store.errorLoading(this);
            this.showError(err.response);
          }
        });
    },
    getMerchantTagsFromServer(res) {
      axios
        .post("/api/auth/getMerchantTags", {
          username: this.user.username,
          password: this.user.password,
        })
        .then((res2) => {
          if (res2.data.responseCode !== 0) {
            this.$store.errorLoading(this);
            this.showError(res2.data.responseMessage);
          } else {
            this.$store.finishLoading(this);
            this.$store.SET_USER(res.data);
            //this.setUserData(res.data);
            this.setMerchantTags(res2.data);
            this.setUser(res.data);
            this.setAutomaticLogin(false);
            this.redirectLoggedInUser(res);
          }
        })
        .catch((err) => {
          if (typeof err.response.data === "string") {
            this.$store.errorLoading(this);
            this.showError("El usuario y/o password no coinciden");
          } else {
            this.$store.errorLoading(this);
            this.showError(err.response);
          }
        });
    },
    getMerchantTagsFromServerFromGoogle(res, username, token) {
      axios
        .post("/api/auth/getMerchantTagsFromGoogle", {
          username: username,
          id_token: token,
        })
        .then((res2) => {
          if (res2.data.responseCode !== 0) {
            this.$store.errorLoading(this);
            this.showError(res2.data.responseMessage);
          } else {
            this.$store.finishLoading(this);
            this.$store.SET_USER(res.data);
            this.setMerchantTags(res2.data);
            this.setUser(res.data);
            this.setAutomaticLogin(false);
            this.redirectLoggedInUser(res);
          }
        })
        .catch((err) => {
          if (typeof err.response.data === "string") {
            this.$store.errorLoading(this);
            this.showError("El usuario y/o password no coinciden");
          } else {
            this.$store.errorLoading(this);
            this.showError(err.response);
          }
        });
    },
    recoverMail() {
      this.$store.initLoading(this);
      axios
        .post("/api/auth/forgotten", {
          RecoveryMail: this.RecoveryMail,
        })
        .then((res) => {
          if (res.data.responseCode !== 0) {
            this.$store.errorLoading(this);
            this.showError(res.data.responseMessage);
          } else {
            this.$store.finishLoading(this);
            this.recover = true;
          }
        })
        .catch((err) => {
          if (typeof err.response.data === "string") {
            this.$store.errorLoading(this);
            this.showError("El usuario no existe");
          } else {
            this.$store.errorLoading(this);
            this.showError(err.response);
          }
        });
    },
  },
  computed: {
    getInstanceProductName() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
      case "PROVENTAS":
        return "proventas";
      case "ENERGY_POS_SL":
        return "energy-pos";
      default:
        return "bistro";
      }
    },
  },
  components: {
    BistroLogo,
    CountrySelector,
    CustomerServiceLink,
    LogoProVentas,
    CustomerServiceQr,
    GoogleAuth,
    Spinner
  },
};
</script>

<style scoped>
.migration-login{
  width: 100vw;   /* 100% del ancho de la ventana */
  height: 100vh;  /* 100% del alto de la ventana */
  display: flex;
  justify-content: center;
  align-items: center;
}
.migration-login-spinner{
  transform: scale(1.5);
}
</style>

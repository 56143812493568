<script>
import axios from "axios";
export default {
  computed: {
    getWaiterReportName() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				default:
					return "Mozos";
				case "ES":
					return "Camareros";
				case "MX":
					return "Meseros";
			}
		},
  },
  methods: {
    processReportInBackend(endpoint, params) {
      this.$store.initLoading(this);
      axios
        .get(`/api/report/${endpoint}/?${params}`)
        .then((res) => {
          if (res.data.responseCode === 0) {
            if (res.data.reportUrl) {
              var download = document.createElement("a");
              download.href = res.data.reportUrl;
              download.download = `${res.data.reportFileName}.xlsx`;
              download.click();
            } else {
              this.showSuccess(res.data.responseMessage);
            }
            this.$store.finishLoading(this);
          } else {
            this.showError(res.data.responseMessage);
            this.$store.errorLoading(this);
          }
        })
        .catch((err) => {
          this.showError(
            "Hubo un problema al procesar el detalle de transacciones"
          );
          console.error(`processReportInBackend ERROR->`, err);
          this.$store.errorLoading(this);
        });
    },
  },
};
</script>

import createIndexedDb from '../db.js';
import Dexie from 'dexie';

const getOrders = async (shopCode) => {
  const db = createIndexedDb(shopCode);
  return await db.orders.toArray();
};

const insertOrder = async (shopCode, order) => {
  const db = createIndexedDb(shopCode);
  const orderId = await db.orders.add(order);
  return { id: orderId, ...order }
};

const updateOrder = async (shopCode, order) => {
  const db = createIndexedDb(shopCode);
  const clonedOrder = Dexie.deepClone(order);
  await db.orders.put(clonedOrder);
};

const deleteOrder = async (shopCode, order) => {
  const db = createIndexedDb(shopCode);
  await db.orders.delete(order.id);
};

export default { getOrders, insertOrder, updateOrder, deleteOrder };

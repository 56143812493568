<template>
    <div class="modal modal-block-outside" :class="{'modal-integrated': isIntegrated}"  style="overflow-y: auto !important;" @keydown.esc="closeModal()">
        <!-- TITULO -->
        <div class="modal-header">
            <div class="image-title">
                <h4>Mercado Pago Point Plus/Smart</h4>
                <p>Integración medio de pago</p>
            </div>
    
            <div class="btnClose">
                <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>
        <!-- BODY -->
        <div class="modal-body">
            <form action class="formGeneral">
                <!-- Si tiene mp point plus integrado, o sea si esta en enabled -->
                <section class="formMain">
                    <div v-if="isIntegrated">
                        <div class="mp-integration-description">
                            <label class="settings-label">Tu comercio ya está integrado. Al momento de cobrar con tarjeta tenes que seleccionar la opción Mercado Pago. En caso de tener mas de un terminal vinculado a tu cuenta, debes indicar el id del terminal.</label>
                            <!-- Serial number -->
                            <p class="mp-input"><b>ID del terminal:</b></p>
                            <input
                                class="search-input"
                                type="text"
                                :value="PointPlusSerial"
                                @input="validateInput"
                                placeholder="últimos 8 dígitos del serial"
                            />
                            <p v-if="showWarning" class="mp-input-warning">Debe ingresar 8 dígitos.</p>
                            <!-- Mail -->
                            <p><b>Usuario de Mercado Pago: </b> {{ getMpUser }}</p>
                        </div>
                        <!-- BOTON ACEPTAR -->
                        <div class="mp-footer">
                            <article class="btn-main mp-eliminate-btn">
                                <Spinner v-if="isUpdateConfigLoading" />
                                <a v-else @click="deleteIntegration()">
                                    <p>ELIMINAR INTEGRACIÓN</p>
                                </a>
                            </article>
                            <article  class="btn-main modal-action" :class="{'modal-close': !hasChanged}">
                                <a @click="handleClick" :class="{'mp-btn-disabled': showWarning}">
                                    <p>{{ hasChanged ? 'GUARDAR' : 'ACEPTAR' }}</p>
                                </a>
                            </article>
                        </div>
                    </div>
                    <!-- --------------------------------------------------------------- -->
    
                    <!-- Si no muestro el cartel para la integracion de mp point plus -->
                    <div v-else>
                        <div class="mp-title-container">
                            <p>
                                Al integrar Bistrosoft con tu terminal de Mercado Pago, el monto a cobrar se envía al terminal y una vez realizado el pago, el estado de la transacción se actualiza automáticamente en Bistrosoft, evitando errores en el registro de crobos.
                            </p>
                        </div>
                        <div class="mp-information-description-container">
                            <p class="mp-information-description-title">Pasos para la integración:</p>
                            <div class="mp-information-description-steps">
                                <p>1- Presiona el botón que dice “INTEGRAR TERMINAL DE MERCADO PAGO”.</p>
                                <p>2- Se abrirá una nueva ventana para aceptar los permisos necesarios para la integración.</p>
                                <p>3- Una vez aceptados los permisos es necesario volver a iniciar sesión en el equipo de Bistrosoft.</p>
                                <p>4. Asegúrate que el dispositivo de captura MERCAGO PAGO POINT PLUS esté habilitado en el equipo entrado a <b>Configuraciones > Bistrosoft > Medios de Pago.</b></p>
                                <p>5. ¡Listo! ya puedes empezar a cobrar con Mercado Pago Point Plus.</p>
                            </div>
                        </div>
                        <p class="mp-information-footer">
                            <b>NOTA: Asegurate de iniciar sesión con la cuenta de Mercado Pago en la que vas a recibir los cobros.</b>
                        </p>
                        <!-- BOTON DE INTEGRACION -->
                        <div class="compare-fiscal-data">
                            <article class="btn-main">
                                <a> <!-- Va al computed que me arma la url dinamica para redirigirme al formulario de Modo -->
                                    <p class="btn-text" @click="startIntegration">INTEGRAR TERMINAL DE MERCADO PAGO</p>
                                </a>
                            </article>
                        </div>
                    </div>
                    <!-- -------------------------------------------------- -->
                </section>
            </form>
        </div>
    </div>
    </template>
    
    <script>
    import commons from '@/mixins/mixCommons'
    import axios from 'axios'
    import Spinner from '../SpinnerComp'
    
    export default {
        props: {
            shop: { //Es el shop que estoy analizando en el v-for
                type: Object,
                default:  () => ({}),
            },
        },
        data() {
            return {
                isUpdateConfigLoading: false, //variable que uso para activar o desactivar el spinner
                mpUser: null,
                PointPlusSerial: '',
                hasChanged: false,
            }
        },
        mounted() {
            // Establece el valor original cuando se monta el componente
            this.originalValue = this.PointPlusSerial;
        },
        computed:{
            isIntegrated(){ //Computed que me devuelve si el usuario tiene nave integrado
                var response = false;
                this.shop?.integratedPaymentWays?.forEach( paymentWay => { // veo por todos los fds que tiene configurados ese comercio
                        if(paymentWay.paymentWay == "MercadoPagoPointPlus" && paymentWay.isEnabled)    //si el nombre del fds que clicke coincide con el que estoy analizando
                        {
                            response = true;
                            this.mpUser = paymentWay.inputs[0].value;
                            this.PointPlusSerial = paymentWay.inputs[1].value;
                        }
                    });
                return response;
            },
            getMpUser(){
                return this.mpUser;
            },
            getPointPlusSerial(){
                return this.mpUser;
            },
            showWarning() {
                if(this.PointPlusSerial?.length !== 8 && this.PointPlusSerial?.length !== 0 && this.PointPlusSerial !== null)
                    return true;
                return false
            }
        },
        methods: {
            validateInput(event) {
                let inputValue = event.target.value.replace(/\D/g, '');
                inputValue = inputValue.slice(0, 8);
                this.PointPlusSerial = inputValue;
                event.target.value = inputValue;
                this.hasChanged = inputValue !== this.originalValue;
            },
            handleClick() {
                if(!this.hasChanged){
                    this.closeModal();
                }
                else if (!this.showWarning) {
                    this.savingMPIntegration();
                }
            },
            closeModal() {
       
            },
            refreshPage() {
                setInterval(location.reload(), 1000);
            },
            startIntegration(){
                let body = {
                    shopCode: this.shop.code,
                };
                //Pongo en true para que se empiece a mostrar el spinner
                this.isUpdateConfigLoading = true;
                axios
                    .post(`/api/users/generateLinkMercadoPagoPointPlusIntegration`, body)
                    .then((res) => {
                        if (res.data.responseCode === 0) {
                            if(res.data.url != null)
                                window.open(res.data.url, '_blank');
                        } else {
                            this.showError(res.data.responseMessage);
                        }
    
                        this.$store.finishLoading( this);
                    })
                    .catch((err) => {
                        this.$store.errorLoading( this);
                        this.showErrors(err.response);
                    })
                    .finally(() => {
                        this.isUpdateConfigLoading = false; //pongo en false para que se vaya el spinner
                    });
            },
            savingMPIntegration(){
                let body = {
                    shopCode: this.shop.code,
                    PointPlusSerial: this.PointPlusSerial
                };
                //Pongo en true para que se empiece a mostrar el spinner
                this.isUpdateConfigLoading = true;
                axios
                    .post(`/api/users/saveMercadoPagoPointPlusIntegrationConfig`, body)
                    .then((res) => {
                        if (res.data.responseCode === 0) {
                            this.showSuccess("Configuración actualizada exitósamente");
                            this.hasChanged = false;
                        } else {
                            this.showError(res.data.responseMessage);
                        }
    
                        this.$store.finishLoading( this);
                    })
                    .catch((err) => {
                        this.$store.errorLoading( this);
                        this.showErrors(err.response);
                    })
                    .finally(() => {
                        this.isUpdateConfigLoading = false; //pongo en false para que se vaya el spinner
                    });
            },
            deleteIntegration(){
                let body = {
                    shopCode: this.shop.code,
                };
                //Pongo en true para que se empiece a mostrar el spinner
                this.isUpdateConfigLoading = true;
                axios
                    .post(`/api/users/deleteMercadoPagoPointPlusIntegration`, body)
                    .then((res) => {
                        if (res.data.responseCode === 0) {
                            this.showSuccess("Configuración actualizada exitósamente");
                            // update data
                            this.refreshPage();
                        } else {
                            this.showError(res.data.responseMessage);
                        }
    
                        this.$store.finishLoading( this);
                    })
                    .catch((err) => {
                        this.$store.errorLoading( this);
                        this.showErrors(err.response);
                    })
                    .finally(() => {
                        this.isUpdateConfigLoading = false; //pongo en false para que se vaya el spinner
                    });
            }
        },
        components: {
            Spinner,
        },
        mixins: [
            commons,
        ],
    }
    </script>
    
    <style scoped>
        .modal {
            width: calc(100% - 15%) !important;
            font-size: 14px;
        }
        .modal-integrated{
            width: fit-content!important;
        }
        .formGeneral{
            margin-top: 1em;
        }
        .formMain{
            justify-content: center !important;
        }
        .btn-text{
            padding: 0px 50px;
        }
    /* CUANDO ESTA INTEGRADO */
        .mp-integration-description p{
            margin-top: 30px;
        }
        .mp-integration-description input{
            padding-left: 35px!important;
            margin-bottom: 0!important;
        }
        .mp-input-warning{
            margin: 5px 0px 0px 0px!important;
            font-size: 12px;
            color: red;
            font-family: 'Rubik-Regular';
        }
        .mp-integration-description {
            max-width: 800px;
        }
        .mp-btn-disabled {
            background-color: #9d9d9d;
            cursor: default!important;
        }
        .mp-btn-disabled:hover{
            background-color: #9d9d9d;
            cursor: default!important;
        }
        .mp-btn-disabled p:hover{
            cursor: default!important;
        }

        .mp-input{
            display: block;
            margin: 30px 0px 8px 0px!important;
        }
        .mp-footer{
            display: flex;
            justify-content: flex-end;
        }
        .mp-eliminate-btn a{
            background-color: #ff0000;
        }
        .mp-eliminate-btn a :hover{
            background-color: #c90000;
            border-radius: 6px;
        }
    /* CUANDO NO ESTA INTEGRADO */
    /* TITULO */
        .mp-title-container{
            margin-bottom: 30px;
        }
        .mp-title-container p{
            margin-bottom: 6px;
        }
        .mp-title-container ul{
            margin-left: 15px;
        }
        .mp-title-container ul li{
            list-style: disc;
        }
    /* DESCRIPCIONES */
        .mp-information-description-container{
            margin-top: 25px;
        }
        .mp-information-description-title{
            margin: 0;
        }
        .mp-information-description-steps{
            margin-left: 9px;
        }
        .mp-information-description-steps p{
            margin-bottom: 6px;
        }
    /* FOOTER */
        .mp-information-footer{
            margin-top: 35px !important;
        }
        .settings-label {
            color: black;
        }
        .search-input{
            width: -webkit-fill-available;
            margin: 0 0 1.2em 0  !important;
            color: black !important;
            font-weight: bolder;
            padding-left: 7%!important;
        }
        
    </style>
    
export function cashbox(value, option, paymentTypeIndex) {
  if (!value) return "";

  switch (value) {
    case 1:
      if (option === "text") {
        return "Depósito";
      } else if (option === "class") {
        return "deposit";
      }
      break;
    case 2:
      if (option === "text") {
        return "Retiro";
      } else if (option === "class") {
        return "retirement";
      }
      break;
    case 3:
      if (option === "text") {
        return "Ajuste";
      } else if (option === "class") {
        return "arching";
      }
      break;
    case 4:
      if (option === "text") {
        return "Comanda";
      } else if (option === "class") {
        if (paymentTypeIndex === 12 || paymentTypeIndex === 13) {
          return "order-grey";
        } else {
          return "order";
        }
      }
      break;
    case 5:
      if (option === "text") {
        return "Venta";
      } else if (option === "class") {
        if (paymentTypeIndex === 12 || paymentTypeIndex === 13) {
          return "sale-grey";
        } else {
          return "sale";
        }
      }
      break;
    case 6:
      if (option === "text") {
        return "Apertura de caja";
      } else if (option === "class") {
        return "sale";
      }
      break;
    case 7:
      if (option === "text") {
        return "Cierre de caja";
      } else if (option === "class") {
        return "retirement";
      }
      break;
    case 8:
      if (option === "text") {
        return "Apertura de turno";
      } else if (option === "class") {
        return "sale";
      }
      break;
    case 9:
      if (option === "text") {
        return "Cierre de turno";
      } else if (option === "class") {
        return "retirement";
      }
      break;
    case 10:
      if (option === "text") {
        return "Anulación";
      } else if (option === "class") {
        return "retirement";
      }
      break;
    case 11:
      if (option === "text") {
        return "Anulación por Facturación";
      } else if (option === "class") {
        return "retirement";
      }
      break;
    case 12:
      if (option === "text") {
        return "Nota de Crédito";
      } else if (option === "class") {
        return "retirement";
      }
      break;
    case 13:
      if (option === "text") {
        return "Cobro";
      } else if (option === "class") {
        return "deposit";
      }
      break;
    case 14:
      if (option === "text") {
        return "Propina";
      } else if (option === "class") {
        return "tip";
      }
      break;
    default:
      if (option === "text") {
        return "Depósito";
      } else if (option === "class") {
        return "deposit";
      }
      break;
  }
}

<template>
  <button :class="[buttonClass, { disabled: isDisabled }]" @click="handleClick" type="button">
    <span v-if="icon" class="button-icon">
      <img :src="icon" alt="Icon" />
    </span>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonBistro",
  props: {
    variant: {
      type: String,
      default: "solid-blue",
      validator: function (value) {
        return [
          "solid-blue",
          "solid-green",
          "solid-gray",
          "solid-light-gray",
          "solid-red",
          "gradient-blue",
          "gradient-green",
          "gradient-gray",
          "gradient-red",
          "icon-only",
          "transparent",
          "solid-primary-blue"
        ].includes(value);
      },
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    buttonClass() {
      return `button-bistro ${this.variant}`;
    },
    isDisabled() {
      return this.$pdv.getIsDisabled;
    },
  },
  methods: {
    handleClick() {
      if (this.isDisabled) return;
      this.$emit("handleClick");
    },
  },
};
</script>

<style lang="scss">
.button-bistro {
  padding: 0 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: $lightColorBg;
  display: inline-flex;
  align-items: center;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  min-width: 100px;
  white-space: nowrap;
  min-width: 100px; 
  max-width: 100%;  
  white-space: nowrap; 
  flex-shrink: 0; 
  justify-content: center;

  .button-icon {
    margin-right: 6px;
  }

  &.icon-only {
    background: transparent;
    height: 38px;
    padding: 0;
    min-width: 38px;
    justify-content: center;

    span {
      margin: 0;
    }
  }

  &.solid-blue {
    background-color: $solidBlue;
  }

  &.solid-green {
    background-color: $solidGreen;
  }

  &.solid-red {
    background-color: $solidRed;
  }

  &.solid-gray {
    background-color: $solidGray;
  }

  &.solid-light-gray {
    background-color: $solidLightGray;
    border: 1px solid #acafa9;
    color: $darkColorFont;
    font-weight: normal;
    text-transform: uppercase;
    font-size: 16px;
  }

  &.gradient-blue {
    background: $gradientBlue;
  }

  &.gradient-green {
    background: $gradientGreen;
  }

  &.gradient-red {
    background: $gradientRed;
  }

  &.gradient-gray {
    background: $gradientGray;
  }

  &.solid-primary-blue {
    background-color: #007bff;
    color: white;
  }

  &.transparent {
    background-color: transparent;
    color: #007bff;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  &.solid-gray:hover {
    background-color: #6d6d6d;
}
}
</style>

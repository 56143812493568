<template>
	<div class="profile-qrs">
		<div>
			<div class="profile-qr" style="height: 230px;">
				<div>{{ title }}</div>
				<article class="btn-main profile-btn-qr">
					<figure class="qr pointer" @click="onDownloadQr(url, fileName)">
						<div>
							<img v-if="checkQr()" class="shopLogoImg" :src="url" width="200" height="200" />
						</div>
					</figure>
				</article>
			</div>
		</div>
	</div>
</template>
<script>

import QrGenerator from "../mixins/QrGenerator";
export default {
	mixins: [QrGenerator],
	props: {
		title: {
			type: String,
			default: "QR"
		},
		domain: {
			type: String,
		},
		fileName: {
			type: String
		},
		shopCode: {
			type: String
		}

	},
	data() {
		return {
			url: ""
		}
	},
	async mounted() {
		await this.createQrWithLogo(
			`${this.domain}?commerceId=${this.shopCode}`
		)
			.then((url) => {
				this.url = url;
			})
			.catch((err) => {
				console.error(err);
			});
	},
	methods: {
		onDownloadQr(url, fileName) {
			var download = document.createElement("a");
			download.href = url;
			download.download = `${fileName}.png`;
			download.click();
		},
		checkQr() {
			if (this.url !== "")
				return true
			else
				return false
		}


	}
};
</script>

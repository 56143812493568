const printByUsb = async (content) => {
    try {
        let device = await getConnectedUSBDevice();
        if (!device) {
            return false;
        }

        if (!device.opened) {
            await device.open();
        }

        await device.selectConfiguration(1);
        await device.claimInterface(0);

        const encoder = new TextEncoder();
        const data = encoder.encode(content);

        await device.transferOut(1, data);
        await device.close();

        return true;
    } catch (error) {
        console.error("Error al imprimir:", error);
        return false;
    }
};

const getConnectedUSBDevice = async () => {
    try {
        const devices = await navigator.usb.getDevices();
        if (devices.length === 0) {
            return await requestDevice();
        }
        return devices[0];
    } catch (error) {
        console.error("Error al obtener dispositivos USB:", error);
        return await requestDevice();
    }
};

const requestDevice = async () => {
    try {
        return await navigator.usb.requestDevice({
            filters: [{ classCode: 7 }] // Printers
        });
    } catch (error) {
        console.error("Error al solicitar dispositivo USB:", error);
        return null;
    }
};

export { printByUsb };
<template>
	<li @click="openModal(review)" class="pointer">
		<div class="items emoji">
			<Emoji :childClass="getState"
			/>
		</div>

		<!-- SATISFACTION LEVEL -->
		<div class="items">
			<p>{{ getVerboseState }}</p>
		</div>
		<!-- FECHA -->
		<div class="items">
			<h5>{{ review.timeStamp}}</h5>
			<!-- <p>{{ data.hour }}</p> -->
		</div>
		<!-- USUARIO -->
		<div class="items">
			<div class="reviewUserData">
				<p>{{ review.name }}</p>
				<p>{{ review.email }}</p>
				<p>{{ review.phone }}</p>
			</div>
		</div>
		<!-- COMENTARIO -->
		<div class="items">
			<h5>{{ review.comments }}</h5>
		</div>

		

	</li>
</template>
<script>

import Emoji from './Emoji'

export default {
	props: {
		review: {
			type: Object
		},
		
	},
	data () {
		return {
			params: {
				SaleId: null,
				RelatedDeviceId: null
			}
		}
	},
	computed: {
	getState() {
		switch (this.review.satisfactionLevel) {
			case 5:
				return 'excelent';
			case 4:
				return 'very-good';
			case 3:
				return 'good';
			case 2:
				return 'bad';
			case 1:
				return 'very-bad';
			default:
				return 'unknown'; // Valor predeterminado si no hay coincidencia
		}
	},
	getVerboseState() {
		switch (this.review.satisfactionLevel) {
			case 5:
				return 'Excelente';
			case 4:
				return 'Muy Buena';
			case 3:
				return 'Buena';
			case 2:
				return 'Mala';
			case 1:
				return 'Muy Mala';
			default:
				return 'Desconocida'; // Valor predeterminado si no hay coincidencia
		}
	}
},

	methods: {
		openModal (review) {
			this.$emit('onOpenReviewDetail', review)
		},

	},
	components: {
		Emoji
	}
}
</script>

<template>
	<main>
		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<h1>Facturación</h1>
			</section>

			<!-- OPCIONES ADICIONALE -->
			<section class="mainFilter-filter">
				<div class="filterRecord">
					<div class="item">
						<h4>
							<currency-style :valor="$filters.currency(data.totalOrdersAmount)" />
						</h4>
						<p>Total de comandas</p>
					</div>
					<div class="item">
						<h4>
							<currency-style :valor="$filters.currency(data.totalSalesAmount)" />
						</h4>
						<p>Total de ventas</p>
					</div>
				</div>

				<!-- OPCION MODAL -->
				<div class="btn-main unhide-mobile">
					<Spinner v-if="isLoading" />
					<a v-else @click="generateBills" class="waves-effect waves-light">
						<!-- <span class="icon-Bistro-_Download"></span> -->
						<p>Emitir Facturas</p>
					</a>
				</div>
			</section>
		</article>
		<!-- LISTADO DE PRODUCTOS -->
		<article :class="{ errorLoading: data.orders && data.orders.length === 0 }" class="mainEvents salesDashboard">
			<!-- TIPO DE TRANSACCION -->
			<section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">
				<div class="containTransaction">
					<!-- SEARCH -->
					<div class="searchDashboard">
						<!-- MENSAJE -->
						<div class="itemMessage">
							<div class="text">
								<h5>Filtra tu Consolidado</h5>
								<p>Fácil y Simple</p>
							</div>
							<a @click="search = !search" v-if="!search" id="btnSearchSales">
								<span class="icon-Bistro-_Search"></span>
							</a>
						</div>

						<!-- INPUT -->
						<div v-if="search" class="item">
							<a @click="search = !search" id="btnCloseSearch">
								<span class="icon-Bistro-_Close"></span>
							</a>

							<input v-model="criteria" type="text" class="inputFilter"
								placeholder="Nro de comanda o Nro de factura" />
						</div>
					</div>

					<ul class="containTransaction-lists">
						<!-- Titulo -->
						<li>
							<h4>Medios de Pago</h4>
							<div class="switch">
								<label>
									<input v-model="paymentMethodFilters" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 1 -->
						<li>
							<figure>
								<span class="icon-Bistro-_Visa"></span>
							</figure>
							<p>Tarjeta</p>
							<div class="switch">
								<label>
									<input v-model="card" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 2 -->
						<li>
							<figure>
								<span class="icon-Bistro-_DolarMoney"></span>
							</figure>
							<p>Efectivo</p>
							<div class="switch">
								<label>
									<input v-model="cash" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 3 -->
						<li>
							<figure>
								<span class="icon-Bistro-_Online"></span>
							</figure>
							<p>Online</p>
							<div class="switch">
								<label>
									<input v-model="online" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 5 -->
						<li>
							<figure>
								<span class="icon-Bistro-_QR"><span class="path1"></span><span
										class="path2"></span><span class="path3"></span><span class="path4"></span><span
										class="path5"></span><span class="path6"></span></span>
							</figure>
							<p>QR</p>
							<div class="switch">
								<label>
									<input v-model="qr" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 6 -->
						<li>
							<figure>
								<span class="icon-Bistro-_CuentaCliente"></span>
							</figure>
							<p>Cuenta Cliente</p>
							<div class="switch">
								<label>
									<input v-model="account" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 7 -->
						<li>
							<figure>
								<span class="icon-Bistro-_Invitacion1"></span>
							</figure>
							<p>Invitación</p>
							<div class="switch">
								<label>
									<input v-model="invitation" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 8 -->
						<li>
							<figure>
								<span class="icon-Bistro-_ConsumoEmpleados"></span>
							</figure>
							<p>Consumo Empleado</p>
							<div class="switch">
								<label>
									<input v-model="clerck" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
					</ul>

					<ul class="containTransaction-lists">
						<!-- Titulo -->
						<li>
							<h4>Origenes</h4>
							<div class="switch">
								<label>
									<input v-model="originFilters" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION N -->
						<li v-for="(bool, index) in data.origins" :key="index">
							<p>{{ index }}</p>
							<div class="switch">
								<label>
									<input :checked="bool" @change="chargeFilterOrigin(index, !bool)" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
					</ul>

					<!-- TIPOS DE COMANDAS -->
					<ul class="containTransaction-lists">
						<!-- Titulo -->
						<li>
							<h4>Tipos</h4>
							<div class="switch">
								<label>
									<input v-model="typeFilters" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 1 -->
						<li>
							<p>Comanda c/descuento</p>
							<div class="switch">
								<label>
									<input v-model="order_with_discount" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
						<!-- OPCION 2 -->
						<li>
							<p>Comanda s/descuento</p>
							<div class="switch">
								<label>
									<input v-model="order_no_discount" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 3 -->
						<li>
							<p>Venta c/descuento</p>
							<div class="switch">
								<label>
									<input v-model="sale_with_discount" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
						<!-- OPCION 4 -->
						<li>
							<p>Venta s/descuento</p>
							<div class="switch">
								<label>
									<input v-model="sale_no_discount" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
					</ul>
				</div>
				<!-- BTN ACTIVE FILTER MOBILE -->
				<button @click="showFilter = !showFilter" class="btnFilterMobile">
					<span class="icon-Bistro-_Filter"></span>
				</button>
			</section>

			<!-- LISTADO DE FACTURAS -->
			<ul v-if="data.sales && data.sales.length > 0" class="mainEvents-history">
				<li v-if="hasToShowSelectAll()" class="selectAll-billing">
					<h4>Seleccionar todo</h4>
					<div class="switch">
						<label>
							<input v-model="selectAll" type="checkbox">
							<span class="lever"></span>
						</label>
					</div>
				</li>
				<!-- Historial 1 -->
				<table-row v-for="(sale, index) in data.sales" 
					target="consolidatedV2" 
					:modal="true" 
					:isSaleV2="true"
					:data="sale" 
					:key="index" 
					:canSelectRow="true" 
					@click="handleClickSwitch"
				/>
			</ul>
			<empty-element v-else-if="data.sales && data.sales.length === 0 && loaded" />
			<article v-else class="mainEvents-history">

				<article  v-for="i in 4" :key="i" :class="sectionLoading">
				</article>
			</article>
		</article>
		<div v-if="data.sales && data.sales.length > 0 && isMoreOfOnePage()" class="base-pagination">
			<BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
				class="list__pagination" @nextPage="pageChangeHandle('next')"
				@previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
		</div>
		<modal title="Resumen de" :showBillingActions="showBillingPage" @onCloseModal="onCloseModal"
			@onCloseModalAndReloadPage="onCloseModalAndReloadPage"></modal>
		<modal-global-billing ref="modalGlobalBillingRef" id="modalGlobalBilling" :billsCount="billsCount"
			:billsAmount="billsAmount" :creditNotesCount="creditNotesCount" :creditNotesAmount="creditNotesAmount"
			@onModalClosing="onCloseGlobalBilling" @onConfirmBilling="onConfirmBilling" />
		<modal-error-message-emite ref="modalErrorMessageEmiteRef" id="modalErrorMessageEmite"
			:errorMessages="emiteErrorMessages" />
		<help-center />
	</main>
</template>
<script>
import axios from "axios";
import commons from "@/mixins/mixCommons";
import authCommons from "../../mixins/authCommons";
import filterSaleTypesCommons from "@/mixins/filterSaleTypesCommons";
import rolesCommons from "@/mixins/rolesCommons";
import computeds from "@/mixins/menuOptions";
import selectPdvs from "@/mixins/select_pdvs";
import modals from "@/mixins/mixModals";
import TableRow from "@/components/TableRow";
import Modal from "@/components/ModalSalesDetailsV2";
import EmptyElement from "@/components/EmptyElement";
import { parseToQuery } from "@/utils/utils";
import CurrencyStyle from "@/components/CurrencyStyle";
import BasePagination from "@/components/BasePagination";
import HelpCenter from "@/components/HelpCenter";
import Spinner from "@/components/SpinnerComp";
import ModalGlobalBilling from "@/components/ModalGlobalBilling";
import ModalErrorMessageEmite from "@/components/ModalErrorMessageEmite";

export default {
	mixins: [
		authCommons,
		commons,
		computeds,
		selectPdvs,
		rolesCommons,
		modals,
		filterSaleTypesCommons,
	],
	data() {
		return {
			selectAll: false,
			selectOne: false,
			data: {},
			filterInterval: null,
			showFilter: false,
			search: false,
			excelParams: "",
			loaded: false,
			paymentMethodFilters: true,
			card: true,
			cash: true,
			online: true,
			qr: true,
			invitation: true,
			clerck: true,
			originFilters: true,
			typeFilters: true,
			account: true,
			criteria: "",
			params: {
				FilterPaymentWay: [],
				FilterOrigin: [],
				FilterTrnxStatus: [],
				FilterSaleTypes: [],
			},
			timeout: null,
			currentPage: 1,
			countPerPage: 50,
			isLoading: false,
			creditNotesCount: 0,
			creditNotesAmount: 0,
			billsCount: 0,
			billsAmount: 0,
			emiteErrorMessages: []

		};
	},
	async mounted() {
		document.title = `${this.appTitle}  - Facturación de Transacciones`;
		this.$store.setHasMounted(false)
		this.$store.setLoadingSkeleton(true)
		if (this.isUserLoggedIn() === false) {
			axios
				.get("/api/auth/logout") /// OK
				.then(() => {
					this.$store.SET_USER(null);
					this.resetUser();
					this.setAutomaticLogin(false);
					location.href = "/login";
				});
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.$store.updateTopMenu(0);
				this.$store.updateSideMenu(2);

				if (this.getPeriodSelectionFromLocalStorage() !== null) {
					await this.$store.updateTabSelected(
						this.getPeriodSelectionFromLocalStorage()
					);
					this.params["Period"] = this.getPeriodSelectionFromLocalStorage().param;
				} else {
					await this.$store.initTabs();
				}
				this.$store.hideElements({});
				this.toggleMerchantSelectorByLabel("showMerchantSelectionFilter");
				this.$store.CALL_MERCHANTS("CALL_MERCHANTS");
				this.$store.updateCalendarType("range");

				this.params["CurrentPage"] = this.currentPage;
				this.params["CountPerPage"] = this.countPerPage;
				this.$store.setHasMounted(true)

			}
		}
	},
	watch: {
		selectAll() {
			this.selectAllSales(this.selectAll)
		},
		paymentMethodFilters() {
			this.setAllPaymentTypes(this.paymentMethodFilters);
		},
		card() {
			this.chargeFilterPaymetWay(this.card, "TARJETA");
			this.pageChangeHandle(1);
		},
		qr() {
			this.chargeFilterPaymetWay(this.qr, "QR");
			this.pageChangeHandle(1);
		},
		account() {
			this.chargeFilterPaymetWay(this.account, "ACCOUNT");
			this.pageChangeHandle(1);
		},
		cash() {
			this.chargeFilterPaymetWay(this.cash, "EFECTIVO");
			this.pageChangeHandle(1);
		},
		online() {
			this.chargeFilterPaymetWay(this.online, "ONLINE");
			this.pageChangeHandle(1);
		},
		invitation() {
			this.chargeFilterPaymetWay(this.invitation, "INVITATION");
			this.pageChangeHandle(1);
		},
		clerck() {
			this.chargeFilterPaymetWay(this.clerck, "EMPLOYEE");
			this.pageChangeHandle(1);
		},
		originFilters() {
			for (var key in this.data.origins) {
				this.data.origins[key] = this.originFilters;
				this.chargeFilterOrigin(key, this.originFilters);
			}
		},
		typeFilters() {
			this.setAllTypes(this.typeFilters);
		},
		criteria() {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.params["FilterCriteria"] = this.criteria;
				this.pageChangeHandle(1);
			}, 500);
		},
		tab() {
			if (this.tab.id !== 6 && this.loaded) {
				this.params["Period"] = this.tab.param;
				this.pageChangeHandle(1);
			}
		},
		dateCalendar() {
			if (this.dateCalendar.indexOf("Seleccione") < 0) {
				this.params["Period"] = this.tab.param;
				this.pageChangeHandle(1);
			}
		},
	},
	methods: {
		selectAllSales(bool) {
			if (bool || !this.selectOne) {
				this.data.sales.forEach(sale => {
					sale.isChecked = bool
				})
			}
			this.selectOne = bool && this.selectOne
		},
		handleClickSwitch() {
			this.selectOne = true
			this.selectAll = false
		},
		setAllPaymentTypes(bool) {
			this.card = bool;
			this.cash = bool;
			this.online = bool;
			this.qr = bool;
			this.account = bool;
			this.invitation = bool;
			this.clerck = bool;
		},
		async pageChangeHandle(value) {
			console.log('BillingDetail pageChangeHandle')
			this.avoidRectangleAtTheEnd();
			switch (value) {
				case "next":
					this.currentPage += 1;
					break;
				case "previous":
					this.currentPage -= 1;
					break;
				default:
					this.currentPage = value;
			}
			this.params["CurrentPage"] = this.currentPage;
			this.selectAll = false;
			this.getData();
		},
		isMoreOfOnePage() {
			return this.data.totalCount - this.countPerPage > 0;
		},
		chargeFilterPaymetWay(bool, payment) {
			if (bool) {
				var index = this.params.FilterPaymentWay.indexOf(payment);
				this.params.FilterPaymentWay.splice(index, 1);
			} else {
				this.params.FilterPaymentWay.push(payment);
			}
		},
		chargeFilterOrigin(origen, bool) {
			this.data.origins[origen] = bool;

			var index = this.params.FilterOrigin.indexOf(origen);
			if (bool) {
				this.params.FilterOrigin.splice(index, 1);
			} else if (index < 0) {
				this.params.FilterOrigin.push(origen);
			}
			this.pageChangeHandle(1);
		},
		addExcelParams() {
			let p = this.params["CurrentPage"];
			this.params["CurrentPage"] = null;
			this.excelParams = parseToQuery(this.params);
			this.params["CurrentPage"] = p;
		},
		onCloseModal() {
			this.closeModalById("#modalResumSales");
		},
		onCloseGlobalBilling() {
			this.isLoading = false;
			this.closeModalById("#modalGlobalBilling");
			this.$refs.modalGlobalBillingRef.isLoading = false;
		},
		onConfirmBilling() {
			const uuidList = this.data.sales
				.filter((x) => x.isChecked)
				.map((x) => x.uuid);
			axios
				.post(`/api/${this.getOrdersTarget}`, { uuidList })
				.then((res) => {
					if (res.data.responseCode == 0) {
						this.showSuccess(res.data.responseMessage);
						this.getData();
					} else {
						this.showError(res.data.responseMessage);
					}
					this.onCloseGlobalBilling();
				})
				.catch(() => {
					this.showError(
						"Hubo un error inesperado al intentar facturar. Por favor reintente más tarde"
					);
					this.onCloseGlobalBilling();
				});
		},
		onCloseModalAndReloadPage() {
			this.onCloseModal();
			this.getData();
		},
		getData() {
			if (!this.$store.hasMounted) return
			this.addExcelParams();

			clearInterval(this.filterInterval);
			this.filterInterval = setInterval(() => {
				clearInterval(this.filterInterval);

				this.$store.initLoading(this);
				axios
					.get(`/api/${this.getOrdersTarget}/?${parseToQuery(this.params)}`)
					.then((res) => {
						
						this.$store.finishLoading(this);
						this.loaded = true;
					
						if(res?.data?.responseCode != 0) {
							this.showErrors(res);
						} else {
							this.data = res.data;
						}
						
					})
					.catch((err) => {
						this.$store.errorLoading(this);
						this.showErrors(err.response);
					});
			}, 1000);
		},
		hasToShowSelectAll() {
			if (process.env.VUE_APP_INSTANCE_CODE == "AR")
				return true
			else
				return false
		},
		generateBills() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				case "AR": {
					// llamar a modal
					this.isLoading = true;

					const uuidList = this.data.sales
						.filter((x) => x.isChecked)
						.map((x) => x.uuid);

					if (uuidList.length == 0) {
						this.showError(
							"Es necesario que seleccione al menos una comanda antes de generar una factura."
						);
						this.isLoading = false;
						return;
					}
					this.billsCount = this.data.sales
						.filter((x) => x.isChecked)
						.filter((x) => x.movementType == 3)
						.map((x) => x.movementType).length;
					this.billsAmount = this.data.sales
						.filter((x) => x.isChecked)
						.filter((x) => x.movementType == 3)
						.map((x) => x.amount)
						.reduce((acc, value) => {
							return acc + value;
						}, 0);
					this.creditNotesCount = this.data.sales
						.filter((x) => x.isChecked)
						.filter((x) => x.movementType == 4)
						.map((x) => x.movementType).length;
					this.creditNotesAmount = this.data.sales
						.filter((x) => x.isChecked)
						.filter((x) => x.movementType == 4)
						.map((x) => x.amount)
						.reduce((acc, value) => {
							return acc + value;
						}, 0);

					this.openModalById("#modalGlobalBilling");

					break;
				}
				default: {
					this.isLoading = true;

					const uuidList = this.data.sales
						.filter((x) => x.isChecked)
						.map((x) => x.uuid);

					if (uuidList.length == 0) {
						this.showError(
							"Es necesario que seleccione al menos una comanda antes de generar una factura."
						);
						this.isLoading = false;
						return;
					}

					axios
						.post(`/api/${this.getOrdersTarget}`, { uuidList })
						.then((res) => {

							console.log(`/api/${this.getOrdersTarget}`)
							console.log(res.data.emiteBillingErrorsEx)
							if (res.data.responseCode == 0) {
								this.showSuccess("Factura generada exitosamente");
								this.getData();
							} else {
								this.emiteErrorMessages = res.data.emiteBillingErrorsEx;
								this.openModalById("#modalErrorMessageEmite");
								this.getData();
							}
							this.isLoading = false;
						})
						.catch((err) => {
							this.showErrors(err.response);
							this.isLoading = false;
							this.getData();
						});
					break;
				}
			}
		},
	},
	computed: {
		getOrdersUrl() {
			return "/billingDetail";
		},
		getOrdersTarget() {
			return "billingDetail";
		},
	},
	components: {
		TableRow,
		Modal,
		EmptyElement,
		CurrencyStyle,
		BasePagination,
		HelpCenter,
		Spinner,
		ModalGlobalBilling,
		ModalErrorMessageEmite,
	},

};
</script>
<style scoped>
.skeleton-card {
  margin-bottom: 10px;
}
</style>
<template>
	<section>
		<section class="bodyLogin">
			<figure class="bodyLogin-background"></figure>

			<article class="bodyLogin-information">
				<!-- LOGOTIPO -->
				<figure class="logo">
					<!-- <img src="~assets/img/Bistro-Logo@2x.png" alt="" /> -->
					<Logo/>
				</figure>

				<!-- FORM -->
				<form autocomplete="off" action="" class="formLogin">
					<div class="text-automatic-login">Iniciando Sesión...</div>
					<div class="loader-automatic-login"></div>
				</form>
			</article>
		</section>
	</section>
</template>

<script>
import axios from 'axios'
import commons from '../mixins/mixCommons'
import rolesCommons from '../mixins/rolesCommons'
import Logo from '../components/BistroLogo'

export default {
	mixins: [commons, rolesCommons],
	head () {
		return {
			title: `${this.appTitle} - Login`
		}
	},
	data () {
		return {
			user: {
				username: '',
				password: ''
			}
		}
	},
	mounted () {
		this.user.username = this.$route.query.u
		this.user.password = this.$route.query.p
		console.log('User from queryString', this.user)
		this.makeLogin()
	},
	methods: {
		resetAll () {
			this.login = !this.login
			this.recover = false
			this.RecoveryMail = ''
		},
		getMerchantTagsFromServer(res) {
			console.log('getMerchantTagsFromServer', res)
			axios
				.post('/api/auth/getMerchantTags', {
					username: this.user.username,
					password: this.user.password
				})
				.then(res2 => {
					if (res2.data.responseCode !== 0) {
						this.$store.errorLoading(this)
						this.showError(res2.data.responseMessage)
					} else {
						this.$store.finishLoading(this)
						this.$store.SET_USER(res.data)
						this.setMerchantTags(res2.data)
						this.setUser(res.data)
						this.setAutomaticLogin(false)
						this.redirectLoggedInUser(res)
				}
				})
				.catch(err => {
					if (typeof err.response.data === 'string') {
						this.$store.errorLoading(this)
						this.showError('El usuario y/o password no coinciden')
					} else {
						this.$store.errorLoading(this)
						this.showError(err.response)
					}
				})
		},
		makeLogin () {
			this.resetUser()
			this.setAutomaticLogin(false)
			this.$store.initLoading(this)
			axios
				.post('/api/auth', {
					username: this.user.username,
					password: this.user.password
				})
				.then(res => {
					console.log('res', res)
					if (res.data.responseCode !== 0) {
						this.$store.errorLoading(this)
						this.showError(res.data.responseMessage)
					} else {
						this.getMerchantTagsFromServer(res)
					}
				})
				.catch(err => {
					if (typeof err.response.data === 'string') {
						this.$store.errorLoading(this)
						this.showError('El usuario y/o password no coinciden')
					} else {
						this.$store.errorLoading(this)
						this.showError(err.response)
					}
				})
		}
	},
	components: {
		Logo
	},
	middleware: ['noAuth']
}
</script>

<style scoped lang="stylus"></style>

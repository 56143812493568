<template>
    <div class="delivery-zone-container">
        <div class="delivery-zone-options">
            <!-- DESCRIPCION -->
            <div class="delivery-zone-description" v-if="showTitle">
                <p>Puede definir la zona de delivery a partir de un radio o dibujándola en el mapa.</p>
            </div>
            <!-- SELECTOR DE MODO -->
            <div class="delivery-zone-method">
                <!-- RADIO INPUTS -->
                <div class="delivery-zone-selection">
                    <label :class="{ 'selected': isRadialZoneSelected }" @click="toggleZoneSelection(true)">
                        <span class="delivery-zone-selection-text">Radio</span>
                    </label>
                    <label class="delivery-zone-selection-text" :class="{ 'selected': !isRadialZoneSelected }"
                        @click="toggleZoneSelection(false)">
                        <span class="delivery-zone-selection-text">Zona personalizada</span>
                    </label>
                </div>
                <hr class="delivery-zone-line-divisor">
                <!-- OPCIONES PARA CADA ZONA -->
                <div class="zone-options">
                    <div v-if="isRadialZoneSelected" class="zone-options-radial">
                        <input type="number" v-model="radius" @input="radiusZoneChanged">
                        <p>metros</p>
                    </div>
                    <div v-if="!isRadialZoneSelected" class="zone-options-custom">
                        <button @click="enableAddingVertex">
                            <v-icon :color="isSelectedAddPoint ? 'blue' : 'black'">mdi-plus-circle-outline</v-icon>
                            <span class="zone-options-custom-text">Agregar punto</span>
                        </button>
                        <button @click="enableDeleteVertex">
                            <v-icon :color="isSelectedDeletePoint ? 'blue' : 'black'">mdi-minus-circle-outline</v-icon>
                            <span class="zone-options-custom-text">Quitar punto</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MAPA -->
        <div class="delivery-zone-map" ref="map"></div>
        <!-- BOTON DE GUARDAR -->
        <button v-if="showSaveButton" class="delivery-button save-button"
            :class="{ 'disabled': hasToShowCustomZoneError }">GUARDAR</button>
    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            //Objeto para el mapa y el marcador
            map: null,
            marker: null,
            //Me dicen que opcion esta seleccionada
            isRadialZoneSelected: this.isRadialZoneSelectedProp == null ? 1000 : this.isRadialZoneSelectedProp,
            //Zona radial
            circle: null,
            radius: this.radiusProp,
            //Zona custom
            polygon: null,
            polygonCoords: this.polygonCoordsProp,
            flagDeleteVertex: false,
            //Listeners para eventos
            mapClickListeners: null,
            //Me dice si muestro el error de que necesito 3 vertices para hacer una zona custom
            hasToShowCustomZoneError: false,
            //Me dice que boton presione en las opciones personalizadas
            isSelectedAddPoint: false,
            isSelectedDeletePoint: false,
        }
    },
    props: {
        //props para mostrar titulo o boton guardar
        showTitle: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        showSaveButton: {
            type: Boolean,
            default: () => {
                return false;
            }
        },
        //Props para el mapa
        addressCoords: {
            type: Object,
            default: () => {
                return {
                    lat: -34.604235,
                    lng: -58.381469
                };
            }
        },
        //Props de Zona radial
        isRadialZoneSelectedProp: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        radiusProp: {
            type: Number,
            default: () => {
                return null
            }
        },
        //Props de Zona Custom
        polygonCoordsProp: {
            type: Array,
            default: () => {
                return null;
            }
        },
    },
    mounted() {
        this.loadGoogleMapsAPI();
    },
    methods: {
        saveDeliveryZoneConfig() {//Funcion que emite el evento cada vez que se hace un cambio en el hijo para que lo vea el padre
            var config = {
                isRadialZoneSelected: this.isRadialZoneSelected,
                radius: this.radius,
                polygonCoords: null,
            }
            if (this.polygonCoords)
                if (this.polygonCoords.length > 0)
                    config.polygonCoords = this.polygonCoords

            this.$emit("saveDeliveryZone", config);
        },
        loadGoogleMapsAPI() {//Carga la API para usar las librerias de GMAPs
            if (window.google && window.google.maps) {
                // La API ya está cargada, inicializar el mapa directamente
                this.initMap();
            } else {
                // La API aún no está cargada, cargarla de forma asíncrona
                return new Promise((resolve, reject) => {
                    const script = document.createElement('script');
                    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDgzf3Hrq7Lj21M1zEeaWah94UBeNYi7m0&libraries=maps,geometry,marker&callback=initMap`;
                    script.defer = true;
                    script.async = true;

                    window.initMap = () => {
                        this.initMap();
                        resolve();  // Resuelve la promesa cuando se inicializa el mapa
                    };

                    script.onerror = reject;  // Manejar errores de carga del script
                    document.head.appendChild(script);
                });
            }
        },
        initMap() {
            if (this.radiusProp == 0)
                this.radius = 1000;
            /*eslint-disable*/
            this.map = new google.maps.Map(this.$refs.map, {//Creo el mapa
                center: this.addressCoords,
                clickableIcons: false,
                zoom: 13,
                streetViewControl: false,
                controlSize: 20,//para que los controles del mapa no ocupen tanto espacio
                mapTypeId: 'terrain',
                mapTypeControl: false//para que no pueda poner vista satelite
            });
            this.addShopMarker();//a;ado el marcador en las coordenadas que le pase

            if (this.isRadialZoneSelected)//Me fijo que opcion tenia el usuario habilitada
                this.drawRadialZone();
            else
                this.drawCustomZone();
        },
        addShopMarker() {//Funcion que agrega el marcador
            this.marker = new google.maps.Marker({
                map: this.map,
                position: this.addressCoords,
            })
        },
        drawRadialZone() {//Funcion que dibuja el circulo
            this.circle = new google.maps.Circle({
                strokeColor: "#24a5d3",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#24a5d3",
                fillOpacity: 0.35,
                map: this.map,
                center: this.addressCoords,
                radius: this.radius,
                editable: true
            });

            this.saveDeliveryZoneConfig();//Si entre aca quiere decir que cambie el metodo de zona que usaba

            this.circle.addListener('radius_changed', () => {
                this.radius = Math.trunc(parseFloat(this.circle.getRadius()));//cuando cambio el radio desde el mapa ejecuto esta funcion para actualizar el valor del radio en la opcion
                this.saveDeliveryZoneConfig();//Cada vez que cambie el radio guardo el valor
            })
        },
        radiusZoneChanged() {//Funcion que cambia el radio del circulo cuando lo escribo en el input
            this.radius = Number(this.radius);
            if (this.circle && this.radius != '' && this.radius != 'e') {
                this.circle.setRadius(this.radius);
            }
            this.saveDeliveryZoneConfig();//Cada vez que cambie el radio guardo el valor
        },
        drawCustomZone() {//Funcion que dibuja el polygono
            this.verifyPolygonCoords();//verifico que haya al menos 3 vertices

            this.polygon = new google.maps.Polygon({
                strokeColor: "#24a5d3",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#24a5d3",
                fillOpacity: 0.35,
                map: this.map,
                editable: true,
            });

            if (this.polygonCoords)//solo si existe y tiene vertices le seteo el path
                if (this.polygonCoords.length != 0)
                    this.setPathForCustomZone();

            this.saveDeliveryZoneConfig();
        },
        setPathForCustomZone() {//Funcion que me setea el path y agrega los listeners para los controles dentro del mapa
            this.saveDeliveryZoneConfig();//Cada vez que haya hecho un cambio lo guardo
            this.polygon.setPath(this.polygonCoords);
            google.maps.event.addListener(this.polygon.getPath(), 'set_at', this.moveVertex);//si muevo un vertice entro aca
            google.maps.event.addListener(this.polygon.getPath(), 'insert_at', this.addVertexFromEdge);//si creo un vertice desde el borde
            google.maps.event.addListener(this.polygon.getPath(), 'remove_at', this.eraseLastVertex);//si cree un vertice desde un borde y toco la flechita para volver atras
        },
        enableAddingVertex(event) {//Funcion que se ejecuta cuando toco el boton de a;adir un punto
            event.preventDefault();
            this.deleteListenersForCustomZone();
            this.isSelectedAddPoint = true;
            this.isSelectedDeletePoint = false;
            this.mapClickListeners = google.maps.event.addListener(this.map, 'click', this.addPolygonVertex);//A;ado el listener para agregar vertices
        },
        addVertexToList(newCoords) {//Funcion que me agrega el vertice que le pase al final de la lista
            this.polygonCoords.push(newCoords);
            this.verifyPolygonCoords();
            this.setPathForCustomZone();
        },
        closestCoord(newCoords) {//Funcion que me calcula el indice del vertice mas cercano a las coordenadas que le paso
            var closestDistance = Number.MAX_VALUE;
            var indexOfVertex;
            this.polygonCoords.forEach((coord, index) => {
                var distance = google.maps.geometry.spherical.computeDistanceBetween(newCoords, coord);
                if (distance < closestDistance) {
                    closestDistance = distance;
                    indexOfVertex = index;
                }
            });
            return indexOfVertex;
        },
        addPolygonVertex(event) {//funcion que me agrega el vertice con las coordenadas donde hice click
            var newCoords = {//creo el objeto con los datos del lugar donde hice click
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            }
            if (this.polygonCoords == null) {//Si de la prop me vino null tengo que crear una nueva lista y pushear las coordenadas
                this.polygonCoords = [];
                this.addVertexToList(newCoords);
                return;
            }
            if (this.polygonCoords.length < 3) {//Si tengo menos de 3 vertices lo tengo que a;adir al final
                this.addVertexToList(newCoords);
                return;
            }
            //Si llegue hasta aca quiere decir que tengo que fijarme bien en que index de la lista lo agrego
            var indexOfVertex = this.closestCoord(newCoords);
            //En index of vertex tengo el indice del vertice mas cercano al que estoy intentando a;adir
            //Tengo que ver si lo pongo en el indice del anterior o en el que sigue
            //Si es el primero de la lista tengo que comparar con el ultimo y con el segundo
            if (indexOfVertex == 0) {
                this.addComputedVertex(this.polygonCoords[0], this.polygonCoords[this.polygonCoords.length - 1], this.polygonCoords[1], newCoords, this.polygonCoords.length - 1, 0, 1)
            }
            //Si es el ultimo comparo con el anteultimo y con el primero
            else if (indexOfVertex == this.polygonCoords.length - 1) {
                this.addComputedVertex(this.polygonCoords[indexOfVertex], this.polygonCoords[indexOfVertex - 1], this.polygonCoords[0], newCoords, indexOfVertex - 1, indexOfVertex, 0)
            }
            //Para cualquier elemento del medio de la lista
            else {
                this.addComputedVertex(this.polygonCoords[indexOfVertex], this.polygonCoords[indexOfVertex - 1], this.polygonCoords[indexOfVertex + 1], newCoords, indexOfVertex - 1, indexOfVertex, indexOfVertex + 1)
            }

            this.setPathForCustomZone();
        },
        addComputedVertex(closestVertex, previousVertex, afterVertex, newVertex, previousIndex, indexOfVertex, afterIndex) {//Esta funcion a;ade un vertice a la lista pero procesandolo para que no se intersecten dos lineas seguidas
            var vertexWithoutIntersection = this.checkLineIntersection(closestVertex, previousVertex, afterVertex, newVertex);
            //SI ALGUNO INTERSECTABA
            if (vertexWithoutIntersection != null) {
                //Busco cual es el vertice en el que tengo que insertar las nuevas coordenadas
                var indice = this.polygonCoords.findIndex(coordenadas => coordenadas.lat === vertexWithoutIntersection.lat && coordenadas.lng === vertexWithoutIntersection.lng);
                if (indice == afterIndex)//si coincide con el vertice que sigue
                    this.polygonCoords.splice(afterIndex, 0, newVertex);
                else
                    this.polygonCoords.splice(indexOfVertex, 0, newVertex);
            }
            //SI NINGUNO INTERSECTABA ME FIJO CUAL TIENE MENOR DISTANCIA
            else {
                var distancePreviousVertex = google.maps.geometry.spherical.computeDistanceBetween(newVertex, this.polygonCoords[previousIndex]);
                var distanceVertexAfter = google.maps.geometry.spherical.computeDistanceBetween(newVertex, this.polygonCoords[afterIndex]);
                if (distancePreviousVertex < distanceVertexAfter) {
                    this.polygonCoords.splice(indexOfVertex, 0, newVertex);
                }
                else {
                    this.polygonCoords.splice(afterIndex, 0, newVertex);
                }
            }
        },
        checkLineIntersection(closestVertex, previousVertex, afterVertex, newVertex) {//funcion que me devuelve en que vertice no intersecta
            //Trazo una linea entre closest y previous y veo si la linea entre after y new cortan a la otra linea, si es asi, tengo que poner el nuevo vertice entre closest y previous
            if (this.checkLineIntersectionFormula(closestVertex, previousVertex, afterVertex, newVertex))//si devolvio true quiere decir que si se intersectaba por lo que hay que ponerla en el previous si o si
                return previousVertex;
            //trazo una linea entre closest y after y veo si la linea entre previous y new cortan a esa linea, si es asi, pongo el nuevo entre closest y after
            if (this.checkLineIntersectionFormula(closestVertex, afterVertex, previousVertex, newVertex))//si devolvio true quiere decir que si se intersectaba por lo que hay que ponerla en el after si o si
                return afterVertex;
            return null;
        },
        checkLineIntersectionFormula(a, b, c, d) {//funcion que ve si hay o no interseccion entre rectas (a y b forman una recta, c y d la otra)
            var alpha = (((d.lat - c.lat) * (c.lng - a.lng)) - ((d.lng - c.lng) * (c.lat - a.lat))) / (((d.lat - c.lat) * (b.lng - a.lng)) - ((d.lng - c.lng) * (b.lat - a.lat)));
            var beta = (((b.lat - a.lat) * (c.lng - a.lng)) - ((b.lng - a.lng) * (c.lat - a.lat))) / (((d.lat - c.lat) * (b.lng - a.lng)) - ((d.lng - c.lng) * (b.lat - a.lat)));
            if (alpha <= 1 && beta <= 1)
                return true;
            return false
        },
        moveVertex(index) {//Cuando se mueve un vertice se actualiza la lista con esas nuevas coordenadas
            const newCoordinate = this.polygon.getPath().getAt(index);
            this.polygonCoords[index] = {
                lat: newCoordinate.lat(),
                lng: newCoordinate.lng()
            };
        },
        addVertexFromEdge(index) {//Cuando se crea un vertice desde el borde se actualiza la lista con ese nuevo vertice
            this.polygonCoords.splice(index, 0, this.polygon.getPath().getAt(index));
            this.verifyPolygonCoords();//verifico si con este vertice que a;adi se cumple la condicion de tener al menos 3
        },
        eraseLastVertex(index) {//Cuando se creo un vertice desde el borde y se borra con el 'undo' que te da google maps se saca de la lista ese vertice
            this.polygonCoords.splice(index, 1);
            this.verifyPolygonCoords();//verifico si con el que borre ya no se cumple la condicion de tener 3 vertices
        },
        enableDeleteVertex(event) {//Cuando presiono el boton para eliminar un punto
            event.preventDefault();
            this.deleteListenersForCustomZone();
            this.isSelectedAddPoint = false;
            this.isSelectedDeletePoint = true;
            google.maps.event.addListener(this.polygon, 'click', this.deleteVertex);//agrego el listener para el click en el vertice
        },
        deleteVertex(event) {//Cuando presiono sobre un vertice para borrarlo
            if (event.vertex !== undefined && this.flagDeleteVertex == false) {// Se hizo clic en un vértice específico
                var indexOfVertex = event.vertex;
                this.polygonCoords.splice(indexOfVertex, 1);
                this.setPathForCustomZone();
                this.verifyPolygonCoords();//verifico si con el que elimine se sigue cumpliendo la condicion de tener al menos 3 vertices
                this.flagDeleteVertex = true;
                setTimeout(() => {//pongo un timeout para volver a poner la bandera en false porque sino se entraba varias veces a esta funcion
                    this.flagDeleteVertex = false;
                }, 100);
            }
        },
        verifyPolygonCoords() {//Se fija que la lista tenga 3 elementos o mas para decidir si mostrar el cartel
            if (this.isRadialZoneSelected) {
                this.hasToShowCustomZoneError = false;
                return;
            }
            if (this.polygonCoords == null) {
                this.hasToShowCustomZoneError = true;
                return
            }
            if (this.polygonCoords.length < 3) {
                this.hasToShowCustomZoneError = true;
                return
            }
        },
        toggleZoneSelection(value) {//Funcion que maneja el cambio de opcion
            //Si el input que se presiono fue zona radial entro aca
            if (value == true) {
                this.isRadialZoneSelected = true;
                this.eraseMap();//borro cualquier forma que habia en el mapa
                this.deleteListenersForCustomZone();
                this.isSelectedAddPoint = false;
                this.isSelectedDeletePoint = false;
                this.drawRadialZone();
            }
            //Si el input que se presiono fue zona custom entro aca
            else {
                this.isRadialZoneSelected = false;
                this.eraseMap();//borro cualquier forma que habia en el mapa
                this.isSelectedAddPoint = false;
                this.isSelectedDeletePoint = false;
                this.deleteListenersForCustomZone();
                this.drawCustomZone();
            }
        },
        eraseMap() {//borra todas las formas del mapa
            if (this.circle)
                this.circle.setMap(null);
            if (this.polygon)
                this.polygon.setMap(null);
        },
        deleteListenersForCustomZone() {
            if (this.mapClickListeners) { //Elimino el listener que habia en el poligono si es que habia
                google.maps.event.removeListener(this.mapClickListeners);
                this.mapClickListeners = null;
            }
        },
    },
}
</script>

<template>
	<main>
		<!--  									-->
		<!-- OPCIONES VARIAS HEADER - START 	-->
		<!--  									-->
		<article :class="getMiscTabStyles">
			<section class="mainFilter-title pointer" @click="onExpandSection(`misc`)">
				<div class="fix-title">
					<h1><span>Configuraciones varias</span></h1>
					<p class="clickHint" v-if="hasToShowMiscTab">
						Clickea aquí para esconder configuraciones varias
					</p>
					<p class="clickHint" v-else>
						Clickea aquí para mostrar configuraciones varias
					</p>
				</div>
			</section>
			<section class="mainFilter-filter expand-arrow">
				<div @click="onExpandSection(`misc`)" class="arrow" :class="{ expanded: hasToShowMiscTab }"></div>
			</section>
		</article>
		<!--  								-->
		<!-- OPCIONES VARIAS HEADER - END 	-->
		<!--  								-->
		<!--  								-->
		<!-- OPCIONES VARIAS BODY - START 	-->
		<!--  								-->
		<article v-show="hasToShowMiscTab">
			<form action="" class="formGeneral profile-misc-grid profile-misc-panel profile-form">
				<article class="formMain formNotMain">
					<div v-for="(item, index) in shops" :key="`${index}-${item.code}`">
						<div v-if="hasToShowThisProfile(item.code) && item.fiscalData">

							<div>
								<!--  								-->
								<!-- SWITCHES DE IMPRESION - START 	-->
								<!--  								-->
								<div class="profile-subtitle itemForm">
									<div>
										<div class="settings-div switch">
											<label>
												<a class="settings-label settings-label-checkbox">¿Recibe correos con
													los cierres de caja?</a>
												<input @click="
													handleHasToSendClosureReportByMail(
														!item.hasToSendClosureReportByMail,
														item.code
													)
													" v-model="item.hasToSendClosureReportByMail" type="checkbox" @change="avoidRectangleAtTheEnd" />
												<span class="lever"></span>
											</label>
										</div>
										<div v-show="hasToShowPrintTicket" class="settings-div switch">
											<label>
												<a class="settings-label settings-label-checkbox">¿Imprime Ticket?</a>
												<input @click="
													handleHasToPrintTicket(
														!item.hasToPrintTicket,
														item.code
													)
													" v-model="item.hasToPrintTicket" type="checkbox" />
												<span class="lever"></span>
											</label>
										</div>
										<a v-if="!item.hasToPrintTicket" @click="downloadTicketViewerQr(item.code)"
											class="download-qr settings-label">Descargar QR para visualizar los
											tickets</a>

									</div>
								</div>
								<!--  								-->
								<!-- SWITCHES DE IMPRESION - END 	-->
								<!--  								-->
								<!--  								-->

							</div>
						</div>
					</div>
				</article>
			</form>
		</article>
		<!--  								-->
		<!-- OPCIONES VARIAS BODY - END 	-->
		<!--  								-->

		<!--  									-->
		<!-- LOGOS E IMAGENES HEADER - START 	-->
		<!--  									-->
		<article :class="getLogoAndImagedTabStyles">
			<section class="mainFilter-title pointer" @click="onExpandSection(`logoAndImages`)">
				<div class="fix-title">
					<h1><span>Carga de logo e imágenes</span></h1>
					<p class="clickHint" v-if="hasToShowLogoAndImagesTab">
						Clickea aquí para esconder carga de logo e imágenes
					</p>
					<p class="clickHint" v-else-if="!hasToShowLogoAndImagesTab">
						Clickea aquí para mostrar carga de logo e imágenes
					</p>
				</div>
			</section>
			<section class="mainFilter-filter expand-arrow">
				<div @click="onExpandSection(`logoAndImages`)" class="arrow"
					:class="{ expanded: hasToShowLogoAndImagesTab }"></div>
			</section>
		</article>
		<!--  									-->
		<!-- LOGOS E IMAGENES HEADER - END 		-->
		<!--  									-->
		<!--  									-->
		<!-- LOGOS E IMAGENES BODY - START 	-->
		<!--  									-->
		<article v-show="hasToShowLogoAndImagesTab">
			<form action="" class="formGeneral profile-misc-grid profile-form">
				<article class="formMain formNotMain">
					<div v-for="(item, index) in shops" :key="`${index}-${item.code}`">
						<div v-if="hasToShowThisProfile(item.code)">
							<div>
								<article class="setting-logo-and-images-container">
									<!-- <section class="listItems-product tooltip"> -->
									<section class="setting-logo-and-images-list-item tooltip">
										<a @click="openModalById(`#modalShopLogo_${item.code}`)"
											class="waves-effect waves-light cardMain">
											<figure>
												<img src="@/assets/img/logo_picture.jpg" alt="@/assets/img/" />
											</figure>

											<div class="item">
												<h2>Logo pantalla operador/cajero</h2>
											</div>
										</a>
									</section>
									<section class="setting-logo-and-images-list-item tooltip">
										<a @click="openModalById(`#modalSecondScreen_${item.code}`)"
											class="waves-effect waves-light cardMain">
											<figure>
												<img src="@/assets/img/second_screen_picture.jpg" alt="@/assets/img/" />
											</figure>

											<div class="item">
												<h2>Logo pantalla cliente</h2>
											</div>
										</a>
									</section>
									<section class="setting-logo-and-images-list-item tooltip">
										<a @click="
											openModalById(`#modalBackgroundMenu_${item.code}`)
											" class="waves-effect waves-light cardMain">
											<figure>
												<img src="@/assets/img/background_menu_picture.jpg"
													alt="@/assets/img/" />
											</figure>

											<div class="item">
												<h2>Imágen para las cartas QR</h2>
											</div>
										</a>
									</section>
									<section class="setting-logo-and-images-list-item tooltip">
										<a @click="openModalById(`#modalPrinterLogo_${item.code}`)"
											class="waves-effect waves-light cardMain">
											<figure>
												<img src="@/assets/img/printer_logo_picture.jpg" alt="@/assets/img/" />
											</figure>

											<div class="item">
												<h2>Imágen para la impresora</h2>
											</div>
										</a>
									</section>
									<section class="setting-logo-and-images-list-item tooltip">
										<a @click="
											openModalById(`#modalFixedImageMenu_${item.code}`)
											" class="waves-effect waves-light cardMain">
											<figure>
												<img src="@/assets/img/background_menu_picture.jpg"
													alt="@/assets/img/" />
											</figure>

											<div class="item">
												<h2>
													Menu fijo en formato JPG/PNG (para escanear con QR -
													<strong>solo origen MOSTRADOR</strong>)
												</h2>
											</div>
										</a>
									</section>
									<section class="setting-logo-and-images-list-item tooltip">
										<a @click="openModalById(`#modalFloorPlan_${item.code}`)"
											class="waves-effect waves-light cardMain">
											<figure>
												<img src="@/assets/img/floor_plan_picture.png" alt="@/assets/img/" />
											</figure>

											<div class="item">
												<h2>Mapa estático de mesas</h2>
											</div>
										</a>
									</section>
									<section class="setting-logo-and-images-list-item tooltip">
										<a @click="
											openModalById(`#modalSecondScreenVideo_${item.code}`)
											" class="waves-effect waves-light cardMain">
											<figure>
												<img src="@/assets/img/second_screen_picture.jpg" alt="@/assets/img/" />
											</figure>

											<div class="item">
												<h2>Video pantalla secundaria</h2>
												<badge type="new" />
											</div>
										</a>
									</section>
									<modal-picture-upload :shopCode="item.code" :id="`modalFloorPlan_${item.code}`"
										storageRepo="firebase" title="Mapa estático de mesas"
										:imageUrl="item.floorPlanUrl" @onDeleteImage="
											removeImgByShopCodeAndType(
												item.code,
												'floorPlan',
												`floorPlan_${item.code}`
											)
											" @onPictureUploadedOk="onFloorPlanUploadedOk" />
									<modal-picture-upload :shopCode="item.code" :id="`modalFixedImageMenu_${item.code}`"
										title="Menu fijo en formato JPG/PNG (para escanear con QR)"
										:imageUrl="item.backgroundMenuUrl"
										:disclaimer="`(Archivo .jpg, .jpeg, .bmp o .png. Tamaño no mayor a 2MB, Resolución máxima: 30000px(Alto) x 4500px(Ancho))`"
										:maxWidth="4500" :maxHeight="30000" @onDeleteImage="
											removeImgByShopCodeAndType(
												item.code,
												'backgroundMenu',
												`backgroundMenu_${item.code}`
											)
											" @onPictureUploadedOk="onFixedImageMenuUploadedOk" />
									<modal-picture-upload :shopCode="item.code" :id="`modalShopLogo_${item.code}`"
										title="Logo pantalla operador/cajero" :imageUrl="item.shopLogoUrl"
										@onDeleteImage="
											removeImgByShopCodeAndType(
												item.code,
												'shopLogo',
												`shoplogo_${item.code}`
											)
											" @onPictureUploadedOk="onShopLogoUploadedOk" />
									<modal-picture-upload :shopCode="item.code" :id="`modalPrinterLogo_${item.code}`"
										storageRepo="firebase" title="Imágen para la impresora"
										disclaimer="(Archivo .jpg, .jpeg, .bmp o .png. Preferiblemente 512px x 512px máx, .bmp blanco y negro, y con fondo blanco/transparente)"
										:imageUrl="item.printerLogoUrl" @onDeleteImage="
											removeImgByShopCodeAndType(
												item.code,
												'printerLogo',
												`prnlogo_${item.code}`
											)
											" @onPictureUploadedOk="onPrinterLogoUploadedOk" />
									<modal-picture-upload :shopCode="item.code" :id="`modalSecondScreen_${item.code}`"
										title="Logo pantalla cliente" :imageUrl="item.secondScreenLogoUrl"
										@onDeleteImage="
											removeImgByShopCodeAndType(
												item.code,
												'secondScreen',
												`sslogo_${item.code}`
											)
											" @onPictureUploadedOk="onSecondScreenUploadedOk" />
									<modal-picture-upload :shopCode="item.code" :id="`modalBackgroundMenu_${item.code}`"
										title="Imágen para las cartas QR" :imageUrl="item.menuLogoUrl" @onDeleteImage="
											removeImgByShopCodeAndType(
												item.code,
												'menuBackground',
												`menulogo_${item.code}`
											)
											" @onPictureUploadedOk="onBackgroundMenuUploadedOk" />
									<modal-video-upload :shopCode="item.code"
										:id="`modalSecondScreenVideo_${item.code}`" title="Video pantalla secundaria"
										:imageUrl="item.secondScreenVideo" @onDeleteImage="
											removeVideoByShopCodeAndType(
												item.code,
												'secondScreenVideo',
												`secondScreenVideo_${item.code}`
											)
											" @onPictureUploadedOk="onSecondScreenVideoUploadedOk" />
								</article>
							</div>
						</div>
					</div>
				</article>
			</form>
		</article>
		<!--  									-->
		<!-- LOGOS E IMAGENES BODY - END 		-->
		<!--  									-->

		<!--  									-->
		<!-- GENERACION DE QR HEADER - START 	-->
		<!--  									-->
		<article :class="getQrTabStyles" v-if="hasToShowQrDownloadSection">
			<section class="mainFilter-title pointer" @click="onExpandSection(`qr`)">
				<div class="fix-title">
					<h1><span>Descarga de QR de cartas y mesas</span></h1>
					<p class="clickHint" v-if="hasToShowQrTab">
						Clickea aquí para esconder la descarga de QR de cartas y mesas
					</p>
					<p class="clickHint" v-else-if="!hasToShowQrTab">
						Clickea aquí para mostrar la descarga de QR de cartas y mesas
					</p>
				</div>
			</section>
			<section class="mainFilter-filter expand-arrow">
				<div @click="onExpandSection(`qr`)" class="arrow" :class="{ expanded: hasToShowQrTab }"></div>
			</section>
		</article>
		<!--  									-->
		<!-- GENERACION DE QR HEADER - END 		-->
		<!--  									-->
		<!--  									-->
		<!-- GENERACION DE QR BODY	 - START 	-->
		<!--  									-->
		<article v-if="hasToShowQrDownloadSection" v-show="hasToShowQrTab">

			<form action="" class="formGeneral profile-misc-grid profile-misc-panel profile-form">
				<article class="formMain formNotMain">
					<div v-for="(item, index) in shops" :key="`${index}-${item.code}`">
						<div v-if="hasToShowThisProfile(item.code)">
							<div>
								<Spinner v-if="isLoading" />

								<div v-if="qrs.filter((x) => x.code === item.code)[0]">
									<div class="profile-subtitle itemForm">
										<label class="settings-label"><strong>QR para las cartas</strong> Si desea
											publicar una
											carta descargue el QR correspondiente a
											continuación.</label>
									</div>
									<article class="profile-base-height">
										<div class="profile-qrs">
											<div v-for="(qrItem, qrIndex) in qrs" :key="`${qrIndex}-${qrItem.code}`">
												<div class="profile-qr" v-if="qrItem.code === item.code">
													<div>{{ qrItem.menuName }}</div>
													<div>
														<img v-if="checkQrsShop(qrItem.code, item.code)"
															class="shopLogoImg" :src="qrItem.url" width="200"
															height="200" />
													</div>
													<article class="btn-main profile-btn-qr">
														<a @click="
															downloadQr(
																qrItem.url,
																`Menu_${qrItem.menuName}_${qrItem.code}`
															)
															" type="submit">
															<p>DESCARGAR</p>
														</a>
													</article>
												</div>
											</div>
										</div>
									</article>
								</div>

								<div v-if="tableQrs.filter((x) => x.code === item.code)[0]">
									<div class="profile-subtitle itemForm">
										<label class="settings-label"><strong>QR de mesas </strong>
											<badge type="beta" /> Si
											desea que cada mesa pueda consultar la carta y ver su
											cuenta online entonces cada mesa debera tener su propio
											codigo QR. Puede descargarlos todos a continuación
										</label>
									</div>
									<article class="profile-base-height">
										<div class="profile-qrs">
											<div v-for="(qrItem, qrIndex) in tableQrs"
												:key="`${qrIndex}-${qrItem.code}`">
												<div class="profile-qr" v-if="qrItem.code === item.code">
													<div>{{ qrItem.tableName }}</div>
													<div>
														<img v-if="checkQrsShop(qrItem.code, item.code)"
															class="shopLogoImg" :src="qrItem.url" width="200"
															height="200" />
													</div>
													<article class="btn-main profile-btn-qr">
														<a @click="
															downloadQr(
																qrItem.url,
																`Mesa_${qrItem.tableName}_${qrItem.code}`
															)
															" type="submit">
															<p>DESCARGAR</p>
														</a>
													</article>
												</div>
											</div>
										</div>
									</article>
								</div>
							</div>
						</div>
					</div>
				</article>
			</form>
		</article>
		<!--  									-->
		<!-- GENERACION DE QR BODY - END 		-->
		<!--  									-->

		<!--  									-->
		<!-- MIS DATOS FISCALES HEADER - START 	-->
		<!--  									-->
		<article v-show="hasToShowFiscalDataSection" :class="getMyFiscalDataTabStyles">
			<section class="mainFilter-title pointer" @click="onExpandSection(`fiscal_data`)">
				<div class="fix-title">
					<h1><span>Mis Datos Fiscales</span></h1>
					<p class="clickHint" v-if="hasToShowMyFiscalDataTab">
						Clickea aquí para esconder mis datos fiscales
					</p>
					<p class="clickHint" v-else-if="!hasToShowMyFiscalDataTab">
						Clickea aquí para mostrar mis datos fiscales
					</p>
				</div>
			</section>
			<section class="mainFilter-filter expand-arrow">
				<div @click="onExpandSection(`fiscal_data`)" class="arrow"
					:class="{ expanded: hasToShowMyFiscalDataTab }"></div>
			</section>
		</article>
		<!--  									-->
		<!-- MIS DATOS FISCALES HEADER - END 	-->
		<!--  									-->
		<!--  									-->
		<!-- MIS DATOS FISCALES BODY- START 	-->
		<!--  									-->
		<article v-show="hasToShowMyFiscalDataTab">

			<form action="" class="formGeneral profile-misc-grid profile-misc-panel profile-form">
				<article class="formMain formNotMain">
					<div v-for="(item, index) in shops" :key="`${index}-${item.code}`">
						<FiscalDataAR :shop="item" :hasToShowThisProfile="hasToShowThisProfile(item.code)"
							:isLoading="isSettingLoading"></FiscalDataAR>
						<FiscalDataES :shop="item" :hasToShowThisProfile="hasToShowThisProfile(item.code)"
							:isLoading="isSettingLoading"></FiscalDataES>
						<FiscalDataMX :shop="item" :hasToShowThisProfile="hasToShowThisProfile(item.code)"
							:isLoading="isSettingLoading" :isMounting="isMountingComponent"></FiscalDataMX>
					</div>
				</article>
			</form>
		</article>
		<!--  									-->
		<!-- MIS DATOS FISCALES BODY - END 		-->
		<!--  									-->

		<!--  									-->
		<!-- OPCIONES MULTIDELIVERY - START 	-->
		<!--  									-->
		<article v-show="hasToShowMultideliverySection" :class="getMultideliveryTabStyles">
			<section class="mainFilter-title pointer" @click="onExpandSection(`multidelivery`)">
				<div class="fix-title">
					<h1><span>Integraciones</span></h1>
					<p class="clickHint" v-if="hasToShowMultideliveryTab">
						Clickea aquí para esconder las integraciones con terceros
					</p>
					<p class="clickHint" v-else-if="!hasToShowMultideliveryTab">
						Clickea aquí para ver las integraciones con terceros
					</p>
					<badge type="new" />
				</div>
			</section>
			<section class="mainFilter-filter expand-arrow">
				<div @click="onExpandSection(`multidelivery`)" class="arrow"
					:class="{ expanded: hasToShowMultideliveryTab }"></div>
			</section>
		</article>
		<!--  								-->
		<!-- OPCIONES MULTIDELIVERY - END 	-->
		<!--  								-->
		<!--  										-->
		<!-- OPCIONES MULTIDELIVERY BODY - START 	-->
		<!--  										-->
		<article v-show="hasToShowMultideliveryTab">
			<form action="" class="formGeneral profile-misc-grid profile-misc-panel profile-form">
				<article class="formMain formNotMain">
					<div v-for="(item, index) in shops" :key="`${index}-${item.code}`">
						<!-- Componente -->

						<IntegrationCards :shop="item" :hasToShowThisProfile="hasToShowThisProfile(item.code)">
						</IntegrationCards>
					</div>
				</article>
			</form>
		</article>
		<!--  										-->
		<!-- OPCIONES MULTIDELIVERY BODY - END	 	-->
		<!--  										-->

		<!--  									-->
		<!-- OPCIONES TIENDA ONLINE - START 	-->
		<!--  									-->
		<article v-show="hasToShowOnlineStoreSection" :class="getOnlineStoreTabStyles">
			<section class="mainFilter-title pointer" @click="onExpandSection(`online_store`)">
				<div class="fix-title">
					<h1><span>Configuraciones de Tienda Online</span></h1>
					<p class="clickHint" v-if="hasToShowOnlineStoreTab">
						Clickea aquí para esconder configuraciones de Tienda Online
					</p>
					<p class="clickHint" v-else-if="!hasToShowOnlineStoreTab">
						Clickea aquí para mostrar configuraciones de Tienda Online
					</p>
				</div>
			</section>
			<section class="mainFilter-filter expand-arrow">
				<div @click="onExpandSection(`online_store`)" class="arrow"
					:class="{ expanded: hasToShowOnlineStoreTab }"></div>
			</section>
		</article>
		<!--  								-->
		<!-- OPCIONES TIENDA ONLINE - END 	-->
		<!--  								-->
		<!--  										-->
		<!-- OPCIONES TIENDA ONLINE BODY - START 	-->
		<!--  										-->
		<article v-show="hasToShowOnlineStoreTab">
			<form action="" class="formGeneral profile-misc-grid profile-misc-panel profile-form">
				<article class="formMain formNotMain">
					<div v-for="(item, index) in shops" :key="`${index}-${item.code}`">
						<div v-if="hasToShowThisProfile(item.code)">
							<div>
								<!--  									-->
								<!-- SWITCHES DE TIENDA ONLINE START	-->
								<!--  									-->

								<!--URL-->
								<div class="section-divider">
									<h4>Nombre de la Tienda:</h4>
									<div class="profile-subtitle itemForm">
										<div class="settings-div switch profile-time-range">
											<input v-model="item.displayedName" type="text"
												class="search-input onlinestore-input onlinestore-bottom"
												:placeholder="item.name" />
										</div>
									</div>
									<h4>Acceso a la Tienda Online:</h4>
									<div class="settings-div switch">
										<h5>
											<a :href="`https://${getBOrdersSubDomainByInstance()}.bistrosoft.com/menu/weborders?commerceId=${item.code
												}`">Mi Tienda Online</a>
										</h5>
									</div>
									<h5>QR:</h5>
								</div>

								<!--QR-->
								<div class="profile-subtitle itemForm">
									<article class="profile-base-height">
										<QR title="Tienda virtual"
											:domain="`https://${getBOrdersSubDomainByInstance()}.bistrosoft.com/menu/weborders`"
											fileName="Menu" :shopCode="item.code" />
									</article>
								</div>

								<!--Editor de instrucciones para Tienda Online-->
								<div class="itemForm itemFormFull">
									<div style="display: flex; flex-direction: column">
										<WYSIWYG title="Editor de instrucciones para Tienda Online"
											:shopCode="item.code" :banner="item.bOrdersBanner"
											@onSaving="onSavingBanner" />
									</div>
								</div>

								<time-picker ref="timePickerOpeningHours" :shopCode="item.code"
									title="Horarios de Apertura/Cierre"
									subtitle="Personalice los horarios de apertura y cierre de su tienda"
									:configHours="item.bOrdersOpeningHours" @upsertConfigHours="copyConfigHours" />

								<!--Horarios de delivery-->
								<delivery-time-picker :shopCode="item.code" title="Horarios de Delivery"
									:openingConfigHours="item.bOrdersOpeningHours"
									:configHours="item.bOrdersDeliveryHours"
									:sameOpeningHours="item.sameOpeningHoursForDelivery"
									@upsertDeliveryConfigHours="copyDeliveryConfigHours" />

								<!--Configuracion de Delivery-->
								<div class="section-divider-map">
									<h4>Configuración de Delivery:</h4>
									<div class="profile-subtitle itemForm">
										<div class="settings-div settings-div-onlinestore switch">
											<label>
												<input v-model="item.minimumAmountIsEnabled" @change="avoidRectangleAtTheEnd" type="checkbox" />
												<span class="lever"></span>
												<a class="settings-label settings-label-checkbox onlinestore-text">Monto
													Mínimo</a>
											</label>

											<div v-if="item.minimumAmountIsEnabled" class="ml-5">
												<!--
												<label for="montoInput" class="settings-label">Ingrese el monto mínimo:</label>
												-->
												<input type="number" id="montoInput"
													class="search-input onlinestore-input" v-model="item.minimumAmount"
													min="0" />
											</div>
										</div>

										<!--Costo de envio-->
										<div class="settings-div settings-div-onlinestore switch">
											<label>
												<input v-model="item.shippingCostIsEnabled" type="checkbox" @change="avoidRectangleAtTheEnd" />
												<span class="lever"></span>
												<a class="settings-label settings-label-checkbox onlinestore-text">Costo
													de envío</a>
											</label>

											<div v-if="item.shippingCostIsEnabled" class="ml-5">
												<input type="number" id="montoInput"
													class="search-input onlinestore-input" v-model="item.shippingCost"
													min="0" />
											</div>
										</div>

										<!-- Zona de delivery -->
										<div v-if="hasToShowDeliveryZone(item)"
											class="settings-div switch settings-div-onlinestore">
											<label>
												<input v-model="item.deliveryZone.enabled" type="checkbox" @change="avoidRectangleAtTheEnd" />
												<span class="lever"></span>
												<a class="settings-label settings-label-checkbox">Zona de Delivery</a>
											</label>
										</div>
										<div v-else>ASDASDASDASDASDASD</div>
										<delivery-map v-if="
											hasToShowDeliveryZone(item) && item.deliveryZone.enabled
										" :showTitle="true" :showSaveButton="false" :address-coords="item.deliveryZone.addressCoords"
											:is-radial-zone-selected-prop="item.deliveryZone.isRadialZoneSelected
												" :radius-prop="item.deliveryZone.radius" :polygonCoordsProp="item.deliveryZone.polygonCoords"
											@saveDeliveryZone="(updatedDeliveryZoneConfig) =>
												updateDeliveryZone(
													updatedDeliveryZoneConfig,
													item.deliveryZone
												)
												">
										</delivery-map>
									</div>
								</div>

								<!--Configuración de tiempo de entrega-->
								<div class="section-divider">
									<h4>Tiempos de Entrega (minutos)</h4>
									<div class="profile-subtitle itemForm">
										<!--PickUp-->
										<div class="settings-div switch">
											<label>
												<input v-model="item.pickupTimeIsEnabled" type="checkbox" @change="avoidRectangleAtTheEnd" />
												<span class="lever"></span>
												<a class="settings-label settings-label-checkbox">PickUp</a>
											</label>
										</div>
										<div class="profile-time-range">
											<div class="settings-div switch">
												<span v-if="item.pickupTimeIsEnabled" class="ml-3">
													<label
														class="settings-label settings-label-checkbox">Mínimo:</label>
													<div class="ml-3">
														<input type="number" id="pickupTimeInput"
															class="search-input onlinestore-input"
															v-model="item.minimumPickupTimeInMinutes" min="0"
															max="600" />
													</div>
													<label
														class="settings-label settings-label-checkbox">Máximo:</label>
													<div class="ml-3">
														<input type="number" id="pickupTimeInput"
															class="search-input onlinestore-input"
															v-model="item.maximumPickupTimeInMinutes" min="0"
															max="600" />
													</div>
												</span>
											</div>
										</div>

										<!--Delivery-->
										<div class="settings-div switch">
											<label>
												<input v-model="item.deliveryTimeIsEnabled" type="checkbox" @change="avoidRectangleAtTheEnd" />
												<span class="lever"></span>
												<a class="settings-label settings-label-checkbox">Delivery</a>
											</label>
										</div>
										<div class="profile-time-range">
											<div class="settings-div switch">
												<span v-if="item.deliveryTimeIsEnabled" class="ml-3">
													<label
														class="settings-label settings-label-checkbox">Mínimo:</label>
													<div class="ml-3">
														<input type="number" id="deliveryTimeInput"
															class="search-input onlinestore-input"
															v-model="item.minimumDeliveryTimeInMinutes" min="0"
															max="600" value="0" />
													</div>
													<label
														class="settings-label settings-label-checkbox">Máximo:</label>
													<div class="ml-3">
														<input type="number" id="deliveryTimeInput"
															class="search-input onlinestore-input"
															v-model="item.maximumDeliveryTimeInMinutes" min="0"
															max="600" value="0" />
													</div>
												</span>
											</div>
										</div>
									</div>
								</div>

								<!--Metodos de pago-->
								<div class="section-divider">
									<h4>Medios de Pago Habilitados:</h4>
									<div class="settings-div switch"
										v-for="(paymentWay, index) in item.availablePaymentWays"
										:key="paymentWay.id || index">
										<div v-if="paymentWay.hasToShow">
											<label>
												<input v-model="paymentWay.isEnabled" type="checkbox" @change="avoidRectangleAtTheEnd" />
												<span class="lever"></span>
												<a class="settings-label settings-label-checkbox">
													{{ paymentWay.paymentWay }}
												</a>
											</label>
										</div>
									</div>

								</div>

								<div class="section-divider">
									<h4>Contacto y Redes Sociales:</h4>
									<div class="profile-subtitle itemForm">
										<!--Telefono de contacto-->
										<div class="settings-div switch profile-time-range">
											<label>
												<a class="settings-label settings-label-checkbox">Teléfono:</a>
											</label>
											<!--Input-->
											<div class="ml-3">
												<input type="text" class="search-input onlinestore-input"
													v-model="item.helpContactCenter" placeholder="+54 11 2222 3333" />
											</div>
										</div>
										<!-- Instagram -->
										<div class="settings-div switch profile-time-range">
											<label>
												<a class="settings-label settings-label-checkbox">Instagram:</a>
											</label>
											<!--Input-->
											<div class="ml-3">
												<input type="text" class="search-input onlinestore-input-url"
													v-model="item.instagramUrl"
													placeholder="https://www.instagram.com/tucomercio/" />
											</div>
										</div>
										<!-- Facebook -->
										<div class="settings-div switch profile-time-range">
											<label>
												<a class="settings-label settings-label-checkbox">Facebook:</a>
											</label>
											<!--Input-->
											<div class="ml-3">
												<input type="text" class="search-input onlinestore-input-url"
													v-model="item.facebookUrl"
													placeholder="https://www.facebook.com/tucomercio/" />
											</div>
										</div>
									</div>
								</div>

								<div class="config-hours-container">
									<h4>Otras configuraciones:</h4>
									<div class="profile-subtitle itemForm">
										<div class="settings-div switch profile-time-range">
											<div>
												<label>
													<input v-model="item.hasToShowUnavailableProducts" @change="avoidRectangleAtTheEnd"
														type="checkbox" />
													<span class="lever"></span>
													<a class="settings-label settings-label-checkbox">
														Mostrar productos no disponibles
													</a>
												</label>
											</div>
										</div>
										<!--Validacion de stock-->
										<div class="settings-div switch settings-div-onlinestore">
											<label>
												<input v-model="item.hasStockValidation" type="checkbox" @change="avoidRectangleAtTheEnd"/>
												<span class="lever"></span>
												<a class="settings-label settings-label-checkbox">Ocultar productos sin
													stock</a>
											</label>
										</div>
									</div>
								</div>

								<div class="section-divider">
									<h4>Anuncio Personalizado:</h4>
									<div class="profile-subtitle itemForm">
										<!-- Link del pop-up (opcional) -->
										<div class="settings-div switch profile-time-range settings-image">
											<label>
												<a class="settings-label settings-label-checkbox settings-label-load">Link
													(opcional):</a>
											</label>
											<!--Input-->
											<div class="ml-3 settings-div2">
												<input type="text"
													class="search-input onlinestore-input settings-image settings-input-file-url-popup"
													v-model="item.popUpUrl" placeholder="https://milink.com/tienda" />
											</div>
										</div>

										<!-- Imagen del pop-up -->
										<div class="settings-div switch profile-time-range settings-image">
											<label>
												<a
													class="settings-label settings-label-checkbox settings-label-load">Imagen:</a>
											</label>
											<!-- Input -->
											<div class="ml-3 settings-div2">
												<input type="file"
													class="search-input onlinestore-input settings-image settings-input-file-image-popup"
													id="fileupload" :key="fileInputKey" @change="onFileSelected" />
												<a class="selectImage-trashBin" @click="deleteImage">
													<span class="icon-Bistro-_Trash_bin"></span>
												</a>
											</div>
										</div>
									</div>
								</div>

								<!--Boton de guardar-->
								<article class="btn-main btn-main-certificate">
									<Spinner v-if="isUpdateAllOnlineStoreConfiguration" />
									<a v-else class="btn-active" @click="updateAllOnlineStoreConfiguration(item)">
										<p>GUARDAR TODO</p>
									</a>
								</article>
								<!--  									-->
								<!-- SWITCHES DE TIENDA ONLINE END 		-->
								<!--  									-->
							</div>
						</div>
					</div>
				</article>
			</form>
		</article>
		<!--  										-->
		<!-- OPCIONES TIENDA ONLINE BODY - END	 	-->
		<!--  										-->
		<help-center />
	</main>
</template>

<script>
import axios from "axios";
import "firebase/storage";
import mixCommons from "@/mixins/mixCommons";
import pictureCommons from "@/mixins/pictureCommons";
import modalCommons from "@/mixins/mixModals";
import rolesCommons from "@/mixins/rolesCommons";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import HelpCenter from "@/components/HelpCenter";
import TimePicker from "@/components/TimePicker";
import DeliveryTimePicker from "@/components/DeliveryTimePicker";
import ModalPictureUpload from "@/components/modals/ModalPictureUpload";
import ModalVideoUpload from "@/components/modals/ModalVideoUpload";
import Badge from "@/components/BadgeComp";
import Spinner from "@/components/SpinnerComp";
import computeds from "@/mixins/menuOptions";
import originCommons from "@/mixins/originCommons";
import productV2Commons from "@/mixins/productV2Commons";
import bOrdersCommons from "@/mixins/bOrdersCommons";
import DeliveryMap from "@/components/DeliveryMap";
import QrGenerator from "@/mixins/QrGenerator";
import FiscalDataES from "@/components/FiscalDataES";
import FiscalDataAR from "@/components/FiscalDataAR";
import FiscalDataMX from "@/components/FiscalDataMX";
import IntegrationCards from "@/components/IntegrationCards";
import QR from "@/components/QR";
import WYSIWYG from "@/components/WYSIWYG";
import timePickerCommons from "@/mixins/timePickerCommons";

import SettingsCommons from "@/mixins/settingsCommons.vue";


export default {
	mixins: [
		mixCommons,
		computeds,
		rolesCommons,
		bistroV2Commons,
		bOrdersCommons,
		QrGenerator,
		modalCommons,
		originCommons,
		timePickerCommons,
		productV2Commons,
		pictureCommons,
		SettingsCommons
	],
	async mounted() {
		document.title = `${this.appTitle} - Configuraciones`
		if (this.isUserLoggedIn() === false) {
			axios
				.get("/api/auth/logout") /// OK
				.then(() => {
						this.$store.SET_USER( null);
					this.resetUser();
					this.setAutomaticLogin(false);
					location.href = "/login";
				});
		} else if (this.hasToShowThisOption(8) === false) {
			this.handleUnauthorizedAccess()

		} else {
				if(await this.checkSessionInBackendAsync()) {
				this.onMounted();
			}
		}

	},

	

	components: {
		Spinner,
		Badge,
		HelpCenter,
		TimePicker,
		DeliveryTimePicker,
		ModalPictureUpload,
		ModalVideoUpload,
		
		QR,
		WYSIWYG,
		DeliveryMap,
		FiscalDataES,
		FiscalDataAR,
		FiscalDataMX,
		IntegrationCards,

	},

};
</script>
<style scoped>
main {
	padding-bottom: 30vh;
}
</style>

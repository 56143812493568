import { defineStore } from "pinia";

export const usePdvStore = defineStore("pdv", {
    state: () => {
        return {
            order: null,
            hasToShowDetails: false,
            isExactPayment: true,
            tempReceivedAmount: null,
            isDisabled: false,
        };
    },
    getters: {
        getOrder: (state) => state.order,
        getShowDetails: (state) => state.hasToShowDetails,
        getIsExactPayment: (state) => state.isExactPayment,
        getIsDisabled: (state) => state.isDisabled,
        getTempReceivedAmount: (state )=> state.tempReceivedAmount
    },
    actions: {
        setOrder(order) {
            this.order = order;
        },
        setIsExactPayment(isExactPayment) {
            this.isExactPayment = isExactPayment;
        },
        setShowDetails(hasToShowDetails) {
            this.hasToShowDetails = hasToShowDetails;
        },
        setIsDisabled(isDisabled) {
            this.isDisabled = isDisabled;
        },
        setTempReceivedAmount(tempReceivedAmount){
            this.tempReceivedAmount = tempReceivedAmount
        }
    },
});

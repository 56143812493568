<template>
	<main>
		<article v-if="isProviderActionSelected">
			<!-- LISTADO DE PROVEEDORES --- V2 -->
			<article class="mainFilter mainFilterMobile mainFilter-activeHover"
				:class="{ 'mainFilter-active': this.expandProviders }">
				<!-- TITULO Y SEARCH -->
				<section class="mainFilter-title pointer" @click="onExpandProviders()">
					<div class="border-right fix-title">
						<h1><span>Proveedores</span></h1>
						<!-- <p class="clickHint" v-if="expandProviders">Clickea aquí para esconder Proveedores</p>
						<p class="clickHint" v-else>Clickea aquí para mostrar Proveedores</p> -->
					</div>
					<div class="totalFilter fix-totalFilter">
						<p>
							<!-- Quantity V2 -->
							<strong>{{
								filteredProviders ? filteredProviders.length : 0
							}}</strong>
							<br />Proveedores
						</p>

						<!-- <input v-model="criteria" type="text" class="inputFilter" placeholder="Buscar Productos"> -->
					</div>
				</section>

				<!-- OPCIONES ADICIONALE -->
				<section class="mainFilter-filter showForMobile">
					<input v-if="mobile === false && expandProviders" class="search-input" v-model="providerSearchInput"
						placeholder="Filtrar proveedor" type="text" />

					<!-- OPCIONES DE PROVEEDOR -->
					<article class="menues">
						<a @click="openNewProviderModal()" class="prodDropdown">
							<div class="btn-main">
								<a class="waves-effect waves-light plus-sign">
									<p v-if="mobile">+</p>
									<p v-else>+ Proveedor</p>
								</a>
							</div>
						</a>
					</article>

					<div @click="onExpandProviders()" class="arrow" :class="{ expanded: expandProviders }"></div>
				</section>
			</article>

			<article v-show="expandProviders && filteredProviders && filteredProviders.length > 0
				" class="listItems">
				<section v-for="(provider, index) in filteredProviders" :key="index"
					class="listItems-product listItems-bigCard mainProvider">
					<a @click="onOpenProviderDetails(provider)" class=" waves-effect waves-light cardMain">
						<figure class="nameInitial">
							<p>{{ getInitials(provider.name) }}</p>
						</figure>

						<div class="item">
							<h2>{{ provider.name }}</h2>
							<p>{{ provider.fiscalId }}</p>
						</div>
					</a>
				</section>
			</article>
			<!-- LISTADO DE PROVEEDORES --- V2 -->

			<!-- LISTADO DE CATEGORIAS DE PROVEEDOR --- V2 -->
			<article class="mainFilter mainFilterMobile mainFilter-activeHover"
				:class="{ 'mainFilter-active': this.expandCategories }">
				<!-- TITULO Y SEARCH -->
				<section class="mainFilter-title pointer" @click="onExpandCategories()">
					<div class="border-right fix-title">
						<h1><span>Categorías</span></h1>
						<!-- <p class="clickHint" v-if="expandCategories">Clickea aquí para esconder Categorías</p>
						<p class="clickHint" v-else>Clickea aquí para mostrar Categorías</p> -->
					</div>
					<div class="totalFilter fix-totalFilter">
						<p>
							<!-- Quantity V2 -->
							<strong>{{
								filteredCategories ? filteredCategories.length : 0
							}}</strong>
							<br />Categorías
						</p>
					</div>
				</section>

				<!-- OPCIONES ADICIONALE -->
				<section class="mainFilter-filter showForMobile">
					<input v-if="mobile === false && expandCategories" class="search-input"
						v-model="categorySearchInput" placeholder="Filtrar categoría" type="text" />

					<!-- OPCIONES DE PROVEEDOR -->
					<article class="menues">
						<a @click="openNewCategoryModal()" class="prodDropdown">
							<div class="btn-main">
								<a class="waves-effect waves-light plus-sign">
									<p v-if="mobile">+</p>
									<p v-else>+ Categoría</p>
								</a>
							</div>
						</a>
					</article>

					<div @click="onExpandCategories()" class="arrow" :class="{ expanded: expandCategories }"></div>
				</section>
			</article>

			<article v-show="expandCategories &&
				filteredCategories &&
				filteredCategories.length > 0
				" class="listItems">
				<section v-for="(cat, index) in filteredCategories" :key="index" class="listItems-product mainProvider">
					<a @click="onOpenCategoryDetails(cat)" class=" waves-effect waves-light cardMain">
						<div class="item widthLargeColumnItem">
							<h2>{{ cat.name }}</h2>
							<label>{{ getSubcategoriesQty(cat) }} sub-categorías</label>
						</div>
					</a>
				</section>
			</article>
			<!-- LISTADO DE CATEGORIAS DE PROVEEDORES --- V2 -->
		</article>

		<article v-else>
			<section>
				<!-- FILTRADO DE LA SECCION -->
				<article class="mainFilter filterEvent">
					<!-- TITULO Y SEARCH -->
					<section v-if="hasToShowFilters" class="mainFilter-title">
						<h1>Comprobantes</h1>
					</section>
					<section v-else class="mainFilter-receipt-provider-title">
						<h1>{{ this.selectedProviderName }}</h1>
						<div>{{ this.selectedProviderFiscalId }}</div>
						<div>{{ this.selectedProviderPhone }}</div>
					</section>

					<!-- OPCIONES ADICIONALE -->
					<section class="mainFilter-filter bring-to-front">
						<div class="btnCloseStock">
							<a :href="`/api/providersV2/billingReport?${excelParams}`"
								class="waves-effect waves-light btn-link">
								<div class="btn-link btn-download-provider">
									<span class="icon-Bistro-_Download"></span>
									<span>Descargar Detalle</span>
								</div>
							</a>

							<div class="modal-action modal-close waves-green btn-flat btn-flat-stock-header">
								<div class="btn-main btn-provider">
									<a href="#" @click="openNewReceiptModal" class="waves-effect waves-light plus-sign">
										<p v-if="mobile">+</p>
										<p v-else>+ Comprobante</p>
									</a>
								</div>
							</div>
						</div>
					</section>
				</article>

				<!-- LISTADO DE PRODUCTOS -->
				<article :class="{ errorLoading: receipts && receipts.length === 0 }" class="mainEvents salesDashboard">
					<!-- TIPO DE TRANSACCION -->
					<section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">
						<div class="containTransaction">
							<ul class="containTransaction-lists">
								<!-- Titulo -->
								<li>
									<h4>TIPO</h4>
									<div class="switch">
										<label>
											<input v-model="typeFilters" @change="avoidRectangleAtTheEnd" type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>

								<!-- OPCION 1 -->
								<li>
									<p>Gasto</p>
									<div class="switch">
										<label>
											<input v-model="filterByExpenditure" @change="chargeFilterType"
												type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>
								<!-- OPCION 2 -->
								<li>
										<p>Compra</p>
										<div class="switch">
											<label>
												<input v-model="filterByPurchase" @change="chargeFilterType"
													type="checkbox" />
												<span class="lever"></span>
											</label>
										</div>
									</li>
								</ul>

							<!-- PROVEEDOR -->
							<ul v-if="receiptFilterProviders.length > 0 && hasToShowFilters"
								class="containTransaction-lists">
								<!-- Titulo -->
								<li>
									<h4>PROVEEDOR</h4>
									<div class="switch">
										<label>
											<input v-model="providerFilters" @change="avoidRectangleAtTheEnd" type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>

								<li v-for="(p, index) in receiptFilterProviders" :key="index">
									<p>{{ p.name }}</p>
									<div class="switch">
										<label>
											<input :checked="p.selected"
												@change="chargeFilterProvider(p.id, !p.selected)"
												:id="`provider_${p.id}`" type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>
							</ul>
							<ul class="containTransaction-lists">
								<!-- Titulo -->
								<li>
									<h4>PAGO</h4>
									<div class="switch">
										<label>
											<input v-model="paidFilters" @change="avoidRectangleAtTheEnd" type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>

								<!-- OPCION 1 -->
								<li>
									<p>Pagado</p>
									<div class="switch">
										<label>
											<input v-model="filterByPaid" @change="chargeFilterType" type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>
								<!-- OPCION 2 -->
								<li>
									<p>No Pagado</p>
									<div class="switch">
										<label>
											<input v-model="filterByNonPaid" @change="chargeFilterType"
												type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>
							</ul>

							<!-- CATEGORIA -->
							<ul v-if="receiptFilterCategories.length > 0" class="containTransaction-lists">
								<!-- Titulo -->
								<li>
									<h4>CATEGORÍA</h4>
									<div class="switch">
										<label>
											<input v-model="categoryFilters" @change="avoidRectangleAtTheEnd" type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>

								<li v-for="(c, index) in receiptFilterCategories" :key="index">
									<p>{{ c.name }}</p>
									<div class="switch">
										<label>
											<input :checked="c.selected"
												@change="chargeFilterCategory(c.id, !c.selected)"
												:id="`category_${c.id}`" type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>
							</ul>

							<!-- SUB CATEGORIA -->
							<ul v-if="receiptFilterSubcategories.length > 0" class="containTransaction-lists">
								<!-- Titulo -->
								<li>
									<h4>SUB CATEGOR&iacute;A</h4>
									<div class="switch">
										<label>
											<input v-model="subCategoryFilters" @change="avoidRectangleAtTheEnd" type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>

								<li v-for="(s, index) in receiptFilterSubcategories" :key="index">
									<p>{{ s.name }}</p>
									<div class="switch">
										<label>
											<input :checked="s.selected"
												@change="chargeFilterSubcategory(s.id, !s.selected)" type="checkbox" />
											<span class="lever"></span>
										</label>
									</div>
								</li>
							</ul>
						</div>
						<!-- BTN ACTIVE FILTER MOBILE -->
						<!-- <button @click="showFilter = !showFilter" class="btnFilterMobile">
							<span class="icon-Bistro-_Filter"></span>
						</button> -->
					</section>

					<!-- LISTADO DE FACTURAS -->
					<ul v-if="receipts && receipts.length > 0" class="mainEvents-history">
						<receipt-row-header />
						<receipt-row v-for="(receipt, index) in receipts" :data="receipt" :key="index"
							@onReceiptEdition="onReceiptEdition" />
						<!-- <receipt-row
							v-for="(receipt, index) in receipts"
							:data="receipt"
							:key="index"
							@onDeletingReceipt="onDeletingReceipt"
						/> -->
					</ul>
					<empty-element v-else-if="receipts && receipts.length === 0" />
				</article>
				<div v-if="receipts && receipts.length > 0 && isMoreOfOnePage()" class="base-pagination">
					<!-- <BasePagination
					:current-page="currentPage"
					:totalCount="data.totalCount"
					:countPerPage="countPerPage"
					class="list__pagination"
					@nextPage="pageChangeHandle('next')"
					@previousPage="pageChangeHandle('previous')"
					@loadPage="pageChangeHandle"
					/> -->
				</div>
			</section>
		</article>
		<modal-provider-category ref="modalProviderCategory" :shopCode="selectedShopCode" :category="categoryObj"
			:isNewCategory="isNewCategory" :categories="categories" @onUpsertCategory="onUpsertCategory"
			@onDeleteCategory="onDeleteCategory" />
		<modal-new-provider-receipt ref="modalProviderReceipt" :shopCode="selectedShopCode" :categories="categories"
			:providers="providers" :isNew="true" :newReceiptFilters="newReceiptFilters"
			@onReceiptCreated="onReceiptCreated" />
		<modal-new-provider-receipt ref="modalEditProviderReceipt" :shopCode="selectedShopCode" :categories="categories"
			:providers="providers" :isNew="false" @onDeletingReceipt="onDeletingReceipt"
			@onEditingReceipt="onEditingReceipt" modalId="modalEditProviderReceipt"
			:newReceiptFilters="newReceiptFilters" @onReceiptCreated="onReceiptCreated" />
		<modal-new-provider ref="modalNewProvider" :shopCode="selectedShopCode" :categories="categories"
			:providerCategories="providerCategories" :provider="providerObj" :isNewProvider="isNewProvider"
			@onUpsertProvider="onUpsertProvider" @seeReceiptsOfProvider="seeReceiptsOfProvider"
			@onDeleteProvider="onDeleteProvider" />
		<help-center />
	</main>
</template>
<script>
import computeds from "../mixins/menuOptions";
import axios from "axios";
import commons from "../mixins/mixCommons";
import mixModalsCommons from "../mixins/mixModals";
import authCommons from "../mixins/authCommons";
import rolesCommons from "../mixins/rolesCommons";
import bistroV2Commons from "../mixins/bistroV2Commons";
import { parseToQuery, filterToString } from "../utils/utils";
import EmptyElement from "../components/EmptyElement";
import HelpCenter from "../components/HelpCenter";
import ReceiptRow from "../components/ReceiptRow";
import ReceiptRowHeader from "../components/ReceiptRowHeader";
import ModalProviderCategory from "../components/ModalProviderCategory";
import ModalNewProvider from "../components/ModalNewProvider";
import ModalNewProviderReceipt from "../components/ModalNewProviderReceipt";

export default {
	mixins: [authCommons, commons, computeds, rolesCommons, bistroV2Commons, mixModalsCommons],
	data() {
		return {
			excelParams: "",
			showFilter: false,
			isNewCategory: true,
			isNewProvider: true,
			filterByExpenditure: true,
			filterByPurchase: true,
			filterByPaid: true,
			filterByNonPaid: true,
			providerSearchInput: "",
			categorySearchInput: "",
			categoryObj: {},
			providerObj: {},
			providers: [],
			receipts: [],
			receiptsTotalCount: 0,
			receiptFilterProviders: [],
			receiptFilterCategories: [],
			receiptFilterSubcategories: [],
			filteredProviders: [],
			categories: [],
			providerCategories: [],
			newReceiptFilters: {},
			filteredCategories: [],
			providerLists: [],
			selectedShopCodes: [],
			isFirstLoad: true,
			firstEntry: true,
			expandProviders: false,
			expandCategories: false,
			selectedShop: null,
			selectedList: null,
			params: {
				FilterProviders: [],
				FilterCategories: [],
				FilterSubcategories: [],
			},
			currentPage: 1,
			countPerPage: 50,
			typeFilters: true,
			paidFilters: true,
			providerFilters: true,
			categoryFilters: true,
			subCategoryFilters: true,
			isFirstLoadPage: true,
			hasToRefreshFilters: true,
			selectedProviderName: null,
			selectedProviderFiscalId: null,
			selectedProviderPhone: null,
			selectedProviderId: null,
			selectedShopCode: null,
		};
	},
	mounted() {
		document.title = `${this.appTitle}  - Proveedores`;
		if (this.isUserLoggedIn() === false) {
			axios
				.get("/api/auth/logout") /// OK
				.then(() => {
					this.$store.SET_USER(null);
					this.resetUser();
					this.setAutomaticLogin(false);
					location.href = "/login";
				});
		} else if (this.hasToShowThisOption(3) === false) {
			this.handleUnauthorizedAccess()

		} else {
			this.onMounted();
		}
	},
	watch: {

		typeFilters() {
			this.setBillingTypes(this.typeFilters);
		},
		paidFilters() {
			this.setPaidTypes(this.paidFilters);
		},
		providerFilters() {
			this.receiptFilterProviders.forEach((p, index) => {
				this.receiptFilterProviders[index].selected = this.providerFilters;
				this.chargeFilterProvider(p, this.providerFilters);
			});
		},
		categoryFilters() {
			this.receiptFilterCategories.forEach((c, index) => {
				this.receiptFilterCategories[index].selected = this.categoryFilters;
				this.chargeFilterCategory(c, this.categoryFilters);
			});
		},
		subCategoryFilters() {
			this.receiptFilterSubcategories.forEach((s, index) => {
				this.receiptFilterSubcategories[index].selected =
					this.subCategoryFilters;
				this.chargeFilterSubcategory(s, this.subCategoryFilters);
			});
		},
		async isProviderActionSelected() {
			if (this.isProviderActionSelected === false && this.hasToShowFilters) {
				if (await this.checkSessionInBackendAsync()) {
					this.params = {};
					this.$store.initLoading(this);
					this.params["shopCode"] = this.selectedShopCode;
					this.processFilters(null);
					axios
						.get(`/api/providersV2/getAllReceipts?${parseToQuery(this.params)}`)
						.then((res) => {
							this.$store.finishLoading(this);
							if (res.data.responseCode === 0) {
								this.isFirstLoadPage = false;
								this.receipts = res.data.results;

								this.newReceiptFilters = res.data.filters;
								this.receiptFilterProviders = [];
								res.data.filters.providers.forEach((item) => {
									this.receiptFilterProviders.push({
										id: item.id,
										name: item.name,
										selected: true,
									});
								});
								this.receiptFilterCategories = [];
								res.data.filters.categories.forEach((item) => {
									this.receiptFilterCategories.push({
										id: item.categoryId,
										name: item.name,
										selected: true,
									});
								});
								this.receiptFilterSubcategories = [];
								let subcategories = [];
								res.data.filters.categories.forEach((item) => {
									subcategories = subcategories.concat(item.subCategories);
								});

								subcategories.forEach((x) => {
									if (x.name !== "") {
										this.receiptFilterSubcategories.push({
											id: x.subCategoryId,
											name: x.name,
											selected: true,
										});
									}
								});
							} else {
								this.showError(res.data.responseMessage);
							}
						})
						.catch((err) => {
							this.$store.errorLoading(this);
							this.showErrors(err.response);
						});
				}
			}
		},
		providerSearchInput() {
			this.filterProviders(this.providerSearchInput);
		},
		categorySearchInput() {
			this.filterCategories(this.categorySearchInput);
		},
		onProvidersListChanged() {
			this.selectedShopCode = this.onProvidersListChanged;
			this.getListByShopCode(this.onProvidersListChanged);
			this.hasToRefreshFilters = true;
			this.getData(this.selectedProviderId);
		},
	},
	computed: {
		hasToShowFilters() {
			return this.$store.getHasToShowProviderFilters;
		},
		onProvidersListChanged() {
			return this.$store.getRefreshProviderList;
		},
		hasToSelectFirstItem() {
			if (this.providerLists.length === 1 && this.$store.getHasToSelectFirstItem) {
				this.$store.setRefreshProviderList(this.providerLists[0].providers);
			}
			return this.$store.getHasToSelectFirstItem;
		},

	},
	methods: {
		async onMounted() {
			this.$store.updateSideMenu(-1);
			this.$store.updateTopMenu(3);
			this.toggleMerchantSelectorByLabel('showProviderListSelectionFilter')
			this.$store.CALL_MERCHANTS();
			this.$store.hideElements({
				hideCalendar: true,
				hideFilters: false,
				hideTabs: false,
			});

			if (await this.checkSessionInBackendAsync()) {
				this.getListByShops();
			}
		},
		seeReceiptsOfProvider(
			providerId,
			providerName,
			providerFiscalId,
			providerPhone,
			shopCode
		) {
			this.receipts = [];
			this.selectedProviderName = providerName;
			this.selectedProviderFiscalId = providerFiscalId;
			this.selectedProviderPhone = providerPhone;
			this.closeModalById("#modalNewProvider")
			this.$store.setHasToShowProviderFilters(false);
			this.$store.setProviderActionSelected("bills");
			this.selectedProviderId = providerId;
			this.getData(this.selectedProviderId, shopCode);
		},

		setBillingTypes(bool) {
			this.filterByExpenditure = bool;
			this.filterByPurchase = bool;
			this.chargeFilterType();
		},
		setPaidTypes(bool) {
			this.filterByPaid = bool;
			this.filterByNonPaid = bool;
			this.chargeFilterType();
		},
		isMoreOfOnePage() {
			return this.receiptsTotalCount - this.countPerPage > 0;
		},
		onReceiptEdition(receiptId) {
			this.$store.initLoading(this);
			this.params = {};
			this.params["id"] = receiptId;
			axios
				.get(`/api/providersV2/getReceiptById?${parseToQuery(this.params)}`)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setReceiptForEdition(res.data.receipt);
						this.openModalById("#modalEditProviderReceipt")

					} else {
						this.showErrors(res);
					}

					this.$store.finishLoading(this);
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		onOpenProviderDetails(provider) {
			this.$store.initLoading(this);
			this.params = {};
			this.params["providerId"] = provider.id;
			axios
				.get(`/api/providersV2/details?${parseToQuery(this.params)}`)
				.then((res) => {
					this.$store.finishLoading(this);
					if (res.data.responseCode === 0) {
						this.isNewProvider = false;
						this.$store.setFiscalKeyNameInCulture(
							res.data.fiscalKeyNameInCulture
						);
						this.providerObj = res.data.provider;
						this.providerCategories = res.data.categories;
						this.openModalById("#modalNewProvider")
					} else {
						this.showError(res.data.responseMessage);
					}
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		onEditingReceipt(receipt) {
			this.$store.initLoading(this);
			axios
				.put(`/api/providersV2/editReceipt`, { receipt: receipt })
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.showSuccess(`Comprobante editado exitosamente`);
						this.closeModalById("#modalEditProviderReceipt")
						this.getData(this.selectedProviderId);
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading(this);
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		onDeletingReceipt(receiptId) {
			this.$store.initLoading(this);
			this.params = {};
			this.params["id"] = receiptId;
			axios
				.delete(`/api/providersV2/deleteReceipt?${parseToQuery(this.params)}`)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.showSuccess(`Comprobante eliminado correctamente`);
						this.closeModalById("#modalEditProviderReceipt")
						this.getData(this.selectedProviderId);
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading(this);
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		getData(providerId, shopCode, isFirstLoadPage = false) {
			this.params = {};

			clearInterval(this.filterInterval);
			this.filterInterval = setInterval(() => {
				clearInterval(this.filterInterval);

				this.$store.initLoading(this);
				if (shopCode !== undefined && shopCode !== null) {
					this.params["shopCode"] = shopCode;
				} else {
					this.params["shopCode"] = this.selectedShopCode;
				}
				this.processFilters(providerId);
				axios
					.get(`/api/providersV2/getAllReceipts?${parseToQuery(this.params)}`)
					.then((res) => {
						this.$store.finishLoading(this);
						if (res.data.responseCode === 0) {
							this.receipts = res.data.results;
							this.isFirstLoadPage = isFirstLoadPage;

							if (this.hasToRefreshFilters) {
								this.hasToRefreshFilters = false;
								this.newReceiptFilters = res.data.filters;
								this.receiptFilterProviders = [];
								res.data.filters.providers.forEach((item) => {
									this.receiptFilterProviders.push({
										id: item.id,
										name: item.name,
										selected: true,
									});
								});
								this.receiptFilterCategories = [];
								res.data.filters.categories.forEach((item) => {
									this.receiptFilterCategories.push({
										id: item.categoryId,
										name: item.name,
										selected: true,
									});
								});
								this.receiptFilterSubcategories = [];
								let subcategories = [];
								res.data.filters.categories.forEach((item) => {
									subcategories = subcategories.concat(item.subCategories);
								});

								subcategories.forEach((x) => {
									if (x.name !== "") {
										this.receiptFilterSubcategories.push({
											id: x.subCategoryId,
											name: x.name,
											selected: true,
										});
									}
								});
							}
						} else {
							this.showError(res.data.responseMessage);
						}
					})
					.catch((err) => {
						this.$store.errorLoading(this);
						this.showErrors(err.response);
					});
			}, 1000);
		},
		processFilters(providerId) {
			if (providerId !== undefined && providerId !== null) {
				this.params["providersIds"] = providerId;
				this.params["forceProviderFiltering"] = true;
				this.params["isFirstLoadPage"] = false;

				let typeArr = [];
				typeArr.push({
					id: 1,
					selected: this.filterByExpenditure,
				});
				typeArr.push({
					id: 0,
					selected: this.filterByPurchase,
				});

				this.params["types"] = filterToString(typeArr);

				let paidTypeArr = [];
				paidTypeArr.push({
					id: 0,
					selected: this.filterByNonPaid,
				});
				paidTypeArr.push({
					id: 1,
					selected: this.filterByPaid,
				});

				this.params["paidTypes"] = filterToString(paidTypeArr);

				if (
					this.receiptFilterCategories.filter((x) => x.selected === false)[0]
				) {
					this.params["categoriesIds"] = filterToString(
						this.receiptFilterCategories
					);
					if (this.params["categoriesIds"] === "") {
						this.params["categoriesIds"] = ",";
					}
				} else {
					this.params["categoriesIds"] = null;
				}

				if (
					this.receiptFilterSubcategories.filter((x) => x.selected === false)[0]
				) {
					this.params["subCategoriesIds"] = filterToString(
						this.receiptFilterSubcategories
					);
					if (this.params["subCategoriesIds"] === "") {
						this.params["subCategoriesIds"] = ",";
					}
				} else {
					this.params["subCategoriesIds"] = null;
				}
			} else {
				let typeArr = [];
				typeArr.push({
					id: 1,
					selected: this.filterByExpenditure,
				});
				typeArr.push({
					id: 0,
					selected: this.filterByPurchase,
				});

				this.params["types"] = filterToString(typeArr);

				let paidTypeArr = [];
				paidTypeArr.push({
					id: 0,
					selected: this.filterByNonPaid,
				});
				paidTypeArr.push({
					id: 1,
					selected: this.filterByPaid,
				});

				this.params["paidTypes"] = filterToString(paidTypeArr);

				this.params["forceProviderFiltering"] = false;
				if (
					this.receiptFilterProviders.filter((x) => x.selected === false)[0]
				) {
					this.params["providersIds"] = filterToString(
						this.receiptFilterProviders
					);
					if (this.params["providersIds"] === "") {
						this.params["providersIds"] = ",";
					}
				} else {
					this.params["providersIds"] = null;
				}

				if (
					this.receiptFilterCategories.filter((x) => x.selected === false)[0]
				) {
					this.params["categoriesIds"] = filterToString(
						this.receiptFilterCategories
					);
					if (this.params["categoriesIds"] === "") {
						this.params["categoriesIds"] = ",";
					}
				} else {
					this.params["categoriesIds"] = null;
				}

				if (
					this.receiptFilterSubcategories.filter((x) => x.selected === false)[0]
				) {
					this.params["subCategoriesIds"] = filterToString(
						this.receiptFilterSubcategories
					);
					if (this.params["subCategoriesIds"] === "") {
						this.params["subCategoriesIds"] = ",";
					}
				} else {
					this.params["subCategoriesIds"] = null;
				}
				this.params["isFirstLoadPage"] = this.isFirstLoadPage;
			}

			this.excelParams = parseToQuery(this.params);
		},
		chargeFilterType() {
			this.avoidRectangleAtTheEnd();
			this.getData(this.selectedProviderId);
		},
		chargeFilterProvider(providerId, bool) {
			this.avoidRectangleAtTheEnd();
			let selectedProvider = this.receiptFilterProviders.filter(
				(x) => x.id === providerId
			);
			if (selectedProvider !== null && selectedProvider.length > 0) {
				selectedProvider[0].selected = bool;
			}
			this.getData(this.selectedProviderId);
		},
		chargeFilterCategory(categoryId, bool) {
			this.avoidRectangleAtTheEnd();
			let selectedCategory = this.receiptFilterCategories.filter(
				(x) => x.id === categoryId
			);
			if (selectedCategory !== null && selectedCategory.length > 0) {
				selectedCategory[0].selected = bool;
			}
			this.getData(this.selectedProviderId);
		},
		chargeFilterSubcategory(subCatId, bool) {
			this.avoidRectangleAtTheEnd();
			let selectedSubCategory = this.receiptFilterSubcategories.filter(
				(x) => x.id === subCatId
			);
			if (selectedSubCategory !== null && selectedSubCategory.length > 0) {
				selectedSubCategory[0].selected = bool;
			}
			this.getData(this.selectedProviderId);
		},
		processProviderList(pList) {
			const providerListShops = pList.map((x) => x.shopCode);
			const merchantTags = this.$store.getMerchantTags.map(
				(x) => x.shopCode
			);
			const result = merchantTags.filter(
				(x) => providerListShops.includes(x) === false
			);

			if (result.length === 0) {
				this.providerLists = pList.sort((a, b) => a.shopCode - b.shopCode);
				this.$store.setProviderLists(this.providerLists);
				this.selectedShopCode = this.providerLists[0].shopCode;

				if (this.isProviderActionSelected === false) {
					this.getData(null, this.selectedShopCode, true);
				}

				this.providers = this.providerLists[0].providers;
				this.filteredProviders = this.providerLists[0].providers;
				this.categories = this.providerLists[0].categories;
				this.filteredCategories = this.providerLists[0].categories;
				this.$store.finishLoading(this);
			}
		},
		onUpsertCategory() {
			this.refreshListsAndSelectByShopCode(this.selectedShopCode);
			this.closeModalById("#modalProviderCategory")
		},
		onUpsertProvider() {
			this.refreshListsAndSelectByShopCode(this.selectedShopCode);
			this.closeModalById("#modalNewProvider")
		},
		onReceiptCreated() {
			this.getData(this.selectedProviderId);
			this.closeModalById("#modalProviderReceipt")


		},
		onDeleteProvider() {
			this.refreshListsAndSelectByShopCode(this.selectedShopCode);
			this.closeModalById("#modalNewProvider")
		},
		// closeModalById(modalId) {
		// 	/* eslint-disable */
		// 	modalId = modalId.slice(1);
		// 	var modalInstance = document.getElementById(modalId);
		// 	var instance = M.Modal.getInstance(modalInstance);
		// 	instance.close();
		// 	document.body.style.overflow = "auto";
		// 	/* eslint-enable */
		// },

		onDeleteCategory() {
			this.refreshListsAndSelectByShopCode(this.selectedShopCode);
			this.closeModalById("#modalProviderCategory")

		},
		openNewReceiptModal() {
			this.openModalById("#modalProviderReceipt");
		},
		openNewCategoryModal() {
			this.isNewCategory = true;
			this.categoryObj = {};
			this.openModalById('#modalProviderCategory')
		},
		openNewProviderModal() {
			this.isNewProvider = true;
			this.providerObj = {};
			this.categories.forEach((item) => {
				item.selected = false;
			});
			this.openModalById('#modalNewProvider')
		},

		onOpenCategoryDetails(cat) {
			this.params = {};
			this.$store.initLoading(this);
			this.params["categoryId"] = cat.categoryId;
			axios
				.get(`/api/providersV2/catDetails?${parseToQuery(this.params)}`)
				.then((res) => {
					this.$store.finishLoading(this);
					if (res.data.responseCode === 0) {
						this.isNewCategory = false;
						this.categoryObj = res.data.category;
						this.openModalById("#modalProviderCategory");
					} else {
						this.showError(res.data.responseMessage);
					}
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		// openModalById(modalId) {
		// 	/* eslint-disable */
		// 	modalId = modalId.slice(1);
		// 	var modalInstance = document.getElementById(modalId);
		// 	var instance = M.Modal.init(modalInstance);
		// 	instance.open();
		// 	/* eslint-enable */
		// },
		filterProviders(criteria) {
			const searchTerm = criteria.toLowerCase();
			if (criteria.length === 0) {
				this.filteredProviders = this.providers;
			} else {
				this.filteredProviders = this.providers.filter(
					(x) => x.name.toLowerCase().includes(searchTerm) || x.fiscalId.includes(searchTerm)
				);
			}
		},
		filterCategories(criteria) {
			if (criteria.length === 0) {
				this.filteredCategories = this.categories;
			} else {
				this.filteredCategories = this.categories.filter((x) =>
					x.name.includes(criteria)
				);
			}
		},
		getSubcategoriesQty(cat) {
			if (cat === undefined) return 0;
			else if (cat.subCategories === undefined) return 0;
			else return cat.subCategories.length;
		},
		getListByShopCode(shopCode) {
			if (this.providerLists === undefined) return;
			const providerToSee = this.providerLists.filter(
				(x) => x.shopCode === shopCode
			);
			if (providerToSee.length === 0) {
				this.showError(
					`No hay proveedores cargados para el comercio ${shopCode}`
				);
				return;
			}
			this.providers = providerToSee[0].providers;
			this.filterProviders(this.providerSearchInput);
			this.categories = providerToSee[0].categories;
			this.filterCategories(this.categorySearchInput);
		},
		getListByShops() {

			const t = this;
			let pList = [];
			let listOfShops = this.$store.getMerchantTags;
			if (this.selectedShopCodes.length !== 0) {
				listOfShops = this.selectedShopCodes;
			}

			t.providerLists = [];
			this.$store.setProviderLists(t.providerLists);
			this.$store.initLoading(this);
			listOfShops.forEach((shop) => {
				let innerParams = {};
				innerParams["shopCode"] = shop.shopCode;
				axios
					.get(`/api/providersV2/providers?${parseToQuery(innerParams)}`)
					.then((res) => {
						let providers = [];
						if (res.data.responseCode === 0) {
							if (
								pList.filter((l) => l.shopCode === shop.shopCode).length === 0
							) {
								providers = res.data.providers;
							}
							this.$store.setFiscalKeyNameInCulture(
								res.data.fiscalKeyNameInCulture
							);

							axios
								.get(`/api/providersV2/categories?${parseToQuery(innerParams)}`)
								.then((resCat) => {
									if (resCat.data.responseCode === 0) {
										if (
											pList.filter((l) => l.shopCode === shop.shopCode)
												.length === 0
										) {
											pList.push({
												name: `${shop.merchantName} (${shop.shopCode})`,
												shopCode: shop.shopCode,
												providers: providers,
												categories: resCat.data.categories,
											});
											t.processProviderList(pList);
										}
									} else {
										this.showError(resCat.data.responseMessage);
									}
								});
						} else {
							this.showError(res.data.responseMessage);
						}
					});
			});
		},
		refreshListsAndSelectByShopCode(shopCode) {
			const t = this;

			let listOfShops = this.$store.getMerchantTags;
			if (this.selectedShopCodes.length !== 0) {
				listOfShops = this.selectedShopCodes;
			}

			t.providerLists = this.$store.getProviderLists;

			listOfShops.forEach((shop) => {
				axios
					.get(`/api/providersV2/providers?shopCode=${shop.shopCode}`)
					.then((resProv) => {
						if (resProv.data.responseCode === 0) {
							let providers = resProv.data.providers;

							axios
								.get(`/api/providersV2/categories?shopCode=${shop.shopCode}`)
								.then((resCat) => {

									if (resCat.data.responseCode === 0) {
										let p = t.providerLists.filter(
											(l) => l.shopCode === shop.shopCode
										);
										p[0].providers = providers;
										p[0].categories = resCat.data.categories;
										t.$store.setProviderLists(t.providerLists);

										if (shopCode === shop.shopCode) {
											t.selectedShopCode = shop.shopCode;
											t.providers = providers;
											t.filteredProviders = providers;
											t.categories = resCat.data.categories;
											t.filteredCategories = resCat.data.categories;

										}
									} else {
										t.showError(resCat.data.responseMessage);
									}
								});
						} else {
							t.showError(resProv.data.responseMessage);
						}
					});
			});
		},
		onExpandProviders() {
			let status = !this.expandProviders;
			this.collapseItems();
			this.expandProviders = status;
		},
		onExpandCategories() {
			let status = !this.expandCategories;
			this.collapseItems();
			this.expandCategories = status;
		},
		collapseItems() {
			this.expandCategories = false;
			this.expandProviders = false;
		},
		getInitials(providerName) {
			let str = providerName;
			return str
				.split(" ")
				.map((n) => n[0])
				.join("");
		},
	},
	components: {
		EmptyElement,
		ReceiptRow,
		ReceiptRowHeader,
		ModalProviderCategory,
		ModalNewProvider,
		ModalNewProviderReceipt,
		HelpCenter,
	},

};
</script>

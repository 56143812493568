<script>
import mixCommons from './mixCommons.vue'
export default {
	mixins: [mixCommons],
	data () {
		return {
			departmentsFilter: [],
			categoryFilters: true,
			params: {
				FilterCategory: []
			}
		}
	},
	watch: {
		categoryFilters () {
			this.departmentsFilter.forEach((d, index) => {
				this.chargeFilterCategory(index, this.categoryFilters)
			})
		}
	},
	methods: {
		setupSwitches (categories) {
			this.departmentsFilter = []
			categories.forEach((item, index) => {
				this.departmentsFilter.push({
					isChecked: this.isCategoryFiltered(index),
					department: item
				})
			})
		},
		chargeFilterCategory (categoryIndex, bool) {
			this.avoidRectangleAtTheEnd();
			this.departmentsFilter[categoryIndex].isChecked = bool

			var index = this.params.FilterCategory.indexOf(categoryIndex)
			if (bool) {
				this.params.FilterCategory.splice(index, 1)
			} else if (index < 0) {
				this.params.FilterCategory.push(categoryIndex)
			}

			this.getData()
		},
		isCategoryFiltered (categoryIndex) {
			var index = this.params.FilterCategory.indexOf(categoryIndex)
			if (index === -1) return true
			else return false
		}
	}
}
</script>

<template>
  <article class="productSelector">
    <span class="productSelectorTitle">{{ title }}</span>
    <section class="formMain">
      <article
        v-for="(prod, index) in getProductsInUse"
        :key="index"
        class="itemForm selectImage fixSeeMenu"
      >
        <div class="itemFormCombo">
          <a>{{ prod.name }}</a>
        </div>
        <div class="itemFormPrice" style="text-align: center">
          <img
            class="deleteProductFromComboStep"
            alt="Eliminar"
            title="Eliminar"
            @click="onDeleteProduct(prod.sku)"
            src="@/assets/img/delete.svg"
            width="20px"
            height="20px"
          />
        </div>
      </article>
      <article class="itemForm selectImage">
        <select
          v-if="
            getProductsNotBeingUsed != null &&
            getProductsNotBeingUsed.length > 0
          "
          v-model="keyForReturnSelectToStart"
          @change="onSelectProduct($event)"
          class="browser-default backLabel"
        >
          <option value="-1" selected>Agregar producto</option>
          <option
            v-for="(prod, index) in getProductsNotBeingUsed"
            :value="prod.sku"
            :key="index"
          >
            {{ prod.name }}
          </option>
        </select>
        <div v-else class="success-hint">
          {{ selectAllProductsMessage }}
        </div>
      </article>
    </section>
  </article>
</template>
<script>
export default {
  props: {
    productsByMenu: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    containerRef: {
      type: String,
      default: () => {
        return "";
      },
    },
    selectAllProductsMessage: {
      type: String,
      default: () => {
        return "";
      },
    },
    productsSelected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    allProducts: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    productsSelected() {},
  },
  computed: {
    //hasProductsSelected() {},
    getProductsInUse() {
      if (
        this.productsSelected === undefined ||
        this.productsSelected === null
      ) {
        return [];
      }

      return this.productsSelected;
    },
    getProductsByCategoryInUse() {
      let array = [];

      this.allProducts.forEach((element) => {
        array.push(element);
      });
      return array;
    },
    getProductsNotBeingUsed() {
      let array = [];
      if (this.allProducts === undefined || this.allProducts === null) {
        return array;
      }
      if (
        this.productsSelected === undefined ||
        this.productsSelected === null
      ) {
        return this.allProducts;
      }

      this.allProducts.forEach((element) => {
        let prod = this.productsSelected.filter(
          (x) => x.sku === element.sku
        )[0];
        if (prod === undefined || prod === null) {
          array.push(element);
        }
      });

      return array;
    },
    getCategoriesNotUse() {
      let array = [];

      this.productsByMenu.forEach((element) => {
        if (
          element.products.filter((t) => t.currentPrice !== "").length === 0 &&
          element.name !== this.selectedCategoryToAddInProductList
        ) {
          array.push(element.name);
        }
      });

      return array;
    },
  },
  methods: {
    onSelectProduct(event) {
      this.$nextTick(() => {
        // Se asegura que Vue proceso todas las actualizaciones reactivas pendientes antes de reiniciar el selector
        this.keyForReturnSelectToStart = -1;
      });
      this.$emit(`onSelectProduct`, event, this.containerRef);
    },
    onDeleteProduct(sku) {
      this.$emit(`onDeleteProduct`, sku, this.containerRef);
    },
  },
  data() {
    return {
      keyForReturnSelectToStart: -1,
      selectedCategoryToAddInProductList: "",
    };
  },
};
</script>

<template>
	<div id="modalProviderCategory" class="modal modalNewProvider">
		<div class="modal-body">
			<div class="modal-header">

				<h4>{{ getModalTitle }}</h4>
				<p>Fácil y Simple</p>

				<!-- CLOSE -->
				<div class="btnClose">
					<a href="#!" @click="closeModal()"
						class="modal-action modal-close waves-effect waves-green btn-flat">
						<span class="icon-Bistro-_Close"></span>
					</a>
				</div>
			</div>
			<form action class="formGeneral">
				<article class="formMain article">
					<div class="itemForm">
						<label class="label">Nombre de la Categoría:</label>
						<input class="input" v-model="localCategory.name" type="text"
							placeholder="Ingrese nombre de la categoría" />
					</div>
				</article>
				<article class="formMain article">
					<div class="itemForm" v-for="subCatInput in subCatInputs" :key="subCatInput.pk">
						<label class="label">Sub-Categoria: {{ subCatInput.pk }}</label>
						<input class="input" v-model="subCatInput.name" type="text"
							placeholder="Nombre de sub categoría" />
					</div>
				</article>
				<article class="formMain article">
					<div class="itemForm">
						<label class="label-blue" @click="addOneSubCategoryInput">+ Agregar Sub-Categoria</label>
					</div>
				</article>
			</form>
		</div>

		<div class="modal-footer">
			<article class="btn-main">
				<a v-if="isNewCategory === false" class="deleteProduct" @click.prevent="deleteCategory" type="submit">
					<span class="icon-Bistro-_Trash_bin"></span><span>Eliminar categoría</span>
				</a>
				<Spinner v-if="isLoading" />
				<a v-else @click="upsertCategory" type="submit">
					<p>{{ getTitle }}</p>
				</a>
			</article>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import { toast } from "vue3-toastify";
import Spinner from '../components/SpinnerComp';
import commons from '../mixins/mixCommons';
import { parseToQuery } from '../utils/utils';

export default {
	mixins: [commons],
	props: {
		isNewCategory: {
			type: Boolean
		},
		shopCode: {
			type: String
		},
		category: {
			type: Object,
			default: () => {
				return {}
			}
		},
		categories: {
			type: Array,
			default: function () {
				return []
			}
		},
	},
	data() {
		return {
			isLoading: false,
			localCategory: { ...this.category },
			oldCategoryName: "",
			subCatInputs: [
				{
					pk: 1,
					id: null,
					name: ''
				},
				{
					pk: 2,
					id: null,
					name: ''
				}
			]
		}
	},
	watch: {
		category: {
			immediate: true,
			handler(newVal) {
				this.localCategory = { ...newVal };
				this.oldCategoryName = this.localCategory.name;
				if (this.localCategory.subCategories !== undefined) {
					let pk = 1;
					this.subCatInputs = [];
					this.localCategory.subCategories.forEach((cat) => {
						this.subCatInputs.push({
							pk: pk,
							name: cat.name,
							id: cat.subCategoryId
						});
						pk++;
					});
				} else {
					this.subCatInputs = [
						{
							pk: 1,
							id: null,
							name: ''
						},
						{
							pk: 2,
							id: null,
							name: ''
						}
					];
				}
			}
		}
	},
	computed: {
		getModalTitle() {
			return this.isNewCategory ? 'Crear Categoría' : 'Editar Categoría';
		},
		getTitle() {
			return this.isNewCategory ? 'Crear categoría' : 'Guardar categoría';
		},
		resultSucceessMessage() {
			return this.isNewCategory ? 'Categoría creada exitosamente' : 'Categoría guardada exitosamente';
		}
	},
	methods: {
		isCategoryName(categories, category, t, oldCategoryName) {
			if (oldCategoryName === category.name) return false;
			if (categories.filter((t) => t.name === category.name).length > 0) {
				toast.error(`La categoría ${category.name} ya existe!`);
				return true;
			} else return false;
		},
		addOneSubCategoryInput() {
			const lastPk = this.subCatInputs.reduce((max, item) => Math.max(max, item.pk), 0);
			this.subCatInputs.push({
				pk: lastPk + 1,
				id: null,
				name: ''
			});
		},
		closeModal() {
		},
		deleteCategory() {
			this.$store.initLoading(this);
			this.params = {};
			this.params['id'] = this.category.categoryId;
			axios.delete(`/api/providersV2/deleteCategory?${parseToQuery(this.params)}`)
				.then(res => {
					if (res.data.responseCode === 0) {
						this.showSuccess(`Categoría eliminada correctamente`);
						this.$emit('onDeleteCategory');
					} else {
						this.showErrors(res);
					}
					this.$store.finishLoading(this);
				})
				.catch(err => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		upsertCategory() {
			this.$store.initLoading(this);
			const req = {
				shopCode: this.shopCode,
				categoryId: this.localCategory.categoryId,
				name: this.localCategory.name,
				subCatInputs: this.subCatInputs.filter(x => x.name !== '')
			};
			let http;
			if (this.isNewCategory) {
				if (this.isCategoryName(this.categories, this.localCategory, this, this.oldCategoryName)) {
					this.$store.finishLoading(this);
					return;
				}
				http = axios.post(`/api/providersV2/insertProviderCategories`, req);
			} else {
				if (this.isCategoryName(this.categories, this.localCategory, this, this.oldCategoryName)) {
					this.$store.finishLoading(this);
					return;
				}
				http = axios.put(`/api/providersV2/updateProviderCategories`, req);
			}
			http.then(res => {
				if (res.data.responseCode === 0) {
					this.showSuccess(this.resultSucceessMessage);
					this.$emit('onUpsertCategory');
				} else {
					this.showErrors(res);
				}
				this.$store.finishLoading(this);
			})
				.catch(err => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		}
	},
	components: {
		Spinner
	}
};
</script>

<template>
	<div :id="modalName" class="modal modal-block-outside" style="overflow-y: auto !important"
		>
		<div class="modal-header">
			<div class="image-title">
				<h4>{{ title }}</h4>
				<p>Fácil y Simple</p>
			</div>

			<div class="btnClose">
				<a href="#!"  class="modal-action modal-close waves-effect waves-green btn-flat">
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<div class="modal-body">
			<form action class="formGeneral">
				<section class="formMain article-product">
					<div class="itemForm picture-upload" style="width: 100%">
						<div>
							<label for>{{ this.disclaimer }}</label>
						</div>
						<div v-if="isImageLoading" class="itemForm selectImage beforeAdditional imageSpinner">
							<Spinner />
						</div>
						<div v-else class="itemForm selectImage beforeAdditional">
							<label class="selectImage-label" for>Imagen:</label>
							<input type="file" ref="fileupload" @change="onFileSelected" />
							<a v-if="imageUrl" class="selectImage-trashBin" @click="onDeleteImage">
								<span class="icon-Bistro-_Trash_bin"></span>
							</a>
						</div>
						<div>
							<label v-if="imageUrl">Imágen actual</label>
						</div>
						<div>
							<img v-if="imageUrl" :src="imageUrl" />
						</div>
					</div>
				</section>
			</form>
		</div>
	</div>
</template>

<script>
import pictureCommons from "../../mixins/pictureCommons";

import modal from "../../mixins/mixModals";
import mixCommons from "../../mixins/mixCommons";
import Spinner from "@/components/SpinnerComp";

export default {
	mixins: [mixCommons, modal, pictureCommons],
	props: {
		shopCode: {
			type: String,
			default: () => {
				return "";
			},
		},
		modalName: {
			type: String,
			default: () => {
				return "modalFileUpload";
			},
		},
		title: {
			type: String,
			default: () => {
				return "Subida de archivo";
			},
		},
		storageRepo: {
			type: String,
			default: "s3"
		},
		disclaimer: {
			type: String,
			default: () => {
				return "(Archivo .jpg, .jpeg, .bmp o .png. Tamaño no mayor a 2MB, Resolución máxima: 1920px x 1080px)";
			},
		},
		imageUrl: {
			type: String,
			default: () => {
				return null;
			},
		},
		maxWidth: {
			type: Number,
			default: 1920,
		},
		maxHeight: {
			type: Number,
			default: 1080,
		},
	},
	data() {
		return {
			isImageLoading: false,
			isLoading: false,
		};
	},
	watch: {},
	computed: {},
	methods: {
		onDeleteImage() {
			this.$emit(`onDeleteImage`, this.shopCode);
		},
		onFileSelected(event) {
			const t = this;
			this.selectedFile = event.target.files[0];
			this.isImageLoading = true;
			if (!this.selectedFile) return;
			if (
				(this.selectedFile.name.endsWith(".jpeg") ||
					this.selectedFile.name.endsWith(".jpg") ||
					this.selectedFile.name.endsWith(".bmp") ||
					this.selectedFile.name.endsWith(".png")) === false
			) {
				this.$store.errorLoading(this);
				this.isImageLoading = false;
				this.showError(`Las imágenes deben ser .jpg, .jpeg, .bmp o .png`);
				return;
			}
			const filesize = (this.selectedFile.size / 1024 / 1024).toFixed(4); // MB
			if (filesize > 2) {
				// 2MB
				this.$store.errorLoading(this);
				this.isImageLoading = false;
				this.showError(
					`El tamaño de la foto no puede superar los 2MB. La foto que subiste tiene ${filesize}MB`
				);
				return;
			}

			var _URL = window.URL || window.webkitURL;
			var file, img;
			if ((file = this.selectedFile)) {
				img = new Image();
				var objectUrl = _URL.createObjectURL(file);
				img.onload = function () {
					_URL.revokeObjectURL(objectUrl);
					if (this.width > t.maxWidth) {
						t.showError(
							`El ancho de la imagen es de ${this.width}px. No puede superar los ${t.maxWidth}px`
						);
						t.$store.errorLoading(t);
						t.isImageLoading = false;
						return;
					}

					if (this.height > t.maxHeight) {
						t.showError(
							`El alto de la imagen es de ${this.height}px. No puede superar los ${t.maxHeight}px`
						);
						t.$store.errorLoading(t);
						t.isImageLoading = false;
						return;
					}

					console.log("storageRepo ", t.storageRepo);
					if (t.storageRepo === "s3") {
						t.saveMediaInCloudStorage(
							t,
							`logos/${t.shopCode}/${t.selectedFile.name}`,
							(url) => {
								t.$emit(`onPictureUploadedOk`, url, t.shopCode);
								t.isImageLoading = false;
							},
							false
						);
					} else {
						t.saveMediaInFirebaseStorage(
							t,
							"logos/" + t.shopCode + "/" + t.selectedFile.name,
							(url) => {
								t.$emit(`onPictureUploadedOk`, url, t.shopCode);
								t.isImageLoading = false;
							}
						);
					}
				};
				img.src = objectUrl;
			}
		},

	},
	components: {
		Spinner,
	},
};
</script>

<template>
    <div v-if="hasToShowThisProfile && hasToShowSection">

        <div class="itemForm itemForm-EmiteMx">
            <br />
            <h1><strong>Datos de facturación electrónica:</strong></h1>
            <h1>
                <span class="green-text">{{ GetEmiteUploadStatus(localShop.code) }}</span>
            </h1>
            <Spinner v-if="localIsLoading" />
            <div v-else>
                <div class="settings-div switch emite-cert-disclaimer-container">
                    <div class="arrow-fixed">
                        <label class="settings-label" for>Archivo certificado para Emite (*.cer):</label>
                        <article>
                            <img src="@/assets/img/curved-arrow.webp" alt="" />
                            <div>
                                <p>
                                    El certificado que se debe informar es el
                                    <strong>CSD</strong> (certificado sello digital)
                                </p>
                                <p>No es el FIEL</p>
                                <p>
                                    Ante cualquier duda revise el instructivo haciendo
                                    <a target="_blank" :href="getKnowledgeBaseUrl()">click aquí</a>
                                </p>
                            </div>
                        </article>
                    </div>
                </div>
                <input class="search-input emiteMx" type="file" ref="cerFileUpload" accept=".cer"
                    @change="onCerFileSelected" />
                <div class="settings-div switch">
                    <label class="settings-label" for>Archivo llave para Emite (*.key):</label>
                </div>
                <input class="search-input emiteMx" type="file" ref="keyFileUpload" accept=".key"
                    @change="onKeyFileSelected" />
                <div class="settings-div">
                    <label class="settings-label" for>Contraseña de certificado para Emite:</label>
                </div>
                <input class="search-input emiteMx" type="password" v-model="certificatePassword" />
                <article class="btn-main btn-main-certificate">
                    <a @click="onCertUpload(localShop.code)">
                        <p>CARGAR CERTIFICADO</p>
                    </a>
                </article>
                <div class="settings-div">
                    <label class="settings-label" for>RFC del emisor:</label>
                </div>
                <input class="search-input emiteMx" type="text" v-model="localShop.fiscalData.fiscalKey" />
                <div class="settings-div">
                    <label class="settings-label" for>Razón social del emisor:</label>
                </div>
                <input class="search-input emiteMx" type="text" v-model="localShop.fiscalData.legalName" />
                <div class="settings-div">
                    <label class="settings-label" for>Régimen Fiscal:</label>
                </div>
                <input class="search-input emiteMx" type="number" v-model="localShop.fiscalData.taxRegime" />
                <div class="settings-div">
                    <label class="settings-label" for>Código Postal:</label>
                </div>
                <input class="search-input emiteMx" type="number" v-model="localShop.fiscalData.zipCode" />
                <article class="btn-main btn-main-certificate">
                    <a class="btn-active" @click="processFiscalDataFields()">
                        <p>GUARDAR</p>
                    </a>
                </article>
                <modal-fiscal-data-edition-confirmation-mx
                    :id="`modalFiscalDataEditionConfirmationMx_${localShop.code}`" :shopCode="localShop.code"
                    @onModalClosed="onFiscalDataEditionConfirmationModalClosingMx"
                    @onModalClosedESC="onFiscalDataEditionConfirmationModalClosingMxESC" />
                <modal-test-billing-mx :id="`modalTestBillingMx_${localShop.code}`" :shopCode="localShop.code"
                    @onModalClosed="onTestBillingModalClosingMx" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Spinner from "@/components/SpinnerComp";
import commons from "@/mixins/mixCommons";
import modalCommons from "@/mixins/mixModals";
import ModalFiscalDataEditionConfirmationMx from "@/components/modals/ModalFiscalDataEditionConfirmationMX";
import ModalTestBillingMx from "@/components/modals/ModalTestBillingMX";
import { db } from "../firebase.js";
import {
    ref,
    get,
    set,
} from "firebase/database";


export default {
    data() {
        return {
            emiteMxData: {
                cerSelectedFile: null,
                keySelectedFile: null,
            },
            certificatePassword: null,
            emiteShopUploadInfoes: [],
            localShop: { ...this.shop }, // Copia local de shop para modificar
            localIsLoading: this.isLoading, // Copia local de isLoading
        };
    },
    props: {
        shop: {
            type: Object,
			default: () => ({
				fiscalData: {
					isBillingEnabled: false, // Valor predeterminado
				},
			}),
		},
		hasToShowThisProfile: {
			type: Boolean,
			default: false,
		},
        isLoading: {
            type: Boolean,
            default: false,
        },
        isMounting: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        GetEmiteUploadStatus(shopCode) {
            var shop = this.emiteShopUploadInfoes.filter(
                (x) => x.shopCode == shopCode
            );
            if (!shop[0]) return "";

            return shop[0].certificateUploadStatus;
        },
        onCerFileSelected(event) {
            const fileInput = event.target;
            const selectedFile = fileInput.files[0];

            if (!selectedFile) {
                return;
            }

            const allowedExtensions = [".cer"];
            const fileExtension = selectedFile.name.slice(
                ((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2
            );

            if (allowedExtensions.indexOf(`.${fileExtension}`) === -1) {
                this.showError(
                    `Por favor seleccione un archivo .cer únicamente.`
                );
                fileInput.value = "";
                return;
            }

            this.emiteMxData.cerSelectedFile = event.target.files[0];
        },
        onKeyFileSelected(event) {
            const fileInput = event.target;
            const selectedFile = fileInput.files[0];

            if (!selectedFile) {
                return;
            }

            const allowedExtensions = [".key"];
            const fileExtension = selectedFile.name.slice(
                ((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2
            );

            if (allowedExtensions.indexOf(`.${fileExtension}`) === -1) {
                this.showError(`Por favor seleccione un archivo .key únicamente.`);
                fileInput.value = "";
                return;
            }
            this.emiteMxData.keySelectedFile = event.target.files[0];
        },
        generateSafeFileName(fileName) {
            // Reemplazar caracteres no permitidos con guiones bajos
            return fileName.replace(/[.#$[\]]/g, '_');
        },
        onCertUpload(shopCode) {
            if (!this.emiteMxData.cerSelectedFile) {
                this.showError('Debe seleccionar un archivo de certificado .cer');
                return;
            }

            if (!this.emiteMxData.keySelectedFile) {
                this.showError('Debe seleccionar un archivo de llave .key');
                return;
            }

            if (!this.certificatePassword) {
                this.showError('Debe ingresar una contraseña');
                return;
            }

            let certUrlFile = null;
            let keyUrlFile = null;
            this.localIsLoading = true;
            this.$store.initLoading(this);

            // Generar un nombre de archivo seguro para Firebase
            const safeCerFileName = this.generateSafeFileName(this.emiteMxData.cerSelectedFile.name);
            const safeKeyFileName = this.generateSafeFileName(this.emiteMxData.keySelectedFile.name);

            const cerRef = ref(db, `certificates/${shopCode}/cer/${safeCerFileName}`);

            // Leer el archivo como una URL de datos
            const cerFileReader = new FileReader();
            cerFileReader.onload = (e) => {
                const cerFileContent = e.target.result;

                set(cerRef, {
                    fileName: this.emiteMxData.cerSelectedFile.name,
                    fileContent: cerFileContent,
                })
                    .then(() => {
                        // Obtenemos el archivo .cer desde Firebase Database
                        get(cerRef)
                        .then((snapshot) => {
                            certUrlFile = snapshot.val().fileContent;

                            const keyRef = ref(db, `certificates/${shopCode}/key/${safeKeyFileName}`);

                            // Leer el archivo key como una URL de datos
                            const keyFileReader = new FileReader();
                            keyFileReader.onload = (e) => {
                                const keyFileContent = e.target.result;

                                // Guardamos el archivo .key
                                set(keyRef, {
                                    fileName: this.emiteMxData.keySelectedFile.name,
                                    fileContent: keyFileContent,
                                })
                                    .then(() => {
                                        get(keyRef)
                                        .then((snapshot) => {
                                            keyUrlFile = snapshot.val().fileContent;
                                            this.sendCertificateToApi(certUrlFile, keyUrlFile, shopCode);
                                        });
                                    })
                                    .catch((error) => {
                                        console.error('Error guardando el archivo .key en Firebase Database:', error);
                                        this.$store.errorLoading(this);
                                        this.localIsLoading = false;
                                    });
                            };
                            keyFileReader.readAsDataURL(this.emiteMxData.keySelectedFile);
                        });
                    })
                    .catch((error) => {
                        console.error('Error guardando el archivo .cer en Firebase Database:', error);
                        this.$store.errorLoading(this);
                        this.localIsLoading = false;
                    });
            };
            cerFileReader.readAsDataURL(this.emiteMxData.cerSelectedFile);
        },

        sendCertificateToApi(certUrlFile, keyUrlFile, shopCode) {
            axios
                .post('/api/users/emiteCertificateUpload', {
                    keyUrlFile: keyUrlFile,
                    certUrlFile: certUrlFile,
                    shopCode: shopCode,
                    password: btoa(this.certificatePassword),
                })
                .then((res) => {
                    if (res.data.responseCode === 0) {
                        this.showSuccess(res.data.responseMessage);
                    } else {
                        this.showError(res.data.responseMessage);
                    }
                    this.getEmiteCertificateStatus();
                    this.$store.finishLoading(this);
                    this.localIsLoading = false;
                })
                .catch((err) => {
                    this.$store.errorLoading(this);
                    this.localIsLoading = false;
                    console.error(err);
                });
        },


        getEmiteCertificateStatus() {
            if (!this.getInstanceCode == "MX") {
                return;
            }

            const t = this;
            axios
                .get(`/api/users/GetEmiteCertificateStatus`)
                .then((res) => {
                    if (res.data.responseCode === 0) {
                        t.emiteShopUploadInfoes = res.data.emiteShopInfoes;
                    } else {
                        t.$store.errorLoading(this);
                        t.showError(res.data.responseMessage);
                    }
                })
                .catch((err) => {
                    t.$store.errorLoading(this);
                    t.showErrors(err.response);
                });
        },
        processFiscalDataFields() {
            this.localShop = { ...this.shop };
            this.shopCode = this.localShop.code;

            if (!this.hasFiscalDataChangedMX) {
                this.showError(`No se detectaron cambios en los datos fiscales.`);
                return;
            }
            if (!this.processRFC()) {
                return;
            }
            if (!this.processZipCode()) {
                return;
            }
            if (!this.processLegalName()) {
                return;
            }
            if (!this.processTaxRegimen()) {
                return;
            }

            this.openModalById(`#modalFiscalDataEditionConfirmationMx_${this.shop.code}`);
        },
        processRFC() {
            if (
                !this.localShop.fiscalData?.fiscalKey
            ) {
                this.showError(`No se puede mandar el RFC vacío`);
                return false;
            }
            return true;
        },
        processZipCode() {
            if (
                !this.localShop.fiscalData?.zipCode
            ) {
                this.showError(`El código postal no puede estar vacío`);
                return false;
            }
            return true;
        },
        processLegalName() {
            if (
                !this.localShop.fiscalData?.legalName
            ) {
                this.showError(`La razón social no puede estar vacía`);
                return false;
            }
            return true;
        },
        processTaxRegimen() {
            if (
                !this.localShop.fiscalData?.taxRegime
            ) {
                this.showError(`El régimen fiscal no puede estar vacío`);
                return false;
            }
            return true;
        },
        onFiscalDataEditionConfirmationModalClosingMx() {
            this.closeModalById(
                `#modalFiscalDataEditionConfirmationMx_${this.shop.code}`
            );
            this.openModalById(`#modalTestBillingMx_${this.shop.code}`);
        },
        onTestBillingModalClosingMx() {
            this.closeModalById(`#modalTestBillingMx_${this.shop.code}`);
        },
        onFiscalDataEditionConfirmationModalClosingMxESC() {
            this.closeModalById(`#modalFiscalDataEditionConfirmationMx_${this.shop.code}`);
        },
    },
    mounted() {
        this.getEmiteCertificateStatus();
    },
    computed: {
        getInstanceCode() {
            return process.env.VUE_APP_INSTANCE_CODE;
        },
        hasToShowSection() {
			return process.env.VUE_APP_INSTANCE_CODE == "MX";
		},
    },
    components: {
        Spinner,
        ModalFiscalDataEditionConfirmationMx,
        ModalTestBillingMx,
    },
    mixins: [commons, modalCommons],
};
</script>

<style scoped lang="scss">
    .arrow-fixed {
        display: flex;
        align-items: center;
        article {
            position: static;
            margin: 0 0 40px 80px;
            width: 80%;
        }
    }
</style>
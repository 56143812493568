<template>
  <div
    class="divider-bistro"
    :style="{
      height: `${height}px`,
    }"
  ></div>
</template>

<script>
export default {
  name: "DividerBistro",
  props: {
    height: {
      type: Number,
      default: 16,
    },
  },
};
</script>

<style lang="scss">
.divider-bistro {
  display: block;
  width: 100%;
}
</style>

export function customerDetailCashbox(value, option, paymentTypeIndex) {
  if (!value) return "";

  switch (value) {
    case 1:
      if (option === "text") return "Depósito";
      if (option === "class") return "deposit";
      break;
    case 2:
      if (option === "text") return "Retiro";
      if (option === "class") return "retirement";
      break;
    case 3:
      if (option === "text") return "Ajuste";
      if (option === "class") return "arching";
      break;
    case 4:
      if (option === "text") return "Comanda";
      if (option === "class")
        return paymentTypeIndex === 12 || paymentTypeIndex === 13
          ? "order-grey"
          : "order";
      break;
    case 5:
      if (option === "text") return "Venta";
      if (option === "class")
        return paymentTypeIndex === 12 || paymentTypeIndex === 13
          ? "sale-grey"
          : "sale";
      break;
    case 6:
      if (option === "text") return "Apertura de caja";
      if (option === "class") return "sale";
      break;
    case 7:
      if (option === "text") return "Cierre de caja";
      if (option === "class") return "retirement";
      break;
    case 8:
      if (option === "text") return "Apertura de turno";
      if (option === "class") return "sale";
      break;
    case 9:
      if (option === "text") return "Cierre de turno";
      if (option === "class") return "retirement";
      break;
    case 10:
      if (option === "text") return "Anulación";
      if (option === "class") return "retirement";
      break;
    case 11:
      if (option === "text") return "Anulación por Facturación";
      if (option === "class") return "retirement";
      break;
    case 12:
      if (option === "text") return "Nota de Crédito";
      if (option === "class") return "retirement";
      break;
    case 13:
      if (option === "text") return "Depósito";
      if (option === "class") return "order-grey";
      break;
    case 14:
      if (option === "text") return "Propina";
      if (option === "class") return "tip";
      break;
    default:
      if (option === "text") return "Depósito";
      if (option === "class") return "deposit";
  }
}

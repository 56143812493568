<template>
	<!-- Modal Structure -->
	<div id="modalReportMonthV2" class="modal modalReportMonth" data-modal="01">
		<div class="modal-header">
			<h4>{{ item.date }}</h4>
			<p>Resumen del {{ type }}</p>

			<!-- CLOSE -->
			<div class="btnClose">
				<a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat">
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<!-- BODY MODAL -->
		<div v-if="item.detail" class="modal-body modalStock">

			<!-- FICHA CLIENTE -->
			<article class="cardClient">
				<!-- RECORD -->
				<div class="row justify-content-center">
					<div class="col-md-2 text-center">
						<div class="totalReport">
							<h4>Total</h4>
							<h4><currency-style :valor="$filters.currency(item.detail.total)" /></h4>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-2 text-center">
						<div class="itemsReport">
							<h4>Ticket Promedio</h4>
							<h4><currency-style :valor="$filters.currency(item.detail.avgTicketAmount)" /></h4>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-2 text-center">
						<div class="itemsReport">
							<h4>Ventas</h4>
							<h4>{{ item.detail.salesQuantity }}</h4>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-2 text-center">
						<div class="itemsReport">
							<h4>Comandas</h4>
							<h4>{{ item.detail.ordersQuantity }}</h4>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-2 text-center">
						<div class="itemsReport">
							<h4>Anulaciones</h4>
							<h4>{{ item.detail.ordersVoidQuantity }}</h4>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-2 text-center">
						<div class="itemsReport">
							<h4>N. de Crédito</h4>
							<h4>{{ item.detail.creditNotesQuantity }}</h4>
						</div>
					</div>
				</div>
			</article>

			<!-- RESUMEN CATEGORIAS EXCEL -->
			<article class="categoryMonth">
				<!-- SLIDE -->
				<!-- <section v-if="!mobile" class="categoryMonth-slide">
					<button @click="filtersMore = false" class="btnNextIndicator btnsales">
						<img src="~/assets/img/right-left.svg" alt="">
					</button>

					<button @click="filtersMore = true" class="btnNextIndicator">
						<img src="~/assets/img/right-arrow.svg" alt="">
					</button>
				</section> -->

				<!-- CATEGORIA 1 -->
				<section v-if="!filtersMore || mobile" class="item">
					<h4>Ventas por Origen</h4>
					<ul class="item-list">
						<li v-for="(o, index) in item.byOrigin" :key="index">
							<p>{{ o.origin }}</p>
							<p><currency-style :valor="$filters.currency(o.total)" /></p>
						</li>

						<li>
							<p>Totales</p>
							<p><currency-style :valor="$filters.currency(getTotalByOrigin(item.byOrigin))" /></p>
						</li>
					</ul>
				</section>

				<!-- CATEGORIA 2 -->
				<section class="item">
					<h4>Comprobantes</h4>
					<ul class="item-list">
						<li>
							<p>Comandas</p>
							<p><currency-style :valor="$filters.currency(item.byType.orders)" /></p>
						</li>

						<li>
							<p>Facturas</p>
							<p><currency-style :valor="$filters.currency(item.byType.fiscalSales)" /></p>
						</li>

						<li>
							<p>Totales</p>
							<p><currency-style :valor="$filters.currency(getTotal(item.byType))" /></p>
						</li>
					</ul>
				</section>

				<!-- CATEGORIA 3 -->
				<section class="item">
					<h4>Medios de pago</h4>
					<ul class="item-list">
						<li>
							<p>Efectivo</p>
							<p><currency-style :valor="$filters.currency(item.byPaymentType.cash)" /></p>
						</li>
						<li>
							<p>Tarjetas</p>
							<p><currency-style :valor="$filters.currency(item.byPaymentType.cards)" /></p>
						</li>
						<li>
							<p>Online</p>
							<p><currency-style :valor="$filters.currency(item.byPaymentType.online)" /></p>
						</li>
						<li>
							<p>Voucher</p>
							<p><currency-style :valor="$filters.currency(item.byPaymentType.voucher)" /></p>
						</li>
						<li>
							<p>QR</p>
							<p><currency-style :valor="$filters.currency(item.byPaymentType.qr)" /></p>
						</li>
						<li>
							<p>Cuenta Cliente</p>
							<p><currency-style :valor="$filters.currency(item.byPaymentType.account)" /></p>
						</li>
						<li class="total">
							<p>Subtotal:</p>
							<p><currency-style
									:valor="$filters.currency(getTotalWithoutEmployeesNorInvitations(item.byPaymentType))" />
							</p>
						</li>
						<li>
							<p>Empleados</p>
							<p><currency-style :valor="$filters.currency(item.byPaymentType.employee)" /></p>
						</li>
						<li>
							<p>Invitación</p>
							<p><currency-style :valor="$filters.currency(item.byPaymentType.invitation)" /></p>
						</li>
						<li class="total">
							<p>Total:</p>
							<p><currency-style :valor="$filters.currency(getTotal(item.byPaymentType))" /></p>
						</li>
					</ul>
				</section>

			</article>
		</div>
	</div>
</template>
<script>
import CurrencyStyle from '@/components/CurrencyStyle'

export default {
	props: {
		item: {
			type: Object,
			default: () => {
				return {}
			}
		},
		type: {
			type: String
		}
	},
	watch: {
		item() {
			this.filtersMore = false
		}
	},
	computed: {
		modalInfo() {
			return this.$store.getModalInfo
		},
		mobile() {
			return this.$store.getMobile
		}
	},
	data() {
		return {
			filtersMore: false
		}
	},
	methods: {
		getTotalWithoutEmployeesNorInvitations(Obj) {
			let total = 0
			Object.keys(Obj).map(function (key) {
				if (key !== `employee` && key !== `invitation`) {
					total += Obj[key]
				}
			})

			return total
		},
		getTotal(Obj) {
			let total = 0
			Object.keys(Obj).map(function (key) {
				total += Obj[key]
			})

			return total
		},
		getTotalByOrigin(origins) {
			let total = 0
			if (origins !== undefined) {
				origins.forEach(element => {
					total += element.total
				})
			}

			return total
		}
	},
	components: {
		CurrencyStyle
	}
}
</script>

<script>
import { toast } from "vue3-toastify";
export default {
    data() {
        return {

        };
    },
    mounted() {

    },
    computed: {

    },
    methods: {

        showErrors(res) {
            if(!res) return;
            console.error('showErrors', res)
            if (res?.status === 400) {
                console.error(res)
                toast.error("Ups! Error 400. Algo ha pasado. Intenta luego");
            } else if (res?.status === 401) {
                toast.show("La sesion ha caducado, seras redirigido al login");
                setTimeout(() => {
                    window.location.href = "/login";
                }, 3000);
            } else if (res?.status === 500) {
                toast.error("Ups! Error 500 Algo ha pasado. Intenta luego");
            } else if (res?.data?.responseCode < 0) {
                toast.error(res?.data?.responseMessage);
            } else {
                toast.error("Ups! Algo ha pasado. Intenta luego");
            }
        },
        showSuccess(message) {
            toast.success(message);
        },
        showError(message) {
            toast.error(message);
        },

    },
};
</script>
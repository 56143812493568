<template>
    <div :class="'emoji-'+ childClass">
			<div class="face">
				<div class="eye-left"></div>
				<div class="eye-right"></div>
				<div :class="'mouth-'+ childClass"></div>
			</div>
	</div>
</template>
<script>
export default {
    name: 'CustomEmoji',
    props: {
        childClass:{
            type: String
        }
    },

}
</script>
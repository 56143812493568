import { useGlobalStore } from "@/store/global.js";
import { usePdvStore } from "@/features/pdv/store/pdv.js"; 

export default {
  install: (app) => {
    const globalStore = useGlobalStore();
    app.provide("globalStore", globalStore);
    app.config.globalProperties.$store = globalStore;
    
    const pdvStore = usePdvStore();
    app.provide("pdvStore", pdvStore);
    app.config.globalProperties.$pdv = pdvStore;
  },
};

<script>
export default {
  computed: {},
  methods: {
    createUUID() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    getBOrdersSubDomainByInstance() {
      let subdomain = "borders";

      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "MX": {
          subdomain = "mx.borders";
          break;
        }
        case "UY": {
          subdomain = "uy.borders";
          break;
        }
        case "ES": {
          subdomain = "es.borders";
          break;
        }
      }

      return subdomain;
    },
  },
};
</script>

<template>
	<a
		href="https://wa.me/541137757552"
		class="customerServiceLink"
		v-if="isMobile && getInstance === 'MX'"
	>
		<div class="content">
			<img
				src="https://static.bistrosoft.com/WebAccess/images/WhastappIcon.png"
				alt="Atención al cliente"
				class="WhatsappIcon"
			/>
			<p class="message">
				Para comunicarte con atención al cliente contáctanos por whatsapp
			</p>
		</div>
	</a>
</template>

<script>
import responsive from "@/mixins/mixResponsive";
export default {
	mixins: [responsive],
	computed: {
		getInstance() {
			return process.env.VUA_APP_INSTANCE_CODE;
		},
	},
};
</script>


<style scoped lang="stylus"></style>
function parseToQuery(options) {
	let results = ''
	Object.keys(options).map((key) => {
		if (options[key]) {
			results += `${key}=${options[key]}&`
		}
	})
	return results
}
function parseToQueryEncoded(options) {
	let results = ''
	Object.keys(options).map((key) => {
		if (options[key]) {
			results += `${key}=${encodeURIComponent(options[key])}&`
		}
	})
	return results
}
/*
Format of an object inside this arr ->
{
	id: X,
	selected: true/false
}
*/
function filterToString(arr) {
	let filteredArr = arr.filter(x => x.selected === true).map(x => x.id)
	return filteredArr.join()
}

function parseToObject(url) {
	let result = {}
	let searchIndex = url.indexOf('?')

	if (searchIndex === -1) {
		return result
	}
	let sPageURL = url.substring(searchIndex + 1)
	let sURLVariables = sPageURL.split('&')
	for (let i = 0; i < sURLVariables.length; i++) {
		var sParameterName = sURLVariables[i].split('=')
		result[sParameterName[0]] = sParameterName[1]
	}
	return result
}

module.exports = {
	parseToQuery,
	parseToQueryEncoded,
	parseToObject,
	filterToString
};

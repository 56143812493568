<template>
	<div :id="id" class="modal" style="overflow-y: auto !important;">
		<div class="modal-header">

			<div class="image-title">
				<h4>Recuperar backup de costos de {{ entityName }} para comercio {{ selectedShopCode }}</h4>
				<p>Fácil y Simple</p>
			</div>

			<!-- CLOSE -->
			<div class="btnClose">
				<a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat">
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<div class="modal-body">
			<form action class="formGeneral">
				<article class="formMain">
					<div class="modal-header-product">
						<p>Recuerde que al recuperar el backup se <strong class="advice-red">sobrescribirá</strong> los
							costos de {{ entityName }} con el backup generado al momento de su última importación. ¿Está
							seguro que desea hacerlo?</p>
						<div class="itemForm select-option">
							Seleccione backup
							<select v-model="backupId" class="browser-default">
								<option value disabled selected>Seleccione Backup</option>
								<option v-for="backup in computedAvailableBackups" :value="backup.backupId"
									:key="backup.backupId">{{ backup.creationTimestamp }}</option>
							</select>
						</div>
					</div>
					<div class="modal-body">
					</div>
				</article>
			</form>
		</div>

		<div class="modal-footer">
			<article class="btn-main">
				<a @click="recoveryProducts" type="submit">
					<p>{{ buttonAction }}</p>
				</a>
			</article>
		</div>

	</div>
</template>

<script>
import axios from 'axios'
import { toast } from "vue3-toastify";
import M from 'materialize-css/dist/js/materialize.min.js';

export default {
	props: {
		selectedList: {
			type: Object,
			default: () => {
				return {}
			}
		},
		selectedShop: {
			type: Object,
			default: () => {
				return {}
			}
		},
		availableBackups: {
			type: Array,
			default: () => {
				return []
			}
		},
		id: {
			type: String,
			default: () => {
				return "ModalRecoveryCostsFromBackup"
			}
		},
		entityName: {
			type: String,
			default: () => {
				return "productos"
			}
		},
		recoveryEndpoint: {
			type: String,
			default: () => {
				return "recoveryProducts"
			}
		},
	},
	data() {
		return {
			modalTitle: `Recuperar costos de ${this.entityName}`,
			modalAction: `¡Costos de ${this.entityName} recuperado con éxito!`,
			buttonAction: `Recuperar costos`,
			selectedFile: null,
			selectedShopCode: null,
			excelParams: '',
			backupId: null
		}
	},
	computed: {
		computedAvailableBackups() {
			return this.availableBackups ?? []
		}
	},
	methods: {
		setShopCode(shop) {
			this.selectedShopCode = shop
		},
		recoveryProducts() {
			if (!this.backupId) {
				toast.error('Debe seleccionar un Backup')
				return
			}

			this.$store.initLoading(this)
			const shopCodes = this.$store.getMerchantTags.map(
				(i) => {
					return i.shopCode
				}
			)
			axios.post(`/api/costV2/${this.recoveryEndpoint}`, {
				MerchantCodes: shopCodes,
				ListId: this.selectedList.id,
				backupId: this.backupId
			})
				.then((res) => {
					console.log(res);
					if (res.data.responseCode == 0) {
						toast.success(`Costos de ${this.entityName.charAt(0).toUpperCase() + this.entityName.slice(1)} recuperados exitósamente!`)
						this.$store.finishLoading(this)
						this.$emit('importDone')

						var modalInstance = document.getElementById(`${this.id}`);
						var instance = M.Modal.getInstance(modalInstance);
						instance.close();
					}
					else {
						toast.error(res.data.responseMessage)
						console.error(res.data.responseMessage)
						this.$store.errorLoading(this)
					}
				})
				.catch(err => {
					console.error(err);
					if (err.response.status === 401) {
						toast.show('La sesion ha caducado, seras redirigido al login')
						setTimeout(() => {
							window.location.href = '/login'
						}, 3000)
					} else {
						toast.error(`Hubo un error al recuperar la lista de ${this.entityName}. Reintentá más tarde`)
						console.error(err)
						this.$store.errorLoading(this)
					}
				})
		}
	},
	components: {}
}
</script>

<template>
  <div id="modalStockMovementType" class="modal modal-block-outside" style="overflow-y: auto !important"
    @keydown.esc="closeModal()">
    <div class="modal-header">
      <div class="image-title">
        <h4>Movimiento de Stock</h4>
        <p>Carga {{ selectStockConfig.movementMethod }}</p>
      </div>

      <!-- CLOSE -->
      <div class="btnClose">
        <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div v-if="hasToShowUploadExcelFile" class="modal-body modal-movement-type modal-movement-type-full">
      <p class="title-stock">
        1. Descargue el excel con el formato adecuado para realizar la carga
        masiva de stock
      </p>

      <div class="download">
        <div class="button">
          <SpinnerComp v-if="isLoading && wasDownloadTemplateFile == false" />
          <a v-else @click="onDownloadOriginalTemplateExcel()" class="waves-effect waves-light">
            <div class="btn-link">
              <span class="icon-Bistro-_Download"></span>
              <span>Descargar Detalle</span>
            </div>
          </a>
        </div>
      </div>

      <section v-if="wasDownloadTemplateFile">
        <p class="title-stock">
          2. Complete el excel descargado y vuelva a cargarlo con los cambios
          realizados.
        </p>
        <p class="subtitle-stock">
          Nota: respetar el formato. Si efectúa cambios en la estructura del
          archivo los cambios no se verán reflejados.
        </p>

        <div class="itemForm selectImage beforeAdditional">
          <label class="selectImage-label" for>Seleccione el archivo:</label>
          <input type="file" ref="fileupload" @change="onFileSelected" />
        </div>

        <article v-if="inputFile" class="btn-main">
          <SpinnerComp v-if="isLoading" />
          <a v-else @click="pushStockMovements()" type="submit">
            <p>Confirmar</p>
          </a>
        </article>
      </section>
    </div>

    <div v-else :class="{
      'modal-movement-type-full': hasToShowMovementChangeLogs,
      'modal-body modal-movement-type': true,
    }">
      <p class="title-stock">1. Elija el tipo de movimiento</p>

      <div class="options">
        <article @click="onSelectedMovementType(stockMovementTypes[0])" :class="{
      'btn-option': true,
      'btn-option-active': selectedMovementType === stockMovementTypes[0],
    }" type="submit">
          <span class="icon-Bistro-_Right_blue_arrow img"></span>
          <p class="text">Ingresos</p>
        </article>

        <article @click="onSelectedMovementType(stockMovementTypes[1])" :class="{
      'btn-option': true,
      'btn-option-active': selectedMovementType === stockMovementTypes[1],
    }" type="submit">
          <span class="icon-Bistro-_Left_blue_arrow img"></span>
          <p class="text">Egresos</p>
        </article>

        <article @click="onSelectedMovementType(stockMovementTypes[2])" :class="{
      'btn-option': true,
      'btn-option-active': selectedMovementType === stockMovementTypes[2],
    }" type="submit">
          <span class="icon-Bistro-_Tool_blue img"></span>
          <p class="text">Ajustes</p>
        </article>
      </div>

      <section v-if="hasToShowMovementChangeLogs" class="change-logs">
        <h5 class="type">Tipo</h5>
        <article v-for="(o, index) in getChangeLogByMovementType" :key="index.text">
          <label v-if="o.hasToShow" style="display: inline-flex; padding: 0.3em">
            <input :checked="o.text === selectedMovementChangeLog" v-model="selectedMovementChangeLog" type="radio"
              :id="o.text" :value="o.text">
            <label :for="o.text">{{ o.text }}</label>
          </label>
          <div class="shop-binding-select" v-if="hasToShowShopBindingList && o.text === 'Movimiento de Sucursal'">
            <select class="browser-default" v-model="selectedBoundShop">
              <option v-for="(s, i) in getShopBindingList" :key="i">{{ s }}</option>
            </select>
          </div>
          <input class="otherInput" v-if="hasToShowOtherTextBox && o.text === 'Otros'"
            placeholder="Motivo del movimiento de stock" type="text" v-model="movementChangeLogComments" />
        </article>

        <article class="btn-main">
          <SpinnerComp v-if="isLoading" />
          <a v-else @click="goToUploadFileModal()" type="submit">
            <p>Confirmar</p>
          </a>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import SpinnerComp from "../components/SpinnerComp";
import mixCommons from "../mixins/mixCommons";
import stockV2Commons from "../mixins/stockV2Commons";
import { parseToQuery } from "../utils/utils";
import { toast } from "vue3-toastify";

/* eslint-disable */

export default {
  mixins: [mixCommons, stockV2Commons],
  props: {
    selectStockConfig: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedListProp: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      selectedMovementType: undefined,
      selectedMovementChangeLog: undefined,
      movementChangeLogComments: "",
      hasToShowUploadExcelFile: false,
      isLoading: false,
      params: {},
      wasDownloadTemplateFile: false,
      inputFile: null,
    };
  },
  computed: {
    getChangeLogByMovementType() {
      switch (this.selectedMovementType) {
        case "Ingresos":
          return this.changeLogEntryStockOptions;
        case "Egresos":
          return this.changeLogRemovalStockOptions;
        case "Ajustes":
          return this.changeLogAdjustStockOptions;
        default:
          return [];
      }
    },
    hasToShowOtherTextBox() {
      return this.selectedMovementChangeLog === "Otros";
    },
    hasToShowMovementChangeLogs() {
      return this.selectedMovementType;
    },
    hasToShowShopBindingList() {
      return (this.selectedMovementChangeLog === 'Movimiento de Sucursal'
        && this.$store.getShopsThatAreBoundToLocal
        && this.getShopBindingList?.length > 0
        && this.selectedMovementType === 'Egresos')
    },
    getShopBindingList() {
      let shopbindings = this.$store.getShopsThatAreBoundToLocal;
      if (shopbindings !== undefined) {
        const selectedShopBindings = shopbindings.filter(x => x.shopCode == this.selectedListProp.shopCode)[0];
        if (selectedShopBindings != undefined)
          return selectedShopBindings.shopBindings.map(x => x.shopName)

        return undefined
      }
      return undefined
    }
  },
  methods: {
    closeModal() {
      this.clearData();
      $("#__layout").css("pointer-events", "all");
    },
    onSelectedMovementType(movementType) {
      const hasAnyProductWithProductCost =
        this.$store.getProductsThatHaveProductionCosts?.length > 0;
      this.editChangelogEntry(false, hasAnyProductWithProductCost);
      this.clearData();
      this.selectedMovementType = movementType;
    },
    goToUploadFileModal() {
      if (this.selectedMovementChangeLog === undefined) {
        this.showError("Seleccione un tipo para los movimientos");
        return;
      }

      if (
        this.selectStockConfig.movementMethod === this.stockMovementMethods[1]
      ) {
        this.hasToShowUploadExcelFile = true;
      } else {
        this.$emit(
          "selectedMovementType",
          this.selectedMovementType,
          this.selectedMovementChangeLog,
          this.movementChangeLogComments,
          this.selectedBoundShop
        );
        this.clearData();
      }
    },
    clearData() {
      this.selectedMovementType = undefined;
      this.selectedMovementChangeLog = undefined;
      this.movementChangeLogComments = "";
      this.hasToShowUploadExcelFile = false;
      this.isLoading = false;
      this.params = {};
      this.wasDownloadTemplateFile = false;
      this.inputFile = null;
    },
    onDownloadOriginalTemplateExcel() {
      this.isLoading = true;

      this.params["listId"] = this.selectedListProp.id;
      this.params["movementType"] = this.selectedMovementType;
      this.params["sectionKey"] = this.selectStockConfig.sectionKey;
      this.params["shopCode"] = this.selectedListProp.shopCode;

      console.log("onDownloadOriginalTemplateExcel", this.params);

      let win = window.open(
        `/api/productsV2/exportStockOriginalTemplate/?${parseToQuery(
          this.params
        )}`
      );
      win.focus();
      this.isLoading = false;
      this.wasDownloadTemplateFile = true;
    },
    onDownloadTemplateWithCostExcel() {
      this.isLoading = true;

      this.params["listId"] = this.selectedListProp.id;
      this.params["movementType"] = this.selectedMovementType;
      this.params["sectionKey"] = this.selectStockConfig.sectionKey;
      this.params["shopCode"] = this.selectedListProp.shopCode;
      this.params["selectedBoundShop"] = this.selectedBoundShop;

      let win = window.open(
        `/api/productsV2/exportStockTemplateWithCost/?${parseToQuery(
          this.params
        )}`
      );
      win.focus();
      this.isLoading = false;
      this.wasDownloadTemplateFile = true;
    },
    onFileSelected(event) {
      this.inputFile = event.target.files[0];
    },
    pushStockMovements() {
      this.isLoading = true;

      let formData = new FormData();
      formData.append("file", this.inputFile);
      formData.append("listId", this.selectedListProp.id);
      formData.append("shopCode", this.selectedListProp.shopCode);
      formData.append("movementType", this.selectedMovementType);
      formData.append("movementChangeLog", this.selectedMovementChangeLog);
      formData.append("selectedBoundShop", this.selectedBoundShop);
      formData.append(
        "movementChangeLogComments",
        this.movementChangeLogComments
      );
      formData.append(
        "productType",
        this.getProductTypeBySection(this.selectStockConfig.sectionKey)
      );

      axios
        .post("/api/productsV2/importStockMovements", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.data.responseCode !== 0) {
            toast.error(
              "Ocurrió un error al guardar los movimientos. Por favor, vuelva a intentarlo!"
            );
            this.isLoading = false;
          }

          this.$emit(
            "onLoadStockMovementsByExcel",
            resp.data.syncStock,
            this.selectedMovementType
          );
          this.isLoading = false;
          this.clearData();
        })
        .catch((err) => {
          this.isLoading = false;
          if (err.response.status === 401) {
            toast.error("La sesion ha caducado, seras redirigido al login");
            setTimeout(() => {
              window.location.href = "/login";
            }, 3000);
          } else {
            toast.error(err.response.data.responseMessage);
          }
        });
    },
  },
  components: {
    SpinnerComp,
  },
};

/* eslint-enable */
</script>
<style lang="stylus">
.show-div {
	display: block;
}

.hide-div {
	display: none;
}
</style>

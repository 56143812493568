const padTime = (val) => {
  return val < 10 ? `0${val}` : val;
};

export function time(miliseconds) {
  const totalSeconds = Math.floor(miliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedTime = [
    padTime(hours),
    padTime(minutes),
    padTime(seconds),
  ].join(":");

  return formattedTime;
}

<template>
  <li>
    <div class="titlePDV">
      <p>PDV</p>
      <h5>{{ pdv.merchantName }}</h5>
    </div>

    <!-- RESUMEN GENERAL -->
    <ul class="salesGeneral-containResum resumPDV-info">
      <!-- TOTAL -->
      <li>
        <p>Total</p>
        <h4>
          <currency-style :valor="$filters.currency(pdv.totalSalesAmount)" />
        </h4>
        <span :class="pdv.totalSalesVariation < 0 ? 'negative' : 'positive'"
          >{{ pdv.totalSalesVariation }}%</span
        >
      </li>

      <!-- TRX. PROM -->
      <li>
        <p>Trx. prom.</p>
        <h4>
          <currency-style
            :valor="$filters.currency(pdv.averagePerTransaction)"
          />
        </h4>
        <span
          :class="
            pdv.averagePerTransactionVariation < 0 ? 'negative' : 'positive'
          "
          >{{ pdv.averagePerTransactionVariation }}%</span
        >
      </li>

      <!-- Q. VENTAS. -->
      <li>
        <p>Ventas</p>
        <h4>{{ pdv.quantitySaleTransactions }}</h4>
        <span
          :class="
            pdv.quantitySaleTransactionsVariation < 0 ? 'negative' : 'positive'
          "
          >{{ pdv.quantitySaleTransactionsVariation }}%</span
        >
      </li>
      <!-- Q. COMANDAS. -->
      <li>
        <p>Comandas</p>
        <h4>{{ pdv.quantityOrderTransactions }}</h4>
        <span
          :class="
            pdv.quantityOrderTransactionsVariation < 0 ? 'negative' : 'positive'
          "
          >{{ pdv.quantityOrderTransactionsVariation }}%</span
        >
      </li>
    </ul>

    <!-- ULTIMA CIERRE -->
    <div class="titlePDV">
      <p>últ. Cierre</p>
      <h5>{{ pdv.lastCashboxClosureTimeStamp }}</h5>
    </div>
  </li>
</template>
<script>
import CurrencyStyle from "../components/CurrencyStyle";
// import moment from 'moment'
export default {
  props: {
    pdv: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {},
  components: {
    CurrencyStyle,
  },
};
</script>

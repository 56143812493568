<template>
  <main>
    <div v-if="(canModifyCentralizedProducts || hasCentralizedProducts) && isProductActionSelected"
      class="centralizedProductsSwitch">
      <div v-if="canModifyCentralizedProducts" class="switch">
        <label>
          <input v-model="centralizedProdSwitch" @change="onCentralizedProdsSwitchChanged" type="checkbox" />
          <span class="lever"></span>
        </label>
      </div>
      <h4>{{ centralizedProductsLabel }}</h4>
    </div>
    <article v-if="isProductActionSelected">
      <!-- MÓDULO DE PRODUCTOS -->

      <!-- CARTAS -->
      <ProductCollapseItem :zIndex="7" :expandTabName="expandTabName" :mobile="mobile" :canEdit="canEditProductsCheck"
        :itemSection="getItemSectionList[0]" :list="getMenues" :isProductSectionLoading="isProductSectionLoading"
        @onDownloadQr="onDownloadQr" @onExpandSection="onExpandSection"
        @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById" @onClickDropdownButton="onClickDropdownMenu"
        @onOpenModal="openMenuModal" />

      <!-- PRODUCTOS -->
      <ProductCollapseItem :zIndex="6" :expandTabName="expandTabName" :mobile="mobile" :canEdit="canEditProductsCheck"
        :itemSection="getItemSectionList[1]" :list="products" :stockItemsInTab="stockItemList" :categories="categories"
        :isProductSectionLoading="isProductSectionLoading" @onSelectedCategoryFilter="onSelectedCategoryFilter"
        @onExpandSection="onExpandSection" @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById"
        @onClickDropdownButton="onClickDropdownProduct" @onOpenModal="handleModal" :isEnabledFilterByInput="true"
        @onLoadFilterInput="onFilterProductsInput" :originalSearchInput="productSearchInput" />

      <!-- INGREDIENTES -->
      <ProductCollapseItem v-if="hasToShowIngredientsSection" :zIndex="5" badgeType="beta"
        :canEdit="canEditProductsCheck" :expandTabName="expandTabName" :mobile="mobile"
        :itemSection="getItemSectionList[10]" :list="ingredients" :isProductSectionLoading="isProductSectionLoading"
        defaultPlaceholder="Filtrar ingredientes" @onExpandSection="onExpandSection"
        @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById" @onClickDropdownButton="onClickDropdownIngredient"
        @onOpenModal="handleIngredientModal" :isEnabledFilterByInput="true"
        @onLoadFilterInput="onFilterIngredientsInput" :originalSearchInput="ingredientSearchInput" />

      <!-- ADICIONALES -->
      <ProductCollapseItem v-if="getItemSectionList[2].isVisible" :zIndex="4" :expandTabName="expandTabName"
        :mobile="mobile" :canEdit="canEditProductsCheck" :itemSection="getItemSectionList[2]" :list="aditionals"
        :isProductSectionLoading="isProductSectionLoading" defaultPlaceholder="Filtrar adicionales"
        @onExpandSection="onExpandSection" @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById"
        @onClickDropdownButton="onClickDropdownAditional" @onOpenModal="openOptionModal" :isEnabledFilterByInput="true"
        @onLoadFilterInput="onFilterAditionalsInput" :originalSearchInput="aditionalSearchInput" />

      <!-- GUSTOS -->
      <ProductCollapseItem v-if="getItemSectionList[3].isVisible" :zIndex="3" :expandTabName="expandTabName"
        :mobile="mobile" :canEdit="canEditProductsCheck" :itemSection="getItemSectionList[3]"
        :list="getAditionalsAndTastes.tastes" :isProductSectionLoading="isProductSectionLoading"
        @onExpandSection="onExpandSection" @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById"
        @onClickDropdownButton="onClickDropdownTaste" @onOpenModal="openOptionModal" />

      <!-- CATEGORIAS -->
      <ProductCollapseItem :zIndex="2" :expandTabName="expandTabName" :mobile="mobile" :canEdit="canEditProductsCheck"
        :itemSection="getItemSectionList[4]" :list="categories" :isProductSectionLoading="isProductSectionLoading"
        @onExpandSection="onExpandSection" @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById"
        @onClickDropdownButton="onClickDropdownCategory" @onOpenModal="openCategoryModal" />

      <!-- COMBOS -->
      <ProductCollapseItem v-if="getItemSectionList[5].isVisible" :zIndex="1" :expandTabName="expandTabName"
        :mobile="mobile" :itemSection="getItemSectionList[5]" :list="combos" :canEdit="canEditProductsCheck"
        :isProductSectionLoading="isProductSectionLoading" defaultPlaceholder="Filtrar combos"
        @onExpandSection="onExpandSection" @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById"
        @onClickDropdownButton="onClickDropdownCombo" @onOpenModal="handleComboModal" :isEnabledFilterByInput="true"
        @onLoadFilterInput="onFilterComboInput" :originalSearchInput="combosSearchInput" 
        style="margin-bottom: 30vh;" />
    </article>
    <article v-else-if="stockTabSelected.param == 'costo'">
      <!-- MÓDULO DE COSTOS -->

      <ProductCollapseItem v-if="hasToShowEngineeringMenu && getItemSectionList[16].isVisible" :zIndex="4"
        :mobile="mobile" :itemSection="getItemSectionList[16]"
        :tooltip="`Análisis de las cartas por intermedio de una matriz BCG que permita visualizar la rentabilidad y la popularidad de cada producto; por carta y por categoría.`"
        badgeType="new" @onExpandSection="goToMenuEngineeringPage" />

      <!-- PRODUCTOS COSTOS -->
      <ProductCollapseItem :zIndex="3" :expandTabName="expandTabName" :mobile="mobile" :canEdit="canEditCosts"
        :itemSection="getItemSectionList[13]" :list="products" :categories="categories" :hasToOrderByCost="true"
        :stockItemsInTab="stockItemList" @onSelectedCategoryFilter="onSelectedCategoryFilter"
        :warningMessage="warningMessage" @onExpandSection="onExpandSection"
        @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById" @onClickDropdownButton="onClickDropdownCostProduct"
        @onOpenModal="openCostModalForProduct" :isEnabledFilterByInput="true" @onLoadFilterInput="onFilterProductsInput"
        :originalSearchInput="productSearchInput" :originalCategoryFilter="productCategorySearchSelect" />

      <!-- INGREDIENTES COSTOS -->
      <ProductCollapseItem v-if="hasToShowIngredientsSection" :zIndex="2" :expandTabName="expandTabName"
        :mobile="mobile" :canEdit="canEditCosts" :itemSection="getItemSectionList[15]" :stockItemsInTab="stockItemList"
        :warningMessage="warningMessage" :list="ingredients" defaultPlaceholder="Filtrar ingredientes"
        :hasToOrderIngredientByCost="true" @onExpandSection="onExpandSection"
        @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById" @onClickDropdownButton="onClickDropdownCostIngredient"
        @onOpenModal="openCostModalForIngredients" :isEnabledFilterByInput="true"
        @onLoadFilterInput="onFilterIngredientsInput" :originalSearchInput="ingredientSearchInput" />

      <!-- ADICIONALES COSTOS -->
      <ProductCollapseItem v-if="getItemSectionList[14].isVisible" :zIndex="1" :expandTabName="expandTabName"
        :mobile="mobile" :canEdit="canEditCosts" :itemSection="getItemSectionList[14]" :stockItemsInTab="stockItemList"
        :hasToOrderAdditionalByCost="true" :warningMessage="warningMessage" :list="aditionals"
        defaultPlaceholder="Filtrar adicionales" @onExpandSection="onExpandSection"
        @onOpenAndCloseDropdownById="onOpenAndCloseDropdownById" @onClickDropdownButton="onClickDropdownCostAdditional"
        @onOpenModal="openCostModalForAdditional" :isEnabledFilterByInput="true"
        @onLoadFilterInput="onFilterAditionalsInput" :originalSearchInput="aditionalSearchInput" />
    </article>

    <article v-else-if="isConfirmStockMovementsActionSelected">
      <!-- CONFIRMACIÓN DE STOCK -->

      <article class="mainFilter filterEvent bring-to-front">
        <section class="mainFilter-title">
          <h1>{{ getTitleByMovementTypeForConfirmStockMovementsPage() }}</h1>
        </section>
        <div class="btnCloseStock">
          <a :href="`/api/report/stockMovementsReport/?Code=${getEditStockMovementList.code}&selectedMovementType=${getSelectedMovementType}`"
            class="waves-effect waves-light">
            <div class="btn-link">
              <span class="icon-Bistro-_Download"></span>
              <span>Descargar Detalle</span>
            </div>
          </a>
          <a href="#!" @click="goBeforeConfirmStockMovementsPage()"
            class="modal-action modal-close waves-green btn-flat btn-flat-stock-header">
            <div class="btn-main">
              <a class="error">
                <p>Seguir editando</p>
              </a>
            </div>
          </a>
          <a href="#!" @click="onConfirmStockMovements()"
            class="modal-action modal-close waves-green btn-flat btn-flat-stock-header">
            <div class="btn-main">
              <a class="green">
                <p>Confirmar Movimientos</p>
              </a>
            </div>
          </a>
        </div>
      </article>

      <section class="stockConfirmBackground">
        <article>
          <ul class="mainEvents-history mainStock-history">
            <li class="stockGridTitle">
              <div style="width: 20%">
                {{ getItemTitleBySectionKey() }}
              </div>
              <div style="width: 15%">Categoría</div>
              <div style="width: 10%">Sku</div>
              <div style="width: 18%">Tipo</div>
              <div style="width: 12%">
                {{ getTitlePreLastColumnByMovementType() }}
              </div>
              <div style="width: 12%">
                {{ getTitleLastColumnByMovementType().title }}
              </div>
              <div style="width: 12%">Stock Final</div>
              <div style="width: 1%"></div>
            </li>
            <li class="stockGridTitle" v-for="(transaction, index) in getEditStockMovementList.stocks" :key="index">
              <div class="item itemBlack" style="width: 20%">
                <!-- <span :class="getIconForMovementType(transaction.movementType)"></span> -->
                <p>{{ transaction.name }}</p>
              </div>

              <div class="item itemBlack" style="width: 15%">
                <p>{{ transaction.department }}</p>
              </div>

              <div class="item" style="width: 10%">
                <p>{{ transaction.sku }}</p>
              </div>

              <div class="item" style="width: 18%">
                <p>
                  {{ transaction.changeLog }}
                  {{ transaction.changeLogOptionVerbose }}
                </p>
              </div>

              <div class="item quantity quantityGrey" style="width: 12%">
                <p>
                  {{ transaction.currentStock }}{{ transaction.measureUnit }}
                </p>
              </div>

              <div class="item quantity quantityLightGrey" :class="{
                quantityGreen: getSelectedMovementType === 'Ingresos',
              }" style="width: 12%">
                <p v-if="transaction.measureUnit !== ''">
                  {{ Number(transaction.quantity).toFixed(3)
                  }}{{ transaction.measureUnit }}
                </p>
                <p v-else>{{ transaction.quantity }}</p>
              </div>

              <div class="item quantity" style="width: 12%">
                <p>{{ GetFinalStockByTransaction(transaction) }}</p>
              </div>

              <div class="item quantity" style="width: 1%">
                <span @click="deleteStockMovementItem(index)" class="icon-Bistro-_Trash_bin"></span>
              </div>
            </li>
          </ul>
        </article>
      </section>
    </article>

    <article v-else-if="getSelectedMovementType !== undefined">
      <!-- MODIFICACIÓN DE STOCK -->
      <article class="mainFilter filterEvent bring-to-front">
        <section class="mainFilter-title">
          <h1>{{ getTitleByMovementType() }}</h1>
        </section>
        <div class="btnCloseStock">
          <a href="#!" @click="openConfirmForRemoveStockMovementsModal()"
            class="modal-action modal-close waves-green btn-flat btn-flat-stock-header">
            <!-- <span class="icon-Bistro-_Close"></span> -->
            <div class="btn-main">
              <a class="error">
                <p>Deshacer movimientos</p>
              </a>
            </div>
          </a>
          <a href="#!" @click="onClickDropdownModifiedStock()"
            class="modal-action modal-close waves-green btn-flat btn-flat-stock-header">
            <div class="btn-main">
              <a>
                <p>Verificar Movimientos</p>
              </a>
            </div>
          </a>
        </div>
      </article>

      <ProductCollapseItem :zIndex="3" :expandTabName="expandTabName" :mobile="mobile"
        :itemSection="getItemSectionList[6]" :list="products" @onExpandSection="onExpandSection"
        @onOpenAndCloseDropdownById="onClickDropdownModifiedStock" @onOpenModal="handleStockModal"
        :isEnabledFilterByInput="true" @onLoadFilterInput="onFilterProductsInput" :categories="categories"
        @onSelectedCategoryFilter="onSelectedCategoryFilter" :originalSearchInput="productSearchInput"
        :originalCategoryFilter="productCategorySearchSelect" :stockItemsInTab="stockItemList" />

      <ProductCollapseItem v-if="hasToShowIngredientsSection" :zIndex="2" :expandTabName="expandTabName"
        :mobile="mobile" :itemSection="getItemSectionList[12]" :list="ingredients" @onExpandSection="onExpandSection"
        @onOpenAndCloseDropdownById="onClickDropdownModifiedStock" @onOpenModal="handleStockModal"
        :stockItemsInTab="stockItemList" defaultPlaceholder="Filtrar ingredientes" :isEnabledFilterByInput="true"
        @onLoadFilterInput="onFilterIngredientsInput" :originalSearchInput="ingredientSearchInput" />

      <ProductCollapseItem v-if="getItemSectionList[9].isVisible" :zIndex="1" :expandTabName="expandTabName"
        :mobile="mobile" :itemSection="getItemSectionList[9]" :list="aditionals" @onExpandSection="onExpandSection"
        @onOpenAndCloseDropdownById="onClickDropdownModifiedStock" @onOpenModal="handleStockModal"
        :stockItemsInTab="stockItemList" defaultPlaceholder="Filtrar adicionales" :isEnabledFilterByInput="true"
        @onLoadFilterInput="onFilterAditionalsInput" :originalSearchInput="aditionalSearchInput" />
    </article>

    <article v-else>
      <!-- MÓDULO DE STOCK -->

      <article class="mainFilter filterEvent bring-to-front" v-if="hasToShowStockAdmin">
        <h2></h2>
        <div class="btnCloseStock">
          <a @click="onClickDownloadStockReport" class="waves-effect waves-light">
            <div class="btn-link">
              <span class="icon-Bistro-_Download"></span>
              <span>Descargar Detalle</span>
            </div>
          </a>

          <div href="#!" @click="onOpenAndCloseDropdownById(`dropdown-stock-products`)"
            class="modal-action modal-close waves-green btn-flat btn-flat-stock-header">
            <!-- Si se esta mostrando la pesta;a de stock centralizado quiiero ocultar este boton -->
            <div class="btn-main" v-if="!isShowingCentralizedStock">
              <a>
                <p>+ Stock</p>
              </a>
            </div>
            <!-- Estas son las opciones que se despliegan cuando tocas Movimientos de Stock -->
            <ul id="dropdown-stock-products" class="dropdown-content dropStockOptions">
              <li v-for="(button, index) in getItemSectionList[7].dropdownOptionList" :key="index">
                <a @click="onClickDropdownStockProduct($event, button.name)">
                  <img v-if="button.img && button.img !== ''" :src="require(`../assets/img/${button.img}`)" />
                  <p>{{ button.label }}</p>
                </a>
              </li>
              <!-- Si estoy en el comercio padre muestro una opcion mas que es la del stock centralizado-->
              <li v-if="hasToShowCentralizedStock">
                <a @click="onClickCentralizedStock">
                  <img src="@/assets/img/StockCentralizadoIcon.svg" />
                  <p>
                    Stock <br />
                    Centralizado
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </article>

      <ProductCollapseItem v-if="!isShowingCentralizedStock" :zIndex="3" :expandTabName="expandTabName" :mobile="mobile"
        :itemSection="getItemSectionList[7]" :list="products" :categories="categories"
        @onSelectedCategoryFilter="onSelectedCategoryFilter" :warningMessage="warningMessage"
        @onExpandSection="onExpandSection" @onOpenModal="handleStockModal" :isEnabledFilterByInput="true"
        @onLoadFilterInput="onFilterProductsInput" :originalSearchInput="productSearchInput"
        :originalCategoryFilter="productCategorySearchSelect" :stockItemsInTab="stockItemList" />

      <ProductCollapseItem v-if="hasToShowIngredientsSection && !isShowingCentralizedStock" :zIndex="2"
        :expandTabName="expandTabName" :mobile="mobile" :itemSection="getItemSectionList[11]"
        :warningMessage="warningMessage" :list="ingredients" defaultPlaceholder="Filtrar ingredientes"
        @onExpandSection="onExpandSection" @onOpenModal="handleStockModal" :stockItemsInTab="stockItemList"
        :isEnabledFilterByInput="true" @onLoadFilterInput="onFilterIngredientsInput"
        :originalSearchInput="ingredientSearchInput" />
      <ProductCollapseItem v-if="!isShowingCentralizedStock && getItemSectionList[8].isVisible" :zIndex="1"
        :expandTabName="expandTabName" :mobile="mobile" :itemSection="getItemSectionList[8]"
        :warningMessage="warningMessage" :list="aditionals" defaultPlaceholder="Filtrar adicionales"
        @onExpandSection="onExpandSection" @onOpenModal="handleStockModal" :stockItemsInTab="stockItemList"
        :isEnabledFilterByInput="true" @onLoadFilterInput="onFilterAditionalsInput"
        :originalSearchInput="aditionalSearchInput" />
      <CentralizedStockPanel v-if="isShowingCentralizedStock" :currentShopCode="getCurrentShopCode">
      </CentralizedStockPanel>
    </article>
    <modal-message id="modalMenuUpsertFinished" :title="getSuccessModalMenuTitle" :message="getSuccessModalMenuMessage"
      @onModalClosing="onModalMessageClosing" />
    <modal-message id="modalMenuProductInserted" title="Producto creado OK!" :message="getSuccessModalProductMessage"
      @onModalClosing="onModalProductMessageClosing" />
    <modal-message id="modalMenuComboInserted" title="Combo creado OK!"
      message="El combo fue creado exitosamente. Para visualizarlo en su equipo ahora deberá asignarlo a una carta"
      @onModalClosing="onModalComboMessageClosing" />
    <modal-product :product="product" :currentSku="getCurrentSku" :selectedList="selectedList"
      :isNewProduct="isNewProduct" @upsertProduct="onUpsertingProduct"
      @deleteProduct="openModalOfConfirmationToRemove" />
    <modal-combo :combo="combo" :currentSku="getCurrentComboSku" :orderedCategories="orderedCategories"
      :selectedList="selectedList" :productsByMenu="productsByMenu" :isNewCombo="isNewCombo"
      @upsertCombo="onUpsertCombo" @deleteCombo="openModalOfConfirmationToRemove" />
    <modal-option ref="modalNewAditional" :option="option" :selectedList="selectedList"
      :checkedCategories="checkedCategories" :isNewOption="isNewOption" :optionType="optionType"
      @upsertOption="onUpsertOption" @deleteOption="openModalOfConfirmationToRemove" />
    <modal-import-products ref="modalImportProduct" :selectedList="selectedList" @importDone="onImportDone" />
    <modal-import-ingredients ref="modalImportIngredient" :selectedList="selectedList"
      @importDone="onImportIngredientsDone" />
    <modal-import-costs ref="modalImportCost" :selectedList="selectedList" @importDone="onImportCostDone" />
    <modal-import-recipes ref="modalImportRecipe" :selectedList="selectedList" @importDone="onImportRecipeDone" />
    <modal-recovery-products-from-backup ref="modalRecoveryProductsFromBackup" :selectedList="selectedList"
      :availableBackups="availableBackups" @importDone="onImportDone" />
    <modal-recovery-products-from-backup ref="modalRecoveryIngredientsFromBackup" recoveryEndpoint="recoveryIngredients"
      entityName="ingredientes" id="ModalRecoveryIngredientsFromBackup" :selectedList="selectedList"
      :availableBackups="availableBackups" @importDone="onImportDone" />
    <modal-recovery-costs-from-backup ref="modalRecoveryProductCostsFromBackup" recoveryEndpoint="recoveryProductCosts"
      entityName="productos" id="ModalRecoveryProductCostsFromBackup" :selectedList="selectedList"
      :availableBackups="availableBackups" @importDone="onImportCostDone" />
    <modal-recovery-costs-from-backup ref="modalRecoveryAdditionalCostsFromBackup"
      recoveryEndpoint="recoveryAdditionalCosts" entityName="adicionales" id="ModalRecoveryAdditionalCostsFromBackup"
      :selectedList="selectedList" :availableBackups="availableBackups" @importDone="onImportCostDone" />
    <modal-recovery-costs-from-backup ref="modalRecoveryIngredientCostsFromBackup"
      recoveryEndpoint="recoveryIngredientCosts" entityName="ingredientes" id="ModalRecoveryIngredientCostsFromBackup"
      :selectedList="selectedList" :availableBackups="availableBackups" @importDone="onImportCostDone" />
    <modal-import-aditionals ref="modalImportAditional" :optionType="additionalOptionType" :selectedList="selectedList"
      @importDone="onImportDone" />
    <modal-menu ref="ModalMenu" :allCategories="categories" :currentMenu="currentMenu" :mobile="mobile"
      :canEdit="canEditProductsCheck" :isNewMenu="isNewMenu" :originsForUse="originsForUse" :menu="menu"
      :menuBeforeChanges="menu" :selectedList="selectedList" :productsByMenu="productsByMenu" :isMenuLoading="isMenuLoading" @enableSpinnerOnMenu="enableSpinnerOnMenu"
      @disableSpinnerOnMenu="disableSpinnerOnMenu" @upsertMenu="onUpsertMenu"
      @onModalClosed="onMenuModalClosed" @deleteMenu="openModalOfConfirmationToRemove" />
    <modal-category ref="ModalCategory" :category="category" :isNewCategory="isNewCategory"
      @upsertCategory="onUpsertCategory" @deleteCategory="openModalOfConfirmationToRemove" />
    <modal-stock ref="ModalStock" @onClosed="onStockModalClosed" :product="product" :currentSku="currentSkuStock"
      :selectedList="selectedList" :isNewProduct="isNewProduct" />



    <modal-edit-product-stock ref="ModalEditProductStock" :product="product"
      :selectedMovementType="getSelectedMovementType" :editProductStock="editProductStock"
      @editProductStock="onEditProductStock" />


    <modal-stock-movement-type ref="ModalStockMovementType" @selectedMovementType="onSelectedMovementType"
      @onLoadStockMovementsByExcel="onLoadStockMovementsByExcel" :selectStockConfig="selectStockConfig"
      :selectedListProp="selectedList" />
    <modal-confirm-for-remove-stock-movements ref="ModalConfirmForRemoveStockMovements"
      @onConfirmRemoveStockMovement="onConfirmRemoveStockMovement" />
    <modal-of-confirmation-to-remove ref="ModalOfConfirmationToRemove" :objectToRemove="objectToRemove"
      @confirmRemove="onConfirmRemove" />
    <modal-ingredient :ingredient="ingredient" :isNewIngredient="isNewIngredient" :selectedList="selectedList"
      @closeModal="onClosingIngredientModal" @refreshIngredients="onRefreshIngredientData"
      @deleteIngredient="openModalOfConfirmationToRemove" />
    <modal-cost :ingredients="ingredients" @upsertCost="onUpsertCost" />
    <help-center />
    <modal-centralized-products-msg ref="modalCentralizedProdsRef" :isCentralizedProdEnabled="centralizedProdSwitch"
      @onModalClosing="onCloseCentralizedProductsConfirmationModal"
      @onConfirmCentralizedProductsSync="onConfirmCentralizedProductsSync" />
    <modal-news />
    <modal-setup-wizard
      modalName="modal-setup-wizard-dashboard"
      @onModalClosing="onSetupWizardClosing"
    />
    <modal-debt @onCreatingPaymentLink="onCreatingPaymentLink" />
    <modal-toku-debt @onCreatingTokuPaymentLink="onCreatingTokuPaymentLink" />
  </main>
</template>
<script>
import axios from "axios";
import computeds from "../mixins/menuOptions";
import mixModalsCommons from "../mixins/mixModals";
import authCommons from "../mixins/authCommons";
import mixCommons from "../mixins/mixCommons";
import mixTester from "../mixins/mixTester";
import ModalNews from "../components/ModalNews";
import ModalDebt from "../components/ModalDebt";
import ModalSetupWizard from "../components/ModalSetupWizard";
import ModalTokuDebt from "../components/ModalTokuDebt";
import rolesCommons from "../mixins/rolesCommons";
import bistroV2Commons from "../mixins/bistroV2Commons";
import originCommons from "../mixins/originCommons";
import bOrdersCommons from "../mixins/bOrdersCommons";
import stockV2Commons from "../mixins/stockV2Commons";
import productV2Commons from "../mixins/productV2Commons";
import costV2Commons from "../mixins/costV2Commons";
import ModalProduct from "../components/ModalProduct";
import ModalCombo from "../components/ModalCombo";
import ModalIngredient from "../components/ModalIngredient";
import ModalOption from "../components/ModalOption";
import ModalImportIngredients from "../components/ModalImportIngredients";
import ModalImportProducts from "../components/ModalImportProducts";
import ModalImportCosts from "../components/modals/ModalImportCosts";
import ModalImportRecipes from "../components/modals/ModalImportRecipes";
import ModalRecoveryProductsFromBackup from "../components/ModalRecoveryProductsFromBackup";
import ModalRecoveryCostsFromBackup from "../components/ModalRecoveryCostsFromBackup";
import ModalImportAditionals from "../components/ModalImportAditionals";
import ModalMenu from "../components/ModalMenu";
import ModalMessage from "../components/modals/ModalMessage";
import ModalCategory from "../components/ModalCategory";
import ModalStock from "../components/ModalStock";
import ModalEditProductStock from "../components/ModalEditProductStock";
import ModalStockMovementType from "../components/ModalStockMovementType";
import ModalConfirmForRemoveStockMovements from "../components/ModalConfirmForRemoveStockMovements";
import ModalOfConfirmationToRemove from "../components/ModalOfConfirmationToRemove";
import ModalCost from "../components/ModalCost";
import HelpCenter from "../components/HelpCenter";
import ProductCollapseItem from "../components/ProductCollapseItem";
import CentralizedStockPanel from "../components/CentralizedStockPanel";
import ModalCentralizedProductsMsg from "../components/ModalCentralizedProductsMsg";
import { db } from "../firebase";
import { ref, get } from "firebase/database";
import { toast } from "vue3-toastify";
import { parseToQuery } from "../utils/utils";

export default {
  mixins: [
    authCommons,
    mixCommons,
    computeds,
    mixTester,
    rolesCommons,
    bistroV2Commons,
    bOrdersCommons,
    stockV2Commons,
    productV2Commons,
    costV2Commons,
    originCommons,
    mixModalsCommons
  ],
  data() {
    return {
      data: {},
      selectFirst: true,
      centralizedProdSwitch: false,
      hasAlreadyShownNews: false,
      hasAlreadyShownDebts: false,
      hasToShowSwitch: false,
      warningMessage: `El stock actual puede demorarse hasta 1 minuto en verse actualizado`,
      expandTabName: "",
      isProductSectionLoading: true,
      productSearchInput: "",
      combosSearchInput: "",
      ingredientSearchInput: "",
      aditionalSearchInput: "",
      productCategorySearchSelect: "",
      products: [],
      aditionals: [],
      originalProducts: [],
      originalCombos: [],
      originalIngredients: [],
      originalAditionals: [],
      productLists: [],
      selectedShopCodes: [],
      isFirstLoad: true,
      params: {},
      combo: {
        department: "",
        description: "",
        image: "",
        name: "",
        sku: "",
        rootVat: "",
      },
      product: {
        department: "",
        description: "",
        image: "",
        name: "",
        sku: "",
        optionsGroup: [],
        origins: [],
        shortName: "",
        // stock: 0,
        weightable: false,
        measureUnit: "",
        minimunStockQuantity: 0,
        type: "",
        rootVat: "",
      },
      option: {
        maxQty: 0,
        minQty: 0,
        name: "",
        price: "",
        exempt: false,
        nonTaxed: false,
      },
      ingredient: {
        id: 0,
        name: "",
        sku: "",
        description: "",
        cost: 0,
        weightable: true,
        measureUnit: "",
        minimunStockQuantity: 0,
        ullagePercentage: 0,
      },
      currentProductKey: -1,
      currentComboKey: -1,
      categories: [],
      openedDropDownById: null,
      orderedCategories: null,
      editProductStock: {},
      selectStockConfig: {},
      productCost: {
        costType: -1,
        cost: 0,
        recipe: [],
      },
      isMenuLoading: false
    };
  },
  mounted() {
    document.title = `${this.appTitle}  - Productos`;
    if (this.isUserLoggedIn() === false) {
      axios
        .get("/api/auth/logout") /// OK
        .then(() => {
          this.$store.SET_USER(null);
          this.resetUser();
          this.setAutomaticLogin(false);
          location.href = "/login";
        });
    } else if (this.hasToShowThisOption(0) === false) {
      this.handleUnauthorizedAccess()

    } else {
      this.onMounted();
    }
  },
  watch: {
    isProductActionSelected() {
      this.productCategorySearchSelect = "";
      this.productSearchInput = "";
      this.combosSearchInput = "";
      this.ingredientSearchInput = "";
      this.aditionalSearchInput = "";
    },
    stockTabsSelected() {
      this.productSearchInput = "";
      this.combosSearchInput = "";
      this.ingredientSearchInput = "";
      this.aditionalSearchInput = "";
    },
    productSearchInput() {
      this.filterProduct(this, this.originalProducts);
    },
    combosSearchInput() {
      this.filterCombos(this, this.originalCombos);
    },
    ingredientSearchInput() {
      this.filterIngredient(this, this.originalIngredients);
    },
    aditionalSearchInput() {
      this.filterAditional(this, this.originalAditionals);
    },
    productCategorySearchSelect() {
      this.filterProduct(this, this.originalProducts);
    },
    selectedList() {
      this.orderedCategories = this.orderByAsc(this.selectedList.categories);
      this.getCurrentStock(this.selectedList.id, this.selectedList.shopCode);
      this.getIngredients(this.selectedList.shopCode);
      this.getCategories(() => {
        this.getAditionalsAndTastes
      })
    },
    onProductsListChanged() {
      this.refreshProductList(this.onProductsListChanged);
    },
    hasCentralizedProducts() {
      this.centralizedProdSwitch = this.hasCentralizedProducts
      //this.getListByShops()
      // re check roles
    }
  },
  computed: {
    centralizedProductsLabel() {
      return this.canModifyCentralizedProducts
        ? "Productos centralizados"
        : "Productos centralizados desde comercio principal";
    },
    getSuccessModalMenuTitle() {
      switch (this.$store.getCurrentShopTheme) {
        case 1:
        default:
          return "Carta modificada OK!";
        case 2:
          return "Catálogo modificado OK!";
      }

    },
    getSuccessModalMenuMessage() {
      switch (this.$store.getCurrentShopTheme) {
        case 1:
        default:
          return "Para impactar los cambios realice una ACTUALIZACIÓN MANUAL DE CARTA en la sección CONFIGURACIÓN de su equipo";
        case 2:
          return "Para impactar los cambios realice una ACTUALIZACIÓN MANUAL DE CATALOGO en la sección CONFIGURACIÓN de su equipo";
      }

    },
    getSuccessModalProductMessage() {
      switch (this.$store.getCurrentShopTheme) {
        case 1:
        default:
          return "El producto fue creado exitosamente. Para visualizarlo en su equipo ahora deberá asignarlo a una carta";
        case 2:
          return "El producto fue creado exitosamente. Para visualizarlo en su equipo ahora deberá asignarlo a un catálogo";
      }

    },
    getItemSectionList() {
      const shopCode = this.selectedList?.shopCode;
      const shopTheme = this.getShopTheme(shopCode);
      this.$store.setCurrentShopTheme(shopTheme);
      switch (shopTheme) {
        case 1:
        default:
          return this.getGastronomicShopThemeItemSectionList;
        case 2:
          return this.getRetailShopThemeItemSectionList;
      }

    },
    getGastronomicShopThemeItemSectionList() {
      return [
        {
          name: "menues",
          label: "Cartas",
          buttonName: "+ Carta",
          dropdownOptionList: [{ name: "createMenu", label: "Crear Carta" }],
        },
        {
          name: "products",
          label: "Productos",
          buttonName: "+ Producto",
          dropdownOptionList: [
            { name: "createProduct", label: "Crear Producto" },
            { name: "importProduct", label: "Importar Productos" },
            { name: "exportProduct", label: "Exportar Productos" },
            { name: "recoveryBackup", label: "Recuperar Backup" },
          ],
        },
        {
          name: "aditionals",
          label: "Adicionales",
          isVisible: true,
          buttonName: "+ Adicional",
          dropdownOptionList: [
            { name: "createAdicional", label: "Crear Adicional" },
            { name: "importAditionals", label: "Importar Adicionales" },
            { name: "exportAditionals", label: "Exportar Adicionales" },
          ],
        },
        {
          name: "tastes",
          label: "Gustos",
          isVisible: true,
          buttonName: "+ Gusto",
          dropdownOptionList: [
            { name: "createTaste", label: "Crear Gusto" },
            { name: "importTastes", label: "Importar Gustos" },
            { name: "exportTastes", label: "Exportar Gustos" },
          ],
        },
        {
          name: "categories",
          label: "Categorías",
          buttonName: "+ Categoría",
          dropdownOptionList: [
            { name: "createCategory", label: "Crear Categoría" },
          ],
        },
        {
          name: "combos",
          label: "Combos",
          isVisible: true,
          buttonName: "+ Combo",
          dropdownOptionList: [{ name: "createCombo", label: "Crear Combo" }],
        },
        {
          name: "modified-stock-products",
          label: "Productos",
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "stock-products",
          label: "Productos",
          buttonName: null,
          dropdownOptionList: [
            {
              name: "manualInput",
              label: "Carga Manual",
              img: "CargaManualIcon.svg",
            },
            {
              name: "excelInput",
              label: "Carga Masiva",
              img: "CargaMasivaIcon.svg",
            },
          ],
        },
        {
          name: "stock-aditionals",
          label: "Adicionales",
          isVisible: true,
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "modified-stock-aditionals",
          label: "Adicionales",
          isVisible: true,
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "ingredients",
          label: "Ingredientes",
          buttonName: "+ Ingrediente",
          dropdownOptionList: [
            { name: "createIngredient", label: "Crear Ingrediente" },
            { name: "importIngredient", label: "Importar Ingredientes" },
            { name: "exportIngredient", label: "Exportar Ingredientes" },
            { name: "recoveryIngredientBackup", label: "Recuperar Backup" },
          ],
        },
        {
          name: "stock-ingredients",
          label: "Ingredientes",
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "modified-stock-ingredients",
          label: "Ingredientes",
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "cost-products",
          label: "Productos",
          buttonName: "+ Costos",
          dropdownOptionList: [
            { name: "importProductCosts", label: "Importar Costos" },
            { name: "exportProductCosts", label: "Exportar Costos" },
            { name: "recoveryBackupProductCosts", label: "Recuperar Backup" },
            { name: "importProductRecipes", label: "Importar Recetas" },
            { name: "exportProductRecipes", label: "Exportar Recetas" },
          ],
        },
        {
          name: "cost-aditionals",
          label: "Adicionales",
          isVisible: true,
          buttonName: "+ Costos",
          dropdownOptionList: [
            { name: "importAdditionalCosts", label: "Importar Costos" },
            { name: "exportAdditionalCosts", label: "Exportar Costos" },
            {
              name: "recoveryBackupAdditionalCosts",
              label: "Recuperar Backup",
            },
            { name: "importAdditionalsRecipes", label: "Importar Recetas" },
            { name: "exportAdditionalsRecipes", label: "Exportar Recetas" },
          ],
        },
        {
          name: "cost-ingredients",
          label: "Ingredientes",
          buttonName: "+ Costos",
          dropdownOptionList: [
            { name: "importIngredientCosts", label: "Importar Costos" },
            { name: "exportIngredientCosts", label: "Exportar Costos" },
            {
              name: "recoveryBackupIngredientCosts",
              label: "Recuperar Backup",
            },
            { name: "importIngredientsRecipes", label: "Importar Recetas" },
            { name: "exportIngredientsRecipes", label: "Exportar Recetas" },
          ],
        },
        {
          name: "menu-engineer",
          label: "Ingeniería de menú",
          isVisible: true,
          buttonName: null,
          dropdownOptionList: [],
          hasToHideCounter: true,
        },
      ];
    },
    getRetailShopThemeItemSectionList() {
      return [
        {
          name: "menues",
          label: "Catálogos",
          buttonName: "+ Catálogo",
          dropdownOptionList: [{ name: "createMenu", label: "Crear Catálogo" }],
        },
        {
          name: "products",
          label: "Productos",
          buttonName: "+ Producto",
          dropdownOptionList: [
            { name: "createProduct", label: "Crear Producto" },
            { name: "importProduct", label: "Importar Productos" },
            { name: "exportProduct", label: "Exportar Productos" },
            { name: "recoveryBackup", label: "Recuperar Backup" },
          ],
        },
        {
          name: "aditionals",
          isVisible: false,
          label: "Adicionales",
          buttonName: "+ Adicional",
          dropdownOptionList: [
            { name: "createAdicional", label: "Crear Adicional" },
            { name: "importAditionals", label: "Importar Adicionales" },
            { name: "exportAditionals", label: "Exportar Adicionales" },
          ],
        },
        {
          name: "tastes",
          isVisible: false,
          label: "Gustos",
          buttonName: "+ Gusto",
          dropdownOptionList: [
            { name: "createTaste", label: "Crear Gusto" },
            { name: "importTastes", label: "Importar Gustos" },
            { name: "exportTastes", label: "Exportar Gustos" },
          ],
        },
        {
          name: "categories",
          label: "Categorías",
          buttonName: "+ Categoría",
          dropdownOptionList: [
            { name: "createCategory", label: "Crear Categoría" },
          ],
        },
        {
          name: "combos",
          label: "Combos",
          isVisible: false,
          buttonName: "+ Combo",
          dropdownOptionList: [{ name: "createCombo", label: "Crear Combo" }],
        },
        {
          name: "modified-stock-products",
          label: "Productos",
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "stock-products",
          label: "Productos",
          buttonName: null,
          dropdownOptionList: [
            {
              name: "manualInput",
              label: "Carga Manual",
              img: "CargaManualIcon.svg",
            },
            {
              name: "excelInput",
              label: "Carga Masiva",
              img: "CargaMasivaIcon.svg",
            },
          ],
        },
        {
          name: "stock-aditionals",
          label: "Adicionales",
          isVisible: false,
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "modified-stock-aditionals",
          label: "Adicionales",
          isVisible: false,
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "ingredients",
          label: "Ingredientes",
          buttonName: "+ Ingrediente",
          dropdownOptionList: [
            { name: "createIngredient", label: "Crear Ingrediente" },
            { name: "importIngredient", label: "Importar Ingredientes" },
            { name: "exportIngredient", label: "Exportar Ingredientes" },
            { name: "recoveryIngredientBackup", label: "Recuperar Backup" },
          ],
        },
        {
          name: "stock-ingredients",
          label: "Ingredientes",
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "modified-stock-ingredients",
          label: "Ingredientes",
          buttonName: null,
          dropdownOptionList: [],
        },
        {
          name: "cost-products",
          label: "Productos",
          buttonName: "+ Costos",
          dropdownOptionList: [
            { name: "importProductCosts", label: "Importar Costos" },
            { name: "exportProductCosts", label: "Exportar Costos" },
            { name: "recoveryBackupProductCosts", label: "Recuperar Backup" },
            { name: "importProductRecipes", label: "Importar Recetas" },
            { name: "exportProductRecipes", label: "Exportar Recetas" },
          ],
        },
        {
          name: "cost-aditionals",
          label: "Adicionales",
          isVisible: false,
          buttonName: "+ Costos",
          dropdownOptionList: [
            { name: "importAdditionalCosts", label: "Importar Costos" },
            { name: "exportAdditionalCosts", label: "Exportar Costos" },
            {
              name: "recoveryBackupAdditionalCosts",
              label: "Recuperar Backup",
            },
            { name: "importAdditionalsRecipes", label: "Importar Recetas" },
            { name: "exportAdditionalsRecipes", label: "Exportar Recetas" },
          ],
        },
        {
          name: "cost-ingredients",
          label: "Ingredientes",
          buttonName: "+ Costos",
          dropdownOptionList: [
            { name: "importIngredientCosts", label: "Importar Costos" },
            { name: "exportIngredientCosts", label: "Exportar Costos" },
            {
              name: "recoveryBackupIngredientCosts",
              label: "Recuperar Backup",
            },
            { name: "importIngredientsRecipes", label: "Importar Recetas" },
            { name: "exportIngredientsRecipes", label: "Exportar Recetas" },
          ],
        },
        {
          name: "menu-engineer",
          label: "Ingeniería de menú",
          isVisible: false,
          buttonName: null,
          dropdownOptionList: [],
          hasToHideCounter: true,
        },
      ];
    },
    onProductsListChanged() {
      return this.$store.refreshProductList;
    },
    hasMultipleQuantityOfList() {
      return this.productLists.length > 1;
    },
    hasToSelectFirstItem() {
      /* eslint-disable */
      if (this.productLists.length === 1 && this.isFirstLoad) {
        this.isFirstLoad = false;
        this.$store.hasToSelectFirstItem(true);
        return true;
      } else {
        this.$store.hasToSelectFirstItem(false);
        return false;
      }
      /* eslint-enable */
    },
    getSelectedShop() {
      return this.selectedList.shopCode;
    },
    getAditionalsAndTastes() {
      /* eslint-disable */
      let aditionals = [];
      let tastes = [];
      if (this.categories) {
        this.categories.forEach((element) => {
          if (
            element.optionsGroups !== undefined &&
            element.optionsGroups.length > 0
          ) {
            let ad = element.optionsGroups.filter(
              (t) => t.name === "Adicionales"
            );
            if (ad !== undefined && ad.length > 0) {
              ad[0].options.forEach((a) => {
                let aditionalTemp = aditionals.filter(
                  (t) =>
                    t.aditional.name === a.name && t.aditional.price === a.price
                );

                if (aditionalTemp.length === 0) {
                  aditionals.push({
                    aditional: a,
                    categoriesString: element.name,
                  });
                } else {
                  aditionalTemp[0].categoriesString += ", " + element.name;
                }
              });
            }
            let ta = element.optionsGroups.filter((t) => t.name === "Gustos");
            if (ta !== undefined && ta.length > 0) {
              ta[0].options.forEach((taste) => {
                let tasteTemp = tastes.filter(
                  (t) =>
                    t.taste.name === taste.name && t.taste.price === taste.price
                );

                if (tasteTemp.length === 0) {
                  tastes.push({ taste: taste, categoriesString: element.name });
                } else {
                  tasteTemp[0].categoriesString += ", " + element.name;
                }
              });
            }
          }
        });
      }

      aditionals = aditionals.sort((a, b) =>
        a.aditional.name.toLowerCase() > b.aditional.name.toLowerCase()
          ? 1
          : b.aditional.name.toLowerCase() > a.aditional.name.toLowerCase()
            ? -1
            : 0
      );
      this.originalAditionals = [...aditionals];
      this.aditionals = aditionals;
      tastes = tastes.sort((a, b) =>
        a.taste.name.toLowerCase() > b.taste.name.toLowerCase()
          ? 1
          : b.taste.name.toLowerCase() > a.taste.name.toLowerCase()
            ? -1
            : 0
      );
      return { aditionals, tastes };
      /* eslint-enable */
    },
    getChildShopCodes() {
      let shopbindings = this.$store.getShopsThatAreBoundToLocal
      if (shopbindings !== undefined) {
        const selectedShopBindings = shopbindings.filter(x => x.shopCode == this.selectedList.shopCode)[0];
        if (selectedShopBindings != undefined)
          return selectedShopBindings.shopBindings.map(x => x.shopCode)
        // let mainShop = shopbindings.filter(x => x.isMainShop)[0]
        // if(mainShop != undefined)
        // 	return mainShop.shopBindings.map(x => x.shopName)


        return undefined
      }
      return undefined
    }
  },
  methods: {
    async onMounted() {
      this.$store.updateSideMenu(-1);
      this.$store.updateTopMenu(0);
      this.toggleMerchantSelectorByLabel('showProductListSelectionFilter');
      this.$store.CALL_MERCHANTS();
      this.$store.hideElements({
        hideCalendar: true,
        hideFilters: false,
        hideTabs: false,
      });

      if (await this.checkSessionInBackendAsync()) {
        this.getContextFromBackend(this.getListByShops);
        this.loadOrigins();
      axios
        .get(`/api/dashboardV2/news`)
        .then((res) => {

          this.showNewsIfPossible(res.data.news);
        })
        .catch((err) => {
          console.error(err.response);
        });

        this.processDebtModals();
        this.processWizardModal();

        this.params["Period"] = this.tab.param;

        axios
          .get(`/api/dashboardV2/?${parseToQuery(this.params)}`)
          .then((res) => {
          
            // this.loaded = true;
            if(res.data.responseCode != 0){
              this.showErrors(res);
            } else {
              this.data = res.data;
            }
            // this.showData();
          })
          .catch((err) => {
            this.$store.errorLoading(this);
            this.showErrors(err.response);
          });

        this.$store.setStockMovements(this.getStockMovementList());
      }
    },
    processDebtModals() {
      if (this.$route.query.failure === null) {
        this.processDebtFailure();
      } else if (this.$route.query.success === null) {
        //const t = this;
        this.$store.setDebtPaymentStatus({
          status: "checking",
          uuid: this.$route.query.uuid,
        });
        this.openModalById("#modalDebt");

        this.params = {
          externalReference: this.$route.query.uuid,
          shopCode: null,
        };

        this.$store.initLoading(this);
        axios
          .get(
            `/api/dashboardV2/isPaymentOk/?${parseToQuery(
              this.params
            )}`
          )
          .then((res) => {
            if (res.data.responseCode == 0) {
              if (!res.data.isOk) {
                this.processDebtFailure(res.data.responseMessage);
              } else {
                this.processDebtSuccess();
              }
            } else {
              this.processDebtFailure(res.data.responseMessage);
            }
          })
          .catch((err) => {
            console.error(err.response);
            this.showError(
              `Algo salió mal. Por favor reintentá más tarde (Cod Err. 418)`
            );
            this.processDebtFailure();
          });
      } else {
        axios
          .get(`/api/dashboardV2/shopDebt`)
          .then((res) => {
            if (res.data.responseCode === 0) {
              if (res.data.shopDebts && res.data.shopDebts.length > 0) {

                this.$store.SET_DUE_DAYS( res.data.dueDays);
                this.showDebtModalIfPossible(res.data);
              }
            } else {
              console.error(`ShopDebt error ->`, res);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    processWizardModal() {
      this.$store.initLoading(this);
      axios
        .get(`/api/wizardV2/`)
        .then((res) => {
          if (res.data.responseCode == 0) {
            if (res.data.isSetupWizardEnabled) {
              this.openModalById("#modal-setup-wizard-dashboard");
            }
            this.$store.finishLoading(this);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    onSetupWizardClosing() {
      this.closeModalById("#modal-setup-wizard-dashboard");
    },
    onCreatingTokuPaymentLink(body) {
      const t = this;
      this.$store.initLoading( this);
      axios
        .post(`/api/dashboardV2/createTokuPaymentLink`, body) /// OK
        .then((res) => {
          if (res.data.responseCode != 0) {
            this.$store.errorLoading( t);
            this.showError(res.data.responseMessage);
          } else {
            this.$store.finishLoading( t);
            window.open(res.data.url, "_blank");
          }
        })
        .catch((err) => {
          this.$store.errorLoading( t);
          console.error(err);
          this.showError(
            `Algo salió mal. Por favor reintentá más tarde (Cod Err. 417)`
          );
        });
      },
    onCreatingPaymentLink(body) {
      const t = this;
      this.$store.initLoading( this);
      axios
        .post(`/api/dashboardV2/createPaymentLink`, body) /// OK
        .then((res) => {
          if (res.data.responseCode != 0) {
            this.$store.errorLoading( t);
            this.showError(res.data.responseMessage);
          } else {
            this.$store.finishLoading( t);
            window.location.href = res.data.url;
          }
        })
        .catch(() => {
          this.$store.errorLoading( t);
          this.showError(
            `Algo salió mal. Por favor reintentá más tarde (Cod Err. 417)`
          );
        });
    },
    showDebtModalIfPossible(debt) {
      this.$store.setDebt( debt);
      if (
        debt != null &&
        this.hasAlreadyShownDebts === false &&
        debt.shopDebts.filter((x) => x.amount > 0).length > 0
      ) {
        switch (debt.paymentType) {
          case 2: // TOKU
            this.hasAlreadyShownDebts = true;
            this.openModalById("#modalTokuDebt");
            break;

          case 1: // MP
          default:
            this.hasAlreadyShownDebts = true;
            this.openModalById("#modalDebt");
            break;
        }
      }
    },
    processDebtSuccess() {
      const t = this;
      const body = {
        uuid: this.$route.query.uuid,
        status: "success",
        emailBilling: this.$route.query.emailBilling,
        paymentId: this.$route.query.payment_id,
        message: `Pago recibido correctamente! Los comercios que hayan saldado la deuda serán rehabilitados en unos instantes. Id de pago #${this.$route.query.uuid}`,
      };

            this.$store.setDebtPaymentStatus(body);
                        this.openModalById("#modalDebt");
                        this.$store.finishLoading(t);
                        setInterval(() => {
                            window.location.href = "/login";
                        }, 10000);
    },
    processDebtFailure(message) {
    this.$store.setDebtPaymentStatus( {
        status: "failure",
        uuid: this.$route.query.uuid,
        message:
            (message || `Algo salió mal y no pudimos procesar el pago`) +
            `. Id de pago: #${this.$route.query.uuid}. Por favor reintenta más tarde.`,
    });
    this.openModalById("#modalDebt");
    axios
    .get(`/api/dashboardV2/shopDebt`)
    .then((res) => {
    this.$store.setDebt(res.data);
    })
    .catch((err) => {
    console.error(err.response);
    });
    },
    showNewsIfPossible(news) {
      this.$store.setNews(news);
      if (news != null && this.hasAlreadyShownNews === false) {
        this.hasAlreadyShownNews = true;
        this.openModalById("#modalNews");
      }
    },
    checkSessionInBackend(callback, callbackErr) {
      this.$store.initLoading(this);
      axios
        .get(`/api/productsV2/checkSession`)
        .then(() => {
          // this.$store.finishLoading(this);
          callback();
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          this.showErrors(err.response);
          callbackErr();
        });
    },

    onUpsertingProduct(prod) {
      const t = this;
      this.onUpsertProduct(prod, () => {
        t.getCurrentStock(t.selectedList.id, t.selectedList.shopCode);
      });
    },
    onImportIngredientsDone() {
      const t = this;
      this.$store.initLoading(this);
      this.getIngredients(this.selectedList.shopCode);
      this.getCategories();
      this.refreshProductList(this.selectedList);
      this.getCurrentStock(
        this.selectedList.id,
        this.selectedList.shopCode,
        () => {
          t.$store.finishLoading(t);
        }
      );
    },
    onImportCostDone() {
      const t = this;
      this.$store.initLoading(this);
      this.getIngredients(this.selectedList.shopCode);
      this.getCurrentStock(
        this.selectedList.id,
        this.selectedList.shopCode,
        () => {
          t.$store.finishLoading(t);
        }
      );
    },
    onImportRecipeDone() {
      const t = this;
      this.$store.initLoading(this);
      this.getIngredients(this.selectedList.shopCode);
      this.getCurrentStock(
        this.selectedList.id,
        this.selectedList.shopCode,
        () => {
          t.$store.finishLoading(t);
        }
      );
    },
    onClosingIngredientModal() {
      this.onCloseIngredientModal();
    },
    onRefreshIngredientData() {
      const t = this;
      this.$store.initLoading(this);
      this.getIngredients(this.selectedList.shopCode);
      this.getCurrentStock(
        this.selectedList.id,
        this.selectedList.shopCode,
        () => {
          t.$store.finishLoading(t);
          t.onCloseIngredientModal();
        }
      );
    },
    onUpsertCost() {
      const t = this;
      this.clearItemCost();
      this.closeModalById("#modalCost");
      this.$store.initLoading(this);
      this.getIngredients(this.selectedList.shopCode);
      this.getCurrentStock(
        this.selectedList.id,
        this.selectedList.shopCode,
        () => {
          t.$store.finishLoading(t);
          toast.success("Costo guardado exitosamente!");
        }
      );
    },
    onExpandSection(sectionName) {
      if (this.expandTabName === sectionName) {
        this.expandTabName = "";
      } else {
        this.expandTabName = sectionName;
      }
    },
    onSelectedCategoryFilter(selectedCategoryFilter) {
      this.productCategorySearchSelect = selectedCategoryFilter;
    },
    onFilterProductsInput(filterProductsInput) {
      this.productSearchInput = filterProductsInput;
    },
    onFilterComboInput(filterComboInput) {
      this.combosSearchInput = filterComboInput;
    },
    onFilterIngredientsInput(filterIngredientsInput) {
      this.ingredientSearchInput = filterIngredientsInput;
    },
    onFilterAditionalsInput(filterAditionalsInput) {
      this.aditionalSearchInput = filterAditionalsInput;
    },
    onOpenAndCloseDropdownById(id) {
      this.getItemSectionList.forEach(() => {
        const openedDropdown = document.getElementById(this.openedDropDownById);

        if (id && openedDropdown && openedDropdown.id === id) {
          openedDropdown.style.opacity = 0;
          openedDropdown.style.display = "none";
          this.openedDropDownById = null;
        } else if (id) {
          const dropdown = document.getElementById(id);
          dropdown.style.opacity = 1;
          dropdown.style.display = "block";
          this.openedDropDownById = id;
        } else if (!id && openedDropdown) {
          openedDropdown.style.opacity = 0;
          openedDropdown.style.display = "none";
          this.openedDropDownById = null;
        }
      });

      if (!id) {
        this.openedDropDownById = null;
      }
    },
    onClickDropdownMenu(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[0].dropdownOptionList[0].name:
          this.openNewMenuModal();
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownProduct(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[1].dropdownOptionList[0].name:
          this.openNewModal();
          break;
        case this.getItemSectionList[1].dropdownOptionList[1].name:
          this.openImportModal();
          break;
        case this.getItemSectionList[1].dropdownOptionList[2].name:
          this.exportProductList(this.selectedList.id);
          break;
        case this.getItemSectionList[1].dropdownOptionList[3].name:
          this.recoveryProductListFromBackup();
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownAditional(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[2].dropdownOptionList[0].name:
          this.openNewOptionModal("Adicional");
          break;
        case this.getItemSectionList[2].dropdownOptionList[1].name:
          this.openImportAditionalsModal("Adicional");
          break;
        case this.getItemSectionList[2].dropdownOptionList[2].name:
          this.exportAditionalsList("Adicional");
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownTaste(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[3].dropdownOptionList[0].name:
          this.openNewOptionModal("Gusto");
          break;
        case this.getItemSectionList[3].dropdownOptionList[1].name:
          this.openImportAditionalsModal("Gusto");
          break;
        case this.getItemSectionList[3].dropdownOptionList[2].name:
          this.exportAditionalsList("Gusto");
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownCategory(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[4].dropdownOptionList[0].name:
          this.openNewCategoryModal();
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownCombo(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[5].dropdownOptionList[0].name:
          this.openNewComboModal();
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownIngredient(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[10].dropdownOptionList[0].name:
          this.openNewIngredientModal();
          break;
        case this.getItemSectionList[10].dropdownOptionList[1].name:
          this.openImportIngredientModal();
          break;
        case this.getItemSectionList[10].dropdownOptionList[2].name:
          this.exportIngredientList(this.selectedList.shopCode);
          break;
        case this.getItemSectionList[10].dropdownOptionList[3].name:
          this.recoveryIngredientsListFromBackup();
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownCostProduct(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[13].dropdownOptionList[0].name:
          this.openImportProductCostModal();
          break;
        case this.getItemSectionList[13].dropdownOptionList[1].name:
          this.exportProductCostList(this.selectedList.shopCode);
          break;
        case this.getItemSectionList[13].dropdownOptionList[2].name:
          this.recoveryProductCostListFromBackup();
          break;
        case this.getItemSectionList[13].dropdownOptionList[3].name:
          this.openImportProductRecipeModal();
          break;
        case this.getItemSectionList[13].dropdownOptionList[4].name:
          this.exportProductRecipeList(this.selectedList.shopCode);
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownCostAdditional(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[14].dropdownOptionList[0].name:
          this.openImportAdditionalCostModal();
          break;
        case this.getItemSectionList[14].dropdownOptionList[1].name:
          this.exportAdditionalCostList(this.selectedList.shopCode);
          break;
        case this.getItemSectionList[14].dropdownOptionList[2].name:
          this.recoveryAdditionalCostListFromBackup();
          break;
        case this.getItemSectionList[14].dropdownOptionList[3].name:
          this.openImportAdditionalRecipeModal();
          break;
        case this.getItemSectionList[14].dropdownOptionList[4].name:
          this.exportAdditionalRecipeList(this.selectedList.shopCode);
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownCostIngredient(buttonName) {
      switch (buttonName) {
        case this.getItemSectionList[15].dropdownOptionList[0].name:
          this.openImportIngredientCostModal();
          break;
        case this.getItemSectionList[15].dropdownOptionList[1].name:
          this.exportIngredientCostList(this.selectedList.shopCode);
          break;
        case this.getItemSectionList[15].dropdownOptionList[2].name:
          this.recoveryIngredientCostListFromBackup();
          break;
        case this.getItemSectionList[15].dropdownOptionList[3].name:
          this.openImportIngredientRecipeModal();
          break;
        case this.getItemSectionList[15].dropdownOptionList[4].name:
          this.exportIngredientRecipeList(this.selectedList.shopCode);
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    onClickDropdownModifiedStock() {
      this.selectConfirmStockMovementsPage();
    },
    onClickDropdownStockProduct(event, buttonName) {
      event.stopPropagation();
      switch (buttonName) {
        case this.getItemSectionList[7].dropdownOptionList[0].name:
          this.openStockMovementTypeModal(
            this.stockMovementMethods[0],
            this.getItemSectionList[7].name
          );
          break;
        case this.getItemSectionList[7].dropdownOptionList[1].name:
          this.openStockMovementTypeModal(
            this.stockMovementMethods[1],
            this.getItemSectionList[7].name
          );
          break;
        default:
          break;
      }
      this.onOpenAndCloseDropdownById();
    },
    orderByAsc(cats) {
      if (cats === undefined) {
        return [];
      }

      const sortedArr = cats.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      return sortedArr;
    },
    // closeModalById(modalId) {
    //   /* eslint-disable */
    //   modalId = modalId.slice(1);
    //   var modalInstance = document.getElementById(modalId);
    //   var instance = M.Modal.getInstance(modalInstance);
    //   instance.close();
    //   document.body.style.overflow = "auto";
    //   /* eslint-enable */
    // },
    // openModalById(modalId) {
    //   /* eslint-disable */
    //   modalId = modalId.slice(1);
    //   var modalInstance = document.getElementById(modalId);
    //   var instance = M.Modal.init(modalInstance);
    //   instance.open();
    //   /* eslint-enable */
    // },
    openModalOfConfirmationToRemove() {
      this.closeModalById("#modalProduct");
      this.closeModalById("#modalOption");
      this.closeModalById("#modalCategory");
      this.closeModalById("#modalCombo");
      this.closeModalById("#modalIngredient");
      this.openModalById("#ModalOfConfirmationToRemove");
    },
    getCategories(onFinished) {
      if (this.selectedList && this.selectedList.shopCode) {
        const t = this;

        if (t.hasCentralizedProducts) {
          t.categories = t.selectedList.categories;
          t.orderedCategories = t.orderByAsc(t.selectedList.categories);
        } else {
          const refDataCategories = ref(
            db,
            "products/" + t.selectedList.shopCode
          );

          get(refDataCategories).then((snapshot) => {
            var list = snapshot.val();
            t.categories = list.categories;
            t.selectedList.categories = list.categories;
            t.orderedCategories = t.orderByAsc(list.categories);
            if (onFinished) onFinished()
          });
        }
      }
    },
    getListByShops() {
      const t = this;
      this.$store.initLoading(this);
      if (this.$store.getMerchantTags.length === 1) {
        t.selectFirst = true;
      } else if (this.selectedShopCodes.length === 1) {
        t.selectFirst = true;
      }

      let listOfShops = this.$store.getMerchantTags;

      if (this.selectedShopCodes.length !== 0) {
        listOfShops = this.selectedShopCodes;
      }

      t.productLists = [];
      t.parentProductList = null;
      this.$store.setProductLists(t.productLists);

      let centralizedProductsList = this.$store.getCentralizedProductsList;

      if (centralizedProductsList !== undefined && centralizedProductsList.length > 0) {
        if (t.productLists.length > 0) t.productLists.pop();

        listOfShops.forEach((shop) => {

          let parentShop = centralizedProductsList.filter(
            (x) => x.shopCode !== null && x.shopCode === shop.shopCode && x.parentShopCode !== null
          );

          if (parentShop !== undefined && parentShop.length > 0 && parentShop[0].hasCentralizedProducts) {
            t.parentShopCode = parentShop[0].parentShopCode;
          } else {
            t.parentShopCode = shop.shopCode;
          }

          const productsRef = ref(db, `products/${t.parentShopCode}`);
          get(productsRef).then((snapshot) => {
            t.parentProductList = snapshot.val();

            if (t.parentProductList !== null && t.parentProductList.idList != null) {
              t.productLists.push({
                name: `${shop.merchantName} (${shop.shopCode})`,
                id: t.parentProductList.idList,
                shopCode: shop.shopCode,
                categories: t.parentProductList.categories,
                origins: t.parentProductList.origins,
              });
              this.$store.setProductLists(t.productLists);

              if (t.selectFirst) {
                t.selectFirst = false;
                t.parentProductList = { ...t.parentProductList, shopCode: shop.shopCode };
                t.refreshProductList(t.parentProductList);
              }
            }
          });
        });
        this.$store.finishLoading(this);
        this.isProductSectionLoading = false;	//Aca ya tengo los productos cargados, entonces saco la animacion de productsCollapseItem
      } else {
        listOfShops.forEach((shop) => {
          const shopRef = ref(db, `products/${shop.shopCode}`);
          get(shopRef).then((snapshot) => {
            var list = snapshot.val();

            if (list !== null && list.idList != null) {
              if (t.productLists.filter((l) => l.id === list.idList).length === 0) {
                t.productLists.push({
                  name: `${shop.merchantName} (${shop.shopCode})`,
                  id: list.idList,
                  shopCode: shop.shopCode,
                  categories: list.categories,
                  origins: list.origins,
                });

                this.$store.setProductLists(t.productLists);

                if (t.selectFirst) {
                  t.selectFirst = false;
                  list = { ...list, shopCode: shop.shopCode };
                  t.refreshProductList(list);
                }
              }
            }
          });
        });
        this.$store.finishLoading(this);
        this.isProductSectionLoading = false;	//Aca ya tengo los productos cargados, entonces saco la animacion de productsCollapseItem
      }
    },
    openCostModalForProduct(item) {
      this.handleCostModal(
        this.selectedList.shopCode,
        item.sku,
        item.name,
        item.department,
        item.weightable,
        item.measureUnit,
        0 /*Product*/
      );
    },
    openCostModalForAdditional(item) {
      this.handleCostModal(
        this.selectedList.shopCode,
        item.sku,
        item.name,
        "Adicionales",
        false,
        item.measureUnit,
        1 /*Additionals*/
      );
    },
    openCostModalForIngredients(item) {
      this.handleCostModal(
        this.selectedList.shopCode,
        item.sku,
        item.name,
        "Ingredientes",
        false,
        item.measureUnit,
        2 /*Ingredient*/
      );
    },
    filterAditional(t, filteredAditionals) {
      if (t.aditionalSearchInput !== "") {
        filteredAditionals = filteredAditionals.filter((x) => {
          return (
            x.aditional.name
              .toUpperCase()
              .includes(t.aditionalSearchInput.toUpperCase()) ||
            x.aditional.sku
              .toUpperCase()
              .includes(t.aditionalSearchInput.toUpperCase())
          );
        });
      }
      t.aditionals = filteredAditionals;
    },
    onClickCentralizedStock() {
      this.$store.setShowingCentralizedStock(true);
    },
    onClickDownloadStockReport() {
      if (!this.isShowingCentralizedStock) //Si no estoy en la pesta;a del stock centralizado quiero descargar el reporte que se hacia antes
        this.onDownloadStockReport();
      else
        this.onDownloadCentralizedStock(this.getCurrentShopCode);
    },
    onCentralizedProdsSwitchChanged() {
      this.openModalById("#modalCentralizedProds");
    },
    onCloseCentralizedProductsConfirmationModal() {
      this.centralizedProdSwitch = this.hasCentralizedProducts;
      this.isLoading = false;
      this.closeModalById("#modalCentralizedProds");
      this.$refs.modalCentralizedProdsRef.isLoading = false;
    },
    onConfirmCentralizedProductsSync() {
      this.$store.initLoading(this)
      this.isLoading = true
      let body = {
        parentShopCode: this.selectedList.shopCode,
        parentProductListId: this.selectedList.id,
        childShopCodes: this.getChildShopCodes,
        sync: this.centralizedProdSwitch
      }
      axios
        .post(`/api/productsV2/syncCentralizedProducts`, body)
        .then(res => {
          if (res.data.responseCode === 0) {
            this.$store.finishLoading(this)
            setInterval(location.reload(), 1000)
          } else {
            this.showError(res.data.responseMessage)
            this.$store.errorLoading(this)
          }
          this.isLoading = false
        })
        .catch((err => {
          this.showErrors(err.response)
          this.$store.errorLoading(this)
          this.isLoading = false
        }))
      this.closeModalById("#modalCentralizedProds");
      this.$refs.modalCentralizedProdsRef.isLoading = false;
    },
  },
  components: {
    ModalProduct,
    ModalCombo,
    ModalStock,
    ModalOption,
    ModalImportAditionals,
    ModalMenu,
    ModalSetupWizard,
    ModalMessage,
    ModalDebt,
    ModalTokuDebt,
    ModalCategory,
    ModalImportProducts,
    ModalRecoveryProductsFromBackup,
    ModalRecoveryCostsFromBackup,
    ModalStockMovementType,
    ModalEditProductStock,
    ModalImportIngredients,
    ModalConfirmForRemoveStockMovements,
    ModalOfConfirmationToRemove,
    HelpCenter,
    ProductCollapseItem,
    ModalIngredient,
    ModalImportCosts,
    ModalImportRecipes,
    ModalCost,
    CentralizedStockPanel,
    ModalCentralizedProductsMsg,
    ModalNews,
  },
};
</script>

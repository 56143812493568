<template>
	<div class="qrCustomerService" v-if="getInstance === 'MX'">
		<div class="content">
			<!-- Condición para mostrar la imagen según la instancia -->
			<img src="https://static.bistrosoft.com/WebAccess/images/qr-codeCustomerService.png" alt="QRCode"
				class="qr-image" />
			<p class="qr-text">
				Para comunicarte con atención al cliente escanea el código o contáctanos por whatsapp al
				<span class="numberatc"><br>+54 9 11 3775 7552</span>
			</p>

		</div>
	</div>
</template>

<script>
export default {
	computed: {
		// Obtener la instancia
		getInstance() {
			return process.env.VUE_APP_INSTANCE_CODE;
		},
	},
};
</script>

<style scoped lang="stylus"></style>

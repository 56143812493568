<script>
import { printByUsb } from '../services/printer/usbPrinterService.js';
import { printByBluetooth } from '../services/printer/bluetoothPrinterService.js';
import { printByNet } from '../services/printer/netPrinterService.js';
import { toast } from "vue3-toastify";

export default {
    methods: {
        async printInCounter(content) {
            const config = (this.getPrinterConfig()).counter;
            return await this.printTicket(content, config);
        },
        async printInKitchen(content) {
            const config = (this.getPrinterConfig()).kitchen;
            return await this.printTicket(content, config);
        },
        async printInAdditional(content) {
            const config = (this.getPrinterConfig()).additional;
            return await this.printTicket(content, config);
        },
        async printTicket(content, config) {
            let result = false;
            this.$store.initLoading(this);
            switch (config.type) {
                case 'usb': { result = await printByUsb(content, config.value); break; }
                case 'bluetooth': { result = await printByBluetooth(content, config.value); break; }
                case 'net': { result = await printByNet(content, config); break; }
                default: { console.error('Unknown printer type: ', config.type) }
            }
            if (result == false) {
                toast.error(
                    "No se pudo conectar con la impresora. Por favor, verifique su conexión."
                );
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
            this.$store.finishLoading(this);
            return result;
        },
        setupPrinters() {
            // TODO: setup printers from api
            const printerConfig = localStorage["printersConfig"];
            if(!printerConfig) {
                localStorage["printersConfig"] = '{"counter": { "type": "net", "value": { "ip": "192.168.68.113", "port": "9100" } },"kitchen": { "type": "net", "value": { "ip": "192.168.68.113", "port": "9100" } }, "additional": { "type": "net", "value": { "ip": "192.168.68.113", "port": "9100" } } }';
            }
        },
        getPrinterConfig() {
            return JSON.parse(localStorage["printersConfig"]);
        }
    }
}
</script>
<template>
	<div
		:id="modalName"
		class="modal modal-block-outside"
		style="overflow-y: auto !important"
		
	>
		<div class="modal-header">
			<div class="image-title">
				<h4>{{ title }}</h4>
				<p>Fácil y Simple</p>
			</div>

			<div class="btnClose">
				<a
					href="#!"
					class="modal-action modal-close waves-effect waves-green btn-flat"
				>
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<div class="modal-body">
			<form action class="formGeneral">
				<section class="formMain">
					<div class="itemForm picture-upload" style="width: 100%">
						<div>
							<label for>{{ this.disclaimer }}</label>
						</div>
						<div
							v-if="isImageLoading"
							class="itemForm selectImage beforeAdditional imageSpinner"
						>
							<Spinner />
						</div>
						<div v-else class="itemForm selectImage beforeAdditional">
							<label class="selectImage-label" for>Video:</label>
							<input type="file" ref="fileupload" @change="onFileSelected" />
							<a
								v-if="getVideoUrl"
								class="selectImage-trashBin"
								@click="onDeleteImage"
							>
								<span class="icon-Bistro-_Trash_bin"></span>
							</a>
						</div>
						<div>
							<label v-if="getVideoUrl">Video actual</label>
						</div>
						<div>
							<video v-if="getVideoUrl" controls :src="getVideoUrl">
								Tu navegador no soporta la reproducción del video.
							</video>
						</div>
					</div>
				</section>
			</form>
		</div>
	</div>
</template>

<script>
import pictureCommons from "../../mixins/pictureCommons";
 import "firebase/storage";
import commons from "../../mixins/mixCommons";
import Spinner from "../SpinnerComp";

export default {
	mixins: [commons, pictureCommons],
	props: {
		shopCode: {
			type: String,
			default: () => {
				return "";
			},
		},
		modalName: {
			type: String,
			default: () => {
				return "modalFileUpload";
			},
		},
		title: {
			type: String,
			default: () => {
				return "Subida de archivo";
			},
		},
		disclaimer: {
			type: String,
			default: () => {
				return "(Archivo .mp4. Tamaño máximo 250 MBytes)";
			},
		},
		imageUrl: {
			type: String,
			default: () => {
				return null;
			},
		},
	},
	data() {
		return {
			isImageLoading: false,
			isLoading: false,
			uploadedImageUrl: null,
		};
	},
	watch: {},
	computed: {
		getVideoUrl() {
			return this.uploadedImageUrl || this.imageUrl;
		},
	},
	methods: {
		onDeleteImage() {
			this.$emit(`onDeleteImage`, this.shopCode);
		},
		onFileSelected(event) {
			const t = this;
			this.selectedFile = event.target.files[0];
			this.isImageLoading = true;
			if (!this.selectedFile) return;
			if (this.selectedFile.name.endsWith(".mp4") === false) {
				this.$store.errorLoading( this);
				this.isImageLoading = false;
				this.showError(`Los videos deben ser .mp4`);
				return;
			}
			const filesize = (this.selectedFile.size / 1024 / 1024).toFixed(4); // MB
			if (filesize > 250) {
				// 2MB
				this.$store.errorLoading( this);
				this.isImageLoading = false;
				this.showError(
					`El tamaño del video no puede superar los 250MB. El video que intentás subir tiene ${filesize}MB`
				);
				return;
			}
			
			this.saveMediaInCloudStorage(
				t,
				"videos/" + this.shopCode + "/" + encodeURIComponent(this.selectedFile.name),
				(url) => {
					this.$emit(`onPictureUploadedOk`, url, this.shopCode);
					t.uploadedImageUrl = url;
					t.isImageLoading = false;
				},
				false
			);
			
		},
		closeModal() {

			this.$emit("onModalClosed");
		},
	},
	components: {
		Spinner,
	},
};
</script>

export function payment(value, option) {
  if (!value) return "";

  switch (value) {
    case 1:
      if (option === "text") {
        return "Efectivo";
      } else if (option === "icon") {
        return "icon-Bistro-_DolarMoney";
      }
      break;
    case 2:
      if (option === "text") {
        return "Tarj. Crédito";
      } else if (option === "icon") {
        return "icon-Bistro-_MasterCard";
      }
      break;
    case 3:
      if (option === "text") {
        return "Cuenta Cliente";
      } else if (option === "icon") {
        return "icon-Bistro-_CuentaCliente";
      }
      break;
    case 4:
      if (option === "text") {
        return "QR";
      } else if (option === "icon") {
        return "icon-Bistro-_QR";
      }
      break;
    case 5:
      if (option === "text") {
        return "Otros";
      } else if (option === "icon") {
        return "icon-Bistro-_OtrosOperacion";
      }
      break;
    case 6:
      if (option === "text") {
        return "Glovo";
      } else if (option === "icon") {
        return "icon-Bistro-_glovo";
      }
      break;
    case 7:
      if (option === "text") {
        return "Rappi";
      } else if (option === "icon") {
        return "icon-Bistro-_rappi";
      }
      break;
    case 8:
      if (option === "text") {
        return "Tarj. Débito";
      } else if (option === "icon") {
        return "icon-Bistro-_MasterCard";
      }
      break;
    case 9:
      if (option === "text") {
        return "Multipago";
      } else if (option === "icon") {
        return "icon-Bistro-_multipayment";
      }
      break;
    case 10:
      if (option === "text") {
        return "Tarjeta";
      } else if (option === "icon") {
        return "icon-Bistro-_MasterCard";
      }
      break;
    case 11:
      if (option === "text") {
        return "Online";
      } else if (option === "icon") {
        return "icon-Bistro-_Online";
      }
      break;
    case 12:
      if (option === "text") {
        return "Consumo Empleado";
      } else if (option === "icon") {
        return "icon-Bistro-_ConsumoEmpleados";
      }
      break;
    case 13:
      if (option === "text") {
        return "Invitación";
      } else if (option === "icon") {
        return "icon-Bistro-_Invitacion1";
      }
      break;
    case 14:
      if (option === "text") {
        return "Voucher";
      } else if (option === "icon") {
        return "icon-Bistro-_Voucher";
      }
      break;
    default:
      if (option === "text") {
        return "Efectivo";
      } else if (option === "icon") {
        return "icon-Bistro-_DolarMoney";
      }
      break;
  }
}

<template>
	<div class="base-pagination-trigger" @click="onClick">
		{{ pageNumber }}
	</div>
</template>
<script>
export default {
	props: {
		pageNumber: {
			type: Number,
			required: true
		}
	},
	methods: {
		onClick () {
			this.$emit('loadPage', this.pageNumber)
		}
	}
}
</script>
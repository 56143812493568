<template>
<div class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="closeModal()">
    <!-- TITULO -->
    <div class="modal-header">
        <div class="image-title">
            <h4>SimpleFI</h4>
            <p>Integración medio de pago</p>
        </div>

        <div class="btnClose">
            <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
                <span class="icon-Bistro-_Close"></span>
            </a>
        </div>
    </div>
    <!-- BODY -->
    <div class="modal-body">
        <form action class="formGeneral">
            <section class="formMain">
                <!-- Si tiene simplefi integrado, o sea si esta en enabled -->
                <div v-if="isIntegrated">
                    <div class="simplefi-integration-description">
                        <label class="settings-label">Tu comercio ya está integrado. Al momento de cobrar con QR debes seleccionar la opcion SimpleFI.</label>
                    </div>
                    <!-- BOTON ACEPTAR -->
                    <div class="simplefi-footer">
                        <article class="btn-main simplefi-eliminate-btn">
                            <Spinner v-if="isUpdateConfigLoading" />
                            <a v-else @click="deleteIntegration()">
                                <p>ELIMINAR INTEGRACIÓN</p>
                            </a>
                        </article>
                        <article class="btn-main modal-action modal-close">
                            <a href="#!" @click="closeModal()">
                                <p>ACEPTAR</p>
                            </a>
                        </article>
                    </div>
                </div>
                <!-- --------------------------------------------------------------- -->

                <!-- Si no muestro el cartel para la integracion de simplefi -->
                <div v-else>
                    <p class="simplefi-information-title">
                        Con SimpleFI cobrá en dólares digitales y crypto en el acto y sin comisiones. Conoce más sobre SimpleFI haciendo <a href="https://www.simplefi.tech/" target="_blank"> click acá</a>
                    </p>
                    <div class="simplefi-information-description-container">
                        <p class="simplefi-information-description-title">Pasos para la integración:</p>
                        <div class="simplefi-information-description-steps">
                            <p>1. Tener instalada la versión 2.111 o superior de Bistro. En caso de tener una versión inferior comunicate con <a href="https://wa.me/+5491137757552" target="_blank">atención al cliente</a>.</p>
                            <p>2. Presiona el botón que dice "INTEGRAR SIMPLEFI".</p>
                            <p>3. Se abrirá una nueva ventana para aceptar los permisos necesarios para la integración.</p>
                            <p>4. Una vez aceptados los permisos es necesario volver a iniciar sesión en el equipo de Bistrosoft.</p>
                            <p>5. Asegúrate que el medio de pago QR este habilitado en el equipo entrando a <b>Configuraciones > Bistrosoft > Medios de Pago.</b></p>
                            <p>6. ¡Listo! ya puedes empezar a cobrar con SimpleFI.</p>
                        </div>
                    </div>
                 
                    <!-- BOTON DE INTEGRACION -->
                    <div class="compare-fiscal-data">
                        <article class="btn-main">
                            <a :href="urlIntegration"> <!-- Va al computed que me arma la url dinamica para redirigirme al formulario de Modo -->
                                <p>INTEGRAR SIMPLEFI</p>
                            </a>
                        </article>
                    </div>
                </div>
                <!-- -------------------------------------------------- -->
            </section>
        </form>
    </div>
</div>
</template>

<script>
import commons from '@/mixins/mixCommons'
import axios from 'axios'
import Spinner from '../SpinnerComp'

export default {
    props: {
        shop: { //Es el shop que estoy analizando en el v-for
            type: Object,
            default:  () => ({}),
        },
    },
    data() {
        return {
            isUpdateConfigLoading: false, //variable que uso para activar o desactivar el spinner
        }
    },
    computed:{
        isIntegrated(){ //Computed que me devuelve si el usuario tiene nave integrado
            var response = false;
            this.shop?.integratedPaymentWays?.forEach( paymentWay => { // veo por todos los fds que tiene configurados ese comercio
                    if(paymentWay.paymentWay == "SimpleFI" && paymentWay.isEnabled)    //si el nombre del fds que clicke coincide con el que estoy analizando
                        response = true;
                });
            return response;
        },
        urlIntegration (){
            return `https://www.app.simplefi.tech/#/network_link?network=bistrosoft&network_merchant_id=${this.shop.code}`;
        },
    },
    methods: {
        closeModal() {
            this.$emit('onModalClosed')
        },
        refreshPage() {
			setInterval(location.reload(), 1000);
		},
        deleteIntegration(){
            let body = {
				shopCode: this.shop.code,
			};
            //Pongo en true para que se empiece a mostrar el spinner
			this.isUpdateConfigLoading = true;
            axios
				.post(`/api/users/deleteSimpleFIIntegration`, body)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.showSuccess("Configuración actualizada exitósamente");
						// update data
						this.refreshPage();
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading( this);
				})
				.catch((err) => {
					this.$store.errorLoading( this);
					this.showErrors(err.response);
				})
				.finally(() => {
					this.isUpdateConfigLoading = false; //pongo en false para que se vaya el spinner
				});
        }
        
    },
    components: {
        Spinner,
    },
    mixins: [
        commons,
    ],
}
</script>

<style scoped>
    .modal {
        width: fit-content !important;
        font-size: 14px!important;
    }
    .modo-information-description{
        text-align: center;
    }
    .formGeneral{
        margin-top: 1em;
    }
    .formMain{
        justify-content: center !important;
    }
/* TITULO */
    .simplefi-information-title{
        margin-top: 5px;
    }
/* DESCRIPCIONES */
    .simplefi-integration-description-container{
        margin-top: 25px;
    }
    .simplefi-information-description-title{
        margin: 0;
    }
    .simplefi-information-description-steps{
        margin-left: 9px;
    }
/* FOOTER */
    .simplefi-information-footer{
        margin-top: 35px !important;
    }
    .simplefi-footer{
        display: flex;
        justify-content: flex-end;
    }
    .settings-label {
        color: black;
    }
    .search-input{
        width: -webkit-fill-available;
        margin: 0 0 1.2em 0  !important;
        color: black !important;
        font-weight: bolder;
        padding-left: 7%!important;
    }
    .simplefi-eliminate-btn a{
        background-color: #ff0000;
    }
    .simplefi-eliminate-btn a :hover{
        background-color: #c90000;
        border-radius: 6px;
    }
</style>

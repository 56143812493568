<template>
    <div class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="closeModal()">
        <!-- TITULO -->
        <div class="modal-header">
            <div class="image-title">
                <h4>{{ getName }}</h4>
                <p>Integración Servicios de delivery</p>
            </div>
            <div class="btnClose">
                <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>
        <!-- BODY -->
        <div class="modal-body">
            <form action class="formGeneral">
                <section class="formMain">
                    <div v-if="localFdsConfig.isEnabled">
                        <div class="settings-div">
                            <label class="settings-label" for>Código de la integración:</label>
                        </div>
                        <!-- OPCIONES -->
                        <input class="search-input" type="text" v-model="localFdsConfig.fdsShopCode" disabled />
                        <div class="settings-div">
                            <label class="settings-label" for>Opciones de la integración:</label>
                        </div>
                        <!--  -->
                        <div class="settings-div switch">
                            <label class="settings-label">Aceptar pedidos automáticamente</label>
                            <label>
                                <input v-model="localFdsConfig.isAutoAcceptEnabled" type="checkbox" />
                                <span class="lever"></span>
                            </label>
                        </div>
                        <!--  -->
                        <div class="settings-div switch">
                            <label class="settings-label">Integración de menú</label>
                            <label>
                                <input v-model="localFdsConfig.isMenuIntegrationEnabled" type="checkbox" disabled />
                                <span class="lever"></span>
                            </label>
                        </div>
                        <!--  -->
                        <div class="settings-div switch">
                            <label class="settings-label">Abrir tienda</label>
                            <label>
                                <input v-model="localFdsConfig.isShopOpen" type="checkbox" />
                                <span class="lever"></span>
                            </label>
                        </div>
                        <!--  -->
                        <time-picker v-if="
                            fdsConfig.hasToShowScheduleMenu &&
                            shop.hasToShowScheduleMenu
                        " :shopCode="localFdsConfig.ShopCode" title="Horarios de Delivery" subtitle=""
                            :configHours="localFdsConfig.menuSchedules" :hasToShowSaveButton="false" />
                        <!-- BOTON DE GUARDADO -->
                        <div class="compare-fiscal-data">
                            <article class="btn-main">
                                <Spinner v-if="isUpdateFdsConfigLoading" />
                                <a v-else @click="onSavingDeliveryIntegration()">
                                    <p>GUARDAR</p>
                                </a>
                            </article>
                        </div>
                    </div>
                    <div v-else>
                        <p>Comuniquese con bistro para habilitarlo</p>
                        <div class="compare-fiscal-data">
                            <article class="btn-main">
                                <a @click="closeModal()">
                                    <p>CONFIRMAR</p>
                                </a>
                            </article>
                        </div>
                    </div>
                </section>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import commons from '@/mixins/mixCommons'
import Spinner from '../SpinnerComp'
import timePickerCommons from "@/mixins/timePickerCommons";
import TimePicker from "../TimePicker";
import { toast } from "vue3-toastify"

export default {

    props: {
        fdsConfig: { //Prop que tiene todos los datos del fds que clickee
            type: Object,
            default: () => {
                return {
                    source: 0,
                    sourceLabel: "",
                    fdsShopCode: "",
                    isAutoAcceptEnabled: false,
                    IsEnabled: false,
                    isMenuIntegrationEnabled: false,
                    ShopCode: false,
                    isShopOpen: false,
                    HasToShowScheduleMenu: false,
                    MenuSchedules: {}
                }
            }
        },
        shop: { //Es el shop que estoy analizando en el v-for
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            localFdsConfig: { ...this.fdsConfig }, // Copia local de fdsConfig
            isUpdateFdsConfigLoading: false, //Lo uso cuando se da a guardar para que se ejecute el spinner
        }
    },
    watch: {
        // Sincroniza localFdsConfig con los cambios en fdsConfig
        fdsConfig: {
            deep: true,
            handler(newConfig) {
                this.localFdsConfig = { ...newConfig };
            }
        }
    },
    computed: {
        /*eslint-disable*/
        fdsItems() { // Este computed me devuelve todos los fds que tenga ese comercio que esten habilitados, lo uso cuando mando al back la info porque solo tengo que mandar el de los habilitados
            if (this.shop !== undefined) {
                return this.shop.fdsConfigs?.filter((x) => x.isEnabled);
            }
        },
        getName() {
            var name;
            switch (this.fdsConfig.sourceLabel) {
                case "MP Delivery": name = "Mercado Pago Delivery"; break;
                default: name = this.fdsConfig.sourceLabel ?? "";
            }

            return name;
        },
    },
    methods: {
        updateFdsItems() {
            //Me traigo la lista de todos los fds que estan habilitados
            let fdsToUpdate = this.fdsItems;
            //Busco el fds que cambie y le guardo a ese los nuevos datos que estan almacenados en el prop (porque uso v-models)
            fdsToUpdate?.forEach(fds => {
                if (fds.sourceLabel == this.localFdsConfig.sourceLabel) {
                    fds.source = this.localFdsConfig.source,
                        // fds.sourceLabel = this.localFdsConfig.sourceLabel,
                        fds.fdsShopCode = this.localFdsConfig.fdsShopCode,
                        fds.isAutoAcceptEnabled = this.localFdsConfig.isAutoAcceptEnabled,
                        fds.isMenuIntegrationEnabled = this.localFdsConfig.isMenuIntegrationEnabled,
                        fds.isShopOpen = this.localFdsConfig.isShopOpen,
                        fds.isEnabled = this.localFdsConfig.isEnabled,
                        fds.shopCode = this.localFdsConfig.shopCode,
                        fds.hasToShowScheduleMenu = this.localFdsConfig.hasToShowScheduleMenu,
                        fds.menuSchedules = this.localFdsConfig.menuSchedules
                }
            });
            return fdsToUpdate;
        },
        onSavingDeliveryIntegration() {
            this.$emit('update-fds-config', this.localFdsConfig);
            //Valido los campos que se pusieron 
            const errorMsg = this.validateFdsConfig();
            if (errorMsg && errorMsg.length > 0) {
                return;
            }
            //Preparo los fds a actualizar
            let fdsToUpdate = this.updateFdsItems();
            if (fdsToUpdate === undefined || fdsToUpdate == null)
                return;
            //preparo el body
            let body = {
                shopCode: this.shop.code,
                items: fdsToUpdate,
            };
            //Pongo en true para que se empiece a mostrar el spinner
            this.isUpdateFdsConfigLoading = true;
            //le pego al back
            axios
                .post(`/api/users/updateFdsConfig`, body)
                .then((res) => {
                    if (res.data.responseCode === 0) {
                        this.showSuccess("Configuración actualizada exitósamente");
                        // update data
                        this.refreshPage();
                    } else {
                        this.showError(res.data.responseMessage);
                    }

                    this.$store.finishLoading(this);

                })
                .catch((err) => {
                    this.$store.errorLoading(this);
                    this.showErrors(err.response);
                })
                .finally(() => {
                    this.isUpdateFdsConfigLoading = false;
                });
        },
        validateFdsConfig() {
            let errorMsg = "";
            if (this.fdsConfig.sourceLabel == "") return; //Si no tiene sourcelabel quiere decir que no hay nada por lo tanto vuelvo

            let fdsItem = this.fdsConfig.menuSchedules;//Analizo los menuSchedules

            if (fdsItem == null) return; //me fijo que el comercio tenga apertura de delivery

            if (!fdsItem.enabled) return;

            const regex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;

            fdsItem.hoursByDay.forEach((day) => {
                if (day.isOpen) {
                    day.hours.forEach((hour) => {
                        if (!regex.test(hour.from)) {
                            errorMsg =
                                "El horario 'desde' del día " +
                                this.getDayLabelByKey(day.dayKey) +
                                " es inválido";
                            toast.error(errorMsg);
                        }
                        if (!regex.test(hour.to)) {
                            errorMsg =
                                "El horario 'hasta' del día " +
                                this.getDayLabelByKey(day.dayKey) +
                                " es inválido";
                            toast.error(errorMsg);
                        }
                        let from = parseFloat(hour.from.replace(":", "."));
                        let to = parseFloat(hour.to.replace(":", "."));
                        if (from > to) {
                            errorMsg =
                                "El horario 'desde' del día " +
                                this.getDayLabelByKey(day.dayKey) +
                                " debe ser menor al horario 'hasta'";
                            toast.error(errorMsg);
                        }
                    });
                }
            });
            return errorMsg;
        },
        closeModal() {

        }
    },
    components: {
        Spinner,
        TimePicker,
    },
    mixins: [
        commons,
        timePickerCommons,
    ],
}
</script>

<style scoped>
.modal {
    width: 700px !important;
}

.formGeneral {
    margin-top: 1em;
}

.formMain {
    justify-content: center;
}

.settings-div {
    justify-content: space-between;
}

.settings-label {
    color: black;
    margin-right: 10em;
}

.search-input {
    width: -webkit-fill-available;
    margin: 0 0 1.2em 0 !important;
    color: black !important;
    font-weight: bolder;
    padding-left: 7% !important;
}
</style>
<template>
  <div id="modalOption" class="modal modal-block-outside" style="overflow-y: auto !important"
    @keydown.esc="closeModal()">
    <div class="modal-header">
      <div class="image-title">
        <h4>{{ getModalTitle }}</h4>
        <p>Fácil y Simple</p>
      </div>
      <!-- CLOSE -->
      <div class="btnClose">
        <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body" v-if="getCategories.length !== 0">
      <form action class="formGeneral">
        <article v-if="optionType === 'Adicional'" class="formMain article-product">
          <div class="itemFormAditional itemFormForMobile">
            <label class="label" for>Nombre:</label>
            <input :class="getInputClass" v-model="/* eslint-disable */ option.name /* eslint-enable */" type="text"
              :disabled="isNewOption === false" placeholder="Ingrese nombre" />
          </div>

          <!-- <div class="itemFormAditional itemFormForMobile">
            <label for>Cant. Mínima:</label>
            <input v-model="option.minQty" type="number" placeholder="Catidad Minima" />
          </div> -->

          <div v-if="doesSkuExist" class="itemFormAditional itemFormForMobile">
            <label for>Sku:</label>
            <input v-model="/* eslint-disable */ option.sku /* eslint-enable */" type="text" placeholder="Sku" disabled
              class="input-disabled" />
          </div>

          <div class="itemFormAditional itemFormForMobile">
            <label for>Precio:</label>
            <input class="money-input" v-model="/* eslint-disable */ option.price /* eslint-enable */" :step="getStep"
              type="number" placeholder="Ingrese precio" />
          </div>

          <div class="itemFormAditional itemFormForMobile" v-if="optionType === 'Adicional'">
            <vat-selector :rootVat="option.vat" :exempt="option.exempt" :nonTaxed="option.nonTaxed"
              @onVatChanged="onVatChanged" />
            <!-- <label for>IVA:</label>
						<select v-model="option.vat" class="browser-default">
							<option v-for="iva in getTaxValues()" :value="iva" :key="iva">
								{{ iva }} %
							</option>
						</select> -->
          </div>

          <div v-if="doesSkuExist" class="itemFormAditional itemFormForMobile"></div>

          <div v-if="doesSkuExist" class="itemFormAditional itemFormForMobile"></div>

          <div class="itemForm itemCategories" v-for="(category, index) in getCategories" :key="index">
            <div class="switch item-categories">
              <label style="display: inline-flex">
                <input :checked="getValue(category.name)" @change="onCheckCategory(category.name)" type="checkbox" />
                <span class="lever lever-width"></span>
                <div class="category-name">{{ category.name }}</div>
              </label>
            </div>
          </div>
        </article>
        <article v-else class="formMain article-product">
          <div class="itemForm">
            <label class="label" for>Nombre:</label>
            <input :class="getInputClass" v-model="/* eslint-disable */ option.name /* eslint-enable */" type="text"
              :disabled="isNewOption === false" placeholder="Ingrese nombre" />
          </div>

          <div v-if="doesSkuExist" class="itemForm">
            <label for>Sku:</label>
            <input v-model="/* eslint-disable */ option.sku /* eslint-enable */" type="text" placeholder="Sku" disabled
              class="input-disabled" />
          </div>
          <div v-else class="itemForm"></div>
          <!-- <div class="itemForm" v-if="optionType === 'Adicional'">
						<vat-selector
							:rootVat="option.vat"
							:exempt="option.exempt"
							@onVatChanged="onVatChanged"
						/>
					</div> -->

          <div class="itemForm itemCategories" v-for="(category, index) in getCategories" :key="index">
            <div class="switch item-categories">
              <label style="display: inline-flex">
                <input :checked="getValue(category.name)" @change="onCheckCategory(category.name)" type="checkbox" />
                <span class="lever lever-width"></span>
                <div class="category-name">{{ category.name }}</div>
              </label>
            </div>
          </div>
        </article>
      </form>
    </div>
    <div class="modal-body" v-else>
      <form action class="formGeneral">
        <div class="no-category-disclaimer">
          Para crear un adicional, primero debes agregar categorías.
        </div>
      </form>
    </div>

    <div class="modal-footer" v-if="getCategories.length !== 0">
      <article class="btn-main">
        <a v-if="isNewOption === false" class="deleteProduct" @click.prevent="deleteOption">
          <span class="icon-Bistro-_Trash_bin"></span><span>Eliminar {{ optionType }}</span>
        </a>
        <SpinnerComp v-if="isLoading" />
        <a v-else @click="upsertOption" type="submit">
          <p>{{ getTitle }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
import CurrencyStyle from "../components/CurrencyStyle";
import SpinnerComp from "../components/SpinnerComp";
import mixCommons from "../mixins/mixCommons";
import currencyCommons from "../mixins/currencyCommons.vue";
import VatSelector from "../components/VatSelector";
import $ from "jquery";
import { toast } from "vue3-toastify";

export default {
  /* eslint-disable */
  mixins: [currencyCommons, mixCommons],
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    checkedCategories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isNewOption: {
      type: Boolean,
    },
    optionType: {
      type: String,
    },
  },
  data() {
    return {
      modalAction: "¡Creado con éxito!",
      isLoading: false,
      savingControl: false,
    };
  },
  computed: {
    getInputClass() {
      if (this.isNewOption === false) {
        return "input input-disabled";
      } else {
        return "input";
      }
    },
    getCategories() {
      const array = [];
      if (!this.selectedList || !Array.isArray(this.selectedList.categories))
        return array;

      this.selectedList.categories.forEach((element) => {
        array.push({ name: element.name, check: false });
      });
      return array;
    },
    getTitle() {
      if (this.isNewOption) {
        return "Crear " + this.optionType;
      } else {
        return "Guardar " + this.optionType;
      }
    },
    getModalTitle() {
      if (this.isNewOption) {
        return "Crear " + this.optionType;
      } else {
        return "Editar " + this.optionType;
      }
    },
    doesSkuExist() {
      return (
        this.option.sku !== undefined &&
        this.option.sku !== null &&
        this.option.sku !== ""
      );
    },
  },
  methods: {
    closeModal() {
      $("#__layout").css("pointer-events", "all");
    },
    deleteOption() {
      this.$emit("deleteOption");
    },
    getValue(category) {
      let c = this.checkedCategories.filter((t) => t === category);

      if (c !== undefined && c !== null && c.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    onCheckCategory(category) {
      var index = this.checkedCategories.indexOf(category);
      if (index === -1) {
        this.checkedCategories.push(category);
      } else {
        this.checkedCategories.splice(index, 1);
      }
    },
    upsertOption() {
      this.isLoading = true;
      const t = this;

      let upsertOptionHandler = setInterval(() => {
        clearInterval(upsertOptionHandler);
        try {
          if (this.option.name === "") {
            toast.error("El nombre es obligatorio");
          } else if (
            this.optionType === "Adicional" &&
            this.option.price === ""
          ) {
            toast.error("El precio es obligatorio");
          } else if (this.checkedCategories.length === 0) {
            toast.error(
              `El ${this.optionType} debe contener al menos una categoría asociada`
            );
          } else if (this.optionType === "Adicional" && !this.option.vat) {
            toast.error("El IVA es obligatorio");
          } else {
            this.option.price = this.getParse(this.option.price, "justNumber"); //HZ ADV OK
            this.option.minQty = 0; // BIS-4761 -> Por ahora en 0 hasta que resolvamos que hacer

            if (this.optionType === "Gusto") {
              this.option.vat = "0";
            }

            if (this.savingControl) return;

            this.savingControl = true;

            this.$emit("upsertOption", this.option, this.checkedCategories);
          }

          /* if (this.optionType === "Gusto") {
            this.option.vat = "0";
          } */
        } finally {
          t.isLoading = false;
          this.savingControl = false;
        }
      }, 500);

    },
    onVatChanged(optionVat) {
      this.option.vat = optionVat.rootVat;
      this.option.exempt = optionVat.exempt;
      this.option.nonTaxed = optionVat.nonTaxed;
    },
  },
  components: {
    CurrencyStyle,
    SpinnerComp,
    VatSelector,
  },
  /* eslint-enable */
};
</script>
<style scoped>
.no-category-disclaimer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}
</style>
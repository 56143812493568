export const ASYNC_STATUS = {
  PENDING: "pending",
  SYNCED: "synced",
};

export const ORDER_STATUS = {
  CLOSED: "closed",
  ACTIVE: "active",
};



<template>
<div class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="closeModal()">
    <!-- TITULO -->
    <div class="modal-header">
        <div class="image-title">
            <h4>kamiPay</h4>
            <p>Integración medio de pago</p>
        </div>

        <div class="btnClose">
            <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
                <span class="icon-Bistro-_Close"></span>
            </a>
        </div>
    </div>
    <!-- BODY -->
    <div class="modal-body">
        <form action class="formGeneral">
            <section class="formMain">
                <!-- Si tiene kamipay integrado -->
                <div v-if="isIntegrated">

                    <!-- Texto inicial -->
                    <div class="kpay-integration-description">
                        <label class="settings-label">Tu comercio ya está integrado con kamiPay. Al momento de cobrar con QR tenes que seleccionar la opcion kamiPay.</label>
                    </div>

                    <!-- OPCIONES -->
                    <!-- Cotización kamiPay -->
                    <div class="kp-setting kp-setting-oracle">
                        <label class="settings-label" for>{{inputs[0].title}}</label>
                        <input class="kp-input-oracle" type="text" v-model="inputs[0].value" disabled />
                    </div>

                    <!-- Utilizar cotización propia -->
                    <p class="kp-customconfig-title">Opciones de la integración:</p>

                    <div class="switch">
                        <label class="kp-customconfig-lever">
                            <div class="kp-customconfig-lever-title">
                                <label class="settings-label" for>{{inputs[1].title}}</label>
                                <label class="kp-customconfig-lever-hint" for>Si se activa esta opción se utilizará la cotización definida por el usuario en lugar de la kamiPay</label>
                            </div>
                                <input type="checkbox" :checked="booleanValue" @change="updateBooleanValue" />
                            <span class="lever"></span>
                        </label>
                    </div>

                    <!-- Cotización Real Brasilero (Pesos) -->
                     <div class="kp-setting">
                         <label class="settings-label" for>{{inputs[2].title}}</label>
                         <input 
                             class="search-input" 
                             type="text" 
                             :value="inputs[2].value" 
                             @input="updateInput(2, $event)" 
                             :disabled="!booleanValue"
                         />
                     </div>

                    <div class="kp-footer">
                        <!-- BOTON CANCELAR -->
                        <article class="btn-main modal-action modal-close kp-cancel-btn">
                            <a href="#!" @click="closeModal()">
                                <p>CANCELAR</p>
                            </a>
                        </article>
                        <!-- BOTON ACEPTAR -->
                        <article class="btn-main">
                            <Spinner v-if="isUpdateConfigLoading" />
                            <a v-else @click="saveKamiPayValues()">
                                <p>GUARDAR</p>
                            </a>
                        </article>
                    </div>
                </div>
                <!-- --------------------------------------------------------------- -->

                <!-- Si no muestro el cartel para la integracion de nave -->
                <div v-else>
                    <p class="kpay-information-title">
                        ¿Qué es kamiPay?
                    </p>
                    <p class="kpay-information-description">
                        kamiPay es la primera APP que te permite cobrarle a tu cliente de Brasil con PIX, el popular método por QR.
                    </p>
                    <p class="kpay-information-description">
                        El pago se realiza en reales y tu negocio recibe dólares digitales en el acto y 0% de comisión.
                    </p>
                    <!-- BOTON DE INTEGRACION -->
                    <div class="compare-fiscal-data">
                        <article class="btn-main">
                            <a :href="urlIntegration"> <!-- Va al computed que me arma la url dinamica para redirigirme al formulario de Modo -->
                                <p>INTEGRAR KAMIPAY</p>
                            </a>
                        </article>
                    </div>
                </div>
                <!-- -------------------------------------------------- -->
            </section>
        </form>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import commons from '../../mixins/mixCommons'
import Spinner from '../SpinnerComp'

export default {
    props: {
        shop: { //Es el shop que estoy analizando en el v-for
            type: Object,
            default:  () => ({}),
        },
    },
    data() {
        return {
            inputs: [], //Tiene todos los inputs que tengo que mostrar
            isUpdateConfigLoading: false, //variable que uso para activar o desactivar el spinner
        }
    },
    computed:{
        isIntegrated(){ //Computed que me devuelve si el usuario tiene kamipay integrado
            var response = false;
            this.shop?.integratedPaymentWays?.forEach( paymentWay => { // veo por todos los paymentWays que tiene configurados ese comercio
                    if(paymentWay.paymentWay == "KamiPay" && paymentWay.isEnabled && paymentWay.inputs?.length > 0)  //si tiene las condiciones para que este habilitado
                    {
                        paymentWay.inputs.forEach((input) => {
                            let value = input.value;
                            if (this.inputs.length === 0) {
                                value = "$" + value;
                            }
                            this.inputs.push({
                                title: input.title,
                                value: value
                            });
                        });
                        response = true;
                    }
                });
            return response;
        },
        booleanValue() { //Computed que me devuelve un boleano dependiendo del valor que haya en el input que dice si tengo que usar la cotizacion propia
            return this.inputs[1]?.value === "true" || this.inputs[1]?.value === "True" ;
        },
        urlIntegration (){ //Computed que me devuelve una url dinamica para llevarme al chat de wpp
            let originalURL = "https://wa.me/+5491137757552?text=Quisiera%20integrar%20kamiPay%20a%20mi%20comercio";
            let nuevaURL = `${originalURL}&ID=${this.shop.code}`;
            return nuevaURL
        },
    },
    methods: {
        closeModal() { //Para cerrar el modal
            this.$emit('onModalClosed')
        },
        updateBooleanValue(event) { //Cuando se cambia el valor de la checkbox se ejecuta esta funcion que me guarda true o false en el input correspondiente
            if(!event.target.checked)
                this.inputs[2].value = null

            this.inputs[1].value = event.target.checked.toString();
        },
        formatInput(value) { //Permite solo numeros y comas
            if (value === null || value === undefined) return '';
            return value.replace(/[^\d,]/g, '');
        },
        updateInput(index, event) { //Cada vez que hago un cambio en la cotizacion custom analizo que solo sean numeros y coma y los guardo en el value del input correspondiente
            const formattedValue = this.formatInput(event.target.value);
            if (formattedValue !== event.target.value) {
                // Si el valor formateado es diferente, actualiza el input
                event.target.value = formattedValue;
            }
            this.inputs[index] = { ...this.inputs[index], value: formattedValue };
        },
        saveKamiPayValues() { //Funcion que le envia mis valores al back
            //preparo el body
			let body = {
				shopCode: this.shop.code,
				hasToUseOracleRate: this.booleanValue,
                customRate: this.inputs[2].value == null || this.inputs[2].value == "" ? null : this.inputs[2].value
			};
            //Pongo en true para que se empiece a mostrar el spinner
			this.isUpdateConfigLoading = true;
            //le pego al back
			axios
				.post(`/api/users/updateKamiPayConfig`, body)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.showSuccess("Configuración actualizada exitósamente");
						// update data
						this.refreshPage();
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading( this);
				})
				.catch((err) => {
					this.$store.errorLoading( this);
					this.showErrors(err.response);
				})
				.finally(() => {
					this.isUpdateConfigLoading = false; //pongo en false para que se vaya el spinner
				});
        }
    },
    components: {
        Spinner,
    },
    mixins: [
        commons,
    ],
}
</script>

<style scoped>
    .modal {
        width: fit-content !important;
    }
    .formGeneral{
        margin-top: 1em;
    }
    .settings-label {
        color: black;
    }
    

/* Estilos para cuando no esta integrado */
    .kpay-information-title{
        font-size: medium;
        font-weight: bold;
        display: flex;
        justify-content: center;
    }
    .kpay-information-description{
        display: flex;
        justify-content: center;
        text-align: center;
        font: normal 1rem/24px Rubik-Regular;
    }
    
    .kpay-integration-description{
        margin-bottom: 0.9em !important;
    }


/* Opcion de cotizacion de kamipay */
    .kp-setting{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0 ;
    }
    .kp-setting-oracle {
        border-bottom: 2px solid #bdbdbd;
    }
    .kp-input-oracle{
        color: black !important;      
        height: 40px !important;
        border: 1px solid #fff !important;
        text-align: end;
        font: bolder 1rem/24px Rubik-Regular;
        margin: 0 !important;
    }
/* Opciones de customizacion */
    .kp-customconfig-title {
        margin: 20px 0 10px 0;
        font: normal 1rem/24px Rubik-Regular;
    }
/* Switch para habilitar custom rate */
    .kp-customconfig-lever{
        display: flex;
        justify-content: space-between;
        margin: 10px 0 0 0;
    }
    .kp-customconfig-lever-title{
        display: flex;
        flex-direction: column;
    }
    .kp-customconfig-lever-hint {
        color: #828282;
        font-size: 12px !important;
        font-weight: 200 !important;
        letter-spacing: 0px !important;
        font-family: 'Rubik-Regular', sans-serif !important;
        width: 80%;
    }
/* Valor del custom rate */
    .search-input{
        margin: 0 !important;
        color: black !important;
        font-weight: bolder;
        padding-right: 3%!important;
        text-align: right;
        width: 100px;
        align-self: auto;
    }
/* footer */
    .kp-footer{
        display: flex;
        justify-content: flex-end;
    }
    .kp-cancel-btn a{
        background-color: #a5a5a5;
    }
    .kp-cancel-btn a :hover{
        background-color: #828282;
        border-radius: 6px;
    }
    
</style>
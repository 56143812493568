<template>
  <div
    class="info-box-bistro"
    :class="{ 'vertical-layout': isVertical, 'horizontal-layout': !isVertical }"
    :style="{
      backgroundColor: background,
      color: textColor,
      maxWidth: maxWidth,
    }"
  >
    <div v-if="icon" class="info-icon">
      <img :src="icon" alt="Icon" />
    </div>
    <div class="info-text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "InfoBoxBistro",
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      required: true,
    },
    background: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      required: true,
    },
    isVertical: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.info-box-bistro {
  display: flex;
  padding: 16px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
  &.vertical-layout {
    flex-direction: column;
    .info-icon {
      margin-bottom: 16px;
    }
  }
  &.horizontal-layout {
    flex-direction: row;
    align-items: center;
    .info-icon {
      margin-right: 16px;
    }
  }
  .info-icon img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>

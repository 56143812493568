<script>
export default {
	watch: {
		selectedPdvs() {
			if (this.$store.hasMounted) {
				let ids = this.selectedPdvs.map(item => {
					return item.shopCode
				})
				if (ids.length > 0) {
					this.params['MerchantFilter'] = ids
				} else {
					delete this.params['MerchantFilter']
				}
				this.pageChangeHandle(1)
			}
		}
	},
}
</script>

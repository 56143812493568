<template>
    <div class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="closeModal()">
        <!-- TITULO -->
        <div class="modal-header">
            <div class="image-title">
                <h4>Nave</h4>
                <p>Integración medio de pago</p>
            </div>

            <div class="btnClose">
                <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>
        <!-- BODY -->
        <div class="modal-body">
            <form action class="formGeneral">
                <section class="formMain">
                    <!-- Si tiene nave integrado, o sea si tiene clientId y esta en enabled -->
                    <div v-if="isIntegrated">
                        <div class="modo-integration-description">
                            <label class="settings-label">Tu comercio ya esta integrado con NAVE. Al momento de cobrar
                                con QR tenes que seleccionar la opción MODO.</label>
                        </div>
                        <!-- OPCIONES -->
                        <div class="settings-div">
                            <label class="settings-label" for>{{ title }}</label>
                        </div>
                        <input class="search-input" type="text" v-model="clientId" disabled />
                        <!-- BOTON ACEPTAR -->
                        <div class="compare-fiscal-data">
                            <article class="btn-main odal-action modal-close">
                                <a href="#!" @click="closeModal()">
                                    <p>ACEPTAR</p>
                                </a>
                            </article>
                        </div>
                    </div>
                    <!-- --------------------------------------------------------------- -->

                    <!-- Si no muestro el cartel para la integracion de nave -->
                    <div v-else>
                        <p class="modo-information-title">
                            ¿Qué es nave?
                        </p>
                        <p class="modo-information-description">
                            Es la plataforma para cobrar y manejar tu negocio. Podés cobrar con QR o integrarlo con la
                            Tienda online de Bistrosoft. Conoce mas sobre nave haciendo click <a
                                href="https://navenegocios.ar/home" target="_blank">aca</a>.
                        </p>
                        <p class="modo-information-description">
                            <b>NOTA:</b> Para poder cobrar con nave en tu equipo Bistrosoft debes tener una versión
                            igual o superior a 2.105. Esto lo podes ver en la esquina inferior izquierda de la sección
                            de Configuración del equipo. En caso de tener una versión anterior o necesitar ayuda,
                            comunicate con atención al cliente.
                        </p>
                        <!-- BOTON DE INTEGRACION -->
                        <div class="compare-fiscal-data">
                            <article class="btn-main">
                                <a :href="urlIntegration">
                                    <!-- Va al computed que me arma la url dinamica para redirigirme al formulario de Modo -->
                                    <p>INTEGRAR NAVE</p>
                                </a>
                            </article>
                        </div>
                    </div>
                    <!-- -------------------------------------------------- -->
                </section>
            </form>
        </div>
    </div>
</template>

<script>
import commons from '@/mixins/mixCommons'

export default {
    props: {
        shop: { //Es el shop que estoy analizando en el v-for
            type: Object,
            default:  () => ({}),
        },
    },
    data() {
        return {
            title: null,
            clientId: null,
        }
    },
    computed: {
        isIntegrated() { //Computed que me devuelve si el usuario tiene nave integrado
            var response = false;
            this.shop?.integratedPaymentWays?.forEach(paymentWay => { // veo por todos los fds que tiene configurados ese comercio
                if (paymentWay.paymentWay == "Modo" && paymentWay.isEnabled && paymentWay.inputs?.length > 0)    //si el nombre del fds que clicke coincide con el que estoy analizando
                {
                    response = true;
                    this.title = paymentWay.inputs[0].title;
                    this.clientId = paymentWay.inputs[0].value;
                }
            });
            return response;
        },
        urlIntegration() {
            var authUser = this.$store.getAuthUser
            let storeId = this.shop.code;
            let email = authUser.user.name;
            let domain = 'bistrosoft.com';
            let token = 'U2FsdGVkX19OYc2AfQ1nSinn23HAreVHVgCP7G2gBjY=';
            let notificationUrl = 'https://modo.bistrosoft.com/api/V1/Webhook/processEvent';
            let callbackUrl = 'https://webaccess.bistrosoft.com/settingsV2';

            let queryParams = {
                store_id: storeId,
                email: email,
                domain: domain,
                token: token,
                notification_url: notificationUrl,
                callback_url: callbackUrl
            };
            // Crear instancia de URLSearchParams
            const searchParams = new URLSearchParams();
            // Añadir los parámetros a searchParams
            for (const [key, value] of Object.entries(queryParams)) {
                searchParams.append(key, value);
            }
            // Devolver la URL con los parámetros query
            return `https://ecommerce.ranty.io/bistrosoft/nave/login?${searchParams.toString()}`;
        },
    },
    methods: {
        closeModal() {
            this.$emit('onModalClosed')
        }
    },
    components: {
    },
    mixins: [
        commons,
    ],
}
</script>

<style scoped>
.modal {
    font-size: 14px !important;
}

.modo-information-title {
    font-size: medium;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.modo-information-description {
    text-align: center;
}

.formGeneral {
    margin-top: 1em;
}

.modo-integration-description {
    margin-bottom: 0.9em !important;
}

.settings-label {
    color: black;
}

.search-input {
    width: -webkit-fill-available;
    margin: 0 0 1.2em 0 !important;
    color: black !important;
    font-weight: bolder;
    padding-left: 7% !important;
}
</style>

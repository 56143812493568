<script>
export default {
  /* eslint-disable */
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
    window.removeEventListener("resize", this.getWindowHeight);
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      // window.addEventListener('resize', this.getWindowHeight)
      // Init
      this.getWindowWidth();
      // this.getWindowHeight()
    });
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 991) {
        this.$store.toggleMobile(true);
      } else {
        this.$store.toggleMobile(false);
      }
    },
  },
};
</script>

<template>
  <section></section>
  <div v-show="value" class="aselect" :data-value="value" :data-list="optionList">
    <div class="selector" @click="toggle()">
      <div class="label">
        <span>{{
          getCurrentValue.name ? getCurrentValue.name : getCurrentValue.listName
        }}</span>
      </div>
      <div class="arrow" :class="{ expanded: visible }"></div>
      <div :class="{ hidden: !visible, visible }">
        <ul>
          <li :class="{ current: item === value }" v-for="(item, index) in optionList" :key="index"
            @click="onSelectOption(item)">
            {{ item.name ? item.name : item.listName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optionList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedShopCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: null,
      visible: false,
    };
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    onSelectOption(option) {
      this.value = option;
      this.$emit("onSelectOption", this.value);
    },
  },
  computed: {

    getCurrentValue() {
      /* eslint-disable */
      if (
        Object.keys(this.optionList).length > 1 &&
        this.optionList[1].listName &&
        this.selectedShopCode
      ) {
        this.value = this.optionList.filter(
          (x) => x.shopCode == this.selectedShopCode
        )[0];
      }
      if (this.value && this.optionList.filter((x) => x == this.value)[0]) {
        return this.value;
      }
      if (this.optionList[0]) {
        this.onSelectOption(this.optionList[0]);
        return this.value;
      }

      return "";
      /* eslint-enable */
    },
  },
};
</script>

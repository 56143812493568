<template>
  <div id="modalCalendar" class="modal modalCalendar">
    <div class="modal-header">
      <h4>{{ title }}</h4>
      <p>Fácil y Simple</p>

      <!-- CLOSE -->
      <div class="btnClose">
        <a
          href="#!"
          class="modal-action modal-close waves-effect waves-green btn-flat"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <!-- BODY MODAL -->
    <div class="modal-body modalStock">
      <div class="contain-calendar3">
        <template v-if="calendarType !== 'month'">
          <div v-if="calendarType !== 'single'" class="modalStock-time">
            <input
              type="time"
              class="hourInput"
              v-model="time1"
              @change="checkTime"
            />
            <input
              type="time"
              class="hourInput"
              v-model="time2"
              @change="checkTime"
            />
          </div>

          <div class="datepicker-trigger">
            <Datepicker
              v-if="!elements.hideCalendar"
              v-model="dateFull"
              model-type="yyyy-MM-dd"
              range
              multi-calendars
              inline
              locale="es"
              auto-apply
              :hide-navigation="['time']"
              @internal-model-change="
                (val) => {
                  if (val) {
                    var fechaOriginal = new Date(val[0]);

                    var año = fechaOriginal.getFullYear();
                    var mes = ('0' + (fechaOriginal.getMonth() + 1)).slice(-2);
                    var dia = ('0' + fechaOriginal.getDate()).slice(-2);

                    dateOne = año + '-' + mes + '-' + dia;

                    if (val.length == 1) {
                      dateTwo = dateOne;
                    }
                  }
                }
              "
              @update:model-value="
                (val) => {
                  dateOne = val[0];
                  dateTwo = val[1];
                  console.log('zm inicio', dateOne);
                  console.log('zm fin', dateTwo);
                }
              "
            />
          </div>
        </template>
        <template v-else>
          <div class="modalStock-month">
            <div style="margin: 10px">
              <h2>Desde:</h2>
              <Datepicker
               v-model="monthFrom"
               month-picker
               inline
               auto-apply/>
            </div>
            <div style="margin: 10px">
              <h2>Hasta:</h2>
              <Datepicker 
              v-model="monthUntil" 
              month-picker  
              inline 
              auto-apply/>
            </div>
          </div>
        </template>
      </div>
      <!-- <button type="button" class="btn-main" @click="applyDate" >

				Aplicar
			</button> -->
      <div class="modal-footer">
        <article class="btn-main">
          <a @click="applyDate" type="submit">
            <p>Aplicar</p>
          </a>
        </article>
      </div>
    </div>
  </div>
</template>
<script>
import mixCommons from "../mixins/mixCommons";
import calendarCommons from "../mixins/calendarCommons";
import headerCommons from "../mixins/headerCommons";
import moment from "moment";
import $ from "jquery";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  mixins: [mixCommons, headerCommons, calendarCommons],
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dateFull: "",
      dateOne: "",
      dateTwo: "",
      // mobile: false,
      time1: "00:00",
      time2: "23:59",
      monthFrom: "",
      monthUntil: "",
      tabs: [
        {
          id: 1,
          title: "Hoy",
          param: "Today",
          show: this.$store.getAuthUser
        },
        {
          id: 2,
          title: "Ayer",
          param: "Yesterday",
          show: false,
        },
        {
          id: 3,
          title: "Semana",
          param: "Week",
          show: this.$store.getAuthUser
        },
        {
          id: 4,
          title: "Mes",
          param: "Month",
          show: this.$store.getAuthUser
        },
        {
          id: 5,
          title: "Todos",
          param: "All",
          show: false,
        },
        {
          id: 6,
          title: "Personalizado",
          param: "ConfigurablePeriod",
          show: false,
        },
        {
          id: 7,
          title: "Caja en curso",
          param: "CurrentCashBox",
          show: true,
        },
        {
          id: 8,
          title: "Caja anterior",
          param: "PreviusCashBox",
          show: true,
        },
      ],
    };
  },
  computed: {
    date() {
      return this.$store.getDateCalendar;
    },
    elements() {
      return this.$store.getHideHeader;
    },
    calendarType() {
      return this.$store.getCalendarType;
    },
  },
  watch: {
    date() {
      if (this.date.indexOf("Seleccione") >= 0) {
        this.$store.updateDate("");
        this.formatDates("", "");
        this.dateOne = "";
        this.dateTwo = "";
      }
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("resize", this.getWindowHeight);
      // Init
      this.getWindowWidth();
      this.getWindowHeight();
    });
  },
  methods: {
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 991) {
        // this.mobile = true
        setTimeout(() => {
          $(".dropdown-trigger").unbind("click");
          //$(".dropdown-trigger").dropdown();
          /* eslint-disable */
          var dropdown = document.getElementsByClassName("dropdown-trigger");
          var instance = M.Dropdown.init(dropdown);
          //instance.open();
          /* eslint-enable */
        }, 1000);
      } else {
        // this.mobile = false
      }
    },
    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
    },
    closeCalendar() {
      //$("#modalCalendar").modal("close");
      /* eslint-disable */
      var calendar = document.getElementById("modalCalendar");
      var instance = M.Modal.getInstance(calendar);
      instance.close();
      /* eslint-enable */
    },
    checkTime() {
      if (this.time1 === "") {
        this.time1 = "00:00";
      }
      if (this.time2 === "") {
        this.time2 = "23:59";
      }
    },
    applyDate() {
      let tab = this.tabs.find((item) => {
        return item.title === "Personalizado";
      });

      if (this.calendarType === "month") {
        var d = new Date();
        let currentDate =
          d.getFullYear().toString() + "-" + (d.getMonth() + 1).toString().padStart(2, "0");

        // Si ambas fechas están vacías
        if (!this.monthFrom && !this.monthUntil) {
          this.monthUntil = currentDate;
          this.monthFrom = moment(this.monthUntil).subtract(12, "months").format("YYYY-MM");

        // Si solo monthFrom está vacío
        } else if (!this.monthFrom) {
          this.monthFrom = moment(this.monthUntil).subtract(12, "months").format("YYYY-MM");

        // Si solo monthUntil está vacío
        } else if (!this.monthUntil) {
          this.monthUntil = moment(this.monthFrom).add(12, "months").format("YYYY-MM");
        }

        // Si monthFrom y monthUntil son objetos, extraemos los valores y los formateamos
        const formattedMonthFrom = typeof this.monthFrom === 'object' 
          ? `${this.monthFrom.year}-${String(this.monthFrom.month + 1).padStart(2, "0")}`
          : this.monthFrom;
        
        const formattedMonthUntil = typeof this.monthUntil === 'object'
          ? `${this.monthUntil.year}-${String(this.monthUntil.month + 1).padStart(2, "0")}`
          : this.monthUntil;

        tab.param = `${
          "ConfigurablePeriod&From=" +
          formattedMonthFrom +
          "&Until=" +
          formattedMonthUntil
        }`;
        
        this.changeTab(tab);
        this.formatDates(formattedMonthFrom, formattedMonthUntil);
      } else if (this.calendarType === "single") {
        const from = moment(this.dateOne)
          .subtract(10, "days")
          .format("YYYY-MM-DD");
        tab.param = `${
          this.dateOne !== "" ? "&From=" + from + "&Until=" + this.dateOne : ""
        }`;

        this.changeTab(tab);
        this.formatDates(this.dateOne);
      } else {
        let date1 = this.dateOne
          ? this.dateOne + "T" + this.time1 + ":00.000Z"
          : "";
        let date2 = this.dateTwo
          ? this.dateTwo + "T" + this.time2 + ":00.000Z"
          : "";

        tab.param = `ConfigurablePeriod${
          this.dateOne !== "" ? "&From=" + date1 : ""
        }${this.dateTwo !== "" ? "&Until=" + date2 : ""}`;

        this.changeTab(tab);
        this.formatDates(date1, date2);
      }

      this.closeCalendar();
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne === "" && dateTwo === "") {
        formattedDates = "Seleccione fecha";
      } else {
        if (this.calendarType === "single") {
          formattedDates = moment(dateOne).utcOffset(0).format("ll");
        } else if (dateOne) {
          formattedDates = moment(dateOne).utcOffset(0).format("lll");
        }
        if (dateTwo) {
          formattedDates += " - " + moment(dateTwo).utcOffset(0).format("lll");
        }
      }
      this.storeFormattedDates(formattedDates);
    },
    changeTab(tab) {
      return this.storeDate(tab);
    },
  },
  components: { Datepicker },
};
</script>
<style lang="scss">
.datepicker-trigger {
  > div {
    justify-content: center;
  }
}
</style>

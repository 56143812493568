<template>
	<img class="logoProVentas"
		src="@assets/img/logo_tas.png"
		:width="width"
		:height="height"
	/>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: "0",
		},
		width: {
			type: String,
			default: "18%",
		},
		height: {
			type: String,
			default: "11%",
		},
		marginTop: {
			type: String,
			default: "15%",
		},
		backgroundColor: {
			type: String,
			default: "transparent",
		},
		computed: {
			getInstance() {
				return process.env.VUE_APP_INSTANCE_CODE;
			},
		},
	},
};
</script>

<script>
export default {
	data () {
		return {
			order_no_discount: true,
			sale_no_discount: true,
			order_with_discount: true,
			sale_with_discount: true
		}
	},
	watch: {
		order_no_discount () {
			this.chargeFilterSaleType(this.order_no_discount, 'ORDER_NO_DISCOUNT')
			this.pageChangeHandle(1)
		},
		order_with_discount () {
			this.chargeFilterSaleType(this.order_with_discount, 'ORDER_WITH_DISCOUNT')
			this.pageChangeHandle(1)
		},
		sale_no_discount () {
			this.chargeFilterSaleType(this.sale_no_discount, 'SALE_NO_DISCOUNT')
			this.pageChangeHandle(1)
		},
		sale_with_discount () {
			this.chargeFilterSaleType(this.sale_with_discount, 'SALE_WITH_DISCOUNT')
			this.pageChangeHandle(1)
		}
	},
	methods: {
		setAllTypes (bool) {
				this.sale_no_discount = bool
				this.sale_with_discount = bool
				this.order_no_discount = bool
				this.order_with_discount = bool
			},
		chargeFilterSaleType (bool, status) {
				if (bool) {
					var index = this.params.FilterSaleTypes.indexOf(status)
					this.params.FilterSaleTypes.splice(index, 1)
				} else {
					this.params.FilterSaleTypes.push(status)
				}
			},
	}
}
</script>

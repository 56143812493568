<script>
export default {
	computed: {
		getMeasureUnit() {
			if (this.cost === null || this.cost === undefined) return "Unidad";

			if (
				this.cost.measureUnit === "" ||
				this.cost.measureUnit === undefined ||
				this.cost.measureUnit === null
			)
				return "Unidad";

			return this.cost.measureUnit;
		},
	},
	methods: {
		filterSameIngredientWeAreEditing() {
			let resp = [...this.ingredients];
			const index = resp.findIndex((t) => t.sku === this.cost.sku);
			if (index > -1) {
				resp.splice(index, 1);
			}

			return resp;
		},
		getIngredientMeasureUnit(measureUnit) {
			if (measureUnit === "") {
				return " x U";
			}

			return ` x ${measureUnit}`;
		},
		getCostTypeValue(key) {
			if (this.cost === undefined || this.cost === null) return false;

			return this.cost?.itemCost?.costType == key;
		},
		onChangeCostType(key) {
			if (this.cost.itemCost.costType === key) this.cost.itemCost.costType = -1;
			else this.cost.itemCost.costType = key;
		},
	},
};
</script>

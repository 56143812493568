<template>
	<li class="receipt-row" @click="onReceiptEdition(data.id)">
		<!-- <div>{{JSON.stringify(this.data)}}</div> -->
		<figure class="iconHistory">
			<!-- <span :class="{'salesNull': data.status === 1, 'yellow': data.status === 3, 'icon-Bistro-_Check': true }"></span> -->
			<span :class="{'icon-Bistro-_RedCheck': data.paidType === 'N' , 'icon-Bistro-_Check': data.paidType === 'S' }"></span>
		</figure>

		<!-- FECHA -->
		<div class="items-receipt-date">
			<h5>{{ data.date }}</h5>
		</div>
		<div class="items-receipt-type">
			{{ data.type }}
		</div>
		<div class="items-receipt-provider-name">
			{{ data.providerName }}
		</div>

		<div class="items-receipt-cat-subcat">
			<div>{{data.category}}</div>
			<div>{{data.subCategory}}</div>
		</div>

		<div class="items-receipt-gross-amount">
			<currency-style :valor="$filters.currency(data.grossAmount)" />
		</div>

		<div class="items-receipt-vat-amount">
			<currency-style :valor="$filters.currency(data.tax)" />
		</div>

		<div class="items-receipt-total-amount">
			<currency-style :valor="$filters.currency(data.total)" />
		</div>

		<!-- <div class="items-receipt-trash-bin">
			<a @click="deleteReceipt(data.id, data.type, data.providerName, `$${data.total}`, data.date)">
				<span class="icon-Bistro-_Trash_bin"></span>
			</a>
		</div> -->
	</li>
</template>
<script>
import CurrencyStyle from './CurrencyStyle'

export default {
	props: {
		data: {
			type: Object
		}
	},
	data () {
		return {
			params: {

			}
		}
	},
	methods: {
		onReceiptEdition(receiptId) {
			this.$emit(`onReceiptEdition`, receiptId)
		}
		// deleteReceipt (receiptId, type, name, totalAmount, date) {
		// 	const result = confirm(`¿Está seguro que desea borrar el comprobante${name ? `, proveedor ${name}` : ``} (${type}) de ${totalAmount} en fecha ${date}?`)
		// 	if (result) {
		// 		this.$emit(`onDeletingReceipt`, receiptId)
		// 	}
		// }
	},
	components: {
		CurrencyStyle
	}
}
</script>

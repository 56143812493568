<template>
<div class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="closeModal()">
    <div class="modal-header">
        <div class="image-title">
            <h4>Se intentará generar una factura de prueba</h4>
            <p>Aguarde un instante</p>
        </div>

        <div class="btnClose">
            <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
                <span class="icon-Bistro-_Close"></span>
            </a>
        </div>
    </div>

    <div class="modal-body">
        <form action class="formGeneral">
            <section class="formMain">
                <div class="compare-fiscal-data">
                    <p>Realizaremos una breve verificación de los datos de facturación ingresados mediante la generación y anulación de una factura de prueba.</p>
                </div>
                <div class="compare-fiscal-data">
					<article class="btn-main">
                        <Spinner v-if="isLoading" />
						<a v-else @click="issueTestBill()">
							<p>CONFIRMAR</p>
						</a>
					</article>
				</div>
            </section>
        </form>
    </div>

</div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import commons from '../../mixins/mixCommons'
import Spinner from '../SpinnerComp'

export default {
    mixins: [commons],
    props: {
        shopCode: {
            type: String,
            default: () => {
                return ''
            }
        },
    },
    data() {
        return {
			profile: {},
            isLoading: false
        }
    },
    watch: {},
    computed: {

    },
    methods: {
        issueTestBill(){
            const t = this
            this.isLoading = true;
            t.$store.initLoading( t)
			let params = {}
			params['shopCode'] = this.shopCode
			axios
				.post(`api/billingDetail/issueTestBill`, params)
				.then((res) => {
					if (res.data.responseCode === 0) {
						t.showSuccess('Factura de prueba emitida y anulada exitosamente')
                        this.closeModal()
					} else {
						t.$store.errorLoading(this);
						t.showError(res.data.responseMessage)
					}
                    //t.showErrors(res)
                    t.$store.finishLoading( this)
				})
				.catch(() => {
					t.$store.errorLoading( this)
					t.showError('Error al generar factura de prueba')
                    
				})
                .finally(() => {
                    t.isLoading = false;
                })
		},
		
        closeModal() {
            $('#__layout').css('pointer-events', 'all')

            this.$emit('onModalClosed')
        }
    },
    components: {
        Spinner
    }
}
</script>

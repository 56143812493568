<template>
	<div :id="modalId" class="modal modalNewProviderReceipt">
		<div class="modal-header">
			<h4 v-if="isNew">Crear Comprobante</h4>
			<h4 v-else>Editar Comprobante</h4>
			<p>Fácil y Simple</p>

			<!-- CLOSE -->
			<div class="btnClose">
				<a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>
		<div class="modal-body">
			<form action class="formGeneral">
				<article class="formMain article">
					<div class="itemForm">
						<label class="label" for>Tipo:</label>
						<div>
							<label class="input-radio" @click="onRadioButtonChange(`Gasto`)">
								<input :checked="receipt.receiptType === 'Gasto'" type="radio" />
								<label class="input-radio-text">Gasto</label>
							</label>
						</div>
						<div>
							<label class="input-radio" @click="onRadioButtonChange(`Compra`)">
								<input :checked="receipt.receiptType === 'Compra'" type="radio" />
								<label class="input-radio-text">Compra</label>
							</label>
						</div>
					</div>
				</article>
				<article v-if="receipt.receiptType === 'Compra'" class="formMain article">
					<div class="itemForm select-option">
						<label class="label" for>Proveedor *</label>
						<select v-model="providerId" class="browser-default">
							<option value disabled selected>Seleccione Proveedor</option>
							<option v-for="provider in newReceiptFilters.providers" :value="provider.id"
								:key="provider.name">
								{{ provider.name }}
							</option>
						</select>
					</div>
				</article>
				<article class="formMain article">
					<div class="itemForm">
						<label class="label" for>{{ getReceiptName }}</label>
						<input class="input" v-model="receipt.ticket" @click="hideCalendar" type="text"
							placeholder="Ingresar número de factura" />
					</div>
					<div v-if="receipt.receiptType === 'Compra'" class="itemForm">
						<label class="label" for>Número de Remito:</label>
						<input class="input" @click="hideCalendar" v-model="receipt.dispatchNoteNumber" type="text"
							placeholder="Ingrese número de remito" />
					</div>

					<div class="itemForm">
						<label class="label" for>Fecha: *</label>
						<!-- Mostrar la fecha en formato YYYY-MM-DD en el input -->
						<input readonly class="itemFormReadOnly" :value="formattedInputDate" @click="showHideCalendar"
							type="text" placeholder="Por ej: 2021-01-30" />

						<!-- DatePicker de V-Calendar -->
						<v-row v-if="showCalendar" justify="center">
							<DatePicker v-model="receipt.date" :is-inline="true" :popover="{ visibility: 'click' }">

							</DatePicker>
						</v-row>
					</div>
				</article>
				<article class="formMain article">
					<div class="itemForm select-option">
						<label class="label" for>Categoría *</label>
						<select v-if="receipt.receiptType === 'Gasto'" v-model="categoryId" @click="hideCalendar"
							class="browser-default">
							<option value disabled selected>Seleccione Categoría</option>
							<option v-for="category in categories" :value="category.categoryId" :key="category.name">
								{{ category.name }}
							</option>
						</select>
						<select v-else v-model="categoryId" @click="hideCalendar" class="browser-default">
							<option value disabled selected>Seleccione Categoría</option>
							<option v-for="category in providerCategories" :value="category.categoryId"
								:key="category.name">
								{{ category.name }}
							</option>
						</select>
					</div>
					<div class="itemForm select-option">
						<label class="label" for>Subcategoría</label>
						<select v-if="receipt.receiptType === 'Gasto'" v-model="subCategoryId" class="browser-default">
							<option value disabled selected>Seleccione Subcategoría</option>
							<option v-for="subCategory in subCategories" :value="subCategory.subCategoryId"
								:key="subCategory.name">
								{{ subCategory.name }}
							</option>
						</select>
						<select v-else v-model="subCategoryId" class="browser-default">
							<option value disabled selected>Seleccione Subcategoría</option>
							<option v-for="subCategory in providerSubcategories" :value="subCategory.subCategoryId"
								:key="subCategory.name">
								{{ subCategory.name }}
							</option>
						</select>
					</div>
				</article>
				<article class="formMain article">
					<div v-if="receipt.receiptType === 'Gasto'" class="itemForm">
						<label class="label" for>Monto: *</label>
						<div class="input-group">
							<div class="input-group-icon">{{ getCurrencySymbol }}</div>
							<input @input="handleInputInputEvent(receipt.totalAmount, 'totalAmount')"
								@click="handleInputClick(receipt.totalAmount, 'totalAmount')" ref="totalAmount"
								class="input" v-model="receipt.totalAmount" type="number" :step="getStep"
								placeholder="Ingresar monto total" />
						</div>
					</div>
					<div v-if="receipt.receiptType === 'Gasto'" class="itemForm">
						<label class="label" for>Esta pagado: *</label>
						<div class="switch">
							<label>
								<input v-model="receipt.isPaid" type="checkbox" />
								<span class="lever receipt"></span>
							</label>
						</div>
					</div>
					<div v-if="receipt.receiptType === 'Compra'" class="itemForm">
						<label class="label" for>Monto Neto: *</label>
						<div class="input-group">
							<div class="input-group-icon">{{ getCurrencySymbol }}</div>
							<input @input="handleInputInputEvent(receipt.grossAmount, 'grossAmount')"
								@click="handleInputClick(receipt.grossAmount, 'grossAmount')" ref="grossAmount"
								class="input" v-model="receipt.grossAmount" type="number" :step="getStep"
								placeholder="Ingresar monto neto" />
						</div>
					</div>
					<div v-if="receipt.receiptType === 'Compra'" class="itemForm">
						<label class="label" for>Iva:</label>
						<div class="input-group">
							<div class="input-group-icon">{{ getCurrencySymbol }}</div>
							<input class="input" @input="handleInputInputEvent(receipt.vatAmount, 'vatAmount')"
								@click="handleInputClick(receipt.vatAmount, 'vatAmount')" ref="vatAmount"
								v-model="receipt.vatAmount" type="number" :step="getStep" placeholder="Ingresar IVA" />
						</div>
					</div>
				</article>
				<article v-if="receipt.receiptType === 'Compra'" class="formMain article">
					<div class="itemForm">
						<label class="label" for>Otros impuestos</label>
						<div class="input-group">
							<div class="input-group-icon">{{ getCurrencySymbol }}</div>
							<input class="input"
								@input="handleInputInputEvent(receipt.otherTaxesAmount, 'otherTaxesAmount')"
								@click="handleInputClick(receipt.otherTaxesAmount, 'otherTaxesAmount')"
								ref="otherTaxesAmount" v-model="receipt.otherTaxesAmount" type="number" :step="getStep"
								placeholder="Ingresar otros impuestos" />
						</div>
					</div>
					<div class="itemForm">
						<label class="label" for>Total:</label>
						<div class="input-group">
							<div class="input-group-icon">{{ getCurrencySymbol }}</div>
							<input class="input"
								@input="handleInputInputEvent(receipt.totalAmount, 'purchaseTotalAmount')"
								@click="handleInputClick(receipt.totalAmount, 'purchaseTotalAmount')"
								ref="purchaseTotalAmount" v-model="receipt.totalAmount" type="number" :step="getStep"
								placeholder="Ingresar monto total" />
						</div>
					</div>
					<div class="itemForm">
						<label class="label" for>Esta pagado: *</label>
						<div class="switch">
							<label>
								<input v-model="receipt.isPaid" type="checkbox" />
								<span class="lever receipt"></span>
							</label>
						</div>
					</div>
				</article>
				<article class="formMain article">
					<div class="itemForm itemFormFull">
						<label class="label" for>Comentario</label>
						<textarea v-model="receipt.comments" placeholder="Ingresar comentarios"></textarea>
					</div>
				</article>
			</form>
		</div>
		<div v-if="isNew" class="modal-footer">
			<article class="btn-main">
				<Spinner v-if="isLoading" />
				<a v-else @click="addReceipt" type="submit">
					<p>Crear Comprobante</p>
				</a>
			</article>
		</div>
		<div v-else class="modal-footer">
			<article class="btn-main">
				<a class="deleteProduct" @click.prevent="deleteReceipt">
					<span class="icon-Bistro-_Trash_bin"></span><span>Eliminar Comprobante</span>
				</a>
				<Spinner v-if="isLoading" />
				<a v-else @click="editReceipt" type="submit">
					<p>Guardar Comprobante</p>
				</a>
			</article>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import Spinner from "../components/SpinnerComp";
import commons from "../mixins/mixCommons";
import currencyCommons from "../mixins/currencyCommons";
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';  // Importa los estilos de V-Calendar
export default {
	mixins: [commons, currencyCommons],
	props: {
		shopCode: String,
		providers: {
			type: Array,
			default: () => [],
		},
		categories: {
			type: Array,
			default: () => [],
		},
		newReceiptFilters: {
			type: Object,
			default: () => ({}),
		},
		isNew: {
			type: Boolean,
			default: true,
		},
		modalId: {
			type: String,
			default: "modalProviderReceipt",
		},
	},
	data() {
		return {
			isLoading: false,
			categoryId: -1,
			providerId: null,
			showCalendar: false,
			subCategoryId: null,
			receipt: {
				ticket: null,
				dispatchNoteNumber: null,
				receiptType: "Gasto",
				date: null,
				comments: null,
				isPaid: false,
				totalAmount: 0.0,
				grossAmount: 0.0,
				vatAmount: 0.0,
				otherTaxesAmount: 0.0,
			},
			subCategories: [],
			providerSubcategories: [],
			providerCategories: [],
		};
	},
	watch: {
		showCalendar(newValue) {
			if (newValue) {
				this.setTodayAsDefault();
			}
		},
		receiptType() {
			if (this.receipt.receiptType === "Gasto") {
				if (this.categoryId !== -1) {
					const results = this.categories.filter(x => x.categoryId === this.categoryId);
					if (results.length > 0) this.subCategories = results[0].subCategories;
				}
			} else {
				if (this.providerId !== -1) {
					this.newReceiptFilters.categories
						.filter(x => x.providersIds.includes(this.providerId))
						.forEach(item => {
							this.providerCategories.push({
								categoryId: item.categoryId,
								name: item.name,
								subCategories: item.subCategories,
							});
						});
				}
				if (this.categoryId !== -1) {
					const results = this.providerCategories.filter(x => x.categoryId === this.categoryId);
					if (results.length > 0) this.providerSubcategories = results[0].subCategories;
				}
			}
		},
		categoryId() {
			if (this.receipt.receiptType === "Gasto") {
				if (this.categoryId === -1) {
					this.subCategories = [];
				} else {
					const results = this.categories.filter(x => x.categoryId === this.categoryId);
					if (results.length > 0) this.subCategories = results[0].subCategories;
				}
			} else {
				if (this.categoryId === -1) {
					this.providerSubcategories = [];
				} else {
					const results = this.providerCategories.filter(x => x.categoryId === this.categoryId);
					if (results.length > 0) this.providerSubcategories = results[0].subCategories;
				}
			}
		},
		providerId() {
			this.providerSubcategories = [];
			this.providerCategories = [];
			this.newReceiptFilters.categories
				.filter(x => x.providersIds.includes(this.providerId))
				.forEach(item => {
					this.providerCategories.push({
						categoryId: item.categoryId,
						name: item.name,
						subCategories: item.subCategories,
					});
				});

			if (this.categoryId !== -1) {
				const results = this.providerCategories.filter(x => x.categoryId === this.categoryId);
				if (results.length > 0) this.providerSubcategories = results[0].subCategories;
			}
		},
		currentReceipt() {
			if (!this.isNew) {
				this.isLoading = false;
				this.receipt.ticket = this.currentReceipt.ticketNumber;
				this.receipt.receiptType = this.currentReceipt.type;
				// Ajustar la fecha para evitar desfases
				let date = new Date(this.currentReceipt.date);
				date.setHours(date.getHours() + 24); // Ajustar la fecha a medianoche local
				this.receipt.date = date;
				this.receipt.comments = this.currentReceipt.comments;
				this.receipt.totalAmount = this.currentReceipt.total;
				this.receipt.grossAmount = this.currentReceipt.grossAmount;
				this.receipt.vatAmount = this.currentReceipt.tax;
				this.receipt.id = this.currentReceipt.id;
				this.receipt.otherTaxesAmount = this.currentReceipt.otherTaxes;
				this.receipt.dispatchNoteNumber = this.currentReceipt.dispatchNoteNumber;
				this.providerId = this.currentReceipt.provider;
				this.receipt.isPaid = this.currentReceipt.isPaid;
				setTimeout(() => {
					this.categoryId = this.currentReceipt.category;
					this.subCategoryId = this.currentReceipt.subCategory;
				}, 500);
			}
		},
		isNew(newVal) {
			if (newVal) {
				this.showCalendar = false;
				this.cleanInputs();
			}
			this.showCalendar = false;
		},
	},
	computed: {
		receiptType() {
			return this.receipt.receiptType;
		},
		currentReceipt() {
			return this.$store.getCurrentReceipt;
		},
		getReceiptName() {
			return this.receipt.receiptType === "Gasto" ? `Ticket: *` : `Número de Factura: *`;
		},

		formattedInputDate() {
			if (!this.receipt.date) return '';
			const date = this.receipt.date;
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			return `${year}-${month}-${day}`;
		},

	},
	methods: {
		openModal() {
			this.showCalendar = false;
		},
		setTodayAsDefault() {
			if (!this.receipt.date) {
				this.receipt.date = new Date();
			}
		},
		showHideCalendar() {
			this.showCalendar = !this.showCalendar;
		},
		hideCalendar() {
			this.showCalendar = false;
		},
		handleInputClick(number, input) {
			this.hideCalendar();
			if (number.toString() === "0") {
				switch (input) {
					case "totalAmount":
						this.receipt.totalAmount = "";
						break;
					case "grossAmount":
						this.receipt.grossAmount = "";
						break;
					case "vatAmount":
						this.receipt.vatAmount = "";
						break;
					case "otherTaxesAmount":
						this.receipt.otherTaxesAmount = "";
						break;
					case "purchaseTotalAmount":
						this.receipt.totalAmount = "";
						break;
				}
			}
		},
		handleInputInputEvent(number, input) {
			let indexOfDecimalPoint = number.toString().lastIndexOf(`.`);
			if (indexOfDecimalPoint === -1) {
				indexOfDecimalPoint = number.toString().lastIndexOf(`,`);
			}
			if (indexOfDecimalPoint !== -1) {
				if (number.toString().substring(indexOfDecimalPoint + 1).length > 2) {
					const integerPart = number.toString().substring(0, indexOfDecimalPoint);
					const processedNumber = `${integerPart}${number
						.toString()
						.substring(indexOfDecimalPoint, 3 + integerPart.length)}`;

					switch (input) {
						case "totalAmount":
							this.receipt.totalAmount = parseFloat(processedNumber);
							break;
						case "grossAmount":
							this.receipt.grossAmount = parseFloat(processedNumber);
							break;
						case "vatAmount":
							this.receipt.vatAmount = parseFloat(processedNumber);
							break;
						case "otherTaxesAmount":
							this.receipt.otherTaxesAmount = parseFloat(processedNumber);
							break;
						case "purchaseTotalAmount":
							this.receipt.totalAmount = parseFloat(processedNumber);
							break;
					}
				}
			}
		},
		onRadioButtonChange(val) {
			this.receipt.receiptType = val;
		},
		closeModal() {
			this.cleanInputs();
		},
		cleanInputs() {
			this.receipt = {
				ticket: null,
				dispatchNoteNumber: null,
				receiptType: "Gasto",
				date: null,
				comments: null,
				totalAmount: 0.0,
				isPaid: false,
				grossAmount: 0.0,
				vatAmount: 0.0,
				otherTaxesAmount: 0.0,
			};
		},
		processAmounts() {
			if (this.receipt.totalAmount === null || this.receipt.totalAmount === "") {
				this.receipt.totalAmount = 0;
			}
			if (isNaN(this.receipt.totalAmount)) {
				this.showError(`El monto Total debe ser numérico`);
				return;
			}
			if (this.receipt.otherTaxesAmount === null || this.receipt.otherTaxesAmount === "") {
				this.receipt.otherTaxesAmount = 0;
			}
			if (isNaN(this.receipt.otherTaxesAmount)) {
				this.showError(`El monto Otros Impuestos debe ser numérico`);
				return;
			}
			if (this.receipt.grossAmount === null || this.receipt.grossAmount === "") {
				this.receipt.grossAmount = 0;
			}
			if (isNaN(this.receipt.grossAmount)) {
				this.showError(`El monto neto debe ser numérico`);
				return;
			}
			if (this.receipt.vatAmount === null || this.receipt.vatAmount === "") {
				this.receipt.vatAmount = 0;
			}
			if (isNaN(this.receipt.vatAmount)) {
				this.showError(`El monto de IVA debe ser numérico`);
			}
		},
		editReceipt() {
			this.isLoading = true;
			let receipt = {
				category: this.categoryId,
				subCategory: this.subCategoryId,
				provider: this.providerId,
				type: this.receipt.receiptType,
				ticketNumber: this.receipt.ticket,
				date: `${this.receipt.date.getFullYear()}-${(this.receipt.date.getMonth() + 1).toString().padStart(2, '0')}-${this.receipt.date.getDate().toString().padStart(2, '0')}`,
				comments: this.receipt.comments,
				total: parseFloat(this.receipt.totalAmount),
				grossAmount: parseFloat(this.receipt.grossAmount),
				tax: parseFloat(this.receipt.vatAmount),
				id: this.receipt.id,
				otherTaxes: parseFloat(this.receipt.otherTaxesAmount),
				dispatchNoteNumber: this.receipt.dispatchNoteNumber,
				isPaid: this.receipt.isPaid,
			};
			if (this.receipt.receiptType === "Gasto") {
				receipt.provider = 0;
				receipt.otherTaxes = 0;
				receipt.tax = 0;
			}
			console.log('ecitreceipt', receipt);
			this.$emit("onEditingReceipt", receipt);
		},
		deleteReceipt() {
			this.isLoading = true;
			this.$emit("onDeletingReceipt", this.receipt.id);
		},
		addReceipt() {
			this.isLoading = true;
			this.processAmounts();

			if (this.receipt.ticket === null || this.receipt.ticket === "") {
				this.showError(`El campo ${this.getReceiptName} es obligatorio`);
				this.isLoading = false;
				return;
			}
			if (this.receipt.date === null || this.receipt.date === "") {
				this.showError(`El campo Fecha es obligatorio`);
				this.isLoading = false;
				return;
			}
			if (this.categoryId === null || this.categoryId === "" || this.categoryId < 1) {
				this.showError(`El campo Categoría es obligatorio`);
				this.isLoading = false;
				return;
			}

			let adjustedDate = new Date(this.receipt.date);
			adjustedDate.setHours(12);

			const req = {
				type: this.receipt.receiptType === "Gasto" ? 1 : 0,
				shopCode: this.shopCode,
				total: parseFloat(this.receipt.totalAmount),
				isPaid: this.receipt.isPaid,
				otherTaxes: parseFloat(this.receipt.otherTaxesAmount),
				grossAmount: parseFloat(this.receipt.grossAmount),
				tax: parseFloat(this.receipt.vatAmount),
				ticketNumber: this.receipt.ticket,
				dispatchNoteNumber: this.receipt.dispatchNoteNumber,
				date: adjustedDate, // Usa la fecha ajustada
				categoryId: this.categoryId,
				subCategoryId: this.subCategoryId,
				comments: this.receipt.comments,
				providerId: this.providerId,
			};
			this.$store.initLoading(this);

			axios
				.post(`/api/providersV2/insertProviderReceipt`, req)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.showSuccess(`Comprobante agregado exitosamente`);
						this.cleanInputs();
						this.$emit("onReceiptCreated");
					} else {
						this.showErrors(res);
					}

					this.$store.finishLoading(this);
					this.isLoading = false;
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
					this.isLoading = false;
				});
		},
	},
	components: {
		Spinner,
		DatePicker,
	},
};
</script>
<style>
/* Estilo general del DatePicker */
.vc-container {
	background-color: #ffffff !important;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	padding: 10px;
}

.vc-pane-header-wrapper {
	background-color: #24a4d354;
	border-radius: 8px;
	color: rgb(0, 0, 0) !important;
}

.vc-header {
	margin-bottom: 10px;
}

.vc-day {
	font-size: 14px;
	color: black;
	background-color: white !important;
	border-radius: 5px;
}

.vc-day-selected {
	background-color: #24a5d3 !important;
	color: white !important;
}

.vc-day:hover {
	background-color: white !important;
	color: #24a5d3 !important;
	cursor: default;

}

.vc-title-wrapper .vc-title span {
	color: rgb(0, 0, 0) !important;
	font-size: 20px !important;
	text-transform: uppercase !important;
}

.vc-title-wrapper span:hover {
	color: rgb(0, 0, 0) !important;
	font-size: 20px !important;

}

.vc-title-wrapper span {
	font-size: 20px !important;

}

.vc-title-wrapper .vc-title {
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
	padding: 0 !important;
}

.vc-title-wrapper .vc-title span {
	color: black !important;
	font-size: 20px !important;
	text-transform: uppercase !important;
}

.vc-title-wrapper .vc-title:hover {
	background-color: transparent !important;

}
</style>

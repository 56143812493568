<template>
    <!-- CONTENIDO -->
    <section class="mainContain-info">
        <!-- FILTRADO DE LA SECCION -->
        <article class="mainFilter filterEvent">
            <!-- TITULO Y SEARCH -->
            <section class="mainFilter-title">
                <h1>Ranking de Ventas</h1>

            </section>

            <!-- OPCIONES ADICIONALE -->
            <section class="mainFilter-filter">
                <!-- DESCARGAR EXCEL -->


                <a v-if="mobile == false" :href="`/api/report/sales_rankingV2/?${currentQueryString}`" target="_blank"
                    class="waves-effect waves-light btn-link">
                    <span class="icon-Bistro-_Download"></span>
                    <span>Descargar Detalle</span>
                </a>
            </section>
        </article>

        <!-- LISTADO DE PRODUCTOS -->
        <article class="mainEvents salesDashboard">
            <!-- TIPO DE TRANSACCION -->
            <section :class="{ 'active': showFilter }" class="mainEvents-filter FilterMobile">
                <div class="containTransaction FilterMobile active">

                    <!-- SEARCH -->
                    <div class="searchDashboard">
                        <!-- MENSAJE -->
                        <div class="itemMessage">
                            <div class="text">
                                <h5>Filtra tus ventas</h5>
                                <p>Por código</p>
                            </div>
                            <a @click="search = !search" v-if="!search" id="btnSearchSales">
                                <span class="icon-Bistro-_Search"></span>
                            </a>
                        </div>

                        <!-- INPUT -->
                        <div v-if="search" class="item">
                            <a @click="search = !search" id="btnCloseSearch">
                                <span class="icon-Bistro-_Close"></span>
                            </a>

                            <input v-model="criteria" type="text" class="inputFilter"
                                placeholder="Buscar por Código ...">
                        </div>
                    </div>

                    <!-- TIPOS DE PAGOS -->
                    <ul class="containTransaction-lists">
                        <!-- Titulo -->
                        <li>
                            <h4>Categorías</h4>
                            <div class="switch">
                                <label>
                                    <input v-model="categoryFilters" @change="avoidRectangleAtTheEnd" type="checkbox">
                                    <span class="lever"></span>
                                </label>
                            </div>
                        </li>

                        <!-- OPCION 1 -->
                        <li v-for="(item, index) in departmentsFilter" :key="index">
                            <p>{{ item.department }}</p>
                            <div class="switch">
                                <label>
                                    <input :checked="item.isChecked"
                                        @change="chargeFilterCategory(index, !item.isChecked)" type="checkbox">
                                    <span class="lever"></span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>

                <!-- BTN ACTIVE FILTER MOBILE -->
                <button @click="showFilter = !showFilter" class="btnFilterMobile">
                    <span class="icon-Bistro-_Filter"></span>
                </button>
            </section>

            <!-- MENSAJE ERROR -->
            <div v-if="reports.length === 0 && loaded" class="mainEvents-history rankingVentas reportError">
                <h4>No hay reportes de ranking de ventas</h4>
            </div>


            <!-- LISTADO DE FACTURAS -->
            <ul v-if="reports.length > 0" class="mainEvents-history rankingVentas">
                <!-- Historial 1 -->
                <li v-for="(item, index) in reports" :key="index">
                    <figure class="iconHistory">
                        <span :class="{ 'icon-Bistro-_Check': true }"></span>
                    </figure>

                    <!-- FECHA -->
                    <div class="items">
                        <h5>STOCK</h5>
                        <p>{{ item.stockSold }}</p>
                    </div>

                    <!-- OPERACION -->
                    <div class="items">
                        <p>{{ item.productoNombre }}</p>
                    </div>

                    <!-- DESCRIPCION -->
                    <!-- <div class="items">
						<p>Vendidos: {{ item.quantity }}</p>
					</div> -->
                    <div class="items">
                        <p>Vendidos: {{ item.quantity }}</p>
                        <p>Consumidos: {{ item.consumedQuantity }}</p>
                    </div>
                    <!-- TOTAL -->
                    <div class="items tooltip">
                        <h3><currency-style :valor="$filters.currency(item.monto)" /></h3>
                        <p class="tooltiptext">No contempla movimientos de invitaciones ni consumo empleados</p>
                    </div>
                </li>
            </ul>
            <article class="mainEvents-history rankingVentas">
                <article v-for="i in 4" :key="i" :class="sectionLoading">
                </article>
            </article>


        </article>


        <help-center />
    </section>
</template>
<script>
import commons from '@/mixins/mixCommons'
import authCommons from '@/mixins/authCommons'
import rolesCommons from '@/mixins/rolesCommons'
import bistroV2Commons from '@/mixins/bistroV2Commons'
import computeds from '@/mixins/menuOptions'
import selectedPdvs from '@/mixins/select_pdvs_get_data'
import filterCategoryMixin from '@/mixins/filterCategory'
import HelpCenter from '@/components/HelpCenter'
import { parseToQuery } from '@/utils/utils'
import CurrencyStyle from '@/components/CurrencyStyle'
import mixCommons from "@/mixins/mixCommons";
import axios from 'axios'

export default {
    mixins: [authCommons, commons, computeds, selectedPdvs, rolesCommons, bistroV2Commons, mixCommons, filterCategoryMixin],
    data() {
        return {
            departmentsFilter: [],
            categoryFilters: true,
            params: {
                FilterCategory: []
            },
            filterInterval: null,
            departments: [],
            search: false,
            showFilter: false,
            loaded: false,
            currentQueryString: '',
            reports: [],
            criteria: '',
            category: '',
        }
    },
    async mounted() {
        document.title = `${this.appTitle} - Ranking Ventas`
        this.$store.setHasMounted(true)
        if (this.isUserLoggedIn() === false) {
            axios.get('/api/auth/logout') /// OK
                .then(() => {
                    this.$store.SET_USER(null)
                    this.resetUser()
                    this.setAutomaticLogin(false)
                    location.href = '/login'
                })
        } else if (this.hasToShowThisOption(2) === false) {
            this.handleUnauthorizedAccess()

        } else {
            if (await this.checkSessionInBackendAsync()) {
                this.onMounted()
            }

        }

    },
    watch: {
        criteria() {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.params['Code'] = this.criteria
                this.getData()
            }, 500)
        },
        categoryFilters() {
            this.departmentsFilter.forEach((d, index) => {
                this.chargeFilterCategory(index, this.categoryFilters)
            })
        },
        category() {
            if (this.category !== '') {
                this.params['DepartamentName'] = this.category
            } else {
                delete this.params['DepartamentName']
            }
            this.getData()
        },
        dateCalendar() {
            if (this.dateCalendar.indexOf('Seleccione') < 0) {
                this.params['Period'] = this.tab.param
                this.getData()
            }
        },
        tab() {
            if (this.tab.id !== 6 && this.loaded) {
                this.params['Period'] = this.tab.param
                this.getData()
            }
        },
    },
    methods: {
        async onMounted() {
            this.$store.setLoadingSkeleton(true)
            this.$store.hideElements({
                hideCalendar: false,
                hideFilters: false,
                hideTabs: false
            })

            this.$store.updateCalendarType('range')
            if (this.getPeriodSelectionFromLocalStorage() !== null) {
                await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
                this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
            } else {
                await this.$store.initTabs()
            }
            this.$store.setHasMounted(true)

            this.getData()
        },
        getData() {
            if (!this.$store.hasMounted) return
            
            let ids = this.selectedPdvs.map(item => {
                return item.shopCode
            })
            if (ids.length > 0) {
                this.params['MerchantFilter'] = ids
            } else {
                delete this.params['MerchantFilter']
            }
            this.currentQueryString = parseToQuery(this.params)

            clearInterval(this.filterInterval)
            this.filterInterval = setInterval(() => {
                clearInterval(this.filterInterval)

                this.$store.initLoading(this)
                axios.get(`/api/report/salesDataV2?${parseToQuery(this.params)}`)
                    .then(res => {
                        this.$store.finishLoading(this)
                        this.$store.setLoadingSkeleton(false)

                        this.loaded = true
                        if (res.data.responseCode === 0) {
                            this.reports = res.data.saleItems
                            this.departments = res.data.categories
                            this.setupSwitches(res.data.categories)
                            return
                        } else {
                            this.showError(res.data.responseMessage)
                        }

                    })
                    .catch(err => {
                        this.$store.errorLoading(this)
                        this.showErrors(err.response)
                    })
            }, 1000)


        },
        // setupSwitches(categories) {
        //     this.departmentsFilter = []
        //     categories.forEach((item, index) => {
        //         this.departmentsFilter.push({
        //             isChecked: this.isCategoryFiltered(index),
        //             department: item
        //         })
        //     })
        // },
        // chargeFilterCategory(categoryIndex, bool) {
        //     this.departmentsFilter[categoryIndex].isChecked = bool

        //     var index = this.params.FilterCategory.indexOf(categoryIndex)
        //     if (bool) {
        //         this.params.FilterCategory.splice(index, 1)
        //     } else if (index < 0) {
        //         this.params.FilterCategory.push(categoryIndex)
        //     }

        //     this.getData()
        // },
        // isCategoryFiltered(categoryIndex) {
        //     var index = this.params.FilterCategory.indexOf(categoryIndex)
        //     if (index === -1) return true
        //     else return false
        // }
    },
    components: {
        CurrencyStyle,
        HelpCenter
    },
}
</script>

<style scoped>
.skeleton-card {
    margin-bottom: 10px;
}
</style>
<template>
  <div id="ModalOfConfirmationToRemove" class="modal modal-block-outside" style="overflow-y: auto !important"
    @keydown.esc="closeModal()">
    <div class="modal-header" v-show="objectToRemove !== null">
      <div class="image-title">
        <h4>Eliminar {{ getTitle }}</h4>
        <p>Fácil y Simple</p>
      </div>

      <!-- CLOSE -->
      <div class="btnClose">
        <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <article class="formMain">
          <div class="modal-header-product">
            <p>
              Usted está eliminando {{ getDetailToRemove }}.
              <strong class="advice-red">Luego de esta acción no podrá retornar al estado
                anterior.</strong>
            </p>
            <p>¿Está seguro que desea hacerlo?</p>
          </div>
        </article>
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <Spinner v-if="isLoading" />
        <a v-else @click="confirmRemove" type="submit">
          <p>{{ buttonAction }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Spinner from "../components/SpinnerComp";

export default {
  props: {
    objectToRemove: {
      type: Object,
      default: () => {
        return { object: null, index: null, type: "" };
      },
    },
  },
  data() {
    return {
      buttonAction: "Eliminar",
      isLoading: false,
    };
  },
  computed: {
    getCurrentShopTheme() {
      return this.$store.getCurrentShopTheme;
    },
    getMenuLabel() {
      switch (this.getCurrentShopTheme) {
        default:
        case 1:
          return "una carta";
        case 2:
          return "un catálogo";
      }
    },
    getDetailToRemove() {
      if (this.objectToRemove === null) return "";

      switch (this.objectToRemove.type) {
        case "Carta":
          return "una carta";
        case "Producto":
          return "un producto";
        case "Adicional":
          return "un adicional";
        case "Gusto":
          return "un gusto";
        case "Categoría":
          return "una categoría";
        case "Combo":
          return "un combo";
        case "Ingrediente":
          return "un ingrediente";
        default:
          return "";
      }
    },
    getTitle() {
      if (this.objectToRemove === null) return "";

      switch (this.objectToRemove.type) {
        case "Carta":
          switch (this.getCurrentShopTheme) {
            default:
            case 1:
              return "Carta";
            case 2:
              return "Catálogo";
          }
        default:
          return this.objectToRemove.type;
      }
    },
  },
  methods: {
    confirmRemove() {
      this.isLoading = true;
      let confirmRemoveHandler = setInterval(() => {
        clearInterval(confirmRemoveHandler);
        this.$emit("confirmRemove");
        this.isLoading = false;
      }, 500);
    },
    closeModal() {
      $("#__layout").css("pointer-events", "all");
    },
  },
  components: {
    Spinner,
  },
};
</script>

<template>
  <div v-html="parse"></div>
</template>
<script>
import currencyCommons from "../mixins/currencyCommons";

export default {
  mixins: [currencyCommons],
  props: {
    valor: {
      type: String,
      default: "0,00",
    },
  },
  data() {
    return {
      parse: "",
    };
  },
  mounted() {
      this.parse = this.getParse(this.valor);
  },
  watch: {
    valor() {
        this.parse = this.getParse(this.valor);
    },
  },

};
</script>

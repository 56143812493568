<template>
	<div id="modalCost" class="modal modal-block-outside" style="overflow-y: auto !important"
		@keydown.esc="closeModal()">
		<div class="modal-header">
			<div class="image-title">
				<h4>Editar Costo</h4>
				<p>Fácil y Simple</p>
			</div>

			<div class="btnClose">
				<a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<div v-if="cost" class="modal-body">
			<form action class="formGeneral">
				<article class="formMain article-product">
					<div class="itemForm">
						<label class="label" for>Nombre:</label>
						<input class="input" v-model="cost.name" type="text" disabled />
					</div>

					<div class="itemForm itemFormForMobile">
						<label for>SKU:</label>
						<input v-model="cost.sku" type="text" disabled />
					</div>

					<!-- START COSTO FIJO -->
					<section class="cost-section">
						<div class="cost-section-row">
							<div class="cost-col-2">
								<!-- <badge type="new" /> -->
							</div>
							<div class="switch">
								<label>
									<input :checked="getCostTypeValue(costTypes[0].key)"
										@change="onChangeCostType(costTypes[0].key)" type="checkbox" />
									<span class="lever lever-width"></span>
								</label>
							</div>
							<div class="label">{{ costTypes[0].label }}</div>
						</div>

						<div class="cost-section-row">
							<div class="cost-col-3"></div>
							<article class="cost-section-row-panel"
								v-show="cost.itemCost.costType === costTypes[0].key">
								<div class="cost-form">
									<div class="itemForm">
										<label class="cost-title" for>Costo declarado del producto por
											{{ getMeasureUnit }}:</label>
										<input class="input" v-model="cost.itemCost.cost" type="number" step="0.01" />
									</div>

									<div class="itemFormPrice itemFormPriceWithUllage">
										<a>
											<currency-style :valor="$filters.currency(cost.itemCost.cost || '0')" />
											<div style="font-size:12px;"
												v-if="isIngredient && cost.ullagePercentage && cost.ullagePercentage > 0">
												- {{ cost.ullagePercentage }}% de merma =

											</div>
											<currency-style
												v-if="isIngredient && cost.ullagePercentage && cost.ullagePercentage > 0"
												:valor="$filters.currency(getCostWithUllage(cost))" />
										</a>
									</div>
								</div>
								<div v-if="isIngredient" class="cost-form">
									<div class="itemForm">
										<label class="label" for>% Merma:</label>
										<input class="input" v-model="cost.ullagePercentage" type="number" />
									</div>
								</div>
							</article>
						</div>
					</section>
					<!-- END COSTO FIJO -->
					<!-- START COSTO X INGREDIENTES -->
					<section class="cost-section">
						<div class="cost-section-row">
							<div class="cost-col-2">
								<!-- <badge type="new" /> -->
							</div>
							<div class="switch">
								<!-- <label style="display: inline-flex;"> -->
								<label>
									<input :checked="getCostTypeValue(costTypes[1].key)"
										@change="onChangeCostType(costTypes[1].key)" type="checkbox" />
									<span class="lever lever-width"></span>
								</label>
							</div>
							<div class="label">{{ costTypes[1].label }}
								<div>Los ingredientes se rebajarán automáticamente del stock al momento de hacer la
									venta del producto</div>
							</div>
						</div>
						<div class="cost-section-row">
							<div class="cost-col-3"></div>
							<article class="cost-section-row-panel"
								v-show="cost.itemCost.costType === costTypes[1].key">
								<div class="cost-form">
									<div class="itemForm">
										<label class="cost-title" for>Costo calculado del producto por
											{{ getMeasureUnit }}:</label>
									</div>
									<div class="itemFormPrice">
										<a><currency-style
												:valor="$filters.currency(getTotalCostBySelectedIngredients)" /></a>
									</div>
								</div>

								<article v-for="(ingredient, index) in cost.itemCost.recipe" :key="index"
									class="itemForm selectImage cost-ingredient-row">
									<div class="itemFormOrigins">
										<a>{{ ingredient.name }}</a>
									</div>
									<div class="itemFormPrice">
										<a><currency-style :valor="$filters.currency(ingredient.cost)" /></a>
										<div>
											{{ getIngredientMeasureUnit(ingredient.measureUnit) }}
										</div>
									</div>
									<div class="itemFormMenu">
										<label>Cantidad:</label>
										<div class="itemFormMenuAmount">
											<input v-model="ingredient.quantity" type="number"
												@wheel="$event.target.blur()" placeholder="Cantidad" step="0.001" />
										</div>
									</div>
									<div class="itemFormTrash">
										<a class="deleteProduct" @click.prevent="deleteIngredient(ingredient)">
											<span class="icon-Bistro-_Trash_bin"></span>
										</a>
									</div>
								</article>

								<div class="itemForm selectImage">
									<select v-if="getAvailableIngredients.length > 0"
										@change="onSelectIngredient($event)" class="browser-default"
										v-model="ingredientIndexSelection">
										<option :value="ingredientIndexSelection" selected>
											Agregar ingrediente
										</option>
										<option v-for="(
												ingredient, index
											) in getAvailableIngredients.sort((a, b) =>
			a.name.localeCompare(b.name)
		)" :value="index" :key="index">
											{{ ingredient.name }}
										</option>
									</select>
								</div>
							</article>
						</div>
					</section>
					<!-- END COSTO X INGREDIENTES -->
					<production-cost-recipe-selector :cost="cost" :ingredients="ingredients" />
				</article>
			</form>
		</div>

		<div class="modal-footer">
			<article class="btn-main">
				<Spinner v-if="isLoading" />
				<a v-else @click="upsertCost" type="submit">
					<p>Guardar Costo</p>
				</a>
			</article>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import CurrencyStyle from "../components/CurrencyStyle";
import Spinner from "../components/SpinnerComp";
import ProductionCostRecipeSelector from "../components/ProductionCostRecipeSelector";
import currencyCommons from "../mixins/currencyCommons.vue";
import $ from "jquery";
import recipeSelectorCommons from "../mixins/recipeSelectorCommons.vue";
import { toast } from "vue3-toastify";

export default {
	mixins: [currencyCommons, recipeSelectorCommons],
	props: {
		ingredients: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	data() {
		return {
			isLoading: false,
			costTypes: [
				{ key: 0, label: "Costo fijo" },
				{ key: 1, label: "Costo de los ingredientes" },
			],
			ingredientIndexSelection: -1,
			cost: null
		};
	},
	watch: {
		getCurrentCost(value) {
			this.$nextTick(() => {
				this.cost = value;
			});
		}
	},
	computed: {
		getCurrentCost() {
			return this.$store.getCurrentCost;
		},
		isIngredient() {
			return this.cost.productType == 2;
		},
		getAvailableIngredients() {
			if (
				this.ingredients === null ||
				this.ingredients === undefined ||
				this.ingredients.length < 1
			)
				return [];

			let resp = [...this.ingredients];
			if (this.cost === undefined || this.cost === null) {
				return resp;
			}
			if (
				this.cost.itemCost.recipe === undefined ||
				this.cost.itemCost.recipe === null ||
				this.cost.itemCost.recipe.length < 1
			) {
				return this.filterSameIngredientWeAreEditing();
			}
			resp = this.filterSameIngredientWeAreEditing();
			this.cost.itemCost.recipe.forEach((x) => {
				const index = resp.findIndex((t) => t.sku === x.sku);
				if (index > -1) {
					resp.splice(index, 1);
				}
			});

			return resp;
		},
		getTotalCostBySelectedIngredients() {
			let totalCost = 0;
			if (this.cost === undefined || this.cost === null)
				return totalCost.toString();
			if (
				this.cost.itemCost.recipe === undefined ||
				this.cost.itemCost.recipe === null ||
				this.cost.itemCost.recipe.length < 1
			)
				return totalCost.toString();

			this.cost.itemCost.recipe.forEach((x) => {
				totalCost += x.quantity * x.cost;
			});

			return totalCost.toString();
		},
	},
	methods: {
		getCostWithUllage(cost) {
			return (cost?.itemCost?.cost ?? 0) * (1 + (cost?.ullagePercentage ?? 0) / 100);

		},
		deleteIngredient(ingredient) {
			const index = this.cost.itemCost.recipe.indexOf(ingredient);
			if (index >= 0) {
				this.cost.itemCost.recipe.splice(index, 1);
			}
		},
		onSelectIngredient(event) {
			let index = event.target.value;
			if (index === "-1") return;

			const i = this.getAvailableIngredients[index];

			if (
				this.cost.itemCost.recipe === null ||
				this.cost.itemCost.recipe === undefined
			) {
				this.cost.itemCost.recipe = [];
			}

			this.cost.itemCost.recipe.push({
				ingredientId: i.id,
				name: i.name,
				cost: i.cost + (i.cost * (i.ullagePercentage ?? 0) / 100),
				measureUnit: i.measureUnit,
				sku: i.sku,
				quantity: "0",
			});

			this.$nextTick(() => {
				// Se asegura que Vue proceso todas las actualizaciones reactivas pendientes antes de reiniciar el selector
				this.ingredientIndexSelection = -1;
			});
		},
		getCostTypeValue(key) {
			if (this.cost === undefined || this.cost === null) return false;

			return this.cost.itemCost.costType == key;
		},
		onChangeCostType(key) {
			if (this.cost.itemCost.costType === key) this.cost.itemCost.costType = -1;
			else this.cost.itemCost.costType = key;
		},
		closeModal() {
			this.cost = null;
			$("#__layout").css("pointer-events", "all");
		},
		upsertCost() {
			const t = this;
			t.isLoading = true;
			let handler = setInterval(() => {
				clearInterval(handler);
				try {
					if (
						t.cost.productType == 2 &&
						t.cost.itemCost.costType === 0 &&
						(t.cost.itemCost.cost == "" || t.cost.itemCost.cost == null)
					) {
						toast.error(
							"El costo fijo de un ingrediente no puede ser vacío"
						);
						t.isLoading = false;
					} else if (
						t.cost.productType == 2 &&
						t.cost.itemCost.costType === 0 &&
						(t.cost.ullagePercentage === "" || t.cost.ullagePercentage == null)
					) {
						toast.error("La merma de un ingrediente no puede ser vacío");
						t.isLoading = false;
					} else if (
						t.cost.productType == 2 &&
						t.cost.itemCost.costType === 0 &&
						(parseFloat(t.cost.ullagePercentage) < 0 ||
							parseFloat(t.cost.ullagePercentage) > 100)
					) {
						toast.error(
							"La merma de un ingrediente debe estar entre 0% y 100%"
						);
						t.isLoading = false;
					} else if (
						t.cost.itemCost.costType === 0 &&
						t.cost.itemCost.cost < 0
					) {
						toast.error(
							"El costo fijo de un producto debe ser mayor a cero"
						);
						t.isLoading = false;
					} else if (
						t.cost.productType == 0 &&
						t.cost.itemCost.costType === 0 &&
						(t.cost.itemCost.cost == "" || t.cost.itemCost.cost == null)
					) {
						toast.error("El costo fijo de un producto no puede ser vacío");
						t.isLoading = false;
					} else if (
						t.cost.productType == 1 &&
						t.cost.itemCost.costType === 0 &&
						(t.cost.itemCost.cost == "" || t.cost.itemCost.cost == null)
					) {
						toast.error(
							"El costo fijo de un adicional no puede ser vacío"
						);
						t.isLoading = false;
					} else if (
						t.cost.itemCost.costType === 1 &&
						!t.cost?.itemCost?.recipe?.length
					) {
						toast.error(
							"Es necesario seleccionar, al menos, un ingrediente para calcular su costo"
						);
						t.isLoading = false;
					} else if (
						t.cost.itemCost.costType === 2 &&
						!t.cost?.itemCost?.primaryProductionRecipe?.length &&
						!t.cost?.itemCost?.alternateProductionRecipe?.length
					) {
						toast.error(
							"Es necesario seleccionar, al menos, un ingrediente para costo de producción"
						);
						t.isLoading = false;
					} else if (
						t.cost.itemCost.costType === 2 &&
						!t.cost?.itemCost?.primaryProductionRecipe?.length &&
						t.cost?.itemCost?.alternateProductionRecipe?.length
					) {
						toast.error(
							"Es necesario seleccionar al menos algún ingrediente en la receta primaria de costo de producción para poder guardar una receta alternativa"
						);
						t.isLoading = false;
					} else {
						if (t.cost.itemCost.cost) {
							t.cost.itemCost.cost = t.cost.itemCost.cost.toString();
						}

						if (
							t.cost.itemCost.recipe !== undefined &&
							t.cost.itemCost.recipe !== null &&
							t.cost.itemCost.recipe.length > 0
						) {
							t.cost.itemCost.recipe.forEach(element => {
								element.quantity = element.quantity.toString();
							});
						}

						if (
							t.cost.itemCost.primaryProductionRecipe !== undefined &&
							t.cost.itemCost.primaryProductionRecipe !== null &&
							t.cost.itemCost.primaryProductionRecipe.length > 0
						) {
							t.cost.itemCost.primaryProductionRecipe.forEach(element => {
								element.quantity = element.quantity.toString();
							});
						}

						if (
							t.cost.itemCost.alternateProductionRecipe !== undefined &&
							t.cost.itemCost.alternateProductionRecipe !== null &&
							t.cost.itemCost.alternateProductionRecipe.length > 0
						) {
							t.cost.itemCost.alternateProductionRecipe.forEach(element => {
								element.quantity = element.quantity.toString();
							});
						}

						let request = {
							stockProductId: t.cost.stockProductId,
							productType: t.cost.productType,
							ullagePercentage: Number(t.cost.ullagePercentage),
							itemCost: t.cost.itemCost,
						};

						axios
							.post(`/api/costV2`, request)
							.then((res) => {
								t.isLoading = false;
								if (res.data.responseCode == 0) {
									t.cost = null;
									t.$emit("upsertCost");
								} else {
									console.error(res.data);
								}
							})
							.catch((err) => {
								t.isLoading = false;
								console.error(err.response);
							});
					}
				} catch (err) {
					console.error(err);
					t.isLoading = false;
				} finally {
					// t.isLoading = false
				}
			}, 500);
		}
	},
	components: {
		CurrencyStyle,
		Spinner,
		ProductionCostRecipeSelector,
	},
};
</script>
<style lang="stylus">
.show-div {
	display: block;
}

.hide-div {
	display: none;
}
</style>

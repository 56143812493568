<template>
  <div
    id="modalIngredient"
    class="modal modal-block-outside"
    style="overflow-y: auto !important"
    @keydown.esc="closeModal()"
  >
    <div class="modal-header">
      <div class="image-title">
        <h4>{{ getModalTitle }}</h4>
        <p>Fácil y Simple</p>
      </div>

      <div class="btnClose">
        <a
          href="#!"
          @click="closeModal()"
          class="modal-action modal-close waves-effect waves-green btn-flat"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <article class="formMain article-product">
          <div class="itemForm">
            <label class="label" for>Nombre del Ingrediente:</label>
            <input
              class="input"
              v-model="
                /* eslint-disable */ ingredient.name /* eslint-enabled */
              "
              type="text"
              placeholder="Ingrese nombre del ingrediente"
            />
          </div>

          <div class="itemForm itemFormForMobile">
            <label for>SKU:</label>
            <input
              v-model="/* eslint-disable */ ingredient.sku /* eslint-enabled */"
              type="text"
              maxlength="14"
              placeholder="SKU"
            />
          </div>

          <div class="itemForm itemFormForMobile fixItemFormForWeightable">
            <div class="container-weightable">
              <label for>Pesable:</label>
              <div class="switch">
                <label>
                  <input
                    v-model="
                      /* eslint-disable */ ingredient.weightable /* eslint-enabled */
                    "
                    type="checkbox"
                  />
                  <span class="lever" style="margin-top: 19px"></span>
                </label>
              </div>
            </div>
            <div class="container-unit">
              <label for>Unidad:</label>
              <select
                :disabled="ingredient.weightable ? false : true"
                v-model="
                  /* eslint-disable */ ingredient.measureUnit /* eslint-enabled */
                "
                class="browser-default"
              >
                <option
                  v-for="(measureUnit, index) in getMeasureUnits()"
                  :value="measureUnit"
                  :key="index"
                >
                  {{ measureUnit }}
                </option>
              </select>
            </div>
          </div>

          <div class="itemForm">
            <label for>Descripción del Ingrediente:</label>
            <textarea
              v-model="
                /* eslint-disable */ ingredient.description /* eslint-enabled */
              "
              placeholder="Ingrese descripción del ingrediente"
            />
          </div>

          <div class="fix-width">
            <div class="itemForm itemFormForMobile">
              <label for>Alerta de stock mínimo:</label>
              <input
                v-model="
                  /* eslint-disable */ ingredient.minimunStockQuantity /* eslint-enabled */
                "
                type="number"
                placeholder="Stock Mínimo"
              />
            </div>
          </div>
        </article>
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <a
          v-if="isNewIngredient === false"
          class="deleteProduct"
          @click.prevent="deleteIngredient"
        >
          <span class="icon-Bistro-_Trash_bin"></span
          ><span>Eliminar ingrediente</span>
        </a>
        <SpinnerComp v-if="isLoading" />
        <a v-else @click="upsertIngredient" type="submit">
          <p>{{ getButtonText }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import mixCommons from "../mixins/mixCommons";
import CurrencyStyle from "../components/CurrencyStyle";
import { toast } from "vue3-toastify";
import SpinnerComp from "../components/SpinnerComp";

/* eslint-disable */

export default {
  mixins: [mixCommons],
  props: {
    ingredient: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isNewIngredient: {
      type: Boolean,
      default: false,
    },
    selectedList: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    getModalTitle() {
      if (this.isNewIngredient) {
        return "Crear Ingrediente";
      } else {
        return "Editar Ingrediente";
      }
    },
    getButtonText() {
      if (this.isNewIngredient) {
        return "Crear Ingrediente";
      } else {
        return "Guardar Ingrediente";
      }
    },
    getMeasureUnit() {
      if (this.ingredient === null || this.ingredient === undefined) return "U";
      if (this.ingredient.weightable === false) return "U";

      if (
        this.ingredient.measureUnit === "" ||
        this.ingredient.measureUnit === undefined ||
        this.ingredient.measureUnit === null
      )
        return "U";

      return this.ingredient.measureUnit;
    },
  },
  methods: {
    closeModal() {
      $("#__layout").css("pointer-events", "all");
      this.$emit("closeModal");
    },
    refreshIngredients() {
      $("#__layout").css("pointer-events", "all");
      this.$emit("refreshIngredients");
    },
    getMeasureUnits() {
      return ["Kg", "L"];
    },
    deleteIngredient() {
      this.$emit("deleteIngredient");
    },
    upsertIngredient() {
      if (!this.isValidRequest()) {
        return;
      }

      this.isLoading = true;
      const t = this;

      let handler = setInterval(() => {
        clearInterval(handler);

        if (t.isNewIngredient) t.createIngredient(t);
        else t.updateIngredient(t);
      }, 500);
    },
    isValidRequest() {
      if (this.ingredient.name === "") {
        toast.error("El nombre del ingrediente es obligatorio");
        return false;
      }

      if (this.ingredient.weightable && this.ingredient.measureUnit === "") {
        toast.error(
          "La unidad de medida es obligatoria para un ingrediente pesable"
        );
        return false;
      }

      if (this.ingredient.sku !== "" && !this.ingredient.sku.startsWith("97")) {
        toast.error(
          'El SKU debe comenzar con "97", dado que es un rango reservado para ingredientes.'
        );
        return false;
      }

      if (!this.isNewIngredient && this.ingredient.id === 0) {
        toast.error(
          "No es posible guardar el ingrediente. Por favor, contacte a soporte técnico."
        );
        return false;
      }

      return true;
    },
    createIngredient(t) {
      let params = {
        shopCode: t.selectedList.shopCode,
        name: t.ingredient.name,
        sku: t.ingredient.sku,
        description: t.ingredient.description,
        cost: Number(t.ingredient.cost),
        weightable: t.ingredient.weightable,
        measureUnit: t.ingredient.measureUnit,
        minimunStockQuantity: Number(t.ingredient.minimunStockQuantity),
        ullagePercentage: Number(t.ingredient.ullagePercentage),
      };
      this.$store.initLoading(this);

      axios
        .post("/api/ingredientsV2/create", params)
        .then((res) => {
          if (res.data.responseCode === 0) {
            this.$store.finishLoading(this);
            t.showSuccess("Ingrediente creado con éxito!");
            t.refreshIngredients();
          } else {
            this.$store.errorLoading(this);
            t.showError(res.data.responseMessage);
          }
          t.isLoading = false;
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          t.showError("Lo sentimos! no pudimos crear el ingrediente.");
          console.error(err);
          t.isLoading = false;
        });
    },
    updateIngredient(t) {
      let params = {
        id: t.ingredient.id,
        shopCode: t.selectedList.shopCode,
        name: t.ingredient.name,
        sku: t.ingredient.sku,
        description: t.ingredient.description,
        cost: Number(t.ingredient.cost),
        weightable: t.ingredient.weightable,
        measureUnit: t.ingredient.measureUnit,
        minimunStockQuantity: Number(t.ingredient.minimunStockQuantity),
        ullagePercentage: Number(t.ingredient.ullagePercentage),
      };
      this.$store.initLoading(this);

      axios
        .put("/api/ingredientsV2/update", params)
        .then((res) => {
          if (res.data.responseCode === 0) {
            this.$store.finishLoading(this);
            t.showSuccess("Ingrediente actualizado con éxito!");
            t.refreshIngredients();
            // t.closeModal();
          } else {
            this.$store.errorLoading(this);
            t.showError(res.data.responseMessage);
          }
          t.isLoading = false;
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          t.showError("Lo sentimos! no pudimos actualizar el ingrediente.");
          console.error(err);
          t.isLoading = false;
        });
    },
  },
  watch: {
    "ingredient.weightable": {
      handler(newVal) {
        if (!newVal) this.ingredient.measureUnit = "";
      },
    },
  },
  components: {
    CurrencyStyle,
    SpinnerComp,
  },
};

/* eslint-enable */
</script>
<style lang="stylus">
.show-div {
	display: block;
}

.hide-div {
	display: none;
}
</style>

function formatDate() {
  return (
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .split(".")[0] + "Z"
  );
}

function getTicketCount(shopCode) {
  const count = localStorage.getItem(`ticket_counter_${shopCode}`);
  return count ? parseInt(count) : 0;
}

function getAndroidId(shopCode) {
  return localStorage.getItem(`androidId_${shopCode}`);
}
//   android "864161f09c812d8a"
function checkAndroidIsDefined(shopCode) {
  const androidId = getAndroidId(shopCode);
  if (!androidId) {
    setTimeout(() => {
      /* eslint-disable */
      const modalElement = document.getElementById("modalUnauthorized");
      if (modalElement) {
        const modalInstance = M.Modal.init(modalElement, {
          dismissible: false, // No permite cerrar el modal
        });
        modalInstance.open();
      }
      /* eslint-enable */
    }, 800);
  }
  return true;
}

function incrementTicketCount(shopCode) {
  let currentValue = getTicketCount(shopCode);
  currentValue += 1;
  localStorage.setItem(`ticket_counter_${shopCode}`, currentValue.toString());
  return currentValue;
}

function isOlderThan(createdAt, days) {
  const now = Date.now();
  const daysInMilliseconds = days * 24 * 60 * 60 * 1000;
  const difference = now - createdAt;

  return difference > daysInMilliseconds;
}

module.exports = {
  formatDate,
  incrementTicketCount,
  getAndroidId,
  isOlderThan,
  checkAndroidIsDefined,
};

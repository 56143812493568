const printByBluetooth = async (content) => {
    const device = await requestBluetoothPrinter();
    if (!device) return false;

    const { service } = await connectToPrinter(device);
    if (!service) return false;

    const result = await sendPrintCommand(service, content);

    await disconnectPrinter(device);

    return result;
};

const requestBluetoothPrinter = async () => {
    try {
        if (!navigator.bluetooth) {
            console.error('Web Bluetooth API no está disponible en este navegador.');
            return;
        }

        const device = await navigator.bluetooth.requestDevice({
            // filters: [{
            //     name: 'Printer-80' 
            // }],
            filters: [{ classCode: 7 }], // Printers
            optionalServices: ['battery_service', 'generic_access']
        });

        console.log("Dispositivo encontrado:", device);
        return device;
    } catch (error) {
        console.error('Error al solicitar dispositivo Bluetooth:', error);
        return null;
    }
};

const connectToPrinter = async (device) => {
    try {
        const server = await device.gatt.connect();
        console.log("Conexión establecida con el dispositivo:", server);

        const service = await server.getPrimaryService('printer_service_uuid'); // TODO ADV: Change UUID
        console.log("Servicio de impresora encontrado:", service);

        return { server, service };
    } catch (error) {
        console.error('Error al conectar al dispositivo o obtener servicio:', error);
        return null;
    }
};

const sendPrintCommand = async (service, content) => {
    try {
        const characteristic = await service.getCharacteristic('characteristic_uuid');
        console.log("Característica de impresión obtenida:", characteristic);

        const encoder = new TextEncoder();
        const printData = encoder.encode(content);

        await characteristic.writeValue(printData);
        console.log("Impresión enviada exitosamente.");
        return true;

    } catch (error) {
        console.error('Error al enviar el comando de impresión:', error);
        return false;
    }
};

const disconnectPrinter = async (device) => {
    if (device.gatt.connected) {
        device.gatt.disconnect();
        console.log("Impresora desconectada.");
    } else {
        console.log("La impresora ya está desconectada.");
    }
};

export { printByBluetooth }
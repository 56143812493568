<template>
<div class="modal modal-block-outside" :class="{'modal-integrated': isIntegrated}"  style="overflow-y: auto !important;" @keydown.esc="closeModal()">
    <!-- TITULO -->
    <div class="modal-header">
        <div class="image-title">
            <h4>Mercado Pago QR</h4>
            <p>Integración medio de pago</p>
        </div>

        <div class="btnClose">
            <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
                <span class="icon-Bistro-_Close"></span>
            </a>
        </div>
    </div>
    <!-- BODY -->
    <div class="modal-body">
        <form action class="formGeneral">
            <section class="formMain">
                <!-- Si tiene mp qr integrado, o sea si esta en enabled -->
                <div v-if="isIntegrated">
                    <div class="mp-integration-description">
                        <label class="settings-label">Tu comercio ya está integrado. Al momento de cobrar con QR debes seleccionar la opción Mercado Pago.</label>
                        <p><b>Usuario de Mercado Pago: </b> {{ getMpUser }}</p>
                    </div>
                    <!-- BOTON ACEPTAR -->
                    <div class="mp-footer">
                        <article class="btn-main mp-eliminate-btn">
                            <Spinner v-if="isUpdateConfigLoading" />
                            <a v-else @click="deleteIntegration()">
                                <p>ELIMINAR INTEGRACIÓN</p>
                            </a>
                        </article>
                        <article class="btn-main modal-action modal-close">
                            <a href="#!" @click="closeModal()">
                                <p>ACEPTAR</p>
                            </a>
                        </article>
                    </div>
                </div>
                <!-- --------------------------------------------------------------- -->

                <!-- Si no muestro el cartel para la integracion de mp qr -->
                <div v-else>
                    <div class="mp-title-container">
                        <p>
                            Al integrar Bistrosoft con tu cuenta de Mercado Pago, podrás agilizar tus cobros de la siguiente manera:
                        </p>
                        <ul>
                            <li><b>Cobros Rápidos y Seguros:</b> Se genera un QR con el monto a cobrar. Una vez realizado el pago, el estado de la transacción se actualiza automáticamente en Bistrosoft, evitando errores en el registro de cobros.</li>
                            <li><b>Pagos en Línea:</b> Con la misma integración, puedes aceptar pagos en la tienda en línea de Bistrosoft.</li>
                        </ul>
                    </div>
                    <div class="mp-information-description-container">
                        <p class="mp-information-description-title">Pasos para la integración:</p>
                        <div class="mp-information-description-steps">
                            <p>1- Presiona el botón que dice “INTEGRAR MERCADO PAGO QR”.</p>
                            <p>2- Se abrirá una nueva ventana para aceptar los permisos necesarios para la integración.</p>
                            <p>3- Una vez aceptados los permisos es necesario volver a iniciar sesión en el equipo de Bistrosoft.</p>
                            <p>4. Asegúrate que el medio de pago QR este habilitado en el equipo entrando a <b>Configuraciones > Bistrosoft > Medios de Pago.</b></p>
                            <p>5. ¡Listo! ya puedes empezar a cobrar con Mercado Pago QR.</p>
                        </div>
                    </div>
                    <p class="mp-information-footer">
                        <b>NOTA: Asegurate de iniciar sesión con la cuenta de Mercado Pago en la que vas a recibir los cobros.</b>
                    </p>
                    <!-- BOTON DE INTEGRACION -->
                    <div class="compare-fiscal-data">
                        <article class="btn-main">
                            <a> <!-- Va al computed que me arma la url dinamica para redirigirme al formulario de Modo -->
                                <p class="btn-text" @click="startIntegration">INTEGRAR MERCADO PAGO QR</p>
                            </a>
                        </article>
                    </div>
                </div>
                <!-- -------------------------------------------------- -->
            </section>
        </form>
    </div>
</div>
</template>

<script>
import commons from '../../mixins/mixCommons'
import axios from 'axios'
import Spinner from '../SpinnerComp'

export default {
    props: {
        shop: { //Es el shop que estoy analizando en el v-for
            type: Object,
            default:  () => ({}),
        },
    },
    data() {
        return {
            isUpdateConfigLoading: false, //variable que uso para activar o desactivar el spinner
            mpUser: null,
        }
    },
    computed:{
        isIntegrated(){ //Computed que me devuelve si el usuario tiene nave integrado
            var response = false;
            this.shop?.integratedPaymentWays?.forEach( paymentWay => { // veo por todos los fds que tiene configurados ese comercio
                    if(paymentWay.paymentWay == "MercadoPagoQR" && paymentWay.isEnabled)    //si el nombre del fds que clicke coincide con el que estoy analizando
                    {
                        response = true;
                        this.mpUser = paymentWay.inputs[0].value;
                    }
                });
            return response;
        },
        getMpUser(){
            return this.mpUser;
        }
    },
    methods: {
        closeModal() {
            this.$emit('onModalClosed')
        },
        refreshPage() {
			setInterval(location.reload(), 1000);
		},
        startIntegration(){
            let body = {
				shopCode: this.shop.code,
			};
            //Pongo en true para que se empiece a mostrar el spinner
			this.isUpdateConfigLoading = true;
            axios
				.post(`/api/users/generateLinkMercadoPagoQRIntegration`, body)
				.then((res) => {
					if (res.data.responseCode === 0) {
                        if(res.data.url != null)
                            window.open(res.data.url, '_blank');
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading( this);
				})
				.catch((err) => {
					this.$store.errorLoading( this);
					this.showErrors(err.response);
				})
				.finally(() => {
					this.isUpdateConfigLoading = false; //pongo en false para que se vaya el spinner
				});
        },
        deleteIntegration(){
            let body = {
				shopCode: this.shop.code,
			};
            //Pongo en true para que se empiece a mostrar el spinner
			this.isUpdateConfigLoading = true;
            axios
				.post(`/api/users/deleteMercadoPagoQRIntegration`, body)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.showSuccess("Configuración actualizada exitósamente");
						// update data
						this.refreshPage();
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading( this);
				})
				.catch((err) => {
					this.$store.errorLoading( this);
					this.showErrors(err.response);
				})
				.finally(() => {
					this.isUpdateConfigLoading = false; //pongo en false para que se vaya el spinner
				});
        }
    },
    components: {
        Spinner,
    },
    mixins: [
        commons,
    ],
}
</script>

<style scoped>
    .modal {
        width: calc(100% - 15%) !important;
        font-size: 14px;
    }
    .modal-integrated{
        width: fit-content!important;
    }
    .formGeneral{
        margin-top: 1em;
    }
    .formMain{
        justify-content: center !important;
    }
    .btn-text{
        padding: 0px 50px;
    }
/* CUANDO ESTA INTEGRADO */
    .mp-integration-description p{
        margin-top: 40px;
    }
    .mp-footer{
        display: flex;
        justify-content: flex-end;
    }
    .mp-eliminate-btn a{
        background-color: #ff0000;
    }
    .mp-eliminate-btn a :hover{
        background-color: #c90000;
        border-radius: 6px;
    }
/* CUANDO NO ESTA INTEGRADO */
/* TITULO */
    .mp-title-container{
        margin-bottom: 30px;
    }
    .mp-title-container p{
        margin-bottom: 6px;
    }
    .mp-title-container ul{
        margin-left: 15px;
    }
    .mp-title-container ul li{
        list-style: disc;
    }
/* DESCRIPCIONES */
    .mp-information-description-container{
        margin-top: 25px;
    }
    .mp-information-description-title{
        margin: 0;
    }
    .mp-information-description-steps{
        margin-left: 9px;
    }
    .mp-information-description-steps p{
        margin-bottom: 6px;
    }
/* FOOTER */
    .mp-information-footer{
        margin-top: 35px !important;
    }
    .settings-label {
        color: black;
    }
    .search-input{
        width: -webkit-fill-available;
        margin: 0 0 1.2em 0  !important;
        color: black !important;
        font-weight: bolder;
        padding-left: 7%!important;
    }
    
</style>

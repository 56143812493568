
<template>
    <div id="modalGlobalBilling" class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="clear()">
    <div class="modal-header">
        <div class="image-title">
            <h4>Facturación Masiva</h4>
            <p>Fácil y Simple</p>
        </div>
        <div class="btnClose">
            <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
            <span class="icon-Bistro-_Close"></span>
            </a>
        </div>
    </div>

    <!-- BODY MODAL -->
    <div class="modal-body">
            <form action class="formGeneral">
                <article class="formMain">
                    <div class="modal-header-product">
                        <p v-if="billsCount">Se {{ (billsCount > 1 ? "generarán" : "generará")}}  <strong>{{ billsCount }} {{ (billsCount > 1 ? "facturas" : "factura")}}</strong> por un monto total de  <strong>${{billsAmount.toFixed(2)}}</strong>.</p>
                        <p v-if="creditNotesCount">Se {{ (creditNotesCount > 1 ? "generarán" : "generará")}} <strong>{{ creditNotesCount }} {{ (creditNotesCount > 1 ? "notas" : "nota")}} de crédito</strong> por un monto de <strong>${{ creditNotesAmount.toFixed(2) }}</strong></p>
                        <p v-if="billsCount || creditNotesCount">¿Está seguro que desea continuar?</p>
                    </div>
                </article>
            </form>
        </div>

        <div class="modal-footer">
            <article class="btn-main">
                <!-- <a v-if="!isLoading" class="deleteProduct" @click.prevent="closeModal">
                    <span>Cancelar</span>
                </a> -->
                <Spinner v-if="isLoading"/>
                <a v-else  @click="confirmBilling" type="submit">
                    <p>{{ buttonAction }}</p>
                </a>
            </article>
        </div>


</div>
</template>

<script>
import commons from '@/mixins/mixCommons'
import Spinner from '@/components/SpinnerComp'


export default {
    mixins: [commons],
    props: {
        orderExtendedInfo: {
            type: Object,
            default(){
                return {}
            }
        },
        /*eslint-disable*/
        creditNotesCount: 0,
		creditNotesAmount: 0,
		billsCount: 0,
		billsAmount: 0

    },
    data () {
        return {
            buttonAction: 'Continuar',
            params: {
                internalReference: '',
                billType: 'E'
            },
            isLoading: false
            
        }
    },
    computed: {},
    methods: {
        closeModal() {
            this.$emit('onModalClosing')
        },
        confirmBilling(){
            this.isLoading = true;
            this.$emit('onConfirmBilling')
        }

    },
    components: {
        Spinner
    }
}
</script>
<style lang="stylus">
.show-div {
    display: block;
}

.hide-div {
    display: none;
}
</style>

<template>
	<li>
		<figure class="iconHistory">
			<span :class="{'salesNull': data.status === 1, 'yellow': data.status === 3, 'icon-Bistro-_Check': true }"></span>
		</figure>

		<!-- FECHA -->
		<div class="items">
			<h5>{{ data.creationTimestamp }}</h5>
		</div>

		<!-- OPERACION -->
		<div class="items">
			<p v-if="data.type">{{ data.type }}</p>

		</div>

		<!-- DESCRIPCION -->
		<div class="items">
			<p v-if="data.description">{{ data.description }}</p>
		</div>
		<!-- DESCRIPCION -->
		<div class="items">
			<p v-if="data.userName">{{ data.userName }}</p>
		</div>

		<!-- <div class="items">
			<p v-if="data.shopCode">Cod Comercio: {{ data.shopCode }}</p>


		</div> -->


	</li>
</template>
<script>
	import $ from 'jquery'
	import axios from 'axios'

	export default {
		props: {
			modal: {
				type: Boolean,
				default: false
			},
			data: {
				type: Object
			},
			target: {
				type: String
			}
		},
		data () {
			return {

			}
		},
		methods: {
			openModal (info) {
				axios.get(`/api/${this.target}/${info.id}`) /// OK
					.then(res => {
						this.$store.dispatch('updateModalInfo', res.data)
						$('#modalResumSales').modal('open')
					})
			}
		},
		
	}
</script>

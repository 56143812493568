import firebaseRepo from "./productsFirebaseRepository";

// const getOrigins = async (shopCode) => {
//     return await firebaseRepo.getOrigins(shopCode);
// };


const getProductTotalDiscountAmount = (product) => {
    const discounts = getDiscountsFromProduct(product)
    if (!discounts) return 0;
    const data = discounts.reduce((total, product) => total + product.amount, 0);
    return data
}

const getProductTotalDiscountAmountForSync = (product) => {
    const discounts = getDiscountsFromProductForSync(product)
    if (!discounts) return 0;
    const data = discounts.reduce((total, product) => total + product.amount, 0);
    return data
}

const getProductDiscountTotalAmount = (product) => {
    if(product.discountByAmount){
        return getProductTotalDiscountAmount(product) * product.quantity
    } else {
        return getProductTotalDiscountAmount(product)
    }
}

const getProductDiscountPercentageTotalAmount = (product) => {
    if(product.weightable){
        return getProductDiscountPercentageAmount(product) * product.quantity / 1000
    }
    return getProductDiscountPercentageAmount(product) * product.quantity;
}
const getProductDiscountPercentageAmount = (product) => {
    return (getAmountFromProduct(product)) * (product.discountByPercentage / 100);
}

const getProductDiscountAmount = (product) => {
    return product.weightable ? parseFloat((product.discountByAmount).toFixed(3)) * 1000 : parseFloat((product.discountByAmount).toFixed(2))
}

const getDiscountsFromProduct = (product) => {
    const discounts = [];
    if (product.discountByAmount) {
        discounts.push({
            amount: product.discountByAmount,
            description: "Fixed"
        });
    }
    if (product.discountByPercentage) {
        discounts.push({
            amount: getProductDiscountPercentageTotalAmount(product),
            description: `DESCUENTO (${product.discountByPercentage}%)`
        });
    }

    if (discounts.length == 0) return null;
    else return discounts;
}
const getDiscountsFromProductForSync = (product) => {
    const discounts = [];
    if (product.discountByAmount) {
        discounts.push({
            amount: getProductDiscountAmount(product),
            description: "DESCUENTO FIJO"
        });
    }
    if (product.discountByPercentage) {
        discounts.push({
            amount: getProductDiscountPercentageAmount(product),
            description: `DESCUENTO (${product.discountByPercentage}%)`
        });
    }

    if (discounts.length == 0) return null;
    else return discounts;
}

const getTotalAmountFromProduct = (prod) => {
    return getPriceByOriginPriceOrKgLt(prod) - getProductDiscountTotalAmount(prod);
};
// const getTotalAmountFromProduct = (prod) => {
//     return (getAmountFromProduct(prod) * prod.quantity) - getProductDiscountTotalAmount(prod);
// };

const getTotalVatAmountFromProduct = (prod) => {
    return parseFloat((getTotalAmountFromProduct(prod) - getTotalNetAmountFromProduct(prod)).toFixed(2));
};

const getTotalNetAmountFromProduct = (prod) => {
    return parseFloat((getTotalAmountFromProduct(prod) / (1 + (parseFloat(prod.originIva) / 100))).toFixed(2));
}

const getAmountFromProduct = (prod) => {
    return parseFloat(prod.originPrice);
};

const getProducts = async (shopCode, origin) => {
    const productsFromFirebase = await firebaseRepo.getProducts(shopCode);
    let products = [];
    productsFromFirebase.forEach(prod => {
        prod.origins.forEach(origin => {
            products.push({
                id: prod.id,
                name: prod.name,
                sku: prod.sku,
                department: prod.department,
                weightable: prod.weightable,
                measureUnit: prod.measureUnit,
                stock: prod.stock,
                originName: origin.name,
                originPrice: origin.price,
                originIva: origin.iva
            });
        });
    });
    // TODO ADV: Guardar en IndexedDb
    return products.filter(prod => prod.originName == origin);
};

const getCategories = async (shopCode) => {
    return await firebaseRepo.getCategories(shopCode);
};

const getDisplayUnit = (prod) => {
    const { quantity, measureUnit } = prod
    if (quantity >= 1000) return measureUnit
    return measureUnit === "Kg" ? "g" : "ml"
  }

  const getFormattedQuantity = (prod) => {
    const { quantity } = prod
    const newQuantity = quantity >= 1000 ? parseFloat((quantity / 1000).toFixed(3)) : quantity
    return `${newQuantity} ${getDisplayUnit(prod)}`
  }

  const getPriceByOriginPriceOrKgLt = (prod) => {
    const { originPrice, quantity, weightable} = prod;
    if(weightable){
      return parseFloat(originPrice) * quantity / 1000
    } else {
      return parseFloat(originPrice) * quantity
    }
  }

export { 
    // getOrigins, 
    getProducts, 
    getTotalAmountFromProduct, 
    getTotalVatAmountFromProduct, 
    getTotalNetAmountFromProduct, 
    getAmountFromProduct, 
    getProductTotalDiscountAmount, 
    getDiscountsFromProduct, 
    getProductDiscountPercentageAmount, 
    getProductDiscountPercentageTotalAmount, 
    getProductTotalDiscountAmountForSync, 
    getDiscountsFromProductForSync, 
    getProductDiscountTotalAmount, 
    getCategories,
    getFormattedQuantity,
    getPriceByOriginPriceOrKgLt
}
<template>
    <div id="modalUnauthorized" class="modal modal-block-outside modal-pdv">
        <div class="modal-header">
            <h2 class="modal-title">⚠️ No tenés terminal asignado</h2>
            <div class="btnClose">
                <a href="#!" @click="closeModal" class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>
        <div class="modal-body">
            <p class="modal-message">
                No tenés permisos para realizar comandas.
            </p>
            <p class="modal-message">
                Por favor, contactate con soporte si necesitás asistencia.
            </p>
        </div>
        <div class="modal-footer">
            <ButtonBistro text="Cerrar" @handleClick="closeModal" />
        </div>
    </div>
</template>

<script>
import ButtonBistro from '../ButtonBistro.vue';
import router from "@/router";

export default {
    components: {
        ButtonBistro,
    },
    mounted() { },
    methods: {
        closeModal() {
            router.push("/dashboard/indexV2");
        }
    }
}
</script>

<style scoped>
.modal-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #d9534f;
    margin-bottom: 10px;
}
.modal-message {
    font-size: 1.1em;
    margin: 15px 0;
    line-height: 1.5;
}
#modalUnauthorized > .modal-footer {
    display: flex;
    justify-content: center;
    padding: 15px;
}
</style>

<script>
export default {
    methods: {
        convertTimestampToDate(timestamp) {
            let date = new Date(timestamp);

            let day = String(date.getDate()).padStart(2, '0');
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let year = date.getFullYear();
            return `${day}-${month}-${year}`;
        },
        convertTimestampToHour(timestamp) {
            let date = new Date(timestamp);

            let hours = String(date.getHours()).padStart(2, '0');
            let minutes = String(date.getMinutes()).padStart(2, '0');
            let seconds = String(date.getSeconds()).padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        }
    }
}
</script>
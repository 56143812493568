<template>
  <div
    id="modalStock"
    class="modal modal-block-outside"
    style="overflow-y: auto !important"
    @keydown.esc="closeModal()"
  >
    <div class="modal-header">
      <div class="image-title">
        <h4>{{ this.product.name }}</h4>
        <p>{{ this.product.department }}</p>
      </div>

      <!-- CLOSE -->
      <div class="btnClose">
        <a
          href="#!"
          @click="closeModal()"
          class="modal-action modal-close waves-effect waves-green btn-flat"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral formGeneral-stock">
        <article class="formMain article-product-stock">
          <div class="itemForm-stock-left">
            <h4>Stock Actual</h4>
          </div>
          <div class="itemForm-stock-right">
            <h4>{{ this.currentStock }}</h4>
            <p class="itemForm-stock-measureUnit">
              {{ getMeasureUnitForTitle }}
            </p>
          </div>
          <div v-if="noStockMovementsMessage" class="itemForm-stock-error">
            <label>{{ this.noStockMovementsMessage }}</label>
          </div>
        </article>
        <article class="stock-warning">
          <label
            >Tene en cuenta que el stock actual y los movimientos pueden tardar
            hasta 1 minuto en actualizarse
            {{ getLastUpdateStockTimeStamp }}</label
          >
        </article>
        <article>
          <ul class="mainEvents-history mainStock-history stock-grid-table">
            <li>
              <div class="stockGridItemTitle">Movimiento</div>
              <div class="stockGridItemTitle">Fecha</div>
              <div class="stockGridItemTitle">Usuario</div>
              <div class="stockGridItemTitle">Tipo</div>
              <div class="stockGridItemTitle">Cantidad</div>
            </li>
            <li :class="{'listCollapsible': transactions.length > 1}" v-for="transaction in transactions" :key="transaction.timestamp">

              <div class="stockGridItem">
                <span
                  :class="getIconForMovementType(transaction.movementType)"
                ></span>
                <span
                  :class="
                    getDescriptionClassForMovementType(transaction.movementType)
                  "
                  >{{
                    getDescriptionForMovementType(transaction.movementType)
                  }}</span
                >
              </div>

              <div class="stockGridItem">
                <h5 class="stockGridItemDateTime">{{ $filters.date(transaction.timestamp, 'date') }}</h5>
                <p class="stockGridItemDateTime">{{ $filters.date(transaction.timestamp, 'time') }}</p>
              </div>

              <div class="stockGridItem">
                <p class="stockGridItemMail">{{ transaction.userName }}</p>
              </div>

              <div class="stockGridItem">
                <p>{{ transaction.changeLog }}</p>
              </div>

              <div class="stockGridItem">
                <p :class="getClassForStockQuantity(transaction.quantity)">
                  {{ transaction.quantity }}{{ getMeasureUnitForItem }}
                </p>
              </div>
            </li>
          </ul>
        </article>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import commons from "../mixins/mixCommons";
import { parseToQuery } from "../utils/utils";
import $ from "jquery";

export default {
  mixins: [commons],
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isNewProduct: {
      type: Boolean,
    },
    currentSku: {
      type: String,
    },
  },
  data() {
    return {
      params: {},
      currentStock: 0,
      noStockMovementsMessage: null,
      isLoading: false,
      shopCode: null,
      transactions: [],
      lastStockUpdateTimeStamp: {},
    };
  },
  watch: {
    currentSku() {
      if (this.currentSku !== this.product.sku) return;
      this.isLoading = true;
      this.noStockMovementsMessage = null;
      this.currentStock = this.product.stock;
      this.$store.initLoading(this);
      this.params["sku"] = this.currentSku;
      this.params["shopCode"] = this.shopCode;
      axios
        .get(`/api/productsV2/stock/?${parseToQuery(this.params)}`)
        .then((res) => {
          this.$store.finishLoading(this);
          if (res.data.responseCode !== 0) {
            this.noStockMovementsMessage = res.data.responseMessage;
            return;
          }
          this.transactions = res.data.stockMovements;
          this.currentStock = res.data.stock;
          this.lastStockUpdateTimeStamp = {
            dateTime: res.data.lastStockUpdateTimeStamp,
            gmtOffset: res.data.gmtOffset,
          };
          this.isLoading = false;
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          this.showErrors(err.response);
          this.isLoading = false;
        });
    },
  },
  computed: {
    getStockProdId() {
      return `stockProdId_${this.product.sku}`;
    },
    getMeasureUnitForTitle() {
      return this.getMeasureUnit("Unidades");
    },
    getMeasureUnitForItem() {
      return this.getMeasureUnit("");
    },
    getLastUpdateStockTimeStamp() {
      if (this.isLoading) {
        return "";
      }

      // From Backend
      let timeStamp = this.lastStockUpdateTimeStamp.dateTime;
      let gmtOffset = this.lastStockUpdateTimeStamp.gmtOffset;

      if (timeStamp && gmtOffset) {
        var date = new Date(timeStamp);
        date.setTime(date.getTime() + Number(gmtOffset) * 60 * 60 * 1000);
        return `(Fecha de última modificación: ${date.toLocaleDateString(
          "es-AR"
        )} ${date.toLocaleTimeString("es-AR")})`;
      }

      // From Firebase -> Deprecated
      timeStamp = this.product.lastUpdateStockTimeStamp;
      gmtOffset = this.product.gmtOffset;

      if (timeStamp && gmtOffset) {
        var date2 = new Date(timeStamp);
        date2.setTime(date2.getTime() + Number(gmtOffset) * 60 * 60 * 1000);
        return `(Fecha de última modificación: ${date2.toLocaleDateString(
          "es-AR"
        )} ${date2.toLocaleTimeString("es-AR")})`;
      }

      return "";
    },
  },
  methods: {
    getMeasureUnit(unitName) {
      if (
        this.product.measureUnit === "" ||
        this.product.measureUnit === null ||
        this.product.measureUnit === undefined
      ) {
        return unitName;
      } else {
        return this.product.measureUnit;
      }
    },
    getClassForStockQuantity(quantity) {
      if (quantity < 0) {
        return "stockGridItemRightAligned stockGridItem stockGridItemBlack";
      } else {
        return "stockGridItemRightAligned stockGridItem stockGridItemGreen";
      }
    },
    getIconForMovementType(movementType) {
      switch (movementType) {
        case 1: // Ingreso de Stock
          return "icon-Bistro-_Arrow_upward_green";
        case 2: // Egreso de Stock
          return "icon-Bistro-_Arrow_upward_black";
        case 3: // Ajuste
          return "icon-Bistro-_Tool_grey";
        default:
          return "icon-Bistro-_Arrow_upward_green";
      }
    },
    getDescriptionForMovementType(movementType) {
      switch (movementType) {
        case 0:
          return "VENTA/COMANDA";
        case 1: // Ingreso de Stock
          return "INGRESO";
        case 2: // Egreso de Stock
          return "EGRESO";
        case 3: // Ajuste
          return "AJUSTE";
        default:
          return "INGRESO";
      }
    },
    getDescriptionClassForMovementType(movementType) {
      switch (movementType) {
        case 1: // Ingreso de Stock
          return "stockMessage stockMessageGreen";
        case 2: // Egreso de Stock
          return "stockMessage stockMessageBlack";
        case 3: // Ajuste
          return "stockMessage stockMessageGrey";
        default:
          return "stockMessage stockMessageRed";
      }
    },
    setShopCode(shop) {
      this.shopCode = shop;
    },
    closeModal() {
      $("#__layout").css("pointer-events", "all");
      this.transactions = [];
      this.$emit("onClosed");
    },
  },
  components: {},
};
</script>

<template>
	<div
		id="modalReview"
		class="modal modal-block-outside"
		style="overflow-y: auto !important"
		@keydown.esc="closeModal()"
	>
		<div class="modal-header">
			<div class="image-title">
				<h4>Reseña de {{ review.name ? review.name : "Anónimo" }}</h4>
				<!-- <p>Fácil y Simple</p> -->
			</div>

			<!-- CLOSE -->
			<div class="btnClose">
				<a
					href="#!"
					@click="closeModal()"
					class="modal-action modal-close waves-effect waves-green btn-flat"
				>
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<div class="modal-body">
			<form action class="formGeneral">
				<article class="formMain article-product">
					<div class="itemForm itemFormForMobile itemForm-review">
						<p>{{ review.comments }}</p>
					</div>
				</article>
			</form>
		</div>

		<div class="modal-footer">
			<article class="btn-main"></article>
		</div>
	</div>
</template>

<script>
import currencyCommons from "../mixins/currencyCommons.vue";
import mixCommons from "../mixins/mixCommons.vue";
import tester from "../mixins/mixTester";
import $ from "jquery";

export default {
	mixins: [tester, currencyCommons, mixCommons],
	props: {
		review: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {};
	},
	computed: {},
	methods: {
		closeModal() {
			$("#__layout").css("pointer-events", "all");
		},
	},
};
</script>
<style lang="stylus">
.show-div {
	display: block;
}

.hide-div {
	display: none;
}
</style>

<template>
  <!-- MENU -->
  <ul class="mainContain-navigation">
    <!-- TABLERO -->
    <li >
      <router-link to="/dashboard/indexV2" :class="{ active: option === 0 }">
        <figure>
          <span class="icon-Bistro-_Dashboard"></span>
        </figure>
      </router-link>
      <p>Tablero</p>
    </li>

    <!-- CONSOLIDADO -->
    <li>
      <router-link
        to="/dashboard/consolidatedV2"
        :class="{ active: option === 1 }"
      >
        <figure>
          <span class="icon-Bistro-_Comandas"></span>
        </figure>
      </router-link>
      <p>Consolidado</p>
    </li>

    <!-- FACTURACION -->
    <li>
      <router-link
        v-if="showBillingPage"
        to="/dashboard/billingDetail"
        :class="{ active: option === 2 }"
      >
        <figure>
          <span class="icon-Bistro-_TDC"></span>
        </figure>
      </router-link>
      <p>Facturación</p>
    </li>

    <!-- REPORTES PDV-->
    <li v-if="hasToShowPosTab">
      <router-link
        to="/dashboard/reportPDV"
        :class="{ active: option === 3 }"
      >
        <figure>
          <span class="icon-Bistro-_FileDoble"></span>
        </figure>
      </router-link>
      <p>Reportes</p>
    </li>
    <!-- REPORTES -->
    <li v-else>
      <router-link
        to="/dashboard/report"
        :class="{ active: option === 3 }"
      >
        <figure>
          <span class="icon-Bistro-_FileDoble"></span>
        </figure>
      </router-link>
      <p>Reportes</p>
     
    </li>
  </ul>
</template>
<script>
import mixCommons from "../mixins/mixCommons";
import rolesCommons from "../mixins/rolesCommons";

export default {
  mixins: [mixCommons, rolesCommons],
  data() {
    return {};
  },
  computed: {
    option() {
      return this.$store.getSideMenu;
    },
  },
  methods: {},
};
</script>

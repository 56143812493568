<template>
	<section class="mainContain-info">
		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title-customer">
				<div class="customerName">
					Cliente:
					<strong>{{ this.data.customerName }}
						{{ this.data.customerLastName }}</strong>
				</div>
				<div class="customerPhone">
					Teléfono: <span>{{ this.data.customerPhone }}</span>
				</div>
			</section>

			<!-- OPCIONES ADICIONALE -->
			<section class="mainFilter-filter">
				<div class="filterRecord">
					<!-- <div class="item">
						<h4><currency-style :valor="data.trnxAmountShown | currency" /></h4>
						<p>Monto Total</p>
					</div>
					<div class="item">
						<h4><currency-style :valor="data.depositsAmount | currency" /></h4>
						<p>Depósitos</p>
					</div> -->

					<div
						:class="{ 'item advice-red ': parseFloat(data.balance) < 0, 'item advice-green': parseFloat(data.balance) >= 0 }">
						<h4><currency-style :valor="$filters.currency(data.balance)" /></h4>
						<p>Saldo</p>
					</div>
					<div v-if="data.creditLimit" class="item">
						<h4><currency-style :valor="$filters.currency(data.balance)" /></h4>
						<p>Límite de Crédito</p>
					</div>
					<div v-else class="item">
						<h4>N/A</h4>
						<p>Límite de Crédito</p>
					</div>
					<div
						:class="{ 'item advice-red ': parseFloat(data.availableAmount) < 0, 'item advice-green': parseFloat(data.availableAmount) >= 0 }">
						<h4><currency-style :valor="$filters.currency(data.availableAmount)" /></h4>
						<p>Disponible</p>
					</div>
				</div>

				<!-- OPCION MODAL -->
				<a href="#" @click="processReportInBackend(getOrdersUrl, excelParams)"
					class="waves-effect waves-light btn-link hide-mobile">
					<span class="icon-Bistro-_Download"></span>
					<span>Descargar Detalle</span>
				</a>
			</section>
		</article>

		<!-- LISTADO DE PRODUCTOS -->
		<article :class="{ errorLoading: data.orders && data.orders.length === 0 }" class="mainEvents salesDashboard">
			<!-- TIPO DE TRANSACCION -->
			<section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">
				<div class="containTransaction">
					<!-- SEARCH -->
					<div class="searchDashboard">
						<!-- MENSAJE -->
						<div class="itemMessage">
							<div class="text">
								<h5>Filtra tu Consolidado</h5>
								<p>Fácil y Simple</p>
							</div>
							<a @click="search = !search" v-if="!search" id="btnSearchSales">
								<span class="icon-Bistro-_Search"></span>
							</a>
						</div>

						<!-- INPUT -->
						<div v-if="search" class="item">
							<a @click="search = !search" id="btnCloseSearch">
								<span class="icon-Bistro-_Close"></span>
							</a>

							<input v-model="criteria" type="text" class="inputFilter"
								placeholder="Buscar por Transacción, ID ..." />
						</div>
					</div>

					<ul class="containTransaction-lists">
						<!-- Titulo -->
						<li>
							<h4>Medios de Pago</h4>
							<div class="switch">
								<label>
									<input v-model="paymentMethodFilters" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 1 -->
						<li>
							<figure>
								<span class="icon-Bistro-_Visa"></span>
							</figure>
							<p>Tarjeta</p>
							<div class="switch">
								<label>
									<input v-model="card" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 2 -->
						<li>
							<figure>
								<span class="icon-Bistro-_DolarMoney"></span>
							</figure>
							<p>Efectivo</p>
							<div class="switch">
								<label>
									<input v-model="cash" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 3 -->
						<li>
							<figure>
								<span class="icon-Bistro-_Online"></span>
							</figure>
							<p>Online</p>
							<div class="switch">
								<label>
									<input v-model="online" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 5 -->
						<li>
							<figure>
								<span class="icon-Bistro-_QR"><span class="path1"></span><span
										class="path2"></span><span class="path3"></span><span class="path4"></span><span
										class="path5"></span><span class="path6"></span></span>
							</figure>
							<p>QR</p>
							<div class="switch">
								<label>
									<input v-model="qr" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 6 -->
						<li>
							<figure>
								<span class="icon-Bistro-_CuentaCliente"></span>
							</figure>
							<p>Cuenta Cliente</p>
							<div class="switch">
								<label>
									<input v-model="account" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 7 -->
						<li>
							<figure>
								<span class="icon-Bistro-_Invitacion1"></span>
							</figure>
							<p>Invitación</p>
							<div class="switch">
								<label>
									<input v-model="invitation" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 8 -->
						<li>
							<figure>
								<span class="icon-Bistro-_ConsumoEmpleados"></span>
							</figure>
							<p>Consumo Empleado</p>
							<div class="switch">
								<label>
									<input v-model="clerck" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
					</ul>

					<ul class="containTransaction-lists">
						<!-- Titulo -->
						<li>
							<h4>Origenes</h4>
							<div class="switch">
								<label>
									<input v-model="originFilters" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 1 -->
						<li v-for="(bool, index) in data.origins" :key="index">
							<p>{{ index }}</p>
							<div class="switch">
								<label>
									<input :checked="bool" @change="chargeFilterOrigin(index, !bool)" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
					</ul>

					<!-- TIPOS DE COMANDAS -->
					<ul class="containTransaction-lists">
						<!-- Titulo -->
						<li>
							<h4>Tipos</h4>
							<div class="switch">
								<label>
									<input v-model="typeFilters" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 1 -->
						<li>
							<p>Comanda c/descuento</p>
							<div class="switch">
								<label>
									<input v-model="order_with_discount" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
						<!-- OPCION 2 -->
						<li>
							<p>Comanda s/descuento</p>
							<div class="switch">
								<label>
									<input v-model="order_no_discount" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>

						<!-- OPCION 3 -->
						<li>
							<p>Venta c/descuento</p>
							<div class="switch">
								<label>
									<input v-model="sale_with_discount" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
						<!-- OPCION 4 -->
						<li>
							<p>Venta s/descuento</p>
							<div class="switch">
								<label>
									<input v-model="sale_no_discount" type="checkbox" />
									<span class="lever"></span>
								</label>
							</div>
						</li>
					</ul>
				</div>
				<!-- BTN ACTIVE FILTER MOBILE -->
				<button @click="showFilter = !showFilter" class="btnFilterMobile">
					<span class="icon-Bistro-_Filter"></span>
				</button>
			</section>

			<!-- LISTADO DE FACTURAS -->
			<ul v-if="data.sales && data.sales.length > 0" class="mainEvents-history">
				<!-- Historial 1 -->
				<customer-detail-row v-for="(sale, index) in data.sales" target="consolidatedV2" :modal="true"
					:isSaleV2="true" :data="sale" :key="index" />
			</ul>
			<empty-element v-else-if="data.sales && data.sales.length === 0 && loaded" />
			<div v-else class="mainEvents-history">
				<article v-for="i in 4" :key="i" :class="sectionLoading" />
			</div>
		</article>
		<div v-if="data.sales && data.sales.length > 0 && isMoreOfOnePage()" class="base-pagination">
			<BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
				class="list__pagination" @nextPage="pageChangeHandle('next')"
				@previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
		</div>
		<modal title="Resumen de Consolidado"></modal>
		<help-center />
	</section>
</template>
<script>
import axios from "axios";
import commons from "../mixins/mixCommons";
import filterSaleTypesCommons from "../mixins/filterSaleTypesCommons";
import rolesCommons from "../mixins/rolesCommons";
import reportCommons from "../mixins/reportCommons";
import computeds from "../mixins/menuOptions";
import selectPdvs from "../mixins/select_pdvs";
import CustomerDetailRow from "../components/CustomerDetailRow.vue";
import HelpCenter from "../components/HelpCenter";
import Modal from "../components/ModalSalesDetailsV2";
import EmptyElement from "../components/EmptyElement";
import { parseToQuery } from "../utils/utils";
import CurrencyStyle from "../components/CurrencyStyle";
import BasePagination from "../components/BasePagination";

export default {
	mixins: [
		commons,
		computeds,
		selectPdvs,
		rolesCommons,
		reportCommons,
		filterSaleTypesCommons,
	],
	head() {
		return {
			title: `${this.appTitle} - Detalle de Clientes`,
		};
	},
	data() {
		return {
			data: {},
			filterInterval: null,
			customerId: -1,
			customerUuid: null,
			shopCode: -1,
			showFilter: false,
			search: false,
			excelParams: "",
			loaded: false,
			paymentMethodFilters: true,
			card: true,
			cash: true,
			online: true,
			qr: true,
			invitation: true,
			clerck: true,
			originFilters: true,
			typeFilters: true,
			account: true,
			criteria: "",
			params: {
				FilterPaymentWay: [],
				FilterOrigin: [],
				FilterTrnxStatus: [],
				FilterSaleTypes: [],
			},
			timeout: null,
			currentPage: 1,
			countPerPage: 50,
		};
	},
	async mounted() {
		this.$store.setHasMounted(false)
		if (this.isUserLoggedIn() === false) {
			axios.get('/api/auth/logout') /// OK
				.then(() => {
					this.$store.SET_USER(null)
					this.resetUser()
					this.setAutomaticLogin(false)
					location.href = '/login'
				})
		} else if (this.hasToShowThisOption(1) === false) {
			this.handleUnauthorizedAccess()

		} else {
			this.$store.setLoadingSkeleton(true)
			this.onMounted()
		}
	},
	watch: {
		paymentMethodFilters() {
			this.setAllPaymentTypes(this.paymentMethodFilters);
		},
		card() {
			this.chargeFilterPaymetWay(this.card, "TARJETA");
			this.pageChangeHandle(1);
		},
		cash() {
			this.chargeFilterPaymetWay(this.cash, "EFECTIVO");
			this.pageChangeHandle(1);
		},
		online() {
			this.chargeFilterPaymetWay(this.online, "ONLINE");
			this.pageChangeHandle(1);
		},
		qr() {
			this.chargeFilterPaymetWay(this.qr, "QR");
			this.pageChangeHandle(1);
		},
		account() {
			this.chargeFilterPaymetWay(this.account, "ACCOUNT");
			this.pageChangeHandle(1);
		},
		invitation() {
			this.chargeFilterPaymetWay(this.invitation, "INVITATION");
			this.pageChangeHandle(1);
		},
		clerck() {
			this.chargeFilterPaymetWay(this.clerck, "EMPLOYEE");
			this.pageChangeHandle(1);
		},
		originFilters() {
			for (var key in this.data.origins) {
				this.data.origins[key] = this.originFilters;
				this.chargeFilterOrigin(key, this.originFilters);
			}
		},
		typeFilters() {
			this.setAllTypes(this.typeFilters);
		},
		criteria() {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.params["FilterCriteria"] = this.criteria;
				this.pageChangeHandle(1);
			}, 500);
		},
		tab() {
			this.params["Period"] = this.tab.param;
			this.$store.initLoading(this);
			if (this.firstEntry === true) {
				this.firstEntry = false;
			} else if (this.tab.id !== 6) {
				this.getData();
			}
		},
		dateCalendar() {
			if (this.dateCalendar.indexOf("Seleccione") < 0) {
				this.params["Period"] = this.tab.param;
				this.pageChangeHandle(1);
			}
		},
	},
	methods: {
		setAllPaymentTypes(bool) {
			this.card = bool;
			this.cash = bool;
			this.online = bool;
			this.qr = bool;
			this.account = bool;
			this.invitation = bool;
			this.clerck = bool;
		},
		async onMounted() {
			this.customerId = this.$route.query.cId;
			this.customerUuid = this.$route.query.cUuid;
			this.shopCode = this.$route.query.shopCode;

			if (
				this.customerId === null ||
				this.customerId === undefined ||
				this.customerId < 0 ||
				isNaN(this.customerId) ||
				this.shopCode === null ||
				this.shopCode === undefined ||
				this.shopCode < 0 ||
				isNaN(this.shopCode)
			) {
				this.$store.finishLoading(this);
				this.loaded = true;
				this.showError("No se pudo obtener información del cliente");
				return;
			}

			this.$store.updateSideMenu(-1);
			this.$store.updateTopMenu(6);

			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				await this.$store.updateTabSelected(
					this.getPeriodSelectionFromLocalStorage()
				);
				this.params["Period"] = this.getPeriodSelectionFromLocalStorage().param;
			} else {
				await this.$store.initTabs
			}
			this.toggleMerchantSelectorByLabel(null);
			this.$store.CALL_MERCHANTS("CALL_MERCHANTS");
			this.$store.hideElements({});
			this.$store.setShowMerchantSelectionFilter(true);
			this.$store.CALL_MERCHANTS("CALL_MERCHANTS");
			this.$store.updateCalendarType("range")

			//this.chargeFilterTrnxStatus(this.cancel, 'VOID')

			this.params["CurrentPage"] = this.currentPage;
			this.params["CountPerPage"] = this.countPerPage;
			this.$store.setHasMounted(true)
		},
		isMoreOfOnePage() {
			return this.data.totalCount - this.countPerPage > 0;
		},
		chargeFilterPaymetWay(bool, payment) {
			if (bool) {
				var index = this.params.FilterPaymentWay.indexOf(payment);
				this.params.FilterPaymentWay.splice(index, 1);
			} else {
				this.params.FilterPaymentWay.push(payment);
			}
		},
		chargeFilterOrigin(origen, bool) {
			this.data.origins[origen] = bool;

			var index = this.params.FilterOrigin.indexOf(origen);
			if (bool) {
				this.params.FilterOrigin.splice(index, 1);
			} else if (index < 0) {
				this.params.FilterOrigin.push(origen);
			}
			this.pageChangeHandle(1);
		},
		addExcelParams() {
			let p = this.params["CurrentPage"];
			this.params["CurrentPage"] = null;
			this.params["customerId"] = this.customerId;
			this.params["customerUuid"] = this.customerUuid;
			this.params["shopCode"] = this.shopCode;
			this.excelParams = parseToQuery(this.params);
			this.params["CurrentPage"] = p;
		},
		getData() {
			if (!this.$store.hasMounted) return

			this.addExcelParams();

			clearInterval(this.filterInterval);
			this.filterInterval = setInterval(() => {
				clearInterval(this.filterInterval);
				this.$store.initLoading(this);
				axios
					.get(`/api/clientsDetailsV2/?${parseToQuery(this.params)}`)
					.then((res) => {
						if(res?.data?.responseCode != 0) {
							this.showErrors(res);
						} else {
							this.data = res.data;
						}
						
						this.$store.finishLoading(this);
						this.loaded = true;
					})
					.catch((err) => {
						this.$store.errorLoading(this);
						this.showErrors(err.response);
					});
			}, 1000);
		},
	},
	computed: {
		getCustomer() {
			return this.$store.customer;
		},
		getOrdersUrl() {
			return "/clientsDetailsV2";
		},
	},
	components: {
		CustomerDetailRow,
		Modal,
		EmptyElement,
		CurrencyStyle,
		BasePagination,
		HelpCenter,
	},

};
</script>
<style scoped>
.skeleton-card {
  margin-bottom: 10px;
}
</style>
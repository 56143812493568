<template>
	<div>
		<h4 class="wysiwyg-title">{{ title }}</h4>
		<div class="wysiwyg-toolbar">
			<button @click="execCommand('bold')"><strong>B</strong></button>
			<button @click="execCommand('italic')"><em>I</em></button>
			<button @click="execCommand('underline')"><u>U</u></button>
			<button @click="changeFontSize('increase')">+</button>
			<button @click="changeFontSize('decrease')">-</button>
		</div>
		<div>
			<div class="wysiwyg-editor" contenteditable="true">{{ this.banner }}</div>
		</div>
		<article class="btn-main btn-main-certificate">
			<Spinner v-if="isLoading" />
			<a v-else class="btn-active" @click="extractHTML">
				<p>GUARDAR</p>
			</a>
		</article>

	</div>
</template>
  
<script>
import Spinner from "./SpinnerComp.vue";
export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		shopCode: {
			type: String
		},
		banner: {
			type: String,
		}
	},
	data() {
		return {
			fontSize: '20'
		}
	},
	mounted() {
		const editor = document.querySelector('.wysiwyg-editor')
		editor.innerHTML = this.banner
	},
	computed: {
		isLoading () {
            return this.$store.getLoading;
        }
	},
	components: {
		Spinner
},
	methods: {
		execCommand(command) {
			event.preventDefault()
			document.execCommand(command, false, null);
		},
		changeFontSize(action) {
			event.preventDefault()
			if (action === 'increase')
				document.execCommand("fontSize", false, 5);
			else
				document.execCommand("fontSize", false, 3);
		},
		extractHTML() {
			event.preventDefault()
			const editor = document.querySelector('.wysiwyg-editor');
			const htmlContent = editor.innerHTML;
			this.$emit('onSaving', htmlContent, this.shopCode);
		}
	},
};
</script>
  
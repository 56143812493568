<template>
  <main>
    <!-- FILTRADO DE LA SECCION -->
    <article class="mainFilter filterEvent">
      <!-- TITULO Y SEARCH -->
      <section class="mainFilter-title">
        <h1>Caja</h1>
      </section>

      <!-- OPCIONES ADICIONALE -->
      <section class="mainFilter-filter">
        <!-- OPCION MODAL -->
        <a :href="`/api/report/${getBoxTarget}/?${excelParams}`"
          class="waves-effect waves-light btn-link filterRecord download">
          <span class="icon-Bistro-_Download"></span>
          <span>Descargar Detalle</span>
        </a>
      </section>
    </article>

    <!-- LISTADO DE PRODUCTOS -->
    <article :class="{ errorLoading: data.cashBoxItems && data.cashBoxItems.length === 0 }"
      class="mainEvents salesDashboard">
      <!-- TIPO DE TRANSACCION -->
      <section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">
        <div class="containTransaction">
          <ul class="containTransaction-lists">
            <!-- Titulo -->
            <li>
              <h4>Tipos de transacción</h4>
              <div class="switch">
                <label>
                  <input v-model="transactionTypeFilters" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 1 -->
            <li>
              <p>Depósito</p>
              <div class="switch">
                <label>
                  <input v-model="deposit" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 2 -->
            <li>
              <p>Cobro</p>
              <div class="switch">
                <label>
                  <input v-model="collect" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 3 -->
            <li>
              <p>Ajuste</p>
              <div class="switch">
                <label>
                  <input v-model="audit" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 4 -->
            <li>
              <p>Retiro</p>
              <div class="switch">
                <label>
                  <input v-model="withdrawal" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 5 -->
            <li>
              <p>Comanda</p>
              <div class="switch">
                <label>
                  <input v-model="order" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 6 -->
            <li>
              <p>Venta</p>
              <div class="switch">
                <label>
                  <input v-model="sale" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 7 -->
            <li>
              <p>Apertura de Caja</p>
              <div class="switch">
                <label>
                  <input v-model="openCashbox" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 8 -->
            <li>
              <p>Cierre de Caja</p>
              <div class="switch">
                <label>
                  <input v-model="closeCashbox" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>
            <!-- OPCION 9 -->
            <li>
              <p>Apertura de Turno</p>
              <div class="switch">
                <label>
                  <input v-model="openTurn" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 10 -->
            <li>
              <p>Cierre de Turno</p>
              <div class="switch">
                <label>
                  <input v-model="closeTurn" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>

            <!-- OPCION 11 -->
            <li>
              <p>Anulación</p>
              <div class="switch">
                <label>
                  <input v-model="voidMovement" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>
            <!-- OPCION 12 -->
            <li>
              <p>Anulación por facturación</p>
              <div class="switch">
                <label>
                  <input v-model="billed" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>
            <!-- OPCION 13 -->
            <li>
              <p>Nota de crédito</p>
              <div class="switch">
                <label>
                  <input v-model="creditNote" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>
            <!-- OPCION 14 -->
            <li v-if="hasToShowTip">
              <p>Propina</p>
              <div class="switch">
                <label>
                  <input v-model="tip" type="checkbox">
                  <span class="lever"></span>
                </label>
              </div>
            </li>
          </ul>
        </div>
        <!-- BTN ACTIVE FILTER MOBILE -->
        <button @click="showFilter = !showFilter" class="btnFilterMobile">
          <span class="icon-Bistro-_Filter"></span>
        </button>
      </section>

      <!-- LISTADO DE MOVIMIENTOS DE CAJA -->
      <ul v-if="data.cashBoxItems && data.cashBoxItems.length > 0" class="mainEvents-history">
        <table-row v-for="(item, index) in data.cashBoxItems" :target="getBoxTarget" :modal="false" :isSaleV2="false"
          :data="item" :key="index" />
      </ul>

      <empty-element v-else-if="data.cashBoxItems && data.cashBoxItems.length === 0 && loaded" />
      
      <article class="mainEvents-history">
        <article v-for="i in 4" :key="i" :class="sectionLoading"></article>
      </article>
    </article>

    <div v-if="data.cashBoxItems && data.cashBoxItems.length > 0 && isMoreOfOnePage" class="base-pagination">
      <BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
        class="list__pagination" @nextPage="pageChangeHandle('next')" @previousPage="pageChangeHandle('previous')"
        @loadPage="pageChangeHandle" />
    </div>
    <help-center />
  </main>
</template>

<script>
import axios from "axios";
import mixCommons from "../mixins/mixCommons";
import rolesCommons from "../mixins/rolesCommons";
import authCommons from "../mixins/authCommons";
import computeds from "../mixins/menuOptions";
import selectPdvs from "../mixins/select_pdvs";
import bistroV2Commons from "../mixins/bistroV2Commons";
import TableRow from "../components/TableRow";
import EmptyElement from "../components/EmptyElement";
import { parseToQuery } from "../utils/utils";
import BasePagination from "../components/BasePagination";
import HelpCenter from "../components/HelpCenter";

export default {
  mixins: [authCommons, mixCommons, computeds, selectPdvs, rolesCommons, bistroV2Commons],
  data() {
    return {
      data: {},
      filterInterval: null,
      transactionTypeFilters: true,
      deposit: true,
      collect: true,
      showFilter: false,
      loaded: false,
      audit: true,
      withdrawal: true,
      order: true,
      sale: true,
      openCashbox: true,
      closeCashbox: true,
      openTurn: true,
      closeTurn: true,
      voidMovement: true,
      billed: true,
      creditNote: true,
      tip: true,
      excelParams: '',
      params: {
        HasToFilterByDeposits: 'SI',
        HasToFilterByCollects: 'SI',
        HasToFilterByAudits: 'SI',
        HasToFilterByWithdrawals: 'SI',
        HasToFilterByOrders: 'SI',
        HasToFilterBySales: 'SI',
        HasToFilterByOpenCashbox: 'SI',
        HasToFilterByCloseCashbox: 'SI',
        HasToFilterByOpenTurn: 'SI',
        HasToFilterByCloseTurn: 'SI',
        HasToFilterByVoidMovement: 'SI',
        HasToFilterByBilled: 'SI',
        HasToFilterByCreditNote: 'SI',
        HasToFilterByTip: 'SI'
      },
      currentPage: 1,
      countPerPage: 50,
    }
  },
  async mounted() {
    document.title = `${this.appTitle}  - Caja`;
    this.$store.setLoadingSkeleton(true)
    if (this.isUserLoggedIn() === false) {
      axios.get('/api/auth/logout') /// OK
        .then(() => {
          this.$store.SET_USER(null)
          this.resetUser()
          this.setAutomaticLogin(false)
          location.href = '/login'
        })
    } else if (this.hasToShowThisOption(5) === false) {
      this.handleUnauthorizedAccess()
    } else {
      if (await this.checkSessionInBackendAsync()) {
        this.onMounted()
      }
    }
  },
  watch: {
    transactionTypeFilters() {
      this.setAllTransactionTypes(this.transactionTypeFilters)
    },
    deposit() {
      this.params['HasToFilterByDeposits'] = `${this.deposit ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    collect() {
      this.params['HasToFilterByCollects'] = `${this.collect ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    audit() {
      this.params['HasToFilterByAudits'] = `${this.audit ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    withdrawal() {
      this.params['HasToFilterByWithdrawals'] = `${this.withdrawal ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    order() {
      this.params['HasToFilterByOrders'] = `${this.order ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    sale() {
      this.params['HasToFilterBySales'] = `${this.sale ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    openCashbox() {
      this.params['HasToFilterByOpenCashbox'] = `${this.openCashbox ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    closeCashbox() {
      this.params['HasToFilterByCloseCashbox'] = `${this.closeCashbox ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    openTurn() {
      this.params['HasToFilterByOpenTurn'] = `${this.openTurn ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    closeTurn() {
      this.params['HasToFilterByCloseTurn'] = `${this.closeTurn ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    voidMovement() {
      this.params['HasToFilterByVoidMovement'] = `${this.voidMovement ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    billed() {
      this.params['HasToFilterByBilled'] = `${this.billed ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    creditNote() {
      this.params['HasToFilterByCreditNote'] = `${this.creditNote ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    tip() {
      this.params['HasToFilterByTip'] = `${this.tip ? 'SI' : 'NO'}`
      this.pageChangeHandle(1)
    },
    tab() {
      if (this.tab.id !== 6 && this.loaded) {
        this.params['Period'] = this.tab.param
        this.pageChangeHandle(1)
      }
    },
    dateCalendar() {
      if (this.dateCalendar.indexOf('Seleccione') < 0) {
        this.params['Period'] = this.tab.param
        this.pageChangeHandle(1)
      }
    }
  },
  computed: {
    getBoxTarget() {
      return 'boxV2'
    },
    hasToShowTip() {
      if (process.env.VUE_APP_INSTANCE_CODE == "AR") {
        return false
      } else {
        return true
      }
    },
    isMoreOfOnePage() {
      return (this.data.totalCount - this.countPerPage) > 0
    },
  },
  methods: {
    setAllTransactionTypes(bool) {
      this.deposit = bool
      this.collect = bool
      this.audit = bool
      this.withdrawal = bool
      this.order = bool
      this.sale = bool
      this.openCashbox = bool
      this.closeCashbox = bool
      this.openTurn = bool
      this.closeTurn = bool
      this.voidMovement = bool
      this.billed = bool
      this.creditNote = bool
      this.tip = bool
    },
    onMounted() {
      this.$store.setHasMounted(false)
      this.$store.updateSideMenu(-1);
      this.$store.updateTopMenu(5);
      this.$store.hideElements({});
      if (this.getPeriodSelectionFromLocalStorage() !== null) {
        this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
        this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
      } else {
        this.$store.initTabs()
      }
      this.toggleMerchantSelectorByLabel('showMerchantSelectionFilter')
      this.$store.CALL_MERCHANTS("CALL_MERCHANTS");
      this.params['CurrentPage'] = this.currentPage
      this.params['CountPerPage'] = this.countPerPage
      this.$store.updateCalendarType('range');
      this.$store.setHasMounted(true)
    },
    addExcelParams() {
      let p = this.params['CurrentPage']
      this.params['CurrentPage'] = null
      this.excelParams = parseToQuery(this.params)
      this.params['CurrentPage'] = p
    },
    getData() {
      if (!this.$store.hasMounted) return
      this.addExcelParams()
      clearInterval(this.filterInterval)
      this.filterInterval = setInterval(() => {
        clearInterval(this.filterInterval)

        this.$store.initLoading(this);
        axios.get(`/api/${this.getBoxTarget}/?${parseToQuery(this.params)}`)
          .then(res => {
            this.data = res.data
            this.$store.finishLoading(this);
            this.$store.setLoadingSkeleton(false);

            this.loaded = true
          })
          .catch(err => {
            this.$store.errorLoading(this);
            this.showErrors(err.response)
          })
      }, 1000)

    }
  },
  components: {
    TableRow,
    EmptyElement,
    BasePagination,
    HelpCenter,
  },
};
</script>

<style scoped>
.skeleton-card {
  margin-bottom: 10px;
}
</style>

<template>
  <div class="loader-container">
    <v-progress-linear
      v-model="this.$store.getProgress"
      color="#24a5d3"
      striped
      v-if="this.$store.getLoading"
    ></v-progress-linear>
  </div>
  <component :is="$route.meta.layout || 'div'">
    <RouterView />
  </component>
</template>

<script>
import { VProgressLinear } from "vuetify/lib/components/index.mjs";

export default {
  components: {
    VProgressLinear,
  },
};
</script>
<style lang="scss">
.loader-container {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
}
</style>

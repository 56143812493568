<script>
export default {
  methods: {
    changeProviderActionTo(action) {
      this.$store.setHasToShowProviderFilters(true);
      if (action === "provider") {
        this.$store.setProviderActionSelected(action);
      } else if (action === "bills") {
        this.$store.setProviderActionSelected(action);
      }
    },
  },
  computed: {
    providerTabSelected() {
      console.log("MAITINI",this.$store.getProviderActionSelected);
      return this.providerTabs.filter(
        (x) => x.param === this.$store.getProviderActionSelected
      )[0];
    },
  },
  data() {
    return {
      providerTabs: [
        {
          id: 1,
          title: "Administración de Proveedores",
          param: "provider",
          show: true,
          canBeHide: true,
        },
        {
          id: 2,
          title: "Administración de Comprobantes",
          param: "bills",
          show: true,
          canBeHide: true,
        },
      ],
    };
  },
};
</script>

<template>
		<div :id="id" class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="clear()">
		<div class="modal-header">
			<div class="image-title">
				<h4>{{title}}</h4>
			</div>
			<div class="btnClose">
				<a href="#!" @click="clear()" class="modal-action modal-close waves-effect waves-green btn-flat">
				<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<!-- BODY MODAL -->
		<div class="modal-body">
			<form action class="formGeneral modal-message">
				<section class="formMain modal-message">
					<h4>{{message}}</h4>
					<div class="switch">
						<label class="switchTitle">
							<a class="switchLabelExtendedForNews">No quiero recibir esta noticia nuevamente</a>
							<input v-model="dontWantToSeeThisAgain" type="checkbox">
							<span class="lever-small"></span>
						</label>
					</div>
					<section class="modal-message-footer">
						<article class="btn-main">
							<a @click="onModalClosing()">
								<p>ENTENDIDO</p>
							</a>
						</article>
					</section>

				</section>

			</form>
		</div>


	</div>
</template>
<script>
import $ from 'jquery'
import mixCommons from "../../mixins/mixCommons";
export default {
	mixins: [mixCommons],
	props: {
		id: {
			type: String
		},
		message: {
			type: String
		},
		title: {
			type: String
		}
	},
	data () {
		return {
			dontWantToSeeThisAgain: false
		}
	},
	computed: {

	},
	methods: {
		onModalClosing() {
			if(this.dontWantToSeeThisAgain) {
				window.localStorage.setItem(`${this.id}_${this.$store.getAuthUser.user.name}`, true)
			}
			this.$emit('onModalClosing')
		},
		clear () {
			if(this.dontWantToSeeThisAgain) {
				window.localStorage.setItem(`${this.id}_${this.$store.getAuthUser.user.name}`, true)
			}
			$('#__layout').css('pointer-events', 'all')
			// $(`#${this.id}`).modal('close')
		}
	}
}
</script>

import { db } from "../../../../firebase";
import { ref, get, set } from "firebase/database";

const setLastUpdateTimestamp = (shopCode) => {
  localStorage.setItem(`lastUpdateTimestamp_${shopCode}`, Date.now());
};
const cacheShopProducts = (shopCode, products) => {
  localStorage.setItem(`products_${shopCode}`, JSON.stringify(products));
  setLastUpdateTimestamp(shopCode);
};
const cacheShopCategories = (shopCode, categories) => {
  localStorage.setItem(`categories_${shopCode}`, JSON.stringify(categories));
  setLastUpdateTimestamp(shopCode);
};

const getLastUpdateTimestamp = async (shopCode) => {
  try {
    if(shopCode){
      const timestampRef = ref(db, `products/${shopCode}/lastUpdateTimestamp`);
      const snapshot = await get(timestampRef);
      return snapshot.exists() ? snapshot.val() : null;
    }
    return null
  } catch (error) {
    console.error("Error obteniendo lastUpdateTimestamp:", error);
    return null;
  }
};

const getCachedProducts = (shopCode) => {
  const cachedProducts = localStorage.getItem(`products_${shopCode}`);

  if (cachedProducts) return JSON.parse(cachedProducts);

  return null;
};
const getCachedCategories = (shopCode) => {
  const cachedCategories = localStorage.getItem(`categories_${shopCode}`);

  if (cachedCategories) return JSON.parse(cachedCategories);

  return null;
};

const shouldFetchFromFirebase = (lastUpdateTimestamp, shopCode) => {
  const localLastUpdateTimestamp = localStorage.getItem(`lastUpdateTimestamp_${shopCode}`);
  return !lastUpdateTimestamp || lastUpdateTimestamp !== localLastUpdateTimestamp;
};


const createLastUpdateTimestamp = async (shopCode) => {
  try {
      const timestampRef = ref(db, `products/${shopCode}/lastUpdateTimestamp`);
      const timestamp = Date.now();
      await set(timestampRef, timestamp);
      console.log("lastUpdateTimestamp creado exitosamente");
 
  } catch (error) {
    console.error("Error al crear lastUpdateTimestamp:", error);
  }
};

const getFromDb = (shopCode) => {
  return new Promise((resolve, reject) => {
    const productsRef = ref(db, "products/" + shopCode);
    get(productsRef).then(
      (snapshot) => {
        const list = snapshot.val();
        if (!list?.idList) {
          reject(new Error("idList not found"));
          return;
        }

        const listProductsRef = ref(db, "listProducts/" + list.idList);
        get(listProductsRef).then(
          (snapshot) => {
            const resp = snapshot.val();
            if (resp == null) {
              resolve([]);
            } else {
              resolve(resp);
            }
          },
          (error) => reject(error)
        );
      },
      (error) => reject(error)
    );
  });
};

// const getOrigins = async (shopCode) => {
//     try {
//         const resp = await getFromDb(shopCode);
//         if (resp.origins !== undefined && resp.origins !== null) {
//             return resp.origins;
//         } else {
//             return [];
//         }
//     } catch (error) {
//         console.error('Error getting origins:', error);
//         return [];
//     }
// };

const getProducts = async (shopCode) => {
  // Primero, intenta obtener el `lastUpdateTimestamp` desde Firebase
  const lastUpdateTimestamp = await getLastUpdateTimestamp(shopCode);
  //  verificar si shouldFetchFromFirebase es valido
  const hasToGoToFirebaseToGetProducts = shouldFetchFromFirebase(
    lastUpdateTimestamp,
    shopCode
  );
  if (!hasToGoToFirebaseToGetProducts) {
    const cachedProducts = getCachedProducts(shopCode);
    if (cachedProducts) {
      console.log("Productos obtenidos del local");
      return cachedProducts;
    }
  }

  // Si no existe lastUpdateTimestamp o no estan los productos en el localStore, consulta Firebase
  try {
    const resp = await getFromDb(shopCode);
    if (resp.products !== undefined && resp.products !== null) {
      const prods = Object.entries(resp.products)
        .map(([id, product]) => ({ id, ...product }))
        .filter((product) => product.origins && product.origins.length > 0);

      cacheShopProducts(shopCode, prods);
      return prods;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error getting products:", error);
    return [];
  }
};

const getCategories = async (shopCode) => {
  // Primero, intenta obtener el `lastUpdateTimestamp` desde Firebase
  const lastUpdateTimestamp = await getLastUpdateTimestamp(shopCode);
  console.log(lastUpdateTimestamp);

  // Verificar si debería actualizar las categorías desde Firebase
  const hasToGoToFirebaseToGetCategories = shouldFetchFromFirebase(
    lastUpdateTimestamp,
    shopCode
  );
  if (!hasToGoToFirebaseToGetCategories) {
    const cachedCategories = getCachedCategories(shopCode);
    if (cachedCategories) {
      console.log("Categorías obtenidas del local");
      return cachedCategories;
    }
  }

  // Si no existe lastUpdateTimestamp o no están las categorías en el caché local, consulta Firebase
  try {
    const categoriesRef = ref(db, "products/categories" + shopCode);
    const snapshot = await get(categoriesRef);
    const categories = snapshot.val();

    if (categories) {
      cacheShopCategories(shopCode, categories);
      return categories;
    } else {
      console.log("No se encontraron categorías en Firebase.");
      return [];
    }
  } catch (error) {
    console.error("Error obteniendo categorías:", error);
    return [];
  }
};

export default {
  getProducts,
  getCategories,
  createLastUpdateTimestamp,
};

<template>
	<li>
		<figure v-if="data.canSelectRow" class="iconHistory">
			<div class="switch">
				<label class="switchTitle">
					<input v-model="/* eslint-disable */ data.isChecked /* eslint-enabled */" type="checkbox">
					<span class="lever"></span>
				</label>
			</div>
		</figure>
		<figure v-else :class="{ 'iconHistory': true, 'switchColumn': canSelectRow }">
			<span
				:class="{ 'red-tick': data.status === 1, 'salesNull': data.movementType === 12, 'yellow': data.status === 3, 'icon-Bistro-_Check': true }"></span>
		</figure>

		<!-- FECHA -->
		<div class="items">
			<h5>{{ data.date }}</h5>
			<p>{{ data.hour }}</p>
		</div>

		<!-- OPERACION -->
		<div class="items">
			<div v-if="data.movementType && data.movementType !== null">

				<!-- <div class="fiscal-data">{{ data.movementType }}</div> -->
				<p v-if="data.movementType >= 0"
					:class="$filters.customerDetailCashbox(data.movementType + 1, 'class', data.paymentType + 1)">
					{{ $filters.customerDetailCashbox(data.movementType + 1, 'text', data.paymentType + 1) }}
				</p>
			</div>
			<div
				v-else-if="data.relatedFiscalInfo && data.relatedFiscalInfo.billType !== null && data.relatedFiscalInfo.billNumber !== null">
				<div class="fiscal-data">{{ data.relatedFiscalInfo.billType }}</div>
				<div class="fiscal-data">{{ data.relatedFiscalInfo.billNumber }}</div>
				<div class="fiscal-data">{{ data.relatedFiscalInfo.customerName }}</div>
				<div class="fiscal-data">{{ data.relatedFiscalInfo.customerFiscalKey }}</div>

			</div>
			<div v-else>
				<p v-if="data.cashBoxItemType >= 0"
					:class="$filters.customerDetailCashbox(data.cashBoxItemType + 1, 'class', data.paymentType + 1)">
					{{ $filters.customerDetailCashbox(data.cashBoxItemType + 1, 'text', data.paymentType + 1) }}
				</p>
				<p v-if="data.customer">{{ data.customer }}</p>
				<p v-if="data.eventName">{{ data.eventName }}</p>
			</div>


		</div>

		<div class="items">
			<span v-if="data.paymentType !== null" :title="$filters.payment(data.paymentType + 1, 'text')"
				:class="$filters.payment(data.paymentType + 1, 'icon')">
			</span>
			<p class="table-row-info" v-if="data.paymentInfo && data.id == 0">/ {{ data.paymentInfo }} {{
				showComments(data) }}</p>
			<p class="table-row-info" v-else-if="data.paymentInfo">/ {{ data.paymentInfo }}</p>
			<p class="table-row-info" v-else>{{ data.comments }}</p>


			<!-- OPCION MODAL -->
			<div v-if="modal && hasToShowDetail(data)" class="btn-main">
				<a @click="openModal(data)" class="waves-effect waves-light">
					<span class="icon-Bistro-_Search"></span>
					<p>VER DETALLE</p>
				</a>
			</div>
		</div>

		<!-- TOTAL -->
		<div :class="{ 'items': isRealSale, 'items-grey': !isRealSale }">
			<h3 v-if="data.amount"
				:class="{ 'depositAmount': data.movementType === 12, 'negative-numbers': data.amount < 0 }">
				<currency-style :valor="$filters.currency(data.amount)" />
			</h3>
			<h3 v-else class="cero-numbers"><currency-style :valor="$filters.currency(0)" /></h3>
		</div>
	</li>
</template>
<script>
import axios from 'axios'
import CurrencyStyle from '../components/CurrencyStyle'
import { parseToQuery } from '../utils/utils'

export default {
	props: {
		modal: {
			type: Boolean,
			default: false
		},
		isSaleV2: {
			type: Boolean,
			default: false
		},
		data: {
			type: Object
		},
		target: {
			type: String
		},
		canSelectRow: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			params: {
				SaleId: null,
				RelatedDeviceId: null
			}
		}
	},
	computed: {

		isRealSale() {
			if (this.data) {
				return !(this.data.paymentType + 1 === 12 || this.data.paymentType + 1 === 13)
			}
			else {
				return true
			}
		}
	},
	methods: {
		showComments(data) {
			if (data.comments) return data.comments.toUpperCase()
			else return data.comments
		},
		hasToShowDetail(data) {
			return data.id > 0
		},
		openModal(info) {
			if (this.target === 'consolidatedV2') {

				this.$store.initLoading(this)
				this.params['SaleId'] = info.id
				this.params['Timestamp'] = `${info.date} ${info.hour}`
				this.params['RelatedDeviceId'] = info.relatedDeviceId
				this.params['UUID'] = info.uuid
				axios
					.get(`/api/${this.target}/saleDetails/?${parseToQuery(this.params)}`)
					.then(res => {
						this.$store.finishLoading(this)
						if (this.isSaleV2) {
							this.$store.updateModalInfoV2(res.data)
						} else {
							this.$store.updateModalInfo(res.data)
						}
						/* eslint-disable */
						var modalResumSales = document.getElementById("modalResumSales");
						var instance = M.Modal.init(modalResumSales);
						instance.open();
						/* eslint-enable */
					})
					.catch(err => {
						this.showErrors(err.response)
						this.$store.errorLoading(this)

					})
			} else {
				axios
					.get(`/api/${this.target}/${info.id}`) /// OK
					.then(res => {
						if (this.isSaleV2) {
							this.$store.updateModalInfoV2(res.data)
						} else {
							this.$store.updateModalInfo(res.data)
						}
						/* eslint-disable */
						var modalResumSales = document.getElementById("modalResumSales");
						var instance = M.Modal.init(modalResumSales);
						instance.open();
						/* eslint-enable */
					})
			}
		}
	},
	components: {
		CurrencyStyle
	}
}
</script>

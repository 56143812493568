<template>
	<section class="mainContain-info">
		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<h1>Reportes</h1>
			</section>
		</article>

		<!-- LISTADO DE REPORTES -->
		<article class="mainEvents reportDashboard">
			<!-- LISTADO DE REPORTES -->
			<ul class="optionsReport">
				<!-- CIERRE DE CAJA -->
				<li class="tooltip">
					<router-link to="/dashboard/report/box">
						<figure class="iconFactu">
							<span class="icon-Bistro-_ArrowItem"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Histórico cierres de caja</span>
							<p>Reportes</p>
							<h3>Cierre de Caja</h3>
						</div>
					</router-link>
				</li>

				<!-- REPORTES DE VENTAS -->
				<li class="tooltip">
					<router-link to="/dashboard/report/sales">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Buy"></span>
						</figure>

						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Total de productos más vendidos. (No contempla descuentos)</span>
							<p>Reportes</p>
							<h3>Ranking de Ventas</h3>
						</div>
					</router-link>
				</li>

				<li class="tooltip">
					<router-link to="/dashboard/report/dailyRankingSales">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Buy"></span>
						</figure>

						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Total de productos más vendidos por día. (No contempla
								descuentos)</span>
							<p>Reportes</p>
							<h3>Ranking de V. Diario</h3>
						</div>
					</router-link>
				</li>

				<!-- REPORTES DE VENTAS POR DIAS -->
				<li class="tooltip">
					<router-link to="/dashboard/report/days">
						<figure class="iconFactu">
							<span class="icon-Bistro-_24hrs"></span>
						</figure>

						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de: totales de ventas, comandas diarias y sus medios de
								pagos</span>
							<p>Reportes</p>
							<h3>Ventas por días</h3>
						</div>
					</router-link>
				</li>

				<!-- REPORTES DE VENTAS POR DIAS -->
				<li class="tooltip">
					<router-link to="/dashboard/report/month">
						<figure class="iconFactu">
							<span class="icon-Bistro-_calendar"></span>
						</figure>

						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de: totales de ventas, comandas mensuales y sus medios
								de pagos.</span>
							<p>Reportes</p>
							<h3>Ventas por mes</h3>
						</div>
					</router-link>
				</li>
				<!-- DETALLE DE TRANSACCIONES -->
				<li class="tooltip">
					<router-link to="/dashboard/report/transactionDetailReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Dashboard"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de cada una de las ventas y comandas, con ítems,
								descuentos vinculados, medios de pagos y usuarios del
								equipo</span>
							<p>Reportes</p>
							<h3>Detalle de Trnx.</h3>
						</div>
					</router-link>
				</li>
				<!-- VENTAS POR PRODUCTO -->
				<li class="tooltip">
					<a href="#" @click="exportProductSoldPerDayReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Buy"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de los productos vendidos por día</span>
							<p>Reportes</p>
							<h3>Ventas por Prod.</h3>
						</div>
					</a>
				</li>
				<!-- VENTAS POR ADICIONALES -->
				<li class="tooltip">
					<router-link to="/dashboard/report/additionals">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Buy"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de adicionales vendidos</span>
							<p>Reportes</p>
							<h3>Adicionales</h3>
						</div>
					</router-link>
				</li>
				<!-- REPORTE DE STOCK MENSUALES -->
				<li class="tooltip">
					<router-link to="/dashboard/report/stockMonthlyReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_StockReport"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de los productos y sus movimientos de stock
								diarios</span>
							<p>Reportes</p>
							<h3>Control de Stock</h3>
						</div>
					</router-link>
				</li>
				<!-- REPORTE DE QUIEBRE DE STOCK -->
				<li v-if="listOfShopsThatHaveToShowBreakStock" class="tooltip">
					<a href="#" @click="exportBreakStockReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Dashboard"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle del momento en el que cada producto llegó a stock 0</span>
							<p>Reportes</p>
							<h3>Quiebre de Stock</h3>
						</div>
					</a>
				</li>
				<!-- REPORTE DE VENTAS POR COMBO -->
				<li class="tooltip">
					<router-link to="/dashboard/report/comboSalesReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Buy"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de los productos vendidos por día de manera unitaria y
								por combo</span>
							<p>Reportes</p>
							<h3>Ventas por Combo</h3>
						</div>
					</router-link>
				</li>
				<!-- REPORTE DE CONTROL HORARIO DE USUARIOS -->
				<li class="tooltip">
					<a href="#" @click="exportUserTimeTableReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Dashboard"></span>
						</figure>
						<div class="items">
							<span class="tooltiptext">Detalle del ingreso y egreso de cada uno de los usuarios dados
								de alta en el equipo.</span>
							<p>Reportes</p>
							<h3>Control Horario</h3>
						</div>
					</a>
				</li>
				<!-- REPORTE DE FACTURACION ESPAÑA -->
				<li class="tooltip" v-if="hasToShowBillingReport">
					<a href="#" @click="exportBillingReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Dashboard"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de facturación realizada por el comercio.</span>
							<p>Reportes</p>
							<h3>Facturación</h3>
						</div>
					</a>
				</li>
				<!-- REPORTE IVA VENTAS -->
				<li class="tooltip" v-if="hasToShowVatReport">
					<a href="#" @click="exportVatReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Dashboard"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de todas las facturas emitidas
							</span>
							<p>Reportes</p>
							<h3>Iva Ventas</h3>
						</div>
					</a>
				</li>
				<!-- REPORTE MOZOS -->
				<li class="tooltip" v-if="hasToShowThisOption">
					<router-link to="/dashboard/report/waiterReport" :class="{ active: option === 0 }">
						<figure class="iconFactu">
							<span class="icon-Bistro-_StockReport"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Detalle de ventas por {{ getWaiterReportName }} </span>
							<p>Reportes</p>
							<h3>{{ getWaiterReportName }}</h3>
						</div>
					</router-link>
				</li>
				<!-- REPORTE DE TARJETAS -->
				<li class="tooltip">
					<a href="#" @click="exportCardReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Dashboard"></span>
						</figure>
						<div class="items">
							<span class="tooltiptext">Detalle de ventas por tarjeta.</span>
							<p>Reportes</p>
							<h3>Reporte de Tarjetas</h3>
						</div>
					</a>
				</li>
				<!-- REPORTE DE COSTOS DE MERCADERIA -->
				<li v-if="hasToShowIngredientsReport" class="tooltip">
					<a href="#" @click="exportMerchandiseCostReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Dashboard"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Reporte para revisar costos y precios de productos</span>
							<p>Reportes
								<badge type="beta" />
							</p>
							<h3>Costos de Mercadería</h3>
						</div>
					</a>
				</li>
				<!-- REPORTE DE COSTOS DE ADICIONALES -->
				<li v-if="hasToShowIngredientsReport" class="tooltip">
					<a href="#" @click="exportAdditionalCostReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_Dashboard"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Reporte para revisar costos y precios de adicionales</span>
							<p>Reportes
								<badge type="beta" />
							</p>
							<h3>Costos de Adicionales</h3>
						</div>
					</a>
				</li>

				<!-- RENTABILIDAD DE CARTA -->
				<li class="tooltip" v-if="hasToShowIngredientsReport">
					<router-link to="/dashboard/report/menuProfitabilityReport">
						<figure class="iconFactu">
							<span class="icon-Bistro-_StockReport"></span>
						</figure>
						<!-- CONCEPTO -->
						<div class="items">
							<span class="tooltiptext">Reporte para ver como performan los productos dentro de una
								carta</span>
							<p>Reportes
								<badge type="beta" />
							</p>
							<h3>Rentabilidad - Carta</h3>
						</div>
					</router-link>
				</li>
			</ul>
		</article>
		<help-center />
	</section>
</template>
<script>
import commons from "@/mixins/mixCommons";
import rolesCommons from "@/mixins/rolesCommons";
import authCommons from "@/mixins/authCommons";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import productV2Commons from "@/mixins/productV2Commons";
import reportCommons from "@/mixins/reportCommons";
import axios from "axios";
import computeds from "@/mixins/menuOptions";
import tester from "@/mixins/mixTester";
import HelpCenter from "@/components/HelpCenter";
import Badge from "@/components/BadgeComp";
import { parseToQuery } from "@/utils/utils";

export default {
	mixins: [authCommons, commons, computeds, tester, rolesCommons, bistroV2Commons, productV2Commons, reportCommons],
	data() {
		return {
			params: {},
		};
	},
	async mounted() {
		document.title = `${this.appTitle} - Reportes`
		if (this.isUserLoggedIn() === false) {
			axios
				.get("/api/auth/logout") /// OK
				.then(() => {
					this.$store.SET_USER(null);
					this.resetUser();
					this.setAutomaticLogin(false);
					location.href = "/login";
				});
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.onMounted()
			}

		}

	},
	watch: {
		dateCalendar() {
			if (this.dateCalendar.indexOf("Seleccione") < 0) {
				this.params["Period"] = this.tab.param;
			}
		},
		selectedPdvs() {
			this.processMerchantFilter();
		},
		tab() {
			if (this.tab.id !== 6) {
				this.params["Period"] = this.tab.param;
			}
		},
	},
	computed: {

		listOfShopsThatHaveToShowBreakStock() {
			// Verifica que getShopsThatHaveToShowBreakStock esté definido
			if (this.$store.getShopsThatHaveToShowBreakStock !== undefined && Array.isArray(this.$store.getShopsThatHaveToShowBreakStock)) {

				// Si hay comercios seleccionados
				if (this.selectedPdvs.length > 0) {
					for (let i = 0; i < this.selectedPdvs.length; i++) {
						for (let j = 0; j < this.$store.getShopsThatHaveToShowBreakStock.length; j++) {
							// Compara los códigos de los comercios
							if (this.selectedPdvs[i].shopCode === this.$store.getShopsThatHaveToShowBreakStock[j].shopCode) {
								return true;
							}
						}
					}
				}
				// Si NO se ha seleccionado ningún comercio
				else if (this.selectedPdvs.length === 0) {
					let limit = this.$store.getMerchantTags.length;
					for (let i = 0; i < limit; i++) {
						for (let j = 0; j < this.$store.getShopsThatHaveToShowBreakStock.length; j++) {
							// Compara los códigos de los comercios en los MerchantTags
							if (this.$store.getMerchantTags[i].shopCode === this.$store.getShopsThatHaveToShowBreakStock[j].shopCode) {
								return true;
							}
						}
					}
				}
			}
			// Retorno por defecto si no se encuentra coincidencia o la lista no está definida
			return false;
		},
		isSpain() {
			return process.env.VUE_APP_INSTANCE_CODE == "ES";
		},
		hasToShowIngredientsReport() {
			return (
				this.$store.getShopsThatHaveToShowIngredients &&
				this.$store.getShopsThatHaveToShowIngredients[0]
			);
		},
		hasToShowBillingReport() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				default:
					return false;
				case "ES":
					return true;
			}
		},
		hasToShowVatReport() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				default:
					return false;
				case "AR":
					return true;
			}
		},
	},
	methods: {
		onMounted() {
			this.$store.updateTopMenu(0);
			this.$store.updateSideMenu(3);
			this.$store.hideElements({
				hideCalendar: false,
				hideFilters: false,
				hideTabs: false,
			});
			this.$store.updateCalendarType("range");
			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				this.$store.
					updateTabSelected(
						this.getPeriodSelectionFromLocalStorage()
					);
				this.params["Period"] = this.getPeriodSelectionFromLocalStorage().param;
			} else {
				this.$store.initTabs();
			}

			this.toggleMerchantSelectorByLabel("showMerchantSelectionFilter");
			this.$store.CALL_MERCHANTS;


			this.getContextFromBackend();
		},

		processMerchantFilter() {
			let ids = this.selectedPdvs.map((item) => {
				return item.shopCode;
			});
			if (ids.length > 0) {
				this.params["MerchantFilter"] = ids;
			} else {
				delete this.params["MerchantFilter"];
			}
		},
		async exportTransactionDetailReport() {
			if (await this.checkSessionInBackendAsync()) {
				let url = "";
				this.processMerchantFilter();
				url = `/api/report/transaction_details_V2?${parseToQuery(this.params)}`;

				let win = window.open(url, "_blank");
				win.focus();
			}

		},
		async exportUserTimeTableReport() {
			if (await this.checkSessionInBackendAsync()) {
				axios
					.get(`/api/report/userTimeTableReportV2/?${parseToQuery(this.params)}`)
					.then((res) => {
						if (res.data.responseCode === 0) {
							if (res.data.url) {
								var download = document.createElement("a");
								download.href = res.data.url;
								download.download = `${res.data.url}.xlsx`;
								download.click();
							} else {
								this.showSuccess(res.data.responseMessage);
							}
							this.$store.finishLoading(this);
						} else {
							this.showError(res.data.responseMessage);
							this.$store.errorLoading(this);
						}
					})
					.catch((err) => {
						this.showError(
							"Hubo un problema al procesar el reporte de control horario"
						);
						console.error(`exportUserTimeTableReport ERROR->`, err);
						this.$store.errorLoading(this);
					});
			}

		},

		exportBreakStockReport() {

			if (this.params["MerchantFilter"] == undefined || this.params["MerchantFilter"].length == 0) {

				let ids = [];

				for (let i = 0; i < this.$store.getters.merchantTags.length; i++) {
					for (let j = 0; j < this.$store.getters.shopsThatHaveToShowBreakStock.length; j++) {
						if (this.$store.getters.merchantTags[i].shopCode == this.$store.getters.shopsThatHaveToShowBreakStock[j].shopCode) {
							ids.push(this.$store.getters.merchantTags[i].shopCode);
						}
					}
				}

				this.params["MerchantFilter"] = ids;

			}

			axios
				.get(`/api/report/breakStockReport/?${parseToQuery(this.params)}`)
				.then((res) => {
					if (res.data.responseCode === 0) {
						if (res.data.url) {
							var download = document.createElement("a");
							download.href = res.data.url;
							download.download = `${res.data.url}.xlsx`;
							download.click();
						} else {
							this.showSuccess(res.data.responseMessage);
						}
						this.$store.finishLoading(this);
					} else {
						this.showError(res.data.responseMessage);
						this.$store.errorLoading(this);
					}
				})
				.catch((err) => {
					this.showError(
						"Hubo un problema al procesar el reporte de quiebre de stock"
					);
					console.error(`exportBreakStockReport ERROR->`, err);
					this.$store.errorLoading(this);
				});
		},

		async exportBillingReport() {
			if (await this.checkSessionInBackendAsync()) {
				const url = `/api/report/billingReportV2?${parseToQuery(this.params)}`;
				this.processMerchantFilter();

				let win = window.open(url, "_blank");
				win.focus();
			}
		},
		async exportVatReport() {
			if (await this.checkSessionInBackendAsync()) {
				const url = `/api/report/vatReport?${parseToQuery(this.params)}`;
				this.processMerchantFilter();
				let win = window.open(url, "_blank");
				win.focus();
			}

		},
		async exportMerchandiseCostReport() {
			if (await this.checkSessionInBackendAsync()) {
				const url = `/api/report/merchandiseCostReport?${parseToQuery(
					this.params
				)}`;
				this.processMerchantFilter();

				let win = window.open(url, "_blank");
				win.focus();
			}

		},
		async exportAdditionalCostReport() {
			if (await this.checkSessionInBackendAsync()) {
				const url = `/api/report/additionalsCostReport?${parseToQuery(
					this.params
				)}`;
				this.processMerchantFilter();

				let win = window.open(url, "_blank");
				win.focus();
			}
		},
		async exportProductSoldPerDayReport() {
			if (await this.checkSessionInBackendAsync()) {
				let url = "";
				url = `/api/report/products_sold_per_day_V2?${parseToQuery(
					this.params
				)}`;
				let win = window.open(url, "_blank");
				win.focus();
			}

		},
		async exportCardReport() {
			if (await this.checkSessionInBackendAsync()) {
				const url = `/api/report/cardReportV1`;
				this.processMerchantFilter();

				let win = window.open(url, "_blank");
				win.focus();
			}

		},
	},
	components: {
		HelpCenter,
		Badge,
	},
};
</script>

<template>
  <div id="modalCategory" class="modal modal-block-outside" style="overflow-y: auto !important"
    @keydown.esc="closeModal()">
    <div class="modal-header">
      <div class="image-title">
        <h4 v-if="isNewCategory">{{ buttonAction }}</h4>
        <h4 v-else>{{ categoryUpdateTitle }}</h4>
        <p>Fácil y Simple</p>
      </div>

      <div class="btnClose">
        <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <article class="formMain article-product">
          <div class="itemForm selectImage">
            <label class="label" for>Nombre de la categoría:</label>
            <input class="input" v-model="categoryLocal.name" type="text"
              placeholder="Ingrese nombre de la categoría" />
          </div>
          <div class="itemForm selectImage">
            <label class="label" for>Orden en la lista de categorías:</label>
            <input class="input" v-model="categoryLocal.orderNumber" min="1" step="1" type="number"
              placeholder="Ingrese un valor númerico que represente el orden de aparición de esta categoría en la carta" />
          </div>
          <div class="itemForm">
            <div class="switch">
              <label class="switchTitle">
                <a class="switchLabelExtended">Imprime en cocina?</a>
                <input id="hasToPrintInKitchenId" v-model="categoryLocal.hasToPrintInKitchen" type="checkbox" />
                <span class="lever"></span>
              </label>
            </div>
            <div class="switch">
              <label class="switchTitle">
                <a class="switchLabelExtended">Imprime en mostrador?</a>
                <input id="hasToPrintOnCounterId" v-model="categoryLocal.hasToPrintOnCounter" type="checkbox" />
                <span class="lever"></span>
              </label>
            </div>
            <div class="switch">
              <label class="switchTitle">
                <a class="switchLabelExtended">Imprime en adicional?</a>
                <input id="hasToPrintOnAdditionalPrinterId" v-model="categoryLocal.hasToPrintOnAdditionalPrinter"
                  type="checkbox" />
                <span class="lever"></span>
              </label>
            </div>
            <div class="switch">
              <label class="switchTitle">
                <a class="switchLabelExtended">Limitar gustos/adicionales?</a>
                <input id="hasToEnableFlavorLimit" type="checkbox" v-model="hasToShowFlavorOptions" />
                <span class="lever"></span>
              </label>
              <div v-if="hasToShowFlavorOptions" class="flavor-limit-options">
                <label class="flavour-input">
                  <p>Mínimo</p>
                  <input type="number" v-model="parsedMinimumFlavorLimit" />
                </label>
                <label class="flavour-input">
                  <p>Máximo</p>
                  <input type="number" v-model="parsedMaximumFlavorLimit" />
                </label>
              </div>
            </div>
          </div>
        </article>

        <div v-if="!isNewCategory">
          <div class="itemForm fix-width addMargin" v-for="(og, index) in categoryLocal.optionsGroups" :key="index">
            <ul>
              <h4>{{ og.name }}:</h4>
              <div v-if="og.name === 'Adicionales'">
                <li v-for="(o, index) in og.options" :key="index">
                  <span>- {{ o.name }} x </span><span><currency-style class="inlineCurrency"
                      :valor="$filters.currency(o.price)" /></span>
                </li>
              </div>
              <div v-else>
                <li v-for="(o, index) in og.options" :key="index">
                  - {{ o.name }}
                </li>
              </div>
            </ul>
          </div>
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <a v-if="!isNewCategory" class="deleteProduct" @click.prevent="deleteCategory">
          <span class="icon-Bistro-_Trash_bin"></span><span>Eliminar categoria</span>
        </a>
        <SpinnerComp v-if="isLoading" />
        <a v-else @click="upsertCategory" type="submit">
          <p v-if="isNewCategory">{{ buttonAction }}</p>
          <p v-else>{{ buttonUpdate }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import SpinnerComp from "../components/SpinnerComp";
import mixTester from "../mixins/mixTester";
import CurrencyStyle from "../components/CurrencyStyle";
import { toast } from "vue3-toastify";
export default {
  /* eslint-disable */
  mixins: [mixTester],
  props: {
    category: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isNewCategory: {
      type: Boolean,
    },
  },
  data() {
    return {
      buttonAction: "Crear Categoría",
      buttonUpdate: "Guardar Categoría",
      categoryUpdateTitle: "Editar Categoría",
      isLoading: false,
      hasToShowFlavorOptions: false,
      minimumFlavorLimit: null,
      maximumFlavorLimit: null,
      categoryLocal:
      {
        name: ""
      },
    };
  },
  mounted() {
    this.categoryLocal = { ...this.category };
  },
  computed: {
    parsedMinimumFlavorLimit: {
      get() {
        return this.minimumFlavorLimit;
      },
      set(value) {
        // Asegurarse de que el valor sea un número antes de asignarlo
        this.minimumFlavorLimit = parseInt(value);
      },
    },
    parsedMaximumFlavorLimit: {
      get() {
        return this.maximumFlavorLimit;
      },
      set(value) {
        // Asegurarse de que el valor sea un número antes de asignarlo
        this.maximumFlavorLimit = parseInt(value);
      },
    },
  },
  watch: {
    category() {
      this.initHasToPrintInKitchen();
      this.initHasToPrintOnCounter();
      this.initHasToPrintOnAdditionalPrinter();
      this.initHasToEnableFlavorLimit();
      this.categoryLocal = { ...this.category };
    },
    hasToShowFlavorOptions() {
      this.categoryLocal.hasToEnableFlavorLimit = this.hasToShowFlavorOptions;
      this.initHasToEnableFlavorLimitLocal();
    },
  },
  methods: {
    closeModal() {
      $("#__layout").css("pointer-events", "all");
    },
    deleteCategory() {
      this.$emit("deleteCategory");
    },
    initHasToPrintInKitchen() {
      if (
        this.category.hasToPrintInKitchen === null ||
        this.category.hasToPrintInKitchen === undefined
      ) {
        this.category.hasToPrintInKitchen = false;
      }
    },
    initHasToPrintOnCounter() {
      if (
        this.category.hasToPrintOnCounter === null ||
        this.category.hasToPrintOnCounter === undefined
      ) {
        this.category.hasToPrintOnCounter = true;
      }
    },
    initHasToPrintOnAdditionalPrinter() {
      if (
        this.category.hasToPrintOnAdditionalPrinter === null ||
        this.category.hasToPrintOnAdditionalPrinter === undefined
      ) {
        this.category.hasToPrintOnAdditionalPrinter = false;
      }
    },
    initHasToEnableFlavorLimit() {
      if (
        //Me fijo el estado del habilitado del limite
        this.category.hasToEnableFlavorLimit === null ||
        this.category.hasToEnableFlavorLimit === undefined
      ) {
        this.category.hasToEnableFlavorLimit = false;
      }
      if (
        //Seteo el limite minimo
        this.category.minimumFlavorLimit === null ||
        this.category.minimumFlavorLimit === undefined
      ) {
        this.minimumFlavorLimit = 0;
      } else this.minimumFlavorLimit = this.category.minimumFlavorLimit;
      if (
        //Seteo el limite maximo
        this.category.maximumFlavorLimit === null ||
        this.category.maximumFlavorLimit === undefined
      ) {
        this.maximumFlavorLimit = 99;
      } else this.maximumFlavorLimit = this.category.maximumFlavorLimit;

      //Le cargo esos valores a la data para poder usar v-models
      this.hasToShowFlavorOptions = this.category.hasToEnableFlavorLimit;
      $("#hasToEnableFlavorLimit").prop(
        "checked",
        this.category.hasToEnableFlavorLimit
      );
    },
    initHasToEnableFlavorLimitLocal() {
      if (//Me fijo el estado del habilitado del limite
        this.categoryLocal.hasToEnableFlavorLimit === null ||
        this.categoryLocal.hasToEnableFlavorLimit === undefined
      ) {
        this.categoryLocal.hasToEnableFlavorLimit = false;
      }

      if (//Seteo el limite minimo
        this.categoryLocal.minimumFlavorLimit === null ||
        this.categoryLocal.minimumFlavorLimit === undefined
      ) {
        this.minimumFlavorLimit = 0;
      } else
        this.minimumFlavorLimit = this.categoryLocal.minimumFlavorLimit;
      if (//Seteo el limite maximo
        this.categoryLocal.maximumFlavorLimit === null ||
        this.categoryLocal.maximumFlavorLimit === undefined
      ) {
        this.maximumFlavorLimit = 99;
      } else
        this.maximumFlavorLimit = this.categoryLocal.maximumFlavorLimit;
    },
    flavorLimitsVerifications() {
      if (this.category.hasToEnableFlavorLimit) {
        if (isNaN(this.minimumFlavorLimit) || this.minimumFlavorLimit < 0 || this.minimumFlavorLimit > 99) {//Si es NaN, menor a 0, o mayor a 99
          toast.error("El valor mínimo debe ser un número entre 0 y 99.");
          return true;
        }
        if (
          isNaN(this.maximumFlavorLimit) ||
          this.maximumFlavorLimit < 0 ||
          this.maximumFlavorLimit > 99
        ) {
          //Si es NaN,  menor a 0, o mayor a 99
          toast.error("El valor máximo debe ser un número entre 0 y 99.");
          return true;
        }
        if (this.minimumFlavorLimit > this.maximumFlavorLimit) {
          //Si el minimo es mayor al maximo
          toast.error(
            "El valor mínimo no puede ser mayor que el valor máximo."
          );
          return true;
        }
        if (this.maximumFlavorLimit == 0) {
          //Si el minimo es mayor al maximo
          toast.error("El valor mínimo y máximo no pueden ser ambos 0.");
          return true;
        }
      }

      return false;
    },
    upsertCategory() {
      this.isLoading = true;
      const t = this;
      let upsertCategoryHandler = setInterval(() => {
        clearInterval(upsertCategoryHandler);
        try {
          if (this.categoryLocal.name === "") {
           toast.error("El nombre de la categoría es obligatorio");
          } else if (
            this.categoryLocal.orderNumber !== "" &&
            parseInt(this.categoryLocal.orderNumber) < 1
          ) {
           toast.error(
              "El orden de la categoría debe ser mayor a cero"
            );
          } else if (this.categoryLocal.hasToEnableFlavorLimit && this.flavorLimitsVerifications()) {

          } else {
            this.categoryLocal.name = this.categoryLocal.name.trim();
            this.categoryLocal.orderNumber = this.categoryLocal.orderNumber?.toString()
            this.categoryLocal.minimumFlavorLimit = this.categoryLocal.hasToEnableFlavorLimit ? this.minimumFlavorLimit : 0;
            this.categoryLocal.maximumFlavorLimit = this.categoryLocal.hasToEnableFlavorLimit ? this.maximumFlavorLimit : 99;
            this.$emit("upsertCategory", this.categoryLocal);
          }
        } finally {
          t.isLoading = false;
        }
      }, 500);
    },
  },
  components: {
    SpinnerComp,
    CurrencyStyle,
  },
  /* eslint-enable */
};
</script>

<style>
.flavor-limit-options {
  display: flex;
  margin-top: 10px;
}

.flavour-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flavour-input :first-child {
  margin-left: 10px;
}

.flavour-input p {
  padding-right: 10px;
  margin: 0;
}

.flavour-input input {
  margin: 0 10px 0 0 !important;
  width: 50px !important;
  height: 30px !important;
}
</style>

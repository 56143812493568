<template>
  <button :class="[buttonClass, { disabled: isDisabled }]" @click="handleClick">
    <span v-if="icon" class="button-icon-pay">
      <img :src="icon" alt="Icon" />
    </span>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonBistro",
  props: {
    variant: {
      type: String,
      default: "solid-gray",
      validator: function (value) {
        return [
          "solid-green",
          "solid-blue",
          "gradient-gray",
        ].includes(value);
      },
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false, 
    },
  },
  computed: {
    isDisabled() {
      return this.$pdv.getIsDisabled;
    },
    buttonClass() {
      return `button-pay ${this.variant}  ${this.isActive ? 'active' : ''}`;
    },
  },
  methods: {
    handleClick() {
      if (this.isDisabled) return;
      this.$emit("handleClick");
    },
  },
};
</script>
<style lang="scss">
.button-pay {
  cursor: pointer;
  border: none;
  color: $lightColorBg;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 7rem;
  font-size: 16px;
  font-weight: 600;

  .button-icon-pay img {
    width: 45px;
  }

  &.gradient-gray {
    background: $gradientGray;
  }

  &.solid-gray {
    background-color: $solidGray;

    &:hover{
      background: $gradientGreen;
    }
  }

  &.gradient-green {
    background: $gradientGreen;
  }

  &.active {
    background: $gradientGreen;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}
</style>

<script>
	// import { createCanvas, loadImage } from 'canvas'
	import QRCode from 'qrcode'

	export default {
		methods: {
			async createQrWithLogo (dataForQRcode) {
				return QRCode.toDataURL(dataForQRcode)
			}
		},
		components: {
			QRCode
		}
	}
</script>

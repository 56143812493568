<template>
	<div class="tooltip" v-if="fdsSyncStatus">
		<img v-if="origin === 'Pedidos Ya'" class="fdsSyncStatusOrigin" src="@/assets/img/PedidosYaLogo.png">
		<img v-else-if="origin === 'Rappi'" class="fdsSyncStatusOrigin" src="@/assets/img/RappiLogo.png">
		<img v-else-if="origin === 'Uber Eats'" class="fdsSyncStatusOrigin" src="@/assets/img/UberEatsLogo.jpg">
		<img v-else-if="origin === 'Glovo'" class="fdsSyncStatusOrigin" src="@/assets/img/GlovoLogo.png">
		<img v-else-if="origin === 'MP Delivery'" class="fdsSyncStatusOrigin" src="@/assets/img/MPLogo.jpg">
		<img v-else-if="origin === 'Didi Food'" class="fdsSyncStatusOrigin" src="@/assets/img/DidiFoodLogo.png">

		<div class="fdsSyncStatusImg" v-if="fdsSyncStatus.status === 1">
			<img src="@/assets/img/FdsSuccess.svg">
			<span class="fds-tooltip">Última act: {{fdsSyncStatus.timestamp}}</span>
		</div>
		<div v-else class="fdsSyncStatusImg">
			<img src="@/assets/img/FdsWarning.svg">
			<span class="fds-tooltip">{{fdsSyncStatus.lastError}}. Última act: {{fdsSyncStatus.timestamp}}</span>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			fdsSyncStatus: {
				type: Object
			},
			origin: {
				type: String
			}
		},
		data () {
			return {

			}
		}
	}
</script>

<template>
  <div
    id="ModalImportAditionals"
    class="modal"
    style="overflow-y: auto !important"
  >
    <div class="modal-header">
      <div class="image-title">
        <h4>
          Importar {{ getOptionTypePlural }} para comercio
          {{ selectedShopCode }}
        </h4>
        <p>Fácil y Simple</p>
      </div>

      <!-- CLOSE -->
      <div class="btnClose">
        <a
          href="#!"
          class="modal-action modal-close waves-effect waves-green btn-flat"
          @click="clearFile"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <article class="formMain">
          <div class="modal-header-product">
            <p>
              Recuerde que al importar el archivo
              <strong class="advice-red">sobrescribirá</strong> sus
              {{ getOptionTypePlural }}. Si el excel está vacío,
              <strong class="advice-red"
                >borrará todos los {{ getOptionTypePlural }}.</strong
              >
              ¿Está seguro que desea hacerlo?
            </p>
          </div>

          <div class="itemForm">
            <input
              type="file"
              id="importAditionalFile"
              ref="file"
              @change="onFileSelected"
            />
          </div>
        </article>
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <a @click="upsertProducts" type="submit">
          <p>Importar {{ getOptionTypePlural }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
//import $ from "jquery";
import axios from "axios";
import { toast } from "vue3-toastify";
// import { parseToQuery } from '~/utils/utils'

export default {
  props: {
    selectedList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    optionType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedFile: null,
      selectedShopCode: null,
      excelParams: "",
    };
  },
  watch: {
    optionType() {},
  },
  computed: {
    getOptionTypePlural() {
      if (this.optionType === "Gusto") {
        return "gustos";
      } else {
        return "adicionales";
      }
    },
  },
  methods: {
    clearFile() {
      this.$refs.file.value = null;
    },
    setShopCode(shop) {
      this.selectedShopCode = shop;
    },
    onFileSelected() {
      this.selectedFile = this.$refs.file.files[0];
    },
    upsertProducts() {
      this.$store.initLoading(this);

      let formData = new FormData();
      /*
            	Add the form data we need to submit
            */
      formData.append("file", this.selectedFile);
      formData.append("listId", this.selectedList.id);
      formData.append("shopCode", this.selectedList.shopCode);

      /*
			Make the request to the POST /single-file URL
			*/
      axios
        .post("/api/productsV2/importAditionals", formData, {
          headers: {
            // 'Authorization': `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.responseCode === 0) {
            this.selectedFile = null;
            toast.success("Archivo importado exitosamente!");
            this.$store.finishLoading(this);
            this.$emit("importDone");
            //$("#ModalImportAditionals").modal("close");
            /* eslint-disable */
            var modalInstance = document.getElementById("ModalImportAditionals");
            var instance = M.Modal.getInstance(modalInstance);
            instance.close();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            /* eslint-enable */
          } else {
            toast.error(response.data.responseMessage);
            this.$store.errorLoading(this);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.show("La sesion ha caducado, seras redirigido al login");
            setTimeout(() => {
              window.location.href = "/login";
            }, 3000);
          } else {
            toast.error(err.response.data.responseMessage);
            this.$store.errorLoading(this);
          }
        });
    },
  },
  components: {},
};
</script>

<template>
	<li>
		<!-- PAGO  -->
		<div class="items-receipt-header items-receipt-header-paidType">
			PAG.
		</div>
		<!-- FECHA -->
		<div class="items-receipt-header items-receipt-header-date">
			Fecha
		</div>
		<div class="items-receipt-header items-receipt-header-type">
			Tipo
		</div>
		<div class="items-receipt-header items-receipt-header-provider-name">
			Proveedor
		</div>

		<div class="items-receipt-header items-receipt-header-cat-subcat">
			Cat. y SubCat.
		</div>

		<div class="items-receipt-header items-receipt-header-gross-amount">
			Monto Neto
		</div>

		<div class="items-receipt-header items-receipt-header-vat-amount">
			Impuestos
		</div>

		<div class="items-receipt-header items-receipt-header-total-amount">
			Total
		</div>


	</li>
</template>
<script>


export default {
	props: {

	},
	data () {
		return {
			params: {

			}
		}
	},
	methods: {
	},
}
</script>

<template>
  <section>
    <!-- <img src="~assets/img/Bistro-Logo@2x.png" alt="" width="50%" height="50%" /> -->
    <BistroLogo width="50%" height="50%" />

    <section class="ticket-container" v-for="(sale, index) in data.tickets" :key="index">
      <bill-template-ar
        v-if="getCountryCode == 'AR'"
        :index="index"
        :indexOfOpenTab="indexOfOpenTab"
        :data="data"
        :sale="sale"
        @downloadTicket="downloadTicket"
        @collapseTab="collapseTab"
      />
      <bill-template-es
        v-if="getCountryCode == 'ES'"
        :index="index"
        :indexOfOpenTab="indexOfOpenTab"
        :data="data"
        :sale="sale"
        @downloadTicket="downloadTicket"
        @collapseTab="collapseTab"
      />
      <bill-template-ar
        v-if="getCountryCode == 'UY'"
        :index="index"
        :indexOfOpenTab="indexOfOpenTab"
        :data="data"
        :sale="sale"
        @downloadTicket="downloadTicket"
        @collapseTab="collapseTab"
      />
      <bill-template-mx
        v-if="getCountryCode == 'MX'"
        :index="index"
        :indexOfOpenTab="indexOfOpenTab"
        :data="data"
        :sale="sale"
        @downloadTicket="downloadTicket"
        @collapseTab="collapseTab"
      />
    </section>
  </section>
</template>

<script>
import mixCommons from "../mixins/mixCommons";
import axios from "axios";
import { parseToQuery } from "../utils/utils";
import moment from "moment";
import BistroLogo from "../components/BistroLogo";
import html2canvas from "html2canvas";
import BillTemplateAr from "../components/BillTemplateAr.vue";
import BillTemplateEs from "../components/BillTemplateEs.vue";
import BillTemplateMx from "../components/BillTemplateMx.vue";
moment.locale("es");

import jsPDF from "jspdf";

export default {
  mixins: [mixCommons],
  data() {
    return {
      params: {
        shopCode: "",
        internalReference: "",
      },
      data: {},
      isLoading: false,
      indexOfOpenTab: -1,
    };
  },
  computed: {
    getCountryCode() {
      return process.env.VUE_APP_INSTANCE_CODE;
    },
  },
  mounted() {
    document.body.classList.add("section-container")
    document.title = `${this.appTitle}  - Consulta de Tickets`;
    this.shopCode = this.$route.query.ShopCode;
    this.internalReference = this.$route.query.InternalReference;
    if (this.shopCode !== undefined && this.shopCode !== null) {
      this.getTicketsInfo();
    } else {
      this.showError("La url es inválida. Por favor, contáctese con el local.");
    }
  },
  beforeUnmount() {
    document.body.classList.remove("section-container");
  },
  methods: {
    collapseTab(index) {
      if (index === this.indexOfOpenTab) {
        this.indexOfOpenTab = -1;
      } else {
        this.indexOfOpenTab = index;
      }
    },

    getTicketsInfo() {
      this.$store.initLoading(this);
      this.params["shopCode"] = this.shopCode;
      this.params["internalReference"] = this.internalReference;

      axios
        .get(`/api/ticketsViewer/?${parseToQuery(this.params)}`)
        .then((res) => {
          if (res.data.responseCode === 0) {
            this.data = res.data;
            this.$store.finishLoading(this);
          } else {
            this.showError(res.data.responseMessage);
            this.$store.errorLoading(this);
          }
        })
        .catch((err) => {
          this.$store.errorLoading(this);
          this.showErrors(err.response);
        });
    },
    downloadTicket(id) {
      const pdf = new jsPDF();
      const element = document.getElementById(id);
      const imageRatio = element.offsetWidth / element.offsetHeight;
      const width = 280 * imageRatio; // element.style.width
      const height = 280; // element.style.height
      console.log(
        `element w x h x ratio`,
        element.offsetWidth,
        element.offsetHeight,
        imageRatio,
        width,
        height
      );
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL("image/png");
        pdf.addImage(image, "JPEG", 15, 5, width, height);
        pdf.save("bill.pdf");
      });
    },
  },
  components: {
    BistroLogo,
    BillTemplateAr,
    BillTemplateEs,
    BillTemplateMx,
  },
  //middleware: ["auth"], // noAuth
};
</script>

<style lang="scss">
.section-container {
  text-align: -webkit-center; 
  background-color: #f5f7fa;
  overflow: auto;
}
.ticket-container {
  padding: 0px 0px 20px 0px;
}
</style>
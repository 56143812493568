<template>
	<div id="modalEditProductStock" class="modal modal-block-outside" style="overflow-y: auto !important"
		@keydown.esc="closeModal()">
		<div class="modal-header">
			<div class="image-title">
				<h4>Edición de Stock de Producto</h4>
				<p>Fácil y Simple</p>
			</div>

			<!-- CLOSE -->
			<div class="btnClose">
				<a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<div class="modal-body">
			<form action class="formGeneral formGeneral-stock">
				<div>
					<article class="formGeneral-stock-edition">
						<h4 class="stockProductName">{{ product.name }}</h4>
						<section>
							<p>
								{{ localEditProductStock.currentStock
								}}<span>{{ getMeasureUnit }}</span>
							</p>
							<label>Stock actual</label>
						</section>
						<section class="section-line">
							<label for>{{ getConfigByMovementType.label }}</label>
							<input id="stockQuantityId" ref="stockQuantityId" v-model="localEditProductStock.quantity"
								type="number" />
							<span>{{ getMeasureUnit }}</span>
						</section>
					</article>
				</div>
				<div>
					<article class="formMain article-product-stock">
						<section>
							<h5 class="type">Tipo</h5>
							<article v-for="(o, index) in getConfigByMovementType.changeLogOptions" :key="index.text">
								<label v-if="o.hasToShow" style="display: inline-flex; padding: 0.3em">
									<input :checked="o.text === localEditProductStock.changeLog" type="radio"
										:id="o.text" :value="o.text" onclick="return false" />
									<label :for="o.text" style="cursor: initial">{{
			o.text
		}}</label>
								</label>
								<div v-if="o.options && o.text === localEditProductStock.changeLog">
									<ul v-for="(opt, optInd) in o.options" :key="optInd.text"
										class="change-log-options">
										<div class="change-log-option" v-if="opt.hasToShow" @click="onOptionClick(opt)">
											<div :class="{
			'radio-button selected': isChecked(opt),
			'radio-button': true,
		}"></div>
											<div>
												{{ opt.text }}
											</div>
										</div>
									</ul>
								</div>
								<div class="bound-shop-div" v-if="hasToShowShopBindingList &&
			o.text === 'Movimiento de Sucursal'
			">
									{{ localEditProductStock.selectedBoundShop }}
								</div>
								<input class="otherInput" v-if="hasToShowOtherTextBox && o.text === 'Otros'"
									placeholder="Motivo del movimiento de stock" type="text"
									v-model="localEditProductStock.comments" disabled />
							</article>
						</section>
					</article>
				</div>
			</form>
		</div>

		<div class="modal-footer">
			<article class="btn-main">
				<a @click="onEditProductStock" type="submit">
					<p>Continuar</p>
				</a>
			</article>
		</div>
	</div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { parseToQuery } from "../utils/utils";
// import CurrencyStyle from "~/components/CurrencyStyle";
// import Spinner from "~/components/Spinner";
import mixCommons from "../mixins/mixCommons";
import stockV2Commons from "../mixins/stockV2Commons";

export default {
	mixins: [mixCommons, stockV2Commons],
	props: {
		product: {
			type: Object,
			default: () => {
				return {};
			},
		},
		selectedMovementType: {
			type: String,
			default: () => {
				return "";
			},
		},
		editProductStock: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			localEditProductStock: {},
			params: {},
			changeLogOption: "productionCost",
			changeLogOptionVerbose: "Receta principal",
			currentStock: 0,
			shopCode: null,
		};
	},
	watch: {
		changeLogOption() {
			this.localEditProductStock.changeLogOption = this.changeLogOption;
		},
		changeLogOptionVerbose() {
			this.localEditProductStock.changeLogOptionVerbose =
				this.changeLogOptionVerbose;
		},
		editProductStock() {
			this.localEditProductStock = { ...this.editProductStock };

			if (
				this.localEditProductStock === {} ||
				this.localEditProductStock.sku !== this.product.sku
			)
				return;
			// if (this.localEditProductStock.currentStock) return

			this.$refs.stockQuantityId.focus();

			this.currentStock = this.product.stock;
			this.$store.initLoading(this);
			this.params["sku"] = this.localEditProductStock.sku;
			this.params["shopCode"] = this.shopCode;
			axios
				.get(`/api/productsV2/stock/?${parseToQuery(this.params)}`)
				.then((res) => {
					this.$store.finishLoading(this);
					if (res.data.responseCode !== 0) {
						if (!this.localEditProductStock.currentStock) {
							this.localEditProductStock.currentStock = "0";
						}
						return;
					}
					this.localEditProductStock.currentStock = res.data.stock.toString();
					this.setProductionCostOptions(res);
					this.editChangelogEntry(
						res.data.hasAlternateProductCostRecipe,
						res.data.hasToShowProductionCost
					);
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
	},
	computed: {
		isChecked: function () {
			return function (opt) {
				return opt.value === this.changeLogOption;
			};
		},
		hasToShowOtherTextBox() {
			return this.localEditProductStock.changeLog === "Otros";
		},
		getMeasureUnit() {
			if (
				this.product.measureUnit === "" ||
				this.product.measureUnit === null ||
				this.product.measureUnit === undefined
			) {
				return "Unidades";
			} else {
				return this.product.measureUnit;
			}
		},
		getConfigByMovementType() {
			switch (this.selectedMovementType) {
				case "Ingresos":
					return {
						label: "Cantidad a Ingresar",
						changeLogOptions: this.changeLogEntryStockOptions,
					};
				case "Egresos":
					return {
						label: "Cantidad a Egresar",
						changeLogOptions: this.changeLogRemovalStockOptions,
					};
				case "Ajustes":
					return {
						label: "Stock Físico",
						changeLogOptions: this.changeLogAdjustStockOptions,
					};
				default:
					return "";
			}
		},
		hasToShowShopBindingList() {
			return this.localEditProductStock.changeLog === "Movimiento de Sucursal";
		},
	},
	mounted() {
		$(`#stockQuantityId`).on(`click`, () => {
			const inputVal = $(`#stockQuantityId`).val();
			if (inputVal === "0") {
				$(`#stockQuantityId`).val("");
			}
		});
		$(`#stockQuantityId`).on("input", () => {
			const inputVal = $(`#stockQuantityId`).val();
			let indexOfDecimalPoint = inputVal.lastIndexOf(`.`);
			if (indexOfDecimalPoint === -1) {
				indexOfDecimalPoint = inputVal.lastIndexOf(`,`);
			}
			if (indexOfDecimalPoint !== -1) {
				if (inputVal.substring(indexOfDecimalPoint + 1).length > 3) {
					const integerPart = inputVal.substring(0, indexOfDecimalPoint);
					const number = `${integerPart}${inputVal.substring(
						indexOfDecimalPoint,
						4 + integerPart.length
					)}`;

					this.localEditProductStock.quantity = parseFloat(number);
				}
			}
		});
	},
	methods: {
		setProductionCostOptions(res) {
			if (
				res.data.hasToShowProductionCost &&
				res.data.hasAlternateProductCostRecipe
			) {
				this.changeLogOption = "altCost";
				this.changeLogOptionVerbose = "Receta alternativa";
			} else if (
				res.data.hasToShowProductionCost &&
				!res.data.hasAlternateProductCostRecipe
			) {
				this.changeLogOption = "productionCost";
				this.changeLogOptionVerbose = "Receta principal";
			} else {
				this.changeLogOption = null;
				this.changeLogOptionVerbose = "";
			}
		},
		onOptionClick(opt) {
			this.changeLogOption = opt.value;
			this.changeLogOptionVerbose = opt.text;
			this.$forceUpdate();
		},
		onEditProductStock() {
			
			if (this.localEditProductStock.changeLog === "") {
				this.showError("Seleccione un motivo para el movimiento");
				return;
			}
			if (this.localEditProductStock.changeLog != "Producción") {
				this.changeLogOption = null;
				this.localEditProductStock.changeLogOption = null;
				this.changeLogOptionVerbose = null;
				this.localEditProductStock.changeLogOptionVerbose = null;
			} else {
				this.localEditProductStock.changeLogOption = this.changeLogOption;
				this.localEditProductStock.changeLogOptionVerbose = this.changeLogOptionVerbose;
			}


			switch (this.selectedMovementType) {
				case "Ingresos":
				case "Egresos":
					if (this.localEditProductStock.quantity < 0) {
						this.showError("La cantidad no puede ser negativa");
					} else {
						this.$emit("editProductStock", this.localEditProductStock);
					}
					break;
				default:
					this.$emit("editProductStock", this.localEditProductStock);
			}
		},
		closeModal() {
			// this.localEditProductStock = {}
			$("#__layout").css("pointer-events", "all");
		},
		setShopCode(shop) {
			this.shopCode = shop;
		},
	},
	components: {
		// CurrencyStyle,
		// Spinner,
	},
};
</script>
<style lang="stylus">
.show-div {
	display: block;
}

.hide-div {
	display: none;
}
.change-log-options {
	display: flex;
	margin-left: 40px;
}
.change-log-option {
	display: flex;
	cursor: pointer;
	font-size: 1rem;
	flex-direction: row;
}
.radio-button {
    width: 16px; /* Adjust width and height as needed */
    height: 16px;
	margin: 0px 12px 0px 0px;
    border: 2px solid #666; /* Border color */
    border-radius: 50%; /* Makes the div round */
    cursor: pointer; /* Show pointer cursor on hover */
    background-color: #fff; /* Background color of the div */
}

.radio-button.selected {
    background-color: #24a5d3; /* Background color when selected */
	border: 0px solid #666;
}
</style>

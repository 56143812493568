<template>
  <div id="ModalImportProducts" class="modal" style="overflow-y: auto !important">
    <div class="modal-header">
      <div class="image-title">
        <h4>Importar productos para comercio {{ selectedShopCode }}</h4>
        <p>Fácil y Simple</p>
      </div>

      <!-- CLOSE -->
      <div class="btnClose">
        <a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat" @click="clearFile">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <article class="formMain">
          <div class="modal-header-product">
            <p>
              Recuerde que al importar el archivo
              <strong class="advice-red">sobrescribirá</strong> sus productos.
              Si el excel está vacío,
              <strong class="advice-red">borrará todos los productos.</strong>
              En este momento generaremos un backup, para que ante cualquier
              inconveniente, pueda recuperar sus productos al estado actual.
            </p>
            <p>¿Está seguro que desea hacerlo?</p>
          </div>

          <div class="itemForm">
            <input type="file" id="importProductFile" ref="file" @change="onFileSelected" />
          </div>
        </article>
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <a @click="upsertProducts" type="submit">
          <p>{{ buttonAction }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
//import $ from 'jquery'
import axios from "axios";
import { toast } from "vue3-toastify";

/* eslint-disable */

export default {
  props: {
    selectedList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedShop: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modalTitle: "Importar Productos",
      modalAction: "¡Importado con éxito!",
      buttonAction: "Importar Productos",
      selectedFile: null,
      selectedShopCode: null,
      excelParams: "",
    };
  },
  computed: {},
  methods: {
    clearFile() {
      this.$refs.file.value = null;
    },
    setShopCode(shop) {
      this.selectedShopCode = shop;
    },
    onFileSelected(event) {
      this.selectedFile = this.$refs.file.files[0];
    },
    upsertProducts() {
      this.$store.initLoading(this);

      let formData = new FormData();
      /*
              Add the form data we need to submit
            */
      formData.append("file", this.selectedFile);
      formData.append("listId", this.selectedList.id);
      formData.append("shopCode", this.selectedShopCode);
      formData.append(
        "merchantCodes",
        this.$store.getMerchantTags.map((i, j, k) => {
          return i.shopCode;
        })
      );

      /*
      Make the request to the POST /single-file URL
      */
      axios
        .post("/api/productsV2/importProducts", formData, {
          headers: {
            // 'Authorization': `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.responseCode === 0) {
            this.selectedFile = null;
            toast.success("Archivo importado exitósamente!");
            this.$store.finishLoading(this);
            this.$emit("importDone");

            var modalInstance = document.getElementById("ModalImportProducts");
            var instance = M.Modal.getInstance(modalInstance);
            instance.close();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            toast.error(res.data.responseMessage);
            this.$store.errorLoading(this);
          }


        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.info("La sesion ha caducado, seras redirigido al login");
            setTimeout(() => {
              window.location.href = "/login";
            }, 3000);
          } else {
            toast.error(err.response.data.responseMessage);
            this.$store.errorLoading(this);
          }
        });
    },
  },
  components: {},
};

/* eslint-enable */
</script>

<template>
	<div id="ModalImportRecipes" class="modal" style="overflow-y: auto !important">
		<div class="modal-header">
			<div class="image-title">
				<h4>Importar recetas para comercio {{ selectedShopCode }}</h4>
				<p>Fácil y Simple</p>
			</div>

			<!-- CLOSE -->
			<div class="btnClose">
				<a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat" @click="clearFile">
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<div class="modal-body">
			<form action class="formGeneral">
				<article class="formMain">
					<div class="modal-header-product">
						<p>
							Recuerde que al importar el archivo
							<strong class="advice-red">sobrescribirá</strong> sus recetas. Si
							algún {{ getItemType }} tiene costo fijo,
							<strong class="advice-red">se sobrescribirá por el costo de ingredientes.</strong>
						</p>
						<p>¿Está seguro que desea hacerlo?</p>
					</div>

					<div class="itemForm">
						<input type="file" ref="file" @change="onFileSelected" />
					</div>
				</article>
			</form>
		</div>

		<div class="modal-footer">
			<article class="btn-main">
				<Spinner v-if="isLoading" />
				<a v-else @click="upsertRecipes" type="submit">
					<p>{{ buttonAction }}</p>
				</a>
			</article>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import Spinner from "../SpinnerComp";
import { toast } from "vue3-toastify";
import M from 'materialize-css/dist/js/materialize.min.js';

export default {
	props: {
		selectedList: {
			type: Object,
			default: () => {
				return {};
			},
		},
		selectedShop: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			isLoading: false,
			modalTitle: "Importar Recetas",
			modalAction: "¡Importado con éxito!",
			buttonAction: "Importar Recetas",
			selectedFile: null,
			selectedShopCode: null,
			costType: "Product",
			excelParams: "",
		};
	},
	computed: {
		getItemType() {
			switch (this.costType) {
				default:
				case 'Product': return 'producto';
				case 'Ingredient': return 'ingrediente';
				case 'Additional': return 'adicional';
			}
		},
	},
	methods: {
		clearFile() {
			this.$refs.file.value = null;
		},
		setShopCode(shop) {
			this.selectedShopCode = shop;
		},
		onFileSelected() {
			this.selectedFile = this.$refs.file.files[0];
		},
		setCostType(costType) {
			this.costType = costType;
		},
		getEndpoint() {
			switch (this.costType) {
				default:
				case "Product":
					return "importProductRecipes";
				case "Ingredient":
					return "importIngredientRecipes";
				case "Additional":
					return "importAdditionalRecipes";
			}
		},
		upsertRecipes() {
			this.$store.initLoading(this);
			this.isLoading = true;
			let formData = new FormData();
			/*
				Add the form data we need to submit
			*/
			formData.append("file", this.selectedFile);
			formData.append("listId", this.selectedList.id);
			formData.append("shopCode", this.selectedShopCode);
			// formData.append('merchantCodes', this.$store.getters.authUser.merchants)
			formData.append(
				"merchantCodes",
				this.$store.getMerchantTags.map((i) => {
					return i.shopCode;
				})
			);

			/*
			Make the request to the POST /single-file URL
			*/
			axios
				.post(`/api/productsV2/${this.getEndpoint()}`, formData, {
					headers: {
						//'Authorization': `Bearer ${token}`,
						"Content-Type": "multipart/form-data",
					},
				})
				.then((response) => {
					if (response.data.responseCode === 0) {
						this.selectedFile = null;
						toast.success("Archivo importado exitósamente!");
						this.$store.finishLoading(this);
						this.$emit("importDone");
						this.isLoading = false;

						var modalInstance = document.getElementById('ModalImportRecipes');
						var instance = M.Modal.getInstance(modalInstance);
						instance.close();
						setTimeout(() => {
							window.location.reload();
						}, 1000);
					} else {
						toast.error(response.data.responseMessage);
						this.$store.errorLoading(this);
						this.isLoading = false;
					}
				})
				.catch((err) => {
					if (err.response.status === 401) {
						toast.show(
							"La sesion ha caducado, seras redirigido al login"
						);
						setTimeout(() => {
							window.location.href = "/login";
						}, 3000);
					} else {
						toast.error(err.response.data.responseMessage);
						this.$store.errorLoading(this);
						this.isLoading = false;
					}
				});
		},
	},
	components: {
		Spinner,
	},
};
</script>

<template>
    <div v-if="config" class="section-divider">

        <h4>{{ title }}</h4>
        <p v-if="subtitle">{{ subtitle }}</p>

        <!--Configuracion de horarios delivery-->
        <section>
            <div v-if="getOpeningHoursConfig.enabled">
                <switch-component label="Mismo horario de atención" :value="sameOpeningHoursForDelivery"
                    @changeValue="sameOpeningHoursForDelivery = !sameOpeningHoursForDelivery" />
            </div>
            <div v-else>
                <switch-component label="Es necesario habilitar los horarios de apertura y cierre."
                    :value="sameOpeningHoursForDelivery"
                    @changeValue="sameOpeningHoursForDelivery = !sameOpeningHoursForDelivery" :disabled="true" />
            </div>

            <!--Mismos horarios que atención-->
            <div v-if="sameOpeningHoursForDelivery">
                <article v-if="getOpeningHoursConfig.enabled">
                    <div v-for="(config, index) in getOpeningHoursConfig.hoursByDay" :key="index" class="row-config">
                        <switch-component class="day" :label="getDayLabelByKey(config.dayKey)" :value="config.isOpen"
                            :disabled="true" />

                        <div>
                            <div v-for="(h, i) in config.hours" :key="i" class="hour-row">
                                <div class="itemForm input">
                                    <input v-model="h.from" type="time" placeholder="HH:MM" min="00:00" max="23:59"
                                        disabled />
                                </div>
                                <div class="itemForm input">
                                    <input v-model="h.to" type="time" placeholder="HH:MM" min="00:00" max="23:59"
                                        disabled />
                                </div>
                                <img src="@/assets/img/delete.svg" title="Eliminar horario" alt="Eliminar horario">
                            </div>
                        </div>
                        <img src="@/assets/img/add.webp" title="Agregar horario" alt="Agregar horario">
                    </div>
                </article>
            </div>

            <!--Horarios personalizados para delivery-->
            <div v-else>
                <article v-if="getOpeningHoursConfig.enabled">
                    <div v-for="(config, index) in getConfig.hoursByDay" :key="index" class="row-config">
                        <switch-component class="day" :label="getDayLabelByKey(config.dayKey)" :value="config.isOpen"
                            @changeValue="modifyDayStatus(index, !config.isOpen)" />

                        <div>
                            <div v-for="(h, i) in config.hours" :key="i" class="hour-row">
                                <div class="itemForm input">
                                    <input v-model="h.from" type="time" placeholder="HH:MM" min="00:00" max="23:59" />
                                </div>
                                <div class="itemForm input">
                                    <input v-model="h.to" type="time" placeholder="HH:MM" min="00:00" max="23:59" />
                                </div>
                                <img class="clickable" @click="deleteHour(index, i)" src="@/assets/img/delete.svg"
                                    title="Eliminar horario" alt="Eliminar horario">
                            </div>
                        </div>

                        <img class="clickable" @click="addHour(index)" src="@/assets/img/add.webp"
                            title="Agregar horario" alt="Agregar horario">
                    </div>
                </article>
            </div>


        </section>

    </div>
</template>

<script>
import SwitchComponent from "../components/Switch";
import timePickerCommons from "../mixins/timePickerCommons";

export default {
    mixins: [
        timePickerCommons,
    ],
    props: {
        shopCode: {
            type: String
        },
        title: {
            type: String
        },
        subtitle: {
            type: String,
            default: null
        },
        openingConfigHours: {
            type: Object
        },
        configHours: {
            type: Object
        },
        sameOpeningHours: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            openingConfig: null,
            config: null,
            sameOpeningHoursForDelivery: false,
        };
    },
    mounted() {
        this.openingConfig = this.openingConfigHours;
        this.config = this.configHours;
        this.sameOpeningHoursForDelivery = this.sameOpeningHours;
    },
    watch: {
        openingConfigHours() {
            this.openingConfig = this.openingConfigHours;
        },
        config: {
            deep: true,
            handler() {
                this.saveDeliveryConfig();
            }
        },
        sameOpeningHoursForDelivery: {
            handler() {
                this.saveDeliveryConfig();
            }
        },
    },
    computed: {
        getOpeningHoursConfig() {
            return this.openingConfig;
        },
    },
    methods: {
        saveDeliveryConfig() {
            // Verifica si 'this.config' está definido antes de intentar asignarle una propiedad
            if (this.sameOpeningHoursForDelivery) {
                this.$emit("upsertDeliveryConfigHours", this.openingConfig, this.shopCode, this.sameOpeningHoursForDelivery);
            }
            else if (this.config) {
                this.config.enabled = true;
                this.$emit("upsertDeliveryConfigHours", this.config, this.shopCode, this.sameOpeningHoursForDelivery);
            }
        }

    },
    components: {
        SwitchComponent
    }
};
</script>

<style lang="stylus">
.clickable
    cursor: pointer;
.section-divider
    margin 40px 0px
    max-width 550px

    section
        margin 30px 0px

        .row-config
            display flex
            justify-content space-between
            align-items center
            border-top 1px solid rgba(216,216,216,0.4)

            &:last-child
                border-bottom 1px solid rgba(216,216,216,0.4)

            .day
                width 150px

            .hour-row
                display flex
                align-items center

                .input
                    width 110px !important
                    margin 3% 1%

                    input
                        width 110px !important
                        text-indent 5px !important
                        margin 0

    img
        width 25px
        height 25px
        margin 5px


@media (max-width: 530px)
    .row-config
        display flex
        flex-direction column
        padding 20px
</style>
<template>
    <main>

        <!--  									-->
        <!-- LOGOS E IMAGENES HEADER - START 	-->
        <!--  									-->
        <article :class="getLogoAndImagedTabStyles">
            <section class="mainFilter-title pointer" @click="onExpandSection(`logoAndImages`)">
                <div class="fix-title">
                    <h1><span>Carga de logo e imágenes</span></h1>
                    <p class="clickHint" v-if="hasToShowLogoAndImagesTab">
                        Clickea aquí para esconder carga de logo e imágenes
                    </p>
                    <p class="clickHint" v-else-if="!hasToShowLogoAndImagesTab">
                        Clickea aquí para mostrar carga de logo e imágenes
                    </p>
                </div>
            </section>
            <section class="mainFilter-filter expand-arrow">
                <div @click="onExpandSection(`logoAndImages`)" class="arrow"
                    :class="{ expanded: hasToShowLogoAndImagesTab }"></div>
            </section>
        </article>
        <!--  									-->
        <!-- LOGOS E IMAGENES HEADER - END 		-->
        <!--  									-->
        <!--  									-->
        <!-- LOGOS E IMAGENES BODY - START 	-->
        <!--  									-->
        <article v-show="hasToShowLogoAndImagesTab">
            <form action="" class="formGeneral profile-misc-grid profile-form">
                <article class="formMain formNotMain">
                    <div v-for="(item, index) in shops" :key="`${index}-${item.code}`">
                        <div v-if="hasToShowThisProfile(item.code)">
                            <div>
                                <article class="setting-logo-and-images-container">
                                    <!-- <section class="listItems-product tooltip"> -->
                                    <section class="setting-logo-and-images-list-item tooltip">
										<a @click="openModalById(`#modalShopLogo_${item.code}`)"
											class="waves-effect waves-light cardMain">
											<figure>
												<img src="@/assets/img/logo_menu.png" alt="@/assets/img/" />
											</figure>

											<div class="item">
												<h2>Logo para las cartas QR</h2>
											</div>
										</a>
									</section>
                                    <section class="setting-logo-and-images-list-item tooltip">
                                        <a @click="openModalById(`#modalPrinterLogo_${item.code}`)"
                                            class="waves-effect waves-light cardMain">
                                            <figure>
                                                <img src="@/assets/img/printer_logo_picture.jpg" alt="@/assets/img/" />
                                            </figure>

                                            <div class="item">
                                                <h2>Imágen para la impresora</h2>
                                            </div>
                                        </a>
                                    </section>
                                    <section class="setting-logo-and-images-list-item tooltip">
										<a @click="
											openModalById(`#modalBackgroundMenu_${item.code}`)
											" class="waves-effect waves-light cardMain">
											<figure>
												<img src="@/assets/img/background_menu_picture.jpg"
													alt="@/assets/img/" />
											</figure>

											<div class="item">
												<h2>Imágen para las cartas QR</h2>
											</div>
										</a>
									</section>

                                    <modal-picture-upload :shopCode="item.code" :id="`modalShopLogo_${item.code}`"
										title="Logo para las cartas QR" :imageUrl="item.shopLogoUrl"
										@onDeleteImage="
											removeImgByShopCodeAndType(
												item.code,
												'shopLogo',
												`shoplogo_${item.code}`
											)
											" @onPictureUploadedOk="onShopLogoUploadedOk" />                                  
                                    <modal-picture-upload :shopCode="item.code" :id="`modalBackgroundMenu_${item.code}`"
                                            title="Imágen para las cartas QR" :imageUrl="item.menuLogoUrl" @onDeleteImage="
                                                removeImgByShopCodeAndType(
                                                    item.code,
                                                    'menuBackground',
                                                    `menulogo_${item.code}`
                                                )
											" @onPictureUploadedOk="onBackgroundMenuUploadedOk" />
                                    <modal-picture-upload :shopCode="item.code" :id="`modalPrinterLogo_${item.code}`"
                                        storageRepo="firebase" title="Imágen para la impresora"
                                        disclaimer="(Archivo .jpg, .jpeg, .bmp o .png. Preferiblemente 512px x 512px máx, .bmp blanco y negro, y con fondo blanco/transparente)"
                                        :imageUrl="item.printerLogoUrl" @onDeleteImage="
                                            removeImgByShopCodeAndType(
                                                item.code,
                                                'printerLogo',
                                                `prnlogo_${item.code}`
                                            )
                                            " @onPictureUploadedOk="onPrinterLogoUploadedOk" />



                                </article>
                            </div>
                        </div>
                    </div>
                </article>
            </form>
        </article>
        <!--  									-->
        <!-- LOGOS E IMAGENES BODY - END 		-->
        <!--  									-->

        <!--  									-->
        <!-- MIS DATOS FISCALES HEADER - START 	-->
        <!--  									-->
        <article v-show="hasToShowFiscalDataSection" :class="getMyFiscalDataTabStyles">
            <section class="mainFilter-title pointer" @click="onExpandSection(`fiscal_data`)">
                <div class="fix-title">
                    <h1><span>Mis Datos Fiscales</span></h1>
                    <p class="clickHint" v-if="hasToShowMyFiscalDataTab">
                        Clickea aquí para esconder mis datos fiscales
                    </p>
                    <p class="clickHint" v-else-if="!hasToShowMyFiscalDataTab">
                        Clickea aquí para mostrar mis datos fiscales
                    </p>
                </div>
            </section>
            <section class="mainFilter-filter expand-arrow">
                <div @click="onExpandSection(`fiscal_data`)" class="arrow"
                    :class="{ expanded: hasToShowMyFiscalDataTab }"></div>
            </section>
        </article>
        <!--  									-->
        <!-- MIS DATOS FISCALES HEADER - END 	-->
        <!--  									-->
        <!--  									-->
        <!-- MIS DATOS FISCALES BODY- START 	-->
        <!--  									-->
        <article v-show="hasToShowMyFiscalDataTab">

            <form action="" class="formGeneral profile-misc-grid profile-misc-panel profile-form">
                <article class="formMain formNotMain">
                    <div v-for="(item, index) in shops" :key="`${index}-${item.code}`">
                        <FiscalDataAR :shop="item" :hasToShowThisProfile="hasToShowThisProfile(item.code)"
                            :isLoading="isSettingLoading"></FiscalDataAR>
                        <FiscalDataES :shop="item" :hasToShowThisProfile="hasToShowThisProfile(item.code)"
                            :isLoading="isSettingLoading"></FiscalDataES>
                        <FiscalDataMX :shop="item" :hasToShowThisProfile="hasToShowThisProfile(item.code)"
                            :isLoading="isSettingLoading" :isMounting="isMountingComponent"></FiscalDataMX>
                    </div>
                </article>
            </form>
        </article>
        <!--  									-->
        <!-- MIS DATOS FISCALES BODY - END 		-->
        <!--  									-->


        <!--  									-->
        <!-- CONFIGURACIONES DE IMPRESORAS HEADER - START 	-->
        <!--  									-->
        <article :class="getSettingsPrinterTabStyles">
            <section class="mainFilter-title pointer" @click="onExpandSection(`settingPrinter`)">
                <div class="fix-title">
                    <h1><span>Configuraciones de impresora</span></h1>
                    <p class="clickHint" v-if="hasToShowSettingPrinter">
                        Clickea aquí para esconder configuraciones de impresora
                    </p>
                    <p class="clickHint" v-else-if="!hasToShowSettingPrinter">
                        Clickea aquí para mostrar configuraciones de impresora
                    </p>
                </div>
            </section>
            <section class="mainFilter-filter expand-arrow">
                <div @click="onExpandSection(`settingPrinter`)" class="arrow"
                    :class="{ expanded: hasToShowSettingPrinter }"></div>
            </section>
        </article>
        <!--  									-->
        <!-- CONFIGURACIONES DE IMPRESORAS HEADER - END 	-->
        <!--  									-->
        <!--  									-->
        <!-- CONFIGURACIONES DE IMPRESORAS BODY- START 	-->
        <!--  									-->
        <article v-show="hasToShowSettingPrinter">
            <form action="" class="formGeneral profile-misc-grid profile-misc-panel profile-form">
                <article class="formMain formNotMain">
                    <SettingsPrinter />
                </article>
            </form>


        </article>
        <!--  									-->
        <!-- CONFIGURACIONES DE IMPRESORAS BODY - END 		-->
        <!--  									-->



        <help-center />
    </main>
</template>

<script>
import axios from "axios";
import "firebase/storage";
import mixCommons from "@/mixins/mixCommons";
import pictureCommons from "@/mixins/pictureCommons";
import modalCommons from "@/mixins/mixModals";
import rolesCommons from "@/mixins/rolesCommons";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import HelpCenter from "@/components/HelpCenter";
import ModalPictureUpload from "@/components/modals/ModalPictureUpload";
import computeds from "@/mixins/menuOptions";
import originCommons from "@/mixins/originCommons";
import productV2Commons from "@/mixins/productV2Commons";
import bOrdersCommons from "@/mixins/bOrdersCommons";
import QrGenerator from "@/mixins/QrGenerator";
import FiscalDataES from "@/components/FiscalDataES";
import FiscalDataAR from "@/components/FiscalDataAR";
import FiscalDataMX from "@/components/FiscalDataMX";
import SettingsPrinter from "../components/SettingsPrinter.vue";
import SettingsCommons from "@/mixins/settingsCommons.vue";

export default {
    mixins: [
        mixCommons,
        computeds,
        rolesCommons,
        bistroV2Commons,
        bOrdersCommons,
        QrGenerator,
        modalCommons,
        originCommons,
        productV2Commons,
        pictureCommons,
        SettingsCommons
    ],
    computed: {

        hasToShowSettingPrinter() {
            return this.expandTabName === "settingPrinter";
        },
        getSettingsPrinterTabStyles() {
            return {
                mainFilter: true,
                mainFilterMobile: true,
                "mainFilter-activeHover": true,
                "mainFilter-active": this.hasToShowSettingPrinter,
            };
        },

    },
    async mounted() {
        document.title = `${this.appTitle} - Configuraciones`
        if (this.isUserLoggedIn() === false) {
            axios
                .get("/api/auth/logout") /// OK
                .then(() => {
                    this.$store.SET_USER(null);
                    this.resetUser();
                    this.setAutomaticLogin(false);
                    location.href = "/login";
                });
        } else if (!this.hasToShowSettingsPosTab) {
            this.handleUnauthorizedAccess()
        } else {
            if (await this.checkSessionInBackendAsync()) {
                this.onMounted();
                this.setConfirmationReportEmailAndPrintTicket(this.shopCode)
            }
        }

    },
    methods:{
        setConfirmationReportEmailAndPrintTicket(shopCode) {
			this.profile.action = "HasToSendClosureReportByMailUpdate";
			this.profile.hasToSendClosureReportByMail = true;
            this.profile.hasToPrintTicket = true;
			this.profile.shopCode = shopCode;
			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading(this);
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
    },
    components: {

        HelpCenter,
        ModalPictureUpload,
        FiscalDataES,
        FiscalDataAR,
        FiscalDataMX,
        SettingsPrinter

    },

};
</script>

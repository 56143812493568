<template>
    <div v-if="hasToShowThisProfile && hasToShowSection">
        <div>
            <div class="itemForm" v-if="shop.fiscalData.isBistroWebEditionEnabled">
                <Spinner v-if="isLoading" />
                <div v-else>
                    <div class="settings-div">
                        <label class="settings-label" for>CUIT:</label>
                    </div>
                    <input class="search-input" type="text" v-model="/*eslint-disable*/shop.fiscalData.fiscalKey" />
                    <div class="settings-div">
                        <label class="settings-label" for>Domicilio Fiscal:</label>
                    </div>
                    <input class="search-input" type="text" v-model="shop.fiscalData.legalAddress" />
                    <div class="settings-div">
                        <label class="settings-label" for>Razón Social:</label>
                    </div>
                    <input class="search-input" type="text" v-model="shop.fiscalData.legalName" />
                    <div class="settings-div">
                        <label class="settings-label" for>Punto de Venta:</label>
                    </div>
                    <input class="search-input" type="number" v-model="shop.fiscalData.posId" />
                    <div class="settings-div">
                        <label class="settings-label" for>Condición de IVA:</label>
                    </div>
                    <select v-model="shop.fiscalData.vatCondition" class="browser-default">
                        <option value disabled selected>
                            Seleccione condición de IVA
                        </option>
                        <option v-for="vc in availableVatConditions" :value="vc.id" :key="vc.id">
                            {{ vc.name }}
                        </option>
                    </select>
                    <div class="fix-title">
                        <p class="clickHint">
                            Recuerde que si cambia Punto de venta o CUIT, también
                            debe realizar
                            <a
                                href="https://drive.google.com/file/d/15-9GkmpMXsayiS1rNXpDmhQEyL9LtziJ/view?ts=63692bd7">este
                                proceso en ARCA</a>
                        </p>
                    </div>
                    <div class="settings-div">
                        <label class="settings-label" for>IIBB:</label>
                    </div>
                    <input class="search-input" type="text" v-model="shop.fiscalData.grossIncome" />
                    <div class="settings-div">
                        <label class="settings-label" for>Inicio de Actividades:</label>
                    </div>
                    <input class="search-input" type="text" v-model="shop.fiscalData.activitiesStartDate"
                        placeholder="dd/mm/aaaa" />
                    <article class="btn-main btn-main-certificate">
                        <a class="btn-active" @click="processFiscalDataFields()">
                            <p>GUARDAR</p>
                        </a>
                    </article>
                    <modal-fiscal-data-edition-confirmation-ar :id="`modalFiscalDataEditionConfirmationAr_${shop.code}`"
                        :shopCode="shop.code" @onModalClosed="onFiscalDataEditionConfirmationModalClosingAr
                            " />
                </div>
            </div>
            <div v-else>
                Para modificar sus datos comuníquese con
                <a href="https://wa.me/5491137757552">
                    <p>Mesa de Ayuda</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from "./SpinnerComp";
import commons from "../mixins/mixCommons.vue";
import modalCommons from "../mixins/mixModals";
import ModalFiscalDataEditionConfirmationAr from "./modals/ModalFiscalDataEditionConfirmationAR";
export default {
    data() {
        return {
            shopCode: null,
        };
    },
    props: {
        shop: { // Es el shop que estoy analizando en el v-for
            type: Object,
            default: () => ({}), 
        },
        hasToShowThisProfile: { //Me dice si tengo que mostrar toda esta seccion
            type: Boolean,
            default: false
        },
        isLoading: { //Me dice si estan cargando los datos
            type: Boolean,
            default: false
        },
    },
    components: {
        Spinner,
        ModalFiscalDataEditionConfirmationAr,
    },
    mixins: [
        commons,
        modalCommons,
    ],
    methods: {
        processFiscalDataFields() {
            this.shopCode = this.shop.code;//Lo tengo que poner para que cuando se use el commons, pueda acceder al valor de shopcode que hay en este archivo y lo pueda usar para buscar la info de este shop
            //Funcion que se ejecuta cuando le doy a guardar y procesa todos los campos
            if (!this.hasFiscalDataChangedAR) {//Si no cambio ningun campo no quiero hacer nada
                this.showError(`No se detectaron cambios en los datos fiscales. Por favor, revise que se hayan cargado correctamente.`);
                return;
            }
            if (!this.processCUIT()) {
                return;
            }
            if (!this.processAddress()) {
                return;
            }
            if (!this.processLegalName()) {
                return;
            }
            if (!this.processBillPos()) {
                return;
            }
            if (!this.processGrossIncome()) {
                return;
            }
            if (!this.processActivitiesStartDate()) {
                return;
            }
            //Si paso todos los procesos abro el modal que muestra lo que cambio de los datos fiscales
            this.openModalById(`#modalFiscalDataEditionConfirmationAr_${this.shop.code}`);
        },
        processCUIT() {
            if (
                this.shop.fiscalData?.fiscalKey === undefined ||
                this.shop.fiscalData?.fiscalKey === null ||
                this.shop.fiscalData?.fiscalKey.length == 0
            ) {
                this.showError(`El CUIT no puede estar vacío`);
                return false;
            }
            if (this.shop.fiscalData?.fiscalKey.length != 11) {
                this.showError(`El CUIT debe contener 11 digitos`);
                return false;
            }
            return true
        },
        processAddress() {
            //PROCESO ADDRESS, mas que nada que no venga null
            if (
                this.shop.fiscalData?.legalAddress === undefined ||
                this.shop.fiscalData?.legalAddress === null ||
                this.shop.fiscalData?.legalAddress.length == 0
            ) {
                this.showError(`La dirección fiscal no puede estar vacía`);
                return false;
            }
            return true;
        },
        processLegalName() {
            //PROCESO NOMBRE, me fijo que no sea null
            if (
                this.shop.fiscalData?.legalName === undefined ||
                this.shop.fiscalData?.legalName === null ||
                this.shop.fiscalData?.legalName.length == 0
            ) {
                this.showError(`La razón social no puede estar vacía`);
                return false;
            }
            return true;
        },
        processBillPos() {
            if (
                this.shop.fiscalData?.posId === undefined ||
                this.shop.fiscalData?.posId === null ||
                this.shop.fiscalData?.posId.length == 0
            ) {
                this.showError(`El punto de venta no puede estar vacío`);
                return false;
            }
            return true;
        },
        processGrossIncome() {
            if (
                this.shop.fiscalData?.grossIncome === undefined ||
                this.shop.fiscalData?.grossIncome === null ||
                this.shop.fiscalData?.grossIncome.length == 0
            ) {
                this.showError(`El IIBB no puede estar vacío`);
                return false;
            }
            return true;
        },
        processActivitiesStartDate() {
            if (
                this.shop.fiscalData?.activitiesStartDate === undefined ||
                this.shop.fiscalData?.activitiesStartDate === null ||
                this.shop.fiscalData?.activitiesStartDate.length == 0
            ) {
                this.showError(`El inicio de actividades no puede estar vacío`);
                return false;
            }
            return true;
        },
        onFiscalDataEditionConfirmationModalClosingAr() {
            this.closeModalById(
                `#modalFiscalDataEditionConfirmationAr_${this.shop.code}`
            );
        },
    },
    computed: {
        hasToShowSection() {
            return process.env.VUE_APP_INSTANCE_CODE == "AR";
        },
    }
}
</script>

<template>
  <div id="modalConfirmForRemoveStockMovements" class="modal" style="overflow-y: auto !important;">
    <div class="modal-header">

		<div class="image-title">
			<h4>Deshacer movimientos</h4>
			<p>Fácil y Simple</p>
		</div>

      <!-- CLOSE -->
      <div class="btnClose">
        <a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <article class="formMain">
			<div class="modal-header-product">
				<p>Está seguro que desea deshacer los movimientos realizados en el stock? <strong class="advice-red">Una vez eliminados será imposible recuperarlos.</strong> ¿Está seguro que desea hacerlo?</p>
			</div>
        </article>
      </form>
    </div>

	<div class="modal-footer">
		<article class="btn-main">
			<a @click="onConfirmRemoveStockMovement" type="submit">
				<p>{{ buttonAction }}</p>
			</a>
		</article>
	</div>

  </div>
</template>

<script>
export default {
	props: { },
	data () {
		return {
			buttonAction: 'Deshacer Movimientos'
		}
	},
	computed: { },
	methods: {
		onConfirmRemoveStockMovement () {
			this.$emit('onConfirmRemoveStockMovement')
		}
	},
	components: {}
}
</script>
<style lang="stylus">
.show-div {
	display: block;
}

.hide-div {
	display: none;
}
</style>

<template>
    <article class="mainEvents centralized-stock-panel salesDashboard">
        <!-- PANEL DE LA IZQUIERDA (FILTROS) -->
        <section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">
            <!-- FILTRO COMERCIO -->
            <div class="containTransaction">
                <!-- SEARCH -->
                <div class="searchDashboard">
                    <!-- MENSAJE -->
                    <div class="itemMessage">
                        <div class="text">
                            <h5>Filtra tu producto</h5>
                            <p>Fácil y Simple</p>
                        </div>
                        <a @click="search = !search" v-if="!search" id="btnSearchSales">
                            <span class="icon-Bistro-_Search"></span>
                        </a>
                    </div>

                    <!-- INPUT -->
                    <div v-if="search" class="item">
                        <a @click="search = !search; filteredProductByName = ''" id="btnCloseSearch">
                            <span class="icon-Bistro-_Close"></span>
                        </a>

                        <input v-model="filteredProductByName" type="text" class="inputFilter"
                            placeholder="Buscar por producto" />
                    </div>
                </div>

                <div class="centralized-stock-filters">
                    <ul class="containTransaction-lists centralizedStock">
                        <!-- MAIN SWITCH (El que deshabilita toda la categoria) -->
                        <li class="centralized-stock-list-item">
                            <h4>Stock Total</h4>
                            <div class="switch">
                                <label>
                                    <input type="checkbox" v-model="isShopNameFilterChecked">
                                    <span class="lever"></span>
                                </label>
                            </div>
                        </li>
                        <!-- SWITCHES DINAMICOS -->
                        <li v-for="shopName in shopNames" :key="shopName">
                            <p>{{ shopName }}</p>
                            <div class="switch">
                                <label>
                                    <input type="checkbox" @change="avoidRectangleAtTheEnd"  :value="shopName" v-model="selectedShopNames">
                                    <span class="lever"></span>
                                </label>
                            </div>
                        </li>
                    </ul>
                    <!-- FILTROS TIPO / CATEGORIA -->
                    <ul class="containTransaction-lists centralizedStock">
                        <!-- MAIN SWITCH (El que deshabilita toda la categoria) -->
                        <li>
                            <h4>Tipo / Categoria</h4>
                            <div class="switch">
                                <label>
                                    <input type="checkbox" @change="avoidRectangleAtTheEnd"  v-model="isCategoryFilterChecked">
                                    <span class="lever"></span>
                                </label>
                            </div>
                        </li>
                        <!-- SWITCHES DINAMICOS -->
                        <li v-for="category in categories" :key="category">
                            <p>{{ category }}</p>
                            <div class="switch">
                                <label>
                                    <input type="checkbox" :value="category" @change="avoidRectangleAtTheEnd" v-model="selectedCategories">
                                    <span class="lever"></span>
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Boton que aparece cuando estas en mobile, al presionarlo te aparecen los filtros para seleccionar -->
            <button @click="showFilter = !showFilter" class="btnFilterMobile">
                <span class="icon-Bistro-_Filter"></span>
            </button>
        </section>
        <div class="products-container-container">
            <!-- PANEL DE PRODUCTOS -->
            <div :class="{
            'products-container': true,  // Siempre se aplica
            'products-container-bottom': !(totalCount > 0 && isMoreOfOnePage())
        }">
                <!-- NOMBRES DE CADA COLUMNA -->
                <div class="products-container-grid products-container-title">
                    <h2>TIPO/CATEGORIA</h2>
                    <h2>PRODUCTO</h2>
                    <h2>COMERCIO</h2>
                    <h2>SKU</h2>
                    <h2>STOCK</h2>
                </div>
                <!-- LISTADO DE PRODUCTOS -->
                <div class="products-container-list" v-for="product in filteredProduct" :key="product.sku" cols="12"
                    @click="handleClickProduct(product)">
                    <!-- Cada producto tiene esta forma -->
                    <div class="products-container-grid products-container-product">
                        <!-- Flechita que se muestra cuando el producto esta en mas de un comercio -->
                        <label class="products-container-product-dropdown">
                            <v-icon :class="{ 'rotate-90': product.expanded }" size="16"
                                :color="product.shops.length != 1 ? 'black' : 'white'">mdi-chevron-right</v-icon>
                            <span>{{ product.categoryName }}</span>
                        </label>
                        <label :title="product.productName">
                            {{ product.productName }}
                        </label>
                        <!-- Si tengo un solo comercio asociado al producto le pongo el nombre de ese comercio y sino multiples -->
                        <label :title="product.shops.length == 1 ? product.shops[0].shopName : 'Múltiples'">
                            {{ product.shops.length == 1 ? product.shops[0].shopName : "Múltiples" }}
                        </label>
                        <label>
                            {{ product.sku }}
                        </label>
                        <label>
                            {{ product.quantity }} {{ product.weightUnit }}
                        </label>
                    </div>
                    <!-- Si ese producto exta expandido se habilita esta informacion adicional -->
                    <div class="expanded-tab" v-if="product.expanded">
                        <!-- NOMBRES DE CADA COLUMNA -->
                        <div class="expanded-tab-title">
                            <label class="expanded-tab-code">ID COMERCIO</label>
                            <label class="expanded-tab-name">NOMBRE</label>
                            <label class="expanded-tab-quantity">STOCK SUCURSAL</label>
                        </div>
                        <!-- INFORMACION DE TODOS LOS COMERCIOS -->
                        <div class="expanded-tab-info" v-for="shop in product.shops" :key="shop.shopCode">
                            <label class="expanded-tab-code"> {{ shop.shopCode }} </label>
                            <label class="expanded-tab-name"> {{ shop.shopName }} </label>
                            <label class="expanded-tab-quantity"> {{ shop.quantity }} </label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- PAGINACION -->
            <div v-if="totalCount > 0 && isMoreOfOnePage()" class="base-pagination">
                <BasePagination :current-page="currentPage" :totalCount="totalCount" :countPerPage="countPerPage"
                    class="list__pagination" @nextPage="pageChangeHandle('next')"
                    @previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
            </div>
        </div>
    </article>
</template>

<script>
import BasePagination from "../components/BasePagination";
import stockV2Commons from "../mixins/stockV2Commons";
import mixCommons from "../mixins/mixCommons";

export default {
    mixins: [
        stockV2Commons,
        mixCommons
    ],
    components: {
        BasePagination
    },
    props: {
        currentShopCode: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            //Para buscador de productos
            search: false,
            filteredProductByName: "",

            //PARA FILTROS
            isShopNameFilterChecked: true, //Para los main switches
            shopNames: [], //Esto lo cargo desde el back y no se vuelve a tocar
            selectedShopNames: [], //Tiene el nombre de los comercios que estan seleccionados, se usa un v-model para sacar o poner el nombre dependiendo el estado del switch
            isCategoryFilterChecked: true, //Para los main switches
            categories: [], //Esto lo cargo desde el back y no se vuelve a tocar
            selectedCategories: [], //Tiene el nombre de las categorias que estan seleccionadas, se usa un v-model para sacar o poner el nombre dependiendo el estado del switch

            //PARA PRODUCTOS
            productsList: [],//Estos son los productos que me llegan del back
            selectedProducts: [], //Estos son los productos con un poco de procesamiento para dejarlos de la mejor forma para mostrarlo en la lista
            expandedProduct: { //Tiene el nombre y el sku del producto actual que esta expandido
                productName: null,
                sku: null
            },
            //Para el boton que aparece en mobile que muestra los filtros
            showFilter: false,
            //Para la paginacion
            currentPage: 1,
            countPerPage: 50,
            totalCount: 0,
            //Para enviar el codigo
            shopCode: "",
        };
    },
    mounted() {
        this.shopCode = this.currentShopCode;
        this.getCentralizedStockShopAndCategories(this.shopCode);// Llamo al back para recuperar TODOS los comercios y las categorias
    },
    computed: {
        /* eslint-disable */
        filteredProduct() { //Me devuelve todos los productos a mostrar 
            this.selectedProducts = []; //Limpio el array que habia

            this.productsList.forEach(category => { //me fijo cada categoria
                category?.product.forEach(product => { //por cada producto

                    var totalQuantity = 0; //Aca guardo la suma del stock de todos los shops que esten seleccionados
                    var shopsInfo = []; //array donde guardo todos los comercios relacionados a este producto

                    if (product.relatedShops.length >= 1) //Si tiene comercios relacionados entro
                        product.relatedShops.forEach(shop => { //me fijo cada relatedshop que tenga
                            shopsInfo.push(shop);//Agrego este shop al array
                            totalQuantity += shop.quantity;//agrego a la cantidad total
                        });
                    else
                        totalQuantity = 0;

                    if (this.normalizeString(product.name).toUpperCase().includes(this.normalizeString(this.filteredProductByName).toUpperCase()) ||
                        this.filteredProductByName == null) {   // me fijo si el buscador está incluido en el product.name, y tambien si el buscador es null. En caso de que el buscador sea "" (string vacío) el include da true.
                        var product = { //armo el objeto
                            categoryName: category.categoryName,
                            productName: product.name,
                            sku: product.sku,
                            weightUnit: product.weightUnit,
                            quantity: totalQuantity,
                            expanded: (this.expandedProduct.product == product.name && this.expandedProduct.sku == product.sku && shopsInfo.length > 1), //lo pongo en true unicamente si es el producto que registre como el que estaba expandido y si tiene mas de un comercio
                            shops: shopsInfo
                        }
                        if (shopsInfo.length > 0) //si tiene shops relacionados lo agrego al array de productos seleccionados
                            this.selectedProducts.push(product);
                    }
                });
            });
            return this.selectedProducts;
        },
        getAllCategories() {
            return this.$store.getCentralizedStockAllCategories;
        },
        getShopNames() {
            return this.$store.getCentralizedStockShopNames;
        },
        getProducts() {
            return this.$store.getCentralizedStockProducts;
        }
    },
    methods: {
        normalizeString(str) {  //ignora acentos, diéresis.
            if (str == null) {
                return ""
            }
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        },
        handleClickProduct(product) { //Cuando hago click sobre un producto
            if (product.shops.length > 1) { //Pregunto si es mayor a uno porque es la condicion para que un producto sea expandible
                this.filteredProduct.forEach(p => { //Agarro la lista de selectedShops y pongo el expanded en false para todos los productos salvo el que toque para expandir
                    if (p !== product) {
                        p.expanded = false;
                    }
                });
                //Cargo en expandedProduct la informacion del producto que toque
                this.expandedProduct.product = product.expanded ? null : product.productName;
                this.expandedProduct.sku = product.expanded ? null : product.sku;
                //Le invierto el boleano del expanded
                product.expanded = !product.expanded;
            }
        },
        pageChangeHandle(value) { //Cuando se clickea en el pagination se emite un evento que ejecuta esta funcion
            console.log('Centralized stock pageChangeHandle')
			this.avoidRectangleAtTheEnd();
            switch (value) {
                case "next":
                    this.currentPage += 1;
                    break;
                case "previous":
                    this.currentPage -= 1;
                    break;
                default:
                    this.currentPage = value;
            }
            //Llamo al back para que me traiga la lista relacionada a esta nueva pagina
            this.getCentralizedStockProducts(this.categories.every(category => this.selectedCategories.includes(category)) ?? true ? null : this.selectedCategories,
                this.shopNames.every(shop => this.selectedShopNames.includes(shop)) ?? true ? null : this.selectedShopNames,
                this.currentPage,
                this.countPerPage,
                this.shopCode);
        },
        isMoreOfOnePage() {
            return this.totalCount - this.countPerPage > 0;
        },
    },
    watch: {
        getAllCategories(newValue) {
            if (newValue?.length > 0)//Si lo que me vino del back es valido
            {
                this.categories = newValue;
                this.selectedCategories = newValue;
            }
        },
        isCategoryFilterChecked(newValue) { //Me fijo el estado del main switch de las categorias
            if (newValue) {//Si esta marcado, pongo todas las categorias
                this.selectedCategories = this.categories;
            } else { //Si esta desmarcado limpio el array de los seleccionados
                this.selectedCategories = [];
            }
        },
        selectedCategories() { //Cuando cambian las categorias seleccionadas hago una peticion al back para refrescar la lista de productos
            this.currentPage = 1;
            if (this.categories.length > 0) {
                this.$store.setCentralizedStockSelectedCategories(this.selectedCategories);
                if (this.shopNames.length > 0)
                    this.getCentralizedStockProducts(this.categories.every(category => this.selectedCategories.includes(category)) ?? true ? null : this.selectedCategories,
                        this.shopNames.every(shop => this.selectedShopNames.includes(shop)) ?? true ? null : this.selectedShopNames,
                        this.currentPage,
                        this.countPerPage,
                        this.shopCode);
            }
        },
        getShopNames(newValue) {
            if (newValue?.length > 0)//Si lo que me vino del back es valido
            {
                this.shopNames = newValue;
                this.selectedShopNames = newValue;
            }
        },
        isShopNameFilterChecked(newValue) { //Me fijo el estado del main switch de los shops
            if (newValue) {//Si esta marcado, pongo todos los nombres
                this.selectedShopNames = this.shopNames;
            } else { //Si esta desmarcado limpio el array de los seleccionados
                this.selectedShopNames = [];
            }
        },
        selectedShopNames() { //Cuando cambian los shops seleccionados hago una peticion al back para refrescar la lista de productos
            if (this.shopNames.length > 0) {
                this.$store.setCentralizedStockSelectedShopNames(this.selectedShopNames);
                if (this.categories.length > 0)
                    this.getCentralizedStockProducts(this.categories.every(category => this.selectedCategories.includes(category)) ?? true ? null : this.selectedCategories,
                        this.shopNames.every(shop => this.selectedShopNames.includes(shop)) ?? true ? null : this.selectedShopNames,
                        this.currentPage,
                        this.countPerPage,
                        this.shopCode);
            }
        },
        getProducts(newValue) {
            if (newValue?.selectedProducts)//Si lo que me vino del back es valido
            {
                this.productsList = newValue?.selectedProducts; //Cargo la lista que me llego del back
                this.totalCount = newValue?.totalCount;
            }
        },
    },
};
</script>

<style>
/* PANEL DE CONTROL */
.products-container-container {
    display: flex;
    flex-direction: column;
    width: 85%;
}

.products-container {
    background-color: white;
    flex-grow: 1;
    margin-left: 40px;
    padding: 15px;
    border-radius: 5px;
}

.products-container-bottom {
    margin-bottom: 35px;
}

/* SETEA EL GRID PARA TODO EL PANEL EN GENERAL SALVO PARA EL EXPANDIDO */
.products-container-grid {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr 0.75fr 0.4fr;
    gap: 10px;
}

/* PANEL DE PRODUCTOS - CABECERA */
.products-container-title {
    padding-bottom: 20px;
    border-bottom: 1px solid black;
}

.products-container-title h2 {
    font-size: 14px;
}

.products-container-title h2:last-child {
    text-align: right;
}

/* PANEL DE PRODUCTOS - PRODUCTOS */
.products-container-list {
    border-bottom: 1px solid rgb(0, 0, 0, 0.2);
}

.products-container-product {
    align-items: center;
    padding: 20px 0px;
}

.products-container-product-dropdown {
    display: flex;
    align-items: center;
}

.products-container-product label {
    font-size: 13px;
    font-family: 'Rubik-Regular', sans-serif;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.products-container-product label:last-child {
    text-align: right;
}

.expanded-tab {
    background-color: #efefef;
    margin-bottom: 5px;
}

.expanded-tab-title {
    display: grid;
    grid-template-columns: 0.75fr 1fr 2fr 1fr 1fr;
    padding: 10px 0px;
    font-weight: bold;
}

.expanded-tab-info {
    display: grid;
    grid-template-columns: 0.75fr 1fr 2fr 1fr 1fr;
    padding: 10px 0px;
}

.expanded-tab-title label {
    font-size: 13px;
    color: black;
    font-family: 'Rubik-Regular', sans-serif;
}

.expanded-tab-info label {
    font-size: 13px;
    color: black;
    font-family: 'Rubik-Regular', sans-serif;
}

.expanded-tab-code {
    grid-column: 2;
}

.expanded-tab-name {
    grid-column: 3;
}

.expanded-tab-quantity {
    grid-column: 4;
    text-align: right;
}

.rotate-90 {
    transform: rotate(90deg);
}

.centralized-stock-filters {
    min-width: 250px;
}

.centralized-stock-panel {
    flex-wrap: nowrap !important;
}

.centralized-stock-list-item {
    flex-wrap: nowrap !important;
}

@media screen and (max-width: 1300px) {
    .products-container-grid {
        grid-template-columns: 1fr 1fr 1fr 0.75fr 0.4fr;
    }
}
</style>

<template>
  <section class="genericSelect-container">
    <div class="genericSelect" :data-value="value" :data-list="options">
      <div class="selector" @click="toggle()">
        <div class="label">
          {{ getValue }}
        </div>
        <div class="arrow" :class="{ expanded: visible }"></div>

        <ul
          id="dropdown-generic-select"
          class="dropdown-content dropdownGenericSelect"
        >
          <li
            :class="{ current: initialValue === value }"
            @click="onSelectOption(initialValue)"
          >
            {{ initialValue }}
          </li>
          <li
            :class="{ current: item.name === value }"
            v-for="(item, index) in options"
            :key="index"
            @click="onSelectOption(item.name)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    initialValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      value: null,
    };
  },
  methods: {
    toggle() {
      this.visible = !this.visible;

      const id = "dropdown-generic-select";
      const element = document.getElementById(id);

      if (this.visible) {
        element.style.opacity = 1;
        element.style.display = "block";
      } else {
        element.style.opacity = 0;
        element.style.display = "none";
      }
    },
    onSelectOption(option) {
      this.value = option;

      if (option === this.initialValue) this.$emit("onSelectOption", "");
      else this.$emit("onSelectOption", this.value);
    },
  },
  computed: {
    getValue() {
      if (this.value) return this.value;

      return this.initialValue;
    },
  },
};
</script>

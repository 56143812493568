<script>
//import * as firebase from "firebase/app";
//import "firebase/storage";
import { firebaseApp } from "@/firebase";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from "axios";
export default {
  methods: {
    saveMediaInCloudStorage(t, savePath, callback, shouldResize = true, shopCode = null, sku = null) {
      let formData = new FormData();
      formData.append("image", t.selectedFile);
      formData.append("key", savePath);
      formData.append("shouldResize", shouldResize);
      
      if(shopCode != null || sku != null) //Si shopCode tiene algo es porque quiero que se haga el resize para todos los fds.
      {
        formData.append("shopCode", shopCode);
        formData.append("sku", sku);
      }
      

      axios
        .post("/api/productsV2/uploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          t.$store.finishLoading(t);
          t.isImageLoading = false;

          callback(res.data.url);
        })
        .catch((err) => {
          t.$store.errorLoading(t);
          t.isImageLoading = false;
          this.showErrors(err.response);
        });
    },
    saveMediaInFirebaseStorage(t, savePath, callback) {
      // Obtener instancia del storage
      const storage = getStorage(firebaseApp); 
      const storageRef = ref(storage, savePath); // Crear referencia al archivo con la ruta donde se guardará

      const newMetadata = {
        cacheControl: "public,max-age=86400",
      };
      const task = uploadBytesResumable(storageRef, t.selectedFile, newMetadata);

      // Monitorear el progreso de la subida
      task.on(
        "state_changed",
        (snapshot) => {
          // Calcular el progreso
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Progreso de subida: ${progress}%`);
        },
        (error) => {
          // Manejar errores durante la subida
          console.error("Error al subir el archivo:", error);
          t.$store.errorLoading(t);
        },
        () => {
          // Obtener la URL de descarga una vez la subida ha finalizado
          getDownloadURL(task.snapshot.ref).then((url) => {
            callback(url); // Llamar al callback con la URL del archivo
          });
        }
      );
    }

  },
};
</script>

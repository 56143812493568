<template>
    <div id="modalCentralizedProds" class="modal modal-block-outside" style="overflow-y: auto !important;"
        @keydown.esc="closeModal()">
        <div class="modal-header">
            <div class="image-title">
                <h4>Productos centralizados</h4>
                <p>Fácil y Simple</p>
            </div>
            <div class="btnClose">
                <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>

        <!-- BODY MODAL -->
        <div class="modal-body">
            <form action class="formGeneral">
                <article class="formMain">
                    <div class="modal-header-product">
                        <p v-if="isCentralizedProdEnabled">Al habilitar productos centralizados, los productos,
                            adicionales, gustos, ingredientes, combos y cartas de todas las sucursales serán
                            reemplazadas con la información de este comercio</p>
                        <p v-else>Se deshabilitará la configuración de productos centralizados, las sucursales asociadas
                            volverán a tener sus productos, adicionales, gustos, ingredientes, combos y cartas
                            originales</p>
                        <p>¿Está seguro?</p>
                    </div>
                </article>
            </form>
        </div>

        <div class="modal-footer">
            <article class="btn-main">

                <Spinner v-if="isLoading" />
                <a v-else @click="confirmProducts" type="submit">
                    <p>{{ buttonAction }}</p>
                </a>
            </article>
        </div>


    </div>
</template>

<script>
import Spinner from '../components/SpinnerComp'


export default {
    props: {
        isCentralizedProdEnabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            buttonAction: 'Continuar',
            isLoading: false
        }
    },
    computed: {},
    methods: {
        closeModal() {
            this.$emit('onModalClosing')
        },
        confirmProducts() {
            this.isLoading = true;
            this.$emit('onConfirmCentralizedProductsSync')
        }

    },
    components: {
        Spinner
    }
}
</script>
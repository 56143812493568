<template>
  <button :class="[{ disabled: isDisabled, 'no-hover': disableHover }]" @click="handleClick" class="button-pay-cards">
    <span v-if="icon" class="button-icon-pay">
      <img :src="icon" :alt="alt"/>
    </span>
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "ButtonBistro",
  props: {
    variant: {
      type: String,
      default: "solid-gray",
      validator: function (value) {
        return [
          "solid-green",
          "solid-blue",
          "gradient-gray",
        ].includes(value);
      },
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false, 
    },
    alt: {
      type: String
    },
    disableHover: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isDisabled() {
      return this.$pdv.getIsDisabled;
    }
  },
  methods: {
    handleClick() {
      if (this.isDisabled) return;
      this.$emit("handleClick");
    },
  },
};
</script>

<style lang="scss">
.button-pay-cards {
    cursor: pointer;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9rem;
    font-size: 16px;
    font-weight: 600;
    width: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #f8f8f8;
  }

  .button-pay-cards .button-icon-pay img {
    width: 35% !important;
  }

.button-pay-cards:hover:not(.no-hover) {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    background-color: #e6e6e6; 
}

.button-pay-cards.no-hover {
  cursor: default;
  background-color: #f8f8f8;
}
</style>

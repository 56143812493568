<template>
    <div v-if="hasToShowThisProfile && hasToShowSection">
      <div>
        <div class="itemForm" v-if="shop.fiscalData.isBistroWebEditionEnabled">
          <Spinner v-if="isLoading" />
          <!-- Si no esta cargando se muestran las opciones -->
          <div v-else>
            <!-- NIF -->
            <div class="settings-div">
              <label class="settings-label" for>NIF:</label>
            </div>
            <input class="search-input" type="text" v-model="/*eslint-disable*/shop.fiscalData.fiscalKey" />
            <!-- Domicilio fiscal -->
            <div class="settings-div">
              <label class="settings-label" for>Domicilio Fiscal:</label>
            </div>
            <input class="search-input" type="text" v-model="shop.fiscalData.legalAddress" />
                    <!-- Razon Social -->
            <div class="settings-div">
              <label class="settings-label" for>Razón Social:</label>
            </div>
            <input class="search-input" type="text" v-model="shop.fiscalData.legalName" />
            <!-- Punto de venta factura simplificada -->
            <div class="settings-div">
              <label class="settings-label" for>Punto de Venta Factura Simplificada:</label>
            </div>
            <input class="search-input" type="text" v-model="shop.fiscalData.simpleBillPos" />
            <!-- Punto de venta factura ordinaria -->
            <div class="settings-div">
              <label class="settings-label" for>Punto de Venta Factura Ordinaria:</label>
            </div>
            <input class="search-input" type="text" v-model="shop.fiscalData.ordinaryBillPos" />
            <article class="btn-main btn-main-certificate">
              <a class="btn-active" @click="processFiscalDataFields">
                <p>GUARDAR</p>
              </a>
            </article>
            <modal-fiscal-data-edition-confirmation-es
              :id="`modalFiscalDataEditionConfirmationEs_${shop.code}`"
              :shopCode="shop.code"
              :previousFiscalKey="previousFiscalData.fiscalKey"
              :currentFiscalKey="shop.fiscalData.fiscalKey"
              :previousLegalName="previousFiscalData.legalName"
              :currentLegalName="shop.fiscalData.legalName"
              :previousLegalAddress="previousFiscalData.legalAddress"
              :currentLegalAddress="shop.fiscalData.legalAddress"
              :previousSimpleBillPos="previousFiscalData.simpleBillPos"
              :currentSimpleBillPos="shop.fiscalData.simpleBillPos"
              :previousOrdinaryBillPos="previousFiscalData.ordinaryBillPos"
              :currentOrdinaryBillPos="shop.fiscalData.ordinaryBillPos"
              @onModalClosed="onFiscalDataEditionConfirmationModalClosingEs"
            />
          </div>
        </div>
        <!-- Si el comercio no tiene habilitada esta opcion -->
        <div v-else>
          Para modificar sus datos comuníquese con
          <a href="https://wa.me/5491137757552">
            <p>Mesa de Ayuda</p>
          </a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Spinner from "@/components/SpinnerComp";
  import commons from "@/mixins/mixCommons";
  import modalCommons from "@/mixins/mixModals";
  import ModalFiscalDataEditionConfirmationEs from "@/components/modals/ModalFiscalDataEditionConfirmationES";
  
  export default {
    data() {
      return {
        shopCode: null,
        previousFiscalData: { ...this.shop.fiscalData }, // Se guarda el estado anterior de los datos fiscales
      };
    },
    props: {
      shop: {
        type: Object,
        default: () => ({}),
      },
      hasToShowThisProfile: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      Spinner,
      ModalFiscalDataEditionConfirmationEs,
    },
    mixins: [commons, modalCommons],
    methods: {
      hasFiscalDataChangedES() {
        // Comparar todos los campos entre `previousFiscalData` y `shop.fiscalData`
        const prev = this.previousFiscalData;
        const curr = this.shop.fiscalData;
  
        return (
          prev.fiscalKey !== curr.fiscalKey ||
          prev.legalName !== curr.legalName ||
          prev.legalAddress !== curr.legalAddress ||
          prev.simpleBillPos !== curr.simpleBillPos ||
          prev.ordinaryBillPos !== curr.ordinaryBillPos
        );
      },
      processFiscalDataFields() {
        this.shopCode = this.shop.code;
        if (!this.hasFiscalDataChangedES()) {
          this.showError("No se detectaron cambios en los datos fiscales.");
          return;
        }
        if (!this.processNIF() || !this.processAddress() || !this.processLegalName() || !this.processBillPos()) {
          return;
        }
        this.openModalById(`#modalFiscalDataEditionConfirmationEs_${this.shop.code}`);
      },
      processNIF() {
        const alphanumericRegex = /^[a-zA-Z0-9]+$/;
        const nif = this.shop.fiscalData?.fiscalKey;
        if (!nif || nif.length !== 9 || !alphanumericRegex.test(nif)) {
          this.showError("El NIF debe tener 9 caracteres alfanuméricos.");
          return false;
        }
        return true;
      },
      processAddress() {
        if (!this.shop.fiscalData?.legalAddress) {
          this.showError("La dirección fiscal no puede estar vacía.");
          return false;
        }
        return true;
      },
      processLegalName() {
        if (!this.shop.fiscalData?.legalName) {
          this.showError("La razón social no puede estar vacía.");
          return false;
        }
        return true;
      },
      processBillPos() {
        const regex = /^[a-zA-Z0-9\-_]+$/;
        const { simpleBillPos, ordinaryBillPos } = this.shop.fiscalData;
  
        if (!simpleBillPos || !regex.test(simpleBillPos)) {
          this.showError("El punto de venta de factura simplificada es inválido.");
          return false;
        }
        if (!ordinaryBillPos || !regex.test(ordinaryBillPos)) {
          this.showError("El punto de venta de factura ordinaria es inválido.");
          return false;
        }
        return true;
      },
      onFiscalDataEditionConfirmationModalClosingEs() {
        this.closeModalById(`#modalFiscalDataEditionConfirmationEs_${this.shop.code}`);
      },
    },
    computed: {
      hasToShowSection() {
        return process.env.VUE_APP_INSTANCE_CODE == "ES";
      },
    },
  };
  </script>
  
<template>

    <SpacerBistro justify="space-between">
        <div class="big-title">Comanda # {{ order.id }}</div>
        <ButtonBistro variant="icon-only" :icon="require('@assets/icon-trash.svg')" @handleClick="onDelete" v-tooltip="'Eliminar Orden'"/>
    </SpacerBistro>
    <DividerBistro :height="8" />
    <InputBistro type="text" placeholder="Escriba aquí un comentario" :initialValue="order.comment"
        @handleChange="handleMsgBistroClick" class="inputBistro"/>
    <div v-for="(prod, index) in order.products" :key="index">
        <OrderItemBistro :isActive="selectedProduct && selectedProduct.index == index" :prod="prod"
            @handleChangeActive="handleProductSelection(index, prod)" 
            @handleRemoveItem="handleRemoveProduct(index)"
            @contextmenu.prevent="handleRightClick(index)"
            @handleChangeQuantity="handleChangeProdQuantity(index, $event)"/>
        <CommentProduct v-if="prod.discountByAmount || prod.discountByPercentage" :product="prod" @removeComment="() => handleRemoveDiscount(index, prod)" :variant="'bg-purple'" :discount="true"/>
        <CommentProduct v-if="prod.comment" :product="prod" :variant="'bg-gold'"/>
    </div>
    <ModalComment 
        v-if="showProductCommentModal"  
        :showProductCommentModal="showProductCommentModal" 
        :selectedProductComment="selectedProductComment"
        @update:showProductCommentModal="showProductCommentModal = false"
        @onClick="handleProductCommentSave"
        >
    </ModalComment>
</template>

<script>
import SpacerBistro from "./SpacerBistro.vue";
import ButtonBistro from "./ButtonBistro.vue";
import DividerBistro from "./DividerBistro.vue";
import OrderItemBistro from "./OrderItemBistro.vue";
import InputBistro from "./InputBistro.vue";
import clickCommons from "../mixins/clickCommons.vue";
import ModalComment from "./ModalComment.vue";
import CommentProduct from "./CommentProduct.vue";

export default {
    name: "SidebarOrderDetail",
    mixins: [clickCommons],
    components: {
        SpacerBistro,
        ButtonBistro,
        DividerBistro,
        OrderItemBistro,
        InputBistro,
        ModalComment,
        CommentProduct
    },
    props: {
        order: {
            type: Object,
            required: true,
        },
        onDelete: {
            type: Function,
            required: true,
        },
        selectedProduct: {
            type: Object,
            default: null,
        },
    },
    data(){
        return{
            showProductCommentModal: false,
            selectedProductComment: null,
            selectedProductCommentIndex: null,
            localOrder: null
        }
    },
    created() {
        this.localOrder = { ...this.order }
    },
    methods: {
        handleRightClick(index){
            const baseUrl = "/pdv/orders"
            if(this.$route.path !== baseUrl){
                this.showProductCommentModal = true
                this.selectedProductCommentIndex = index    
                if(this.localOrder.products[index].comment) {
                    this.selectedProductComment = this.localOrder.products[index].comment
                } else {
                    this.selectedProductComment = ""
                }
            } 
        },
        handleProductCommentClick(value){
            this.selectedProductComment = value
        },
        handleProductCommentSave(value) {
            const index = this.selectedProductCommentIndex

            if(this.localOrder.products[index].comment || this.localOrder.products[index].quantity == 1 || this.localOrder.products[index].weightable){
                this.localOrder.products[index].comment = value;
            } else {
                const product = this.localOrder.products[index]
                const newProduct = { 
                    ...product, 
                    quantity: 1, 
                    comment: value 
                }

                if(newProduct.discountByAmount > 0 || newProduct.discountByPercentage > 0){
                    newProduct.discountByAmount = 0
                    newProduct.discountByPercentage = 0
                }
                
                this.localOrder.products[index].quantity -= 1
                this.localOrder.products.splice(index + 1, 0, newProduct)
            }

            this.showProductCommentModal = false;
        },
        handleRemoveDiscount(index, product) {
            if (product.discountByAmount) {
                this.localOrder.products[index].discountByAmount = null
            } else {
                this.localOrder.products[index].discountByPercentage = null
            }
        }
    }

};
</script>

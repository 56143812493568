import moment from "moment";
moment.locale("es");

export function date(value, option) {
  if (!value) return "";

  if (option === "date") {
    return moment(value).format("DD/MM/YY");
  } else if (option === "dateLong") {
    return moment(value).format("D MMMM YYYY - h:mm:ss a");
  } else if (option === "dateMedium") {
    return moment(value).format("dddd, MMMM DD. YYYY");
  } else if (option === "time") {
    return moment(value).format("LT");
  }
}

<template>
	<div class="base-pagination">
		<button :disabled="isPreviousButtonDisabled" @click="previousPage" class="arrow-button">&lt;&lt;</button>
		<BasePaginationTrigger
		v-for="paginationTrigger in paginationTriggers"
		:class="{
		'base-pagination__description--current':
			getInt(paginationTrigger) === currentPage
		}"
		:key="paginationTrigger"
		:pageNumber="getInt(paginationTrigger)"
		class="base-pagination__description"
		@loadPage="onLoadPage"
		/>

		<button :disabled="isNextButtonDisabled" @click="nextPage" class="arrow-button">>></button>
	</div>
</template>
<script>
import BasePaginationTrigger from "./BasePaginationTrigger";

export default {
	props: {
		currentPage: {
			type: Number,
			required: true
		},
		totalCount: {
			type: Number,
			required: true
		},
		countPerPage: {
			type: Number,
			required: true
		}
	},
	computed: {
		isPreviousButtonDisabled () {
			return this.currentPage === 1
		},
		isNextButtonDisabled () {
			return this.currentPage === this.getCountPage
		},
		paginationTriggers () {
			const currentPage = this.currentPage
			const pageCount = this.getCountPage
			const visiblePagesCount = this.getVisiblePagesCount
			const visiblePagesThreshold = (visiblePagesCount - 1) / 2
			const pagintationTriggersArray = Array(visiblePagesCount - 1).fill(0)

			if (currentPage <= visiblePagesThreshold + 1) {
				pagintationTriggersArray[0] = 1
				const pagintationTriggers = pagintationTriggersArray.map(
					(paginationTrigger, index) => {
						return pagintationTriggersArray[0] + index
					}
				)
				pagintationTriggers.push(pageCount)
				return pagintationTriggers
			}

			if (currentPage >= pageCount - visiblePagesThreshold + 1) {
				const pagintationTriggers = pagintationTriggersArray.map(
					(paginationTrigger, index) => {
						return pageCount - index
					}
				)
				pagintationTriggers.reverse().unshift(1)
				return pagintationTriggers
			}

			pagintationTriggersArray[0] = currentPage - visiblePagesThreshold + 1
			const pagintationTriggers = pagintationTriggersArray.map(
				(paginationTrigger, index) => {
					return pagintationTriggersArray[0] + index
				}
			)

			pagintationTriggers.unshift(1)
			pagintationTriggers[pagintationTriggers.length - 1] = pageCount
			return pagintationTriggers
		},
		getVisiblePagesCount () {
			let pages = this.getCountPage

			switch (pages) {
			case 1:
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
			case 7:
				return pages
			default:
				return 7
			}
		},
		getCountPage () {
			let rest = this.totalCount % this.countPerPage

			let pages = (this.totalCount - rest) / this.countPerPage

			if (rest !== 0) {
				pages += 1
			}
			return pages
		}
	},
	methods: {
		nextPage () {
			this.$emit('nextPage')
		},
		previousPage () {
			this.$emit('previousPage')
		},
		onLoadPage (value) {
			this.$emit('loadPage', value)
		},
		getInt (value) {
			return Math.floor(value)
		}
	},
	components: {
		BasePaginationTrigger
	}
}
</script>

<script>
export default {
	methods: {
		closeModalById(modalId) {
			/* eslint-disable */
			modalId = modalId.slice(1);
			var modalInstance = document.getElementById(modalId);
			var instance = M.Modal.init(modalInstance);
			instance.close();
			/* eslint-enable */
		},
		openModalById(modalId) {
			/* eslint-disable */
			modalId = modalId.slice(1);
			var modalInstance = document.getElementById(modalId);
			var instance = M.Modal.init(modalInstance);
			if(instance)
				instance.open();
			/* eslint-enable */
		},

	}
}
</script>

import axios from "axios";

const printByNet = async (content, printerConfig) => {
    try {
        const response = await axios.post('/api/printer', { content, printerConfig });

        if (response.status !== 200) {
            console.error('Error al enviar la impresión');
            return false;
        }

        return true;
    } catch (error) {
        console.error('Error al imprimir:', error);
        return false;
    }
};

export { printByNet };
<template>
  <div
    id="ConfirmationModalBeforeVoidBill"
    class="modal"
    style="overflow-y: auto !important"
  >
    <div class="modal-header">
      <div class="image-title">
        <h4>Anular Factura</h4>
        <p>Fácil y Simple</p>
      </div>

      <!-- CLOSE -->
      <!-- <div class="btnClose">
        <a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div> -->
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <article class="formMain">
          <div class="modal-header-product">
            <p>
              Está seguro que desea anular la factura
              <strong>#{{ orderExtendedInfo.id }}</strong
              >?
              <strong class="advice-red"
                >Recuerde que se anulará la factura manteniendo la comanda
                existente.</strong
              >
              ¿Está seguro que desea hacerlo?
            </p>
          </div>
        </article>
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <a v-if="!isLoading" class="deleteProduct" @click.prevent="closeModal">
          <span>Cancelar</span>
        </a>
        <SpinnerComp v-if="isLoading" />
        <a v-else @click="onConfirmVoidBill" type="submit">
          <p>{{ buttonAction }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
import mixCommons from "../mixins/mixCommons";
import axios from "axios";
import SpinnerComp from "../components/SpinnerComp";

export default {
  mixins: [mixCommons],
  props: {
    orderExtendedInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      buttonAction: "Anular Factura",
      params: {
        internalReference: "",
        billType: "E",
      },
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("onCloseModal");
    },
    onConfirmVoidBill() {
      this.params["internalReference"] = this.orderExtendedInfo.uuid;
      this.$store.initLoading(this);
      this.isLoading = true;
      axios
        .post("/api/billViewer", this.params)
        .then((res) => {
          if (res.data.responseCode === 0) {
            this.showSuccess("Venta anulada con éxito!");
            this.$store.finishLoading(this);
            this.$emit("onCloseModalAndReloadPage");
          } else {
            this.showError(res.data.responseMessage);
            this.$store.errorLoading(this);
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
          this.showErrors(err.response);
          this.$store.errorLoading(this);
          this.isLoading = false;
        });
    },
  },
  components: {
    SpinnerComp,
  },
};
</script>
<style lang="stylus">
.show-div {
	display: block;
}

.hide-div {
	display: none;
}
</style>

<template>
  <div>
    <div
      id="g_id_onload"
      :data-client_id="clientId"
      data-callback="handleCredentialResponse"
      data-auto_select="false"
      data-itp_support="true">
    </div>
    <div
      class="g_id_signin"
      data-type="standard"
      data-size="large"
      data-theme="outline"
      data-text="sign_in_with"
      data-shape="rectangular"
      data-logo_alignment="left">
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoogleSignIn',
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoaded: false
    }
  },
  mounted() {
    this.loadGoogleScript()

    window.handleCredentialResponse = (response) => {
      this.$emit('credentialResponse', response)
    }
  },
  methods: {
    loadGoogleScript() {
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.async = true
      script.defer = true
      script.onload = () => {
        this.isLoaded = true
      }
      document.head.appendChild(script)
    }
  }
}
</script>
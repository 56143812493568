<script>
export default {
  computed: {},
  methods: {
    getWebAccessSubDomainByInstance() {
      let subdomain = "https://webaccess.bistrosoft.com";

      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "MX": {
          subdomain = "https://mx.bistrosoft.com";
          break;
        }
        case "UY": {
          subdomain = "https://uy.bistrosoft.com";
          break;
        }
        case "ES": {
          subdomain = "https://es.bistrosoft.com";
          break;
        }
        case "CL": {
          subdomain = "https://cl.bistrosoft.com";
          break;
        }
        case "PROVENTAS": {
          subdomain = "https://webaccess.tasproventas.com";
          break;
        }
        case "ENEERGY_POS_SL": {
          subdomain = "https://sl.energy-pos.com";
          break;
        }
      }

      return subdomain;
    },
  },
};
</script>

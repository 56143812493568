<template>
  <div class="main-body">
    <header class="main-header">
      <header-simple />
      <header-options />
    </header>
    <section v-if="loading" class="containBlock"></section>
    <div class="body-container">
      <slot />
    </div>

    <footer>
      <FooterOrderDetails v-if="order?.products && showDetails" :order="order" />
    </footer>
    <modal-calendar title="Seleccione fecha" />
  </div>
</template>

<script>
import HeaderSimple from "../../../components/HeaderSimple.vue";
import HeaderOptions from "../../../components/HeaderOptions.vue";
import ModalCalendar from "../../../components/ModalCalendar";
import mixResize from "../../../mixins/mixResize";
import FooterOrderDetails from "../components/FooterOrderDetails.vue";
// import SidebarBistro from "../components/SidebarBistro.vue";


export default {
  mixins: [mixResize],

  computed: {
    loading() {
      return this.$store.getLoading;
    },
    order() {
      return this.$pdv.getOrder;
    },
    showDetails() {
      return this.$pdv.getShowDetails;
    }	
  },
  mounted() { },

  components: {
    HeaderSimple,
    HeaderOptions,
    ModalCalendar,
    FooterOrderDetails,
    // SidebarBistro
  },
};
</script>

<style scoped>
/* General body settings */
.main-body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header styles */
header {
  padding: 0px 0px;
}

.main-header {
  padding: .7em 1.5em 0 1.5em;
}


/* Body container layout */
.body-container {
  display: flex;
  flex-grow: 1;
  /* Permite que el contenedor crezca y ocupe el espacio disponible */
  flex-direction: row;
  /* Establece que los hijos (aside y main) se dispongan en filas */
  overflow: hidden;
}
/* Aseguramos que el footer quede al final */
footer {
    margin-top: auto; /* Hace que el footer se mantenga al final incluso si el contenido es pequeño */
}


</style>
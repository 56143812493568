<script>
	export default {
		watch: {
			selectedPdvs () {
				let ids = this.selectedPdvs.map(item => {
					return item.shopCode
				})
				if (ids.length > 0) {
					this.params['MerchantFilter'] = ids
				} else {
					delete this.params['MerchantFilter']
				}
				this.getData()
			}
		}
	}
</script>

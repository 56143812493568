<template>
  <!-- Modal Structure -->
  <article :class="{
      onlyFilter: elements.hideCalendar && elements.hideTabs,
      headerReport: elements.hideSomeTabs,
      headerReportSales:
        !elements.hideCalendar && !elements.hideFilters && elements.hideTabs,
    }" v-if="!mobile" class="headerOptions">
    <!-- TABS -->
    <ShopFilter />

    <!-- CALENDARIO -->
    <section v-if="!elements.hideCalendar" class="calendar">
      <div class="calendar-title">
        <figure>
          <span class="icon-Bistro-_calendar"></span>
        </figure>
        <div class="datepicker-trigger">
          <h3 @click="showCalendar" id="datepicker-trigger">{{ date }}</h3>
        </div>
      </div>
    </section>
    <!-- FILTRADO GENERAL -->
    <section v-if="!elements.hideFilters" class="filterPDV" :class="{
      filterFirebaseShop: getFilterPDVToRight,
      'filterPDV-products':
        showProductListSelectionFilter || showProviderListSelectionFilter,
    }">
      <!-- RESUMEN DE SELECCIONADOS -->

      <ul v-if="showMerchantSelectionFilter" class="filterPDV-link">
        <h5 v-if="selectedPdvs.length > 0">{{ selectedPdvs.length }} Comercios Seleccionados</h5>
      </ul>

      <!-- ENLACE A DROPDOWN -->
      <a v-if="showMerchantSelectionFilter" href="#" class=" filterPDV-link" @click="showGeneralFilter">
        <h5 v-if="selectedPdvs.length === 0">Filtro Comercios</h5>

        <figure>
          <p>+</p>
        </figure>
      </a>

      <div v-if="showMerchantSelectionFilter === false">
        <MerchantSelectionFilter v-if="showProductListSelectionFilter && !isShowingCentralizedStock"
          :optionList="productLists" @onSelectOption="onProductsListChanged" />

        <MerchantSelectionFilter v-if="showProviderListSelectionFilter" :optionList="providerLists"
          @onSelectOption="onProvidersListChanged" />

        <MerchantSelectionFilter v-if="showMenuEngineeringListSelectionFilter" :optionList="menuEngineeringLists"
          @onSelectOption="onMenuEngineeringListChanged" />

        <MerchantSelectionFilter v-if="showUserListSelectionFilter" :optionList="userList"
          :selectedShopCode="selectedShopCode" @onSelectOption="onUserListChanged" />

        <MerchantSelectionFilter v-if="showSettingListSelectionFilter" :optionList="settingList"
          @onSelectOption="onSettingListChanged" />
          
        <!-- Seccion mis pagos -->
        <MerchantSelectionFilter v-if="showMyPaymentsListSelectionFilter" :optionList="myPaymentsList"
          @onSelectOption="onMyPaymentsListChanged" />

        <MerchantSelectionFilter v-if="showClientListSelectionFilter" :optionList="clientList"
          @onSelectOption="onCustomerListChanged" />

        <MerchantSelectionFilter v-if="showPDVListSelectionFilter" :optionList="PDVList"  @onSelectOption="onPDVListChanged"
         />

      </div>

      <!-- Dropdown Structure -->
      <div v-if="hasToShowGeneralFilter" class="dropdown-click-listener" @click="hasToShowGeneralFilter = false"></div>
      <transition name="fade">
        <ul :class="dropdownClassSelector" v-if="hasToShowGeneralFilter">
          <p :class="dropdownOptionClassSelector" @click="unselectAllShopFilter">Desmarcar todos</p>
          <hr class="dropdown-line">
          <li v-for="item in merchantTags" :key="`${item.shopCode}`" @click="selectPdv(item)"
            class="dropdown-item-container">
            <input class="bistro-checkbox shop-filter-checkbox" type="checkbox" style="opacity: 1;"
              v-model="item.selected" />
            <div class="dropdown-item-text">
              <h4
                :ref="`merchantName_${item.shopCode}`" 
								class="dropdown-truncate-text"
								:title="getMerchantNameForDropdown(item.shopCode, item.merchantName)"
							>
                 {{ item.merchantName }}
              </h4>
              <p>{{ item.shopCode }}</p>
            </div>
          </li>
        </ul>
      </transition>
    </section>
  </article>
</template>
<script>
import mixCommons from "../mixins/mixCommons";
import providerCommons from "../mixins/providerCommons";
import calendarCommons from "../mixins/calendarCommons";
import mixTester from "../mixins/mixTester";
import headerCommons from "../mixins/headerCommons";
import ShopFilter from "../components/ShopFilter";
import MerchantSelectionFilter from "../components/MerchantSelectionFilter";
import moment from "moment";
import stockV2Commons from "../mixins/stockV2Commons";

moment.locale("es");

export default {
  components: {
    ShopFilter,
    MerchantSelectionFilter,
  },
  mixins: [
    mixCommons,
    providerCommons,
    headerCommons,
    mixTester,
    calendarCommons,
    stockV2Commons
  ],
  data() {
    return {
      hasToShowGeneralFilter: false,
    };
  },
  mounted() {
    const tab = this.loadDate();

    if (tab !== undefined) {
      if (tab.id === 6) {
        this.loadFormattedDates();
      }
    }
  },
  computed: {
    selectedShopCode() {
      return this.$store.getRefreshSelectedShopCode;
    },
    calendarType() {
      return this.$store.getCalendarType;
    },
    mobile() {
      return this.$store.getMobile;
    },
    getFilterPDVToRight() {
      return (
        this.showProductListSelectionFilter ||
        this.showProviderListSelectionFilter ||
        this.showClientListSelectionFilter ||
        this.showUserListSelectionFilter ||
        this.showSettingListSelectionFilter ||
        this.showMenuEngineeringListSelectionFilter ||
        this.showPDVListSelectionFilter || 
        this.showMyPaymentsListSelectionFilter
      );
    },
    dropdownClassSelector() {
      return {
        'dropdown-filter': true,
        'filterPDV-dropdown': true,
        'dropdown-filter-show': this.hasToShowGeneralFilter,
      };
    },
    dropdownOptionClassSelector() {
      return {
        'dropdown-options': true,
        'dropdown-options-active': this.selectedPdvs?.length > 0,
      };
    },
  },
  methods: {
    showGeneralFilter() {
      this.hasToShowGeneralFilter = !this.hasToShowGeneralFilter;
    },
  },
};
</script>

<style>
/* SCROLLBAR */
	.dropdown-filter::-webkit-scrollbar {
		width: 4px;
	}

	.dropdown-filter::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 10px;
	}

	.dropdown-filter::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 10px;
	}
	.dropdown-filter::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
/* PANTALLA TRANSPARANTE PARA QUE CUANDO SE HAGA CLICK SOBRE ELLA OCULTE EL FILTRO */
	.dropdown-click-listener {
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
	}
/* DESPLEGABLE DE FILTROS DE COMERCIOS */
	.dropdown-filter{
		border-radius: 8px;
		background-color: #fff;
		margin: 0;
		display: none;
		min-width: 100px;
		max-height: 650px;
		overflow-y: auto;
		opacity: 0;
		position: absolute;
		z-index: 999;
		will-change: width, height;
		display: block;
		box-shadow: 0px 0px 13px 0px rgba(102,102,102,0.75);
	}
	.dropdown-filter-show{
		width: 300px;
		left: 0px;
		top: -41.8125px;
		max-height: 80vh;
		transform-origin: 100% 0px;
		opacity: 1;
		transform: scaleX(1) scaleY(1);
	}
/* ANIMACION PARA MOSTRAR EL FILTRO */
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.15s ease;
	}
	.fade-enter,
	.fade-leave-to{
		opacity: 0;
	}
/* CADA COMERCIO QUE APARECE EN EL FILTRO */
	.dropdown-item-container{
		cursor: pointer;
		display: flex;
		padding: 8px 0px;
	}
	.shop-filter-checkbox {
		margin-left: 18px;
		margin-right: 25px;
	}
	/* NOMBRE DEL COMERCIO */
	.dropdown-item-text h4 {
		white-space: nowrap;
		overflow: hidden; 
		text-overflow: ellipsis;
		max-width: 225px;  
		font-size: 14px;
		line-height: 1.1;
	}
	/* CODIGO DE COMERCIO */
	.dropdown-item-text p{
		color: #888888;
		font-size: 12px;
		line-height: 1;
	}
/* OPCION DE DESELECCIONAR */
	.dropdown-options{
		display: flex;
		justify-content: left;
		padding: 11px 0px;
		line-height: 1;
		margin-left: 18px;
		cursor:default;
		font-size: 14px;
		color: #888888;
	}
	.dropdown-options-active{
		color: #1786FA;
		cursor:pointer;
	}
	.dropdown-options-active:hover{
		background-color: rgb(243, 243, 243);
	}
/* LINEA QUE DIVIDE LOS COMERCIOS DEL BOTON DE DESELECCIONAR */
	.dropdown-line {
        border: none;
        height: 1px;
        background-color: #888888;
		margin-bottom: 5px;
    }
    .dropdown-truncate-text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 225px;
	}
</style>
<template>
  <section id="main_content">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: "BistroSidebar",
  props: {},
  components: {},
};
</script>

<style lang="scss">
#main_content {
  width: calc(100% - 460px);
  background-color: $smokeColor;
  padding: 0;
  .content-bistro-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px 0;
  }
  #footer_content_bistro {
    position: fixed; 
    bottom: 0; 
    left: calc(444px); 
    width: calc(100%);
    background: $lightColorBg;
    height: 80px;
    padding: 0 16px; 
    .button-bistro {
      border-radius: 0;
      width: 170px;
      height: 80px;
      justify-content: center;
    }
  }
  #body_content_bistro {
    padding: 16px;
    // height: calc(100vh - 80px - 53px); 
    max-height: -webkit-fill-available;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>

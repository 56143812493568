<template>
    <div v-if="hasToShowThisProfile">
        <div>
            <!-- SECCION SERVICIOS DE PAGO (Solo si tengo muestro la seccion) -->
            <div v-if="hasPaymentWays">
                <h3 class="integration-title">Medios de pago</h3>
                <div >
                    <!-- Me fijo todos los metodos de pagos que tienen el hasToShow para mostrar unicamente esos -->
                    <div v-if="shop.integratedPaymentWays && shop.integratedPaymentWays.length > 0" class="grid">
                        <div class="card" v-for="paymentWay in shop.integratedPaymentWays" :key="paymentWay.paymentWay"
                            @click="onCardClick(false, paymentWay.paymentWay)">
                            <img :src="getImage(paymentWay.paymentWay)" class="card-img" />
                            <h3 class="card-title">{{ getName(paymentWay.paymentWay) }}</h3>
                            <p class="card-text">
                                Estado de la integración:
                                <span>{{ paymentWay.isEnabled ? "ACTIVADA✅" : "SIN INICIAR" }}</span>
                            </p>
                        </div>
                    </div>

                </div>
                <!-- MODALES DE LOS DISTINTOS MEDIOS DE PAGO -->
                <ModalIntegrationCardsNave :id="`modalIntegrationCardsNave`" :shop="shop" @onModalClosed="onNaveIntegrationCardModalClosing
                    " />
                <ModalIntegrationCardsKamiPay :id="`modalIntegrationCardsKamiPay`" :shop="shop" @onModalClosed="onKamiPayIntegrationCardModalClosing
                    " />
                <ModalIntegrationCardsSimpleFI :id="`modalIntegrationCardsSimpleFI`" :shop="shop" @onModalClosed="onSimpleFIIntegrationCardModalClosing
                    " />
                <ModalIntegrationCardsMercadoPagoQR :id="`modalIntegrationCardsMercadoPagoQR`" :shop="shop"
                    @onModalClosed="onMercadoPagoQRIntegrationCardModalClosing
                        " />
                <ModalIntegrationCardsMercadoPagoPointPlus :id="`modalIntegrationCardsMercadoPagoPointPlus`"
                    :shop="shop" @onModalClosed="onMercadoPagoPointPlusIntegrationCardModalClosing
                        " />
                <!-- ------------------------------ -->
            </div>

            <!-- SECCION SERVICIOS DE DELIVERYS (solo si los tiene) -->
            <div v-if="hasFds">
                <h3 class="integration-title">Servicios de Delivery</h3>
                <div >
                    <!-- Me fijo entre todos los fds que tiene el comercio -->
                    <div v-if="shop.fdsConfigs && shop.fdsConfigs.length > 0" class="grid">
                        <div class="card" v-for="fds in shop.fdsConfigs" :key="fds.sourceLabel"
                            @click="onCardClick(true, fds.sourceLabel)">
                            <img :src="getImage(fds.sourceLabel)" class="card-img" />
                            <h3 class="card-title">{{ getName(fds.sourceLabel) }}</h3>
                            <p class="card-text">
                                Estado de la integración:
                                <span>{{ fds.isEnabled ? "ACTIVADA✅" : "SIN INICIAR" }}</span>
                            </p>
                        </div>
                    </div>

                </div>
                <!-- MODAL PARA INTEGRACIONES DE DELIVERY -->
                <modal-integration-cards-delivery-default :id="`modalIntegrationCardsDeliveryDefault`"
                    :fdsConfig="currentFdsProp" :shop="shop" @onModalClosed="onDefaultIntegrationCardDeliveryModalClosing
                        " />
                <!-- -------------------------------- -->
            </div>

            <!-- SI NO TIENE INTEGRACIONES-->
            <div v-if="!hasPaymentWays && !hasFds">
                <p class="no-integration-text">En este momento no tienes ninguna integración activa.</p>
            </div>
        </div>
    </div>
</template>

<script>
import commons from '@/mixins/mixCommons'
import modalCommons from "@/mixins/mixModals";
import ModalIntegrationCardsDeliveryDefault from "@/components/modals/ModalIntegrationCardsDeliveryDefault";
import ModalIntegrationCardsNave from "@/components/modals/ModalIntegrationCardsNave.vue";
import ModalIntegrationCardsKamiPay from "@/components/modals/ModalIntegrationCardsKamiPay.vue";
import ModalIntegrationCardsSimpleFI from "@/components/modals/ModalIntegrationCardsSimpleFI.vue";
import ModalIntegrationCardsMercadoPagoQR from "@/components/modals/ModalIntegrationCardsMercadoPagoQR.vue";
import ModalIntegrationCardsMercadoPagoPointPlus from "@/components/modals/ModalIntegrationCardsMercadoPagoPointPlus.vue";
export default {
    data() {
        return {
            // Objeto que tiene la configuracion base de una integracion de delivery, esto es lo que le paso al modal con la info del que se presiono
            currentFds: {
                source: 0,
                sourceLabel: "",
                fdsShopCode: "",
                isAutoAcceptEnabled: false,
                isMenuIntegrationEnabled: false,
                isShopOpen: false,
                isEnabled: false,
                shopCode: 0,
                hasToShowScheduleMenu: false,
                menuSchedules: {}
            },
        };
    },
    props: {
        shop: { //Es el shop que estoy analizando en el v-for
            type: Object,
            default: () => ({})
        },
        hasToShowThisProfile: { //Me dice si tengo que mostrar toda esta seccion
            type: Boolean,
            default: false
        },
    },
    methods: {
        getImage(label) { //Esta funcion me devuelve el path a la imagen dependiendo el sourcelabel que viene
            var imageName;
            console.log(label);
            switch (label) {
                // METODOS DE PAGOS
                case "Mercado Pago":
                case "MercadoPagoQR":
                case "MercadoPagoPointPlus": imageName = "MPLogo.jpg"; break;
                case "Modo": imageName = "NaveLogo.jpg"; break;
                case "KamiPay": imageName = "KamiPayIntegrationCardLogo.png"; break;
                case "SimpleFI": imageName = "SimplefiIntegrationCardLogo.svg"; break;
                // FDS
                case "Pedidos Ya": imageName = "PYAIntegrationCardLogo.jpg"; break;
                case "Rappi": imageName = "RappiIntegrationCardLogo.png"; break;
                case "MP Delivery": imageName = "MPLogo.jpg"; break;
                case "Uber Eats": imageName = "UberEatsIntegrationCardLogo.svg"; break;
                case "Deliverect": imageName = "DeliverectLogo.png"; break;
                case "Honei": imageName = "HoneiIntegrationCardLogo.png"; break;
                case "Didi Food": imageName = "DidiIntegrationCardLogo.jpg"; break;
                default: imageName = "Error"
            }
            if (imageName == "Error")
                return "";

            return require(`@/assets/img/${imageName}`);
        },
        getName(label) {//Esta funcion me devuelve el nombre del servicio de delivery o medio de pago ya que pueden venir con una abreviacion
            var name;
            switch (label) {
                case "Modo": name = "Nave"; break;
                case "MP Delivery": name = "Mercado Pago Delivery"; break;
                case "MercadoPagoQR": name = "Mercado Pago QR"; break;
                case "MercadoPagoPointPlus": name = "MP Point Plus/Smart"; break;
                case "KamiPay": name = "kamiPay"; break;
                default: name = label;
            }
            return name;
        },
        loadFdsConfig(label) { //A esta funcion se le pasa el label de la tarjeta que clickee y carga en currentFds la info de ese fds
            this.shop?.fdsConfigs?.forEach(fds => { // veo por todos los fds que tiene configurados ese comercio
                if (fds.sourceLabel == label)    //si el nombre del fds que clicke coincide con el que estoy analizando
                {
                    //le cargo al objeto todos los variables que necesito
                    this.currentFds = {
                        source: fds.source,
                        sourceLabel: fds.sourceLabel,
                        fdsShopCode: fds.fdsShopCode,
                        isAutoAcceptEnabled: fds.isAutoAcceptEnabled,
                        isMenuIntegrationEnabled: fds.isMenuIntegrationEnabled,
                        isShopOpen: fds.isShopOpen,
                        isEnabled: fds.isEnabled ?? false,
                        shopCode: fds.shopCode,
                        hasToShowScheduleMenu: fds.hasToShowScheduleMenu,
                        menuSchedules: fds.menuSchedules
                    }
                }
            });
        },
        onCardClick(isFds, label) { //Cuando se clickea una tarjeta ya sea de fds o de metodo de pago entro aca
            //SI LA TARJETA ERA DE UN FDS
            if (isFds) {
                this.loadFdsConfig(label); //cargo en current fds la info del que se presiono
                this.openModalById(`#modalIntegrationCardsDeliveryDefault`);//abro el modal default para el servicio de delivery
            }
            //METODO DE PAGO
            else {
                if (label == "Modo") {
                    this.openModalById(`#modalIntegrationCardsNave`);
                }
                if (label == "KamiPay") {
                    this.openModalById(`#modalIntegrationCardsKamiPay`);
                }
                if (label == "SimpleFI") {
                    this.openModalById(`#modalIntegrationCardsSimpleFI`);
                }
                if (label == "MercadoPagoQR") {
                    this.openModalById(`#modalIntegrationCardsMercadoPagoQR`);
                }
                if (label == "MercadoPagoPointPlus") {
                    this.openModalById(`#modalIntegrationCardsMercadoPagoPointPlus`);
                }
            }
        },
        onDefaultIntegrationCardDeliveryModalClosing() { //Cuando llega el evento del modal diciendo que se cerro entro aca
            this.closeModalById(
                `#modalIntegrationCardsDeliveryDefault`
            );
        },
        onNaveIntegrationCardModalClosing() { //Cuando llega el evento del modal diciendo que se cerro entro aca
            this.closeModalById(
                `#ModalIntegrationCardsNave`
            );
        },
        onKamiPayIntegrationCardModalClosing() { //Cuando llega el evento del modal diciendo que se cerro entro aca
            this.closeModalById(
                `#ModalIntegrationCardsKamiPay`
            );
        },
        onSimpleFIIntegrationCardModalClosing() { //Cuando llega el evento del modal diciendo que se cerro entro aca
            this.closeModalById(
                `#ModalIntegrationCardsSimpleFI`
            );
        },
        onMercadoPagoQRIntegrationCardModalClosing() { //Cuando llega el evento del modal diciendo que se cerro entro aca
            this.closeModalById(
                `#ModalIntegrationCardsMercadoPagoQR`
            );
        },
        onMercadoPagoPointPlusIntegrationCardModalClosing() { //Cuando llega el evento del modal diciendo que se cerro entro aca
            this.closeModalById(
                `#ModalIntegrationCardsMercadoPagoPointPlus`
            );
        }
    },
    computed: {
        hasPaymentWays() {
            return this.shop.integratedPaymentWays?.some(paymentWay => paymentWay.hasToShow) || false;
        },
        hasFds() {
            return this.shop.fdsConfigs?.length > 0;
        },
        currentFdsProp() { //Lo uso para poder cambiar dinamicamente lo que le mando al modal, lo unico que hace es devolver el objeto que yo actualizo en el onclick
            return this.currentFds;
        },
    },
    components: {
        ModalIntegrationCardsDeliveryDefault,
        ModalIntegrationCardsNave,
        ModalIntegrationCardsKamiPay,
        ModalIntegrationCardsSimpleFI,
        ModalIntegrationCardsMercadoPagoQR,
        ModalIntegrationCardsMercadoPagoPointPlus
    },
    mixins: [
        modalCommons,
        commons
    ],
}
</script>


<style scoped>
.integration-title {
    margin-top: 30px;
    font-weight: 400;
    font-family: 'Rubik-Regular';
    font-size: 1.5em;
}

.grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    margin: 0;
}

.card {
    background: white;
    border-radius: 20px;
    box-shadow: 5px 5px 12px -1px rgba(110, 110, 110, 0.75);
    padding: 16px;
    text-align: center;
    cursor: pointer;
}

.card:hover {
    background: rgb(245, 245, 245);
}

.card-img {
    margin: 8px 0 16px 0;
    border-radius: 50%;
    border: 1px solid #e1e1e1;
    overflow: hidden;
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.card-title {
    font-weight: bold;
    font-size: 1.5em;
}

.card-text {
    padding-top: 40px;
    font-size: 0.9em;
}

.no-integration-text {
    padding-top: 20px;
}
</style>

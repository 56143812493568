<template>
	<article :class="`badge badge-${type}`">
	</article>
</template>
<script>
export default {
		props: {
			type: {
				type: String,
				default: 'new'
			}
		},
}
</script>

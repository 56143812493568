<template>
  <div>
    <ul
      v-if="
      !showProductListSelectionFilter &&
      !showProviderListSelectionFilter &&
      !elements.hideTabs &&
      !elements.hideSomeTabs
      "
      class="tabs shop-filter"
    >
      <li
        class="tab"
        v-for="tab in tabsFilter"
        :key="tab.title"
        :class="{ active: tabSelected.id === tab.id }"
      >
        <a
          v-if="tab.show"
          @click="changeTab(tab)"
          :class="{ active: tabSelected.id === tab.id }"
          >{{ tab.title }}</a
        >
      </li>
    </ul>

    <ul
      v-if="
      !showProductListSelectionFilter &&
      !showProviderListSelectionFilter &&
      elements.hideSomeTabs
      "
      class="tabs"
    >
      <li
        class="tab"
        v-for="tab in tabsFilter"
        :key="tab.title"
        :class="{ active: tabSelected.id === tab.id }"
      >
        <a
          v-if="tab.show && (tab.id === 4 || tab.id === 5)"
          @click="changeTab(tab)"
          :class="{ active: tabSelected.id === tab.id }"
          >{{ tab.title }}</a
        >
      </li>
    </ul>

    <!-- PROVIDER TABS -->
    <ul
      v-if="
      showProviderListSelectionFilter &&
      !elements.hideTabs &&
      !elements.hideSomeTabs
      "
      class="tabs"
    >
      <li
        class="tab"
        v-for="tab in providerTabs"
        :key="tab.title"
        :class="{ active: providerTabSelected.id === tab.id }"
      >
        <a
          v-if="tab.show"
          @click="changeProviderActionTo(tab.param)"
          :class="{ active: providerTabSelected.id === tab.id }"
          >{{ tab.title }}</a
        >
      </li>
    </ul>

    <!-- STOCK TABS -->
    <ul
      v-if="
      showProductListSelectionFilter &&
      !elements.hideTabs &&
      !elements.hideSomeTabs
      "
      class="tabs"
    >
      <li
        class="tab"
        v-for="tab in getTabs"
        :key="tab.title"
        :class="{ active: stockTabSelected.id === tab.id }"
      >
        <a
          v-if="tab.show"
          @click="changeProductActionTo(tab.param)"
          :class="{ active: stockTabSelected.id === tab.id }"
          >{{ tab.title }}
          <!-- FIXME HZ Aca agregar badge si se precisa-->
          <BistroBadge v-if="tab.badge" :type="tab.badge" />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import mixCommons from "../mixins/mixCommons";
import headerCommons from "../mixins/headerCommons";
import stockV2Commons from "../mixins/stockV2Commons";
import providerCommons from "../mixins/providerCommons";
import rolesCommons from "../mixins/rolesCommons";
import BistroBadge from "../components/BistroBadge";

export default {
  mixins: [
    mixCommons,
    stockV2Commons,
    headerCommons,
    providerCommons,
    rolesCommons,
  ],
  computed: {
    getTabs() {
      const showIngredients = this.$store.getHasToShowIngredientsSection;
      const showCostAndIngredients = this.hasToShowCostAndIngredients;
      const showTab = showIngredients && showCostAndIngredients;
      this.updateTabShowState(showTab);
      this.stockTabs[2].show; // Acceder al valor de `show` para que funcione mostrar o no 'Costos de Mercadería'
      return this.stockTabs;
    },
  },
  methods: {
    updateTabShowState(showTab) {
      if (this.stockTabs[2].show !== showTab) {
        this.stockTabs[2].show = showTab;
      }
    },
  },

  components: {
    BistroBadge,
  },
};
</script>

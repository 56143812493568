<template>
    <div class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="closeModalESC()">
        <div class="modal-header">
            <div class="image-title">
                <h4>¿Confirma cambios de datos fiscales?</h4>
                <p>Fácil y Simple</p>
            </div>

            <div class="btnClose">
                <a href="#!" @click="closeModalESC()"
                    class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>

        <div class="modal-body">
            <form action class="formGeneral">
                <section class="formMain">
                    <div class="compare-fiscal-data">
                        <p>
                            <label>Valores Anteriores</label>

                        <ul>
                            <li v-if="previousFiscalKey !== currentFiscalKey"><strong>RFC:</strong>{{ previousFiscalKey
                                }}
                            </li>
                            <li v-if="previousLegalName !== currentLegalName"><strong>Razón
                                    social:</strong>{{ previousLegalName }}</li>
                            <li v-if="previousZipCode !== currentZipCode"><strong>Código
                                    postal:</strong>{{ previousZipCode }}</li>
                            <li v-if="previousTaxRegime !== currentTaxRegime"><strong>Régimen
                                    fiscal:</strong>{{ previousTaxRegime }}</li>
                        </ul>
                        </p>
                        <p>
                            <label>Valores Nuevos</label>

                        <ul>
                            <li v-if="previousFiscalKey !== currentFiscalKey"><strong>RFC:</strong>{{ currentFiscalKey
                                }}
                            </li>
                            <li v-if="previousLegalName !== currentLegalName"><strong>Razón
                                    social:</strong>{{ currentLegalName }}</li>
                            <li v-if="previousZipCode !== currentZipCode"><strong>Código
                                    postal:</strong>{{ currentZipCode }}</li>
                            <li v-if="previousTaxRegime !== currentTaxRegime"><strong>Régimen
                                    fiscal:</strong>{{ currentTaxRegime }}</li>
                        </ul>
                        </p>
                    </div>

                    <div class="compare-fiscal-data">
                        <article class="btn-main">
                            <Spinner v-if="isLoading" />
                            <a v-else @click="onSavingMyFiscalData()">
                                <p>CONFIRMAR</p>
                            </a>
                        </article>
                    </div>
                </section>
            </form>
        </div>

    </div>
</template>

<script>
import $ from 'jquery'
import axios from 'axios'
import mixCommons from '../../mixins/mixCommons'
import Spinner from '../SpinnerComp'

export default {
    mixins: [mixCommons],
    props: {
        shopCode: {
            type: String,
            default: () => {
                return ''
            }
        },
    },
    data() {
        return {
            profile: {},
            isLoading: false
        }
    },
    watch: {},
    computed: {

    },
    methods: {
        onSavingMyFiscalData() {
            const t = this;
            this.isLoading = true;

            // Clonamos el objeto fiscal para evitar modificar el original directamente
            const selectedFiscalData = { ...this.currentValues.fiscalData };

            // Convertimos zipCode a string si no lo es
            if (selectedFiscalData.zipCode && typeof selectedFiscalData.zipCode !== 'string') {
                selectedFiscalData.zipCode = String(selectedFiscalData.zipCode);
            }

            t.$store.initLoading(t);
            this.profile.action = 'UpdateFiscalData';
            this.profile.shopCode = this.shopCode;
            this.profile.fiscalData = selectedFiscalData;

            axios.post(`/api/users/userProfile`, this.profile)
                .then(res => {
                    if (res.data.responseCode === 0) {
                        this.$store.setUpdateProfileShops(res.data.shops);
                        this.$store.setUpdateOriginalProfileShops(JSON.parse(JSON.stringify(res.data.shops)));
                        t.showSuccess('Modificación realizada exitosamente');
                        this.closeModal();
                    } else {
                        t.showError(res.data.responseMessage);
                    }
                    t.showErrors(res);
                    t.$store.finishLoading(this);
                })
                .catch(() => {
                    t.$store.errorLoading(this);
                    t.showError('Error al guardar los datos fiscales');
                })
                .finally(() => {
                    t.isLoading = false;
                });
        },
        closeModal() {
            $('#__layout').css('pointer-events', 'all')

            this.$emit('onModalClosed')
        },
        closeModalESC() {
            $('#__layout').css('pointer-events', 'all')

            this.$emit('onModalClosedESC')
        }
    },
    components: {
        Spinner
    }
}
</script>

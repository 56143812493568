<template>
    <div class="modal modal-block-outside" style="overflow-y: auto !important;" @keydown.esc="closeModal()">
      <div class="modal-header">
        <div class="image-title">
          <h4>¿Confirma cambios de datos fiscales?</h4>
          <p>Fácil y Simple</p>
        </div>

        <div class="btnClose">
          <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
            <span class="icon-Bistro-_Close"></span>
          </a>
        </div>
      </div>
  
      <div class="modal-body">
        <form class="formGeneral">
          <section class="formMain">
            <div class="compare-fiscal-data">
              <p>
                <label>Valores Anteriores</label>

                <ul>
                  <li v-if="previousFiscalKey !== currentFiscalKey">
                    <strong>NIF:</strong> {{ previousFiscalKey }}
                  </li>
                  <li v-if="previousLegalName !== currentLegalName">
                    <strong>Razón Social:</strong> {{ previousLegalName }}
                  </li>
                  <li v-if="previousLegalAddress !== currentLegalAddress">
                    <strong>Dirección Fiscal:</strong> {{ previousLegalAddress }}
                  </li>
                  <li v-if="previousSimpleBillPos !== currentSimpleBillPos">
                    <strong>Punto de Venta de Factura Simplificada:</strong> {{ previousSimpleBillPos }}
                  </li>
                  <li v-if="previousOrdinaryBillPos !== currentOrdinaryBillPos">
                    <strong>Punto de Venta de Factura Ordinaria:</strong> {{ previousOrdinaryBillPos }}
                  </li>
                </ul>
              </p>
              <p>
                <label>Valores Nuevos</label>

                <ul>
                  <li v-if="previousFiscalKey !== currentFiscalKey">
                    <strong>NIF:</strong> {{ currentFiscalKey }}
                  </li>
                  <li v-if="previousLegalName !== currentLegalName">
                    <strong>Razón Social:</strong> {{ currentLegalName }}
                  </li>
                  <li v-if="previousLegalAddress !== currentLegalAddress">
                    <strong>Dirección Fiscal:</strong> {{ currentLegalAddress }}
                  </li>
                  <li v-if="previousSimpleBillPos !== currentSimpleBillPos">
                    <strong>Punto de Venta de Factura Simplificada:</strong> {{ currentSimpleBillPos }}
                  </li>
                  <li v-if="previousOrdinaryBillPos !== currentOrdinaryBillPos">
                    <strong>Punto de Venta de Factura Ordinaria:</strong> {{ currentOrdinaryBillPos }}
                  </li>
                </ul>
              </p>
            </div>
            <div class="compare-fiscal-data">
              <article class="btn-main">
                <Spinner v-if="isLoading" />
                <a v-else @click="onSavingMyFiscalData">
                  <p>CONFIRMAR</p>
                </a>
              </article>
            </div>
          </section>
        </form>
      </div>

    </div>
  </template>
  
  <script>
import axios from 'axios'
import commons from '@/mixins/mixCommons'
import Spinner from '@/components/SpinnerComp'
  
  export default {
    props: {
      shopCode: String,
      previousFiscalKey: String,
      currentFiscalKey: String,
      previousLegalName: String,
      currentLegalName: String,
      previousLegalAddress: String,
      currentLegalAddress: String,
      previousSimpleBillPos: String,
      currentSimpleBillPos: String,
      previousOrdinaryBillPos: String,
      currentOrdinaryBillPos: String,
    },
    data() {
      return {
        isLoading: false,
      };
    },
    mixins: [commons],
    methods: {
      onSavingMyFiscalData() {
        this.isLoading = true;
        const profile = {
          action: "UpdateFiscalData",
          shopCode: this.shopCode,
          fiscalData: {
            fiscalKey: this.currentFiscalKey,
            legalName: this.currentLegalName,
            legalAddress: this.currentLegalAddress,
            simpleBillPos: this.currentSimpleBillPos,
            ordinaryBillPos: this.currentOrdinaryBillPos,
          },
        };
  
        axios
          .post("/api/users/userProfile", profile)
          .then((res) => {
            if (res.data.responseCode === 0) {
              this.$store.setUpdateProfileShops(res.data.shops);
              this.$store.setUpdateOriginalProfileShops(JSON.parse(JSON.stringify(res.data.shops)));
              this.showSuccess("Modificación realizada exitosamente");
              this.closeModal();
            } else {
              this.showError(res.data.responseMessage);
            }
          })
          .catch(() => {
            this.showError("Error al guardar los datos fiscales");
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      closeModal() {
        
      },
    },
    components: {
      Spinner,
    },
  };
  </script>
  
<script>
import router from "@/router";
import shopCommons from "./shopCommons";
import { updateOrder, deleteOrder, getActiveOrders } from "../repositories/orders/ordersService.js";
import { getTotalAmountFromProduct, getTotalNetAmountFromProduct } from "../repositories/products/productsService.js";

export default {
    mixins: [shopCommons],
    data() {
        return {
            clickTimer: null,
            isDoubleClick: false,
            showDeleteOrderModal: false
        };
    },
    watch: {
        order: {
            deep: true,
            async handler() {
                if (this.shopCode && this.order) {
                    this.calculateAmounts();
                    await updateOrder(this.shopCode, this.order);
                    this.orders = await getActiveOrders(this.shopCode);
                }
            }
        },
    },
    methods: {
        handleDelete() {
            this.showDeleteOrderModal = true
        },
        handleMsgBistroClick(value) {
            this.order.comment = value;
        },
        deleteOrder() {
            this.handleDeleteOrder(this.$pdv.getOrder)
        },

        async handleDeleteOrder(id) {
            if (id) await deleteOrder(this.shopCode, id);
            else await deleteOrder(this.shopCode, this.order);
            this.$pdv.setShowDetails(false)
            this.$pdv.setOrder(null)
            this.orders = await getActiveOrders(this.shopCode);
            router.push("/pdv/orders/");
        },
        calculateAmounts() {
            let amount = 0; let netAmount = 0;
            this.order.products.forEach(prod => {

                let total = getTotalAmountFromProduct(prod)

                amount += total;
                netAmount += getTotalNetAmountFromProduct(prod);
            });

            this.order.amount = amount;
            this.order.netAmount = netAmount;
            this.order.vatAmount = amount - netAmount;
        },
        handleSearchProduct(value) {
            this.searchProductsValue = value.toLowerCase();
        },
        handleComeBackClick() {
            this.$pdv.setShowDetails(false)
            router.push("/pdv/orders");
        },
        handleRemoveProduct(index) {
            this.order.products.splice(index, 1);
            this.$emit('select-product', null)
        },
        handleChangeProdQuantity(index, quantityToAdd) {
            this.order.products[index].quantity += quantityToAdd;
            if (quantityToAdd > 0 || this.order.products[index].quantityToPrintInPartialTicket > 0) {
                this.order.products[index].quantityToPrintInPartialTicket += quantityToAdd;
            }
        },
        handleProductSelection(index, prod) {
            if (!this.selectedProduct || this.selectedProduct.index != index) {
                this.$emit('select-product', { index: index, prod: prod, input: "" });
            } else {
                this.$emit('select-product', null);
            }
        },
        onHandleClick(callback) {
            this.clickTimer = setTimeout(() => {
                if (!this.isDoubleClick) {
                    callback();
                }
            }, 200);
        },
        onHandleDoubleClick(callback) {
            clearTimeout(this.clickTimer);
            this.isDoubleClick = true;
            callback();

            setTimeout(() => {
                this.isDoubleClick = false;
            }, 500);
        }
    }
};
</script>
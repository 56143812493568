<script>
export default {
    watch: {
        configHours() {
            this.config = this.configHours;
        },
    },
    computed: {
        getConfig() {
            return this.config;
        },
    },
    methods: {
        getDayLabelByKey(key) {
            switch (key) {
                case 1: return "Lunes"
                case 2: return "Martes"
                case 3: return "Miércoles"
                case 4: return "Jueves"
                case 5: return "Viernes"
                case 6: return "Sábado"
                case 0: return "Domingo"
            }
        },
        addHour(hourByDayIndex) {
            this.config.hoursByDay[hourByDayIndex].hours.push({ from: "", to: "" });
        },
        deleteHour(hourByDayIndex, hourIndex) {
            this.config.hoursByDay[hourByDayIndex].hours.splice(hourIndex, 1);

            if (this.config.hoursByDay[hourByDayIndex].hours.length === 0) {
                this.addDefaultHour(hourByDayIndex);
                this.modifyDayStatus(hourByDayIndex, false);
            }
        },
        addDefaultHour(hourByDayIndex) {
            this.config.hoursByDay[hourByDayIndex].hours.push({ from: "--:--", to: "--:--" });
        },
        modifyDayStatus(hourByDayIndex, isOpen) {
            this.config.hoursByDay[hourByDayIndex].isOpen = isOpen;
        },
    }
}
</script>
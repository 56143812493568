<template>
    <div style="display: inline-flex">
        <div class="switch">
            <label style="display: inline-flex">
                <input :checked="value" @change="changeValue()" type="checkbox" :disabled="disabled" />
                <span class="lever lever-width"></span>
            </label>
        </div>
        <p>{{ label }}</p>
    </div>
</template>

<script>
import mixCommons from "../mixins/mixCommons";
export default {
    mixins: [mixCommons],
    name: 'switchBistro',
    props: {
        value: {
            type: Boolean,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false 
        }
    },
    methods: {
        changeValue() {
            this.avoidRectangleAtTheEnd();
            this.$emit('changeValue')
        }
    }
}
</script>

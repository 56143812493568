<template>
    <main class="pdv-main">
        <SidebarBistro>
            <div id="header_sidebar">
                <ButtonBistro variant="solid-gray" text="Regresar" :icon="require('@assets/arrow-left-button.svg')"
                    @handleClick="backClick" />
            </div>
            <div id="body_sidebar">
                <!-- /* PAGO PARCIAL */ -->
                <!-- <div v-if="partialPayment" class="box-partial-pay">
                        <p>Selecciona los ítems a cobrar</p>
                        <hr>
                        <div v-for="(prod, index) in order.products" :key="index" class="articleDebt-main-row">
                            <div class="product-info">
                                <input type="checkbox" />
                                <p>{{ prod.name }}</p>
                                <p class="product-price">
                                    {{ $filters.currency(Number(prod.originPrice) * prod.quantity) }}
                                </p>
                            </div>
                        </div>
                    </div> -->
                <!-- Checkout normal -->
                <div class="contain-button-checkout">
                    <ButtonPayBistro text="Efectivo" :isActive="isPaymentMethodActive('EFECTIVO')"
                        :icon="require('@assets/img/ic_efectivo.png')" @click="selectPaymentMethodAndPay(
                            {
                                paymentWay: 'EFECTIVO',
                                captureWay: null,
                                authCode: null,
                                amount: this.order.amount,
                                receivedPaidAmount: this.order.amount,

                            })" />
                    <ButtonPayBistro text="Tarjeta" :isActive="isPaymentMethodActive('TARJETA')"
                        :icon="require('@assets/img/ic_tarjeta.png')"
                        @handleClick="selectPaymentMethodAndPay({ paymentWay: 'TARJETA', captureWay: 'POS EXTERNO', authCode: null, amount: this.order.amount, receivedPaidAmount: this.order.amount })" />
                    <ButtonPayBistro text="Online" :isActive="isPaymentMethodActive('ONLINE')"
                        :icon="require('@assets/img/ic_online.png')"
                        @handleClick="selectPaymentMethodAndPay({ paymentWay: 'ONLINE', captureWay: null, authCode: null, amount: this.order.amount, receivedPaidAmount: this.order.amount })" />
                    <ButtonPayBistro text="QR" :isActive="isPaymentMethodActive('QR')"
                        :icon="require('@assets/img/ic_qrcode.png')"
                        @handleClick="selectPaymentMethodAndPay({ paymentWay: 'QR', captureWay: null, authCode: null, amount: this.order.amount, receivedPaidAmount: this.order.amount })" />
                </div>
            </div>
        </SidebarBistro>

        <!-- Panel lateral de teclado numérico -->
        <ContentBistro>
            <div id="body_content_bistro">
                <div id="container_keyboard_sidebar">
                    <NumKeyboardBistro v-if="order?.amount" @handleChangeKeyboardInput="handleChangeKeyboardInput"
                        :initialValue="order.amount" />
                    <div id="container_buttons_sidebar">
                        <!-- <ButtonBistro variant="gradient-blue" text="Descuento"
                            @handleClick="handleChangeKeyboardButton(3)" /> -->
                        <!-- <ButtonBistro variant="solid-light-gray" text="Propina"
                            @handleClick="handleChangeKeyboardButton(2)" /> -->
                    </div>
                </div>
            </div>
        </ContentBistro>
        <ModalOrderPayChange :order="this.order" />
    </main>
</template>

<script>
import router from "@/router";
import shopCommons from "../mixins/shopCommons.vue";
import SidebarBistro from "../components/SidebarBistro.vue";
import ContentBistro from "../components/ContentBistro.vue";
import NumKeyboardBistro from "../components/NumKeyboardBistro.vue";
import ButtonBistro from "../components/ButtonBistro.vue";
import ButtonPayBistro from "../components/ButtonPayBistro.vue";
import commons from "@/mixins/mixCommons";
import rolesCommons from "@/mixins/rolesCommons";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import axios from "axios";
import ModalOrderPayChange from "../components/modals/ModalOrderPayChange.vue";
import { getOrder } from "../repositories/orders/ordersService.js";
import clickCommons from "../mixins/clickCommons.vue";
import payCommons from "../mixins/payCommons.vue";


export default {
    name: "PdvOrderCheckout",
    mixins: [shopCommons, commons, rolesCommons, bistroV2Commons, clickCommons, payCommons],
    data() {
        return {
            order: null,
            PaymentMethod: null,
        };
    },
    async mounted() {
        document.title = `${this.appTitle}  - PDV`;
        if (!this.isUserLoggedIn()) {
            axios.get("/api/auth/logout").then(() => {
                this.$store.SET_USER(null);
                this.resetUser();
                this.setAutomaticLogin(false);
                location.href = "/login";
            });
        } else if (!this.hasToShowSettingsPosTab) {
            this.handleUnauthorizedAccess()
        } else {
            this.onMounted();
        }
    },
    methods: {
        async onMounted() {
            this.$store.updateTopMenu(7);
            this.toggleMerchantSelectorByLabel("showPDVListSelectionFilter");
            this.$store.hideElements({
                hideCalendar: true,
                hideFilters: false,
                hideTabs: true,
            });
            const shopCode = this.$route.params['shopcode'];
            const orderId = this.$route.params['id'];
            this.$store.setRefreshPDVList(shopCode);
            this.order = await getOrder(shopCode, orderId);
            this.order.payments = [];
            this.$pdv.setShowDetails(true);
            this.$pdv.setOrder(this.order);
            this.$pdv.setIsExactPayment(true);


        },
        openModalCreditsCards() {
            /* eslint-disable */
            const modalInstance = document.getElementById('modalCreditsCards');
            const instance = M.Modal.init(modalInstance, {
                dismissible: false,
            });
            instance.open();
            /* eslint-enable */
        },
        isPaymentMethodActive(method) {
            return this.order?.payments?.some(payment => payment.paymentWay == method) ?? false;
        },
        backClick() {
            router.push("/pdv/order/details/" + this.shopCode + "/" + this.order.id);
        },

        onSelectedProduct(index) {
            console.log(index)
        },
        handleChangeKeyboardInput(receivedPaidAmount) {
            this.$pdv.setTempReceivedAmount(receivedPaidAmount)
 
            if (this.order.amount != this.$pdv.getTempReceivedAmount) {
                this.$pdv.setIsExactPayment(false);
            } else {
                this.$pdv.setIsExactPayment(true);
            }
        }
    },
    components: {
        SidebarBistro,
        ContentBistro,
        ButtonBistro,
        NumKeyboardBistro,
        ButtonPayBistro,
        ModalOrderPayChange
    },
};
</script>

<style lang="scss" scoped>
.contain-button-checkout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}
</style>

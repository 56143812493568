<script>
import axios from 'axios'
import { parseToQueryEncoded } from "../utils/utils";

export default {
	data() {
		return {
			itemCost: null,
		}
	},
	computed: {},
	methods: {
		handleCostModal(shopCode, sku, name, department, isWeighable, measureUnit, productType) {
			this.clearItemCost()
			this.params = []
			this.params['shopCode'] = shopCode
			this.params['sku'] = sku
			this.params['name'] = name
			this.params['department'] = department
			this.params['isWeighable'] = isWeighable
			this.params['type'] = productType

			axios.get(`/api/costV2/?${parseToQueryEncoded(this.params)}`)
				.then(res => {
					this.itemCost = res.data
					this.itemCost.measureUnit = measureUnit
					this.itemCost.productType = productType
					this.$store.setCurrentCost(this.itemCost);

					this.openModalById('#modalCost')
				})
				.catch(err => {
					console.error(err)
				})
		},
		clearItemCost() {
			this.itemCost = null
		},
		goToMenuEngineeringPage() {
			window.location.href = '/MenuEngineering'
		}
	}
}
</script>

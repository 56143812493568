<script>
	export default {
		computed: {
			isMobile () {
				return this.$store.getMobile;
			}
		},
		
	}
</script>

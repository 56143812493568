<template>
	<div id="modalCustomer" class="modal">
		<div class="modal-header">
			<h4>Resumen del Cliente</h4>
			<p>Fácil y Simple</p>

			<div class="btnClose">
				<a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat">
					<span class="icon-Bistro-_Close"></span>
				</a>
			</div>
		</div>

		<div class="modal-body modalStock">

			<article class="cardClient">
				<section class="itemUser">
					<figure>
						<span class="icon-Bistro-_Smile"></span>
					</figure>
					<div class="item">
						<h3>{{ customer.name }} {{ customer.lastName }}</h3>
						<p>{{ customer.phone }}</p>
						<p><strong>Dirección</strong></p>
						<p>{{ customer.address }}</p>
					</div>
				</section>

				<section class="record">


					<div class="item">
						<h4><currency-style :valor="customer.debt" /></h4>						<p>Deuda</p>
					</div>

				</section>
			</article>

			<article class="transactions">
				<h4>Transacciones</h4>

				<ul class="mainEvents-history">

					<li :class="{ 'listCollapsible': transactions.length > 1 }" v-for="transaction in transactions"
						:key="transaction.id">
						<figure class="iconHistory">
							<span
								:class="{ 'salesNull': transaction.status === 'VOID', 'icon-Bistro-_Check': true }"></span>
						</figure>

						<div class="items">
							<h5>{{ formatDate(transaction.saleDate, 'date') }}</h5>
							<p>{{ formatDate(transaction.saleDate, 'time') }}</p>
						</div>

						<div class="items">
							<p v-if="transaction.saleDetails.length === 1">
								<strong>{{ transaction.saleDetails[0].quantity }}</strong>{{
									transaction.saleDetails[0].description }}
							</p>
							<div v-else-if="transaction.saleDetails.length > 1"
								:class="{ 'bodyCollapsible-active': true, 'bodyCollapsible': true }">
								<p v-for="(sale, index) in transaction.saleDetails" :key="'sale' + index">
									<strong>{{ sale.quantity }}</strong>{{ sale.description }}
								</p>
							</div>
						</div>

						<div class="items">
							<p>{{ transaction.paymentInfo }}</p>

						</div>

						<div class="items">
							<h3><currency-style :valor="transaction.amount" /></h3>
						</div>
					</li>
				</ul>
			</article>
		</div>
	</div>
</template>

<script>

export default {
	props: {
		customer: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	data() {
		return {
			customerInfo: {
				transactions: []
			}
		}
	},
	computed: {
		transactions() {
			return this.$store.getModalCustomerTransactions
		}
	},
	methods: {
		formatDate(date, formatType) {
			const options = formatType === 'date'
				? { year: 'numeric', month: '2-digit', day: '2-digit' }
				: { hour: '2-digit', minute: '2-digit', second: '2-digit' };
			return new Date(date).toLocaleDateString('es-ES', options);
		}
	}


}
</script>
